import { App } from 'emi-frontend';

import Header from './Header';

import JobList from '../job/List';
import Job from '../job/JobForm';
import ClosedList from '../job/ClosedList';
import FileGrid from '../job/FileGrid';

import EsearchList from '../esearch/List';

import ProjectGroupList from '../project_group/List';
import ProjectGroupForm from '../project_group/Form';

import ClientList from '../client/List';
import ClientForm from '../client/ClientForm';
import Contact from '../client/Contact';

import PartnerList from '../partner/List';
import PartnerForm from '../partner/Form';

import UserList from '../user/List';
import UserForm from '../user/Form';

import InvoiceList from '../invoice/List';
import InvoiceForm from '../invoice/Form';
import MultiViewForm from '../multiview/Form';

import CoverageList from '../coverage/List';

import AttributeList from '../attribute/List';
import AttributeForm from '../attribute/Form';

//import FeasibilityList from '../panel_api/feasibility/List';
//import FeasibilityForm from '../panel_api/feasibility/Form';

import ConnectorPage from '../connector/Page';

import AdminPage from '../admin/AdminPage';
import Assignment from '../admin/Assignment';

//import SalesforcePage from '../salesforce';

import TagList from '../tag/List';

export class AdminApp extends App {
  constructor(props) {
    super(props);

    // Define routes for the app
    this.routes = [
      { path: '/jobs/closed', name: 'JobsClosed', component: ClosedList },
      { path: '/jobs/files', name: 'JobFiles', component: FileGrid },
      { path: '/jobs/:id', name: 'Job', component: Job },
      { path: '/jobs', name: 'Jobs', component: JobList },

      { path: '/esearch', name: 'Esearch', component: EsearchList },

      { path: '/groups/:id', name: 'Groups', component: ProjectGroupForm },
      { path: '/groups', name: 'Groups', component: ProjectGroupList },

      { path: '/clients/:id/contact/:cid', name: 'Contact', component: Contact },
      { path: ['/clients/:id/feedback/:fid', '/clients/:id'], name: 'Client', component: ClientForm },
      { path: '/clients', name: 'Clients', component: ClientList },

      { path: ['/partners/:id/feedback/:fid', '/partners/:id'], name: 'Partner', component: PartnerForm },
      { path: '/partners', name: 'Partners', component: PartnerList },

      { path: '/users/:id', name: 'User', component: UserForm },
      { path: '/users', name: 'Users', component: UserList },

      { path: '/invoices/:id', name: 'Invoice', component: InvoiceForm },
      { path: '/invoices', name: 'Invoices', component: InvoiceList },
      { path: '/multi/:id', name: 'Multi View', component: MultiViewForm },

      { path: '/coverage/:id', name: 'Coverage', component: Job },
      { path: '/coverage', name: 'Coverages', component: CoverageList },

      { path: '/attributes/:id', name: 'Attribute', component: AttributeForm },
      { path: '/attributes', name: 'Attributes', component: AttributeList },

      //{ path: '/feasibility/:id', name: 'Universal', component: FeasibilityForm },
      //{ path: '/Feasibility', name: 'Universals', component: FeasibilityList },

      { path: '/connector', name: 'Connector', component: ConnectorPage },

      //{ path: '/salesforce', name: 'Connector', component: SalesforcePage },

      { path: '/tags', name: 'Tags', component: TagList },

      { path: '/admin/assignment/:id', name: 'Assignment', component: Assignment },
      { path: '/admin', name: 'Admin', component: AdminPage }
    ];

    // Define top menus for the app
    this.topMenu = {
      Jobs: '/jobs',
      Groups: '/groups',
      Partners: '/partners',
      Clients: '/clients',
      Users: '/users',
      Invoices: '/invoices',
      Attributes: '/attributes',
      'Coverage requested': '/coverage',
      //Feasibility: '/feasibility',
      Connector: '/connector',
      //'SF sync': '/salesforce',
      'Targeting Library': '/tags',
      Admin: '/admin'
    };

    // Redirect to this path after login
    this.defaultPath = '/jobs';

    Object.assign(this.components, { Header });
  }
}
