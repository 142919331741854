import React from 'react';
import PubSub from 'pubsub-js';
import { 
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import ContactForm from '../client/ContactForm';

export default class ContactModal extends ContactForm {

  getFormHeader() {
    return null;
  }

  getDefaultValues() {
    return {
      first_name: '',
      last_name: '',
      email: '',
      contact_type: 'project',
      client_id: ''
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.values.client_id !== this.state.values.client_id) {
      this.resetForm(nextProps);
    }
  }

  successAction = (response) => {
    this.props.toggle();

    this.resetForm(this.props);

    PubSub.publish(`contact.new.${response.data.contact_type}`, response.data);
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle}>
            <span className="text-uppercase">New Contact</span>
        </ModalHeader>
        <ModalBody>
          {super.render()}
        </ModalBody>
      </Modal>
    );
  }
}
