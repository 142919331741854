import { ProdegeCountrySelect } from '../prodege';

export default class CategorySelect extends ProdegeCountrySelect {
  
  getApiPath() {
    return '/panelapi/researchnow/categories';
  }

  getOptionsFromArray() {
    return this.state.data.map((row) => {
      return { value: row.topic, label: row.description };
    });
  }
}
