import React from 'react';
import PropTypes from 'prop-types';
import { LoadServerData } from '../abstract';
import Quotas from './Quotas';

export default class QuotasPerformance extends LoadServerData {
  constructor(props) {
    super(props, 'performance');

    if (!QuotasPerformance.hasQuotas(props)) {
      this.state.loadingData = false;
    }
  }

  static propTypes = {
    routeId: PropTypes.number.isRequired,
    partnerId: PropTypes.number.isRequired,
    partnerProjectId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    quotas: PropTypes.object,
    onUpdate: PropTypes.func.isRequired
  };

  static hasQuotas(props) {
    return Object.keys(props.quotas).length > 0;
  }

  shouldLoadData() {
    return QuotasPerformance.hasQuotas(this.props);
  }

  getApiUrl() {
    return `/panelapi/${this.props.routeId}/quota/stats`;
  }

  getApiMethod() {
    return 'POST';
  }

  getApiData() {
    return {
      ps_survey_id: this.props.partnerProjectId
    };
  }

  assingPerformanceData() {
    const { quotas } = this.props;
    if (this.shouldLoadData() && this.state.loadingData === false && Array.isArray(this.state.performance)) {
      this.state.performance.forEach(stat => {
        if (quotas[stat.buyer_quota_id]) {
          Object.assign(quotas[stat.buyer_quota_id], {
            completes_fielded: stat.completes_fielded,
            valid_clicks: stat.valid_clicks
          });
        }
      });
    }

    return quotas;
  }

  renderContent() {
    return (
      <Quotas
        {...this.props}
        quotas={this.assingPerformanceData()}
      />
    );
  }
}
