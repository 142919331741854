import { RemoteSelect } from 'emi-frontend';

class CountrySelect extends RemoteSelect {

  getApiPath() {
    return `/panelapi/${this.props.partnerId}/countries`;
  }

  composeProps() {
    const { onValueChange, textOnly, partnerId, ...props } = this.props;
    const options = this.getOptions();

    return Object.assign(props, { options });
  }

  getOptionsFromArray() {
    return this.state.data.map((row) => {
      return { value: row.country_id, label: row.country_name };
    });
  }

  getSelectedOptionText() {
    return this.getArrayOptionText();
  }
}

export default CountrySelect;
