import { AbstractQuotas } from '../abstract';
import Quota from './Quota';

export default class Quotas extends AbstractQuotas {

  static defaultProps = {
    countryId: 0
  };

  setQuotaComponent() {
    this.components = { Quota };
  }

  getDefaultValues() {
    return {
      ps_survey_id: this.props.partnerProjectId
    };
  }
}