import React from 'react';
import { ListGroup } from 'reactstrap';
import { FormDisabledRow } from 'emi-frontend';
import TargetCriteria from './TargetCriteria';
import CountrySelect from './CountrySelect';
import { AbstractFeasibility } from '../abstract';

export default class Feasibility extends AbstractFeasibility {

  getDefaultValues() {
    return {
      targeting_criteria: [],
      country_id: '',
      loi: '',
      expected_incidence_rate: '',
      completes_estimate: null,
      cpi: 0
    };
  }

  getTargetingCriteria() {
    const targeting_criteria = this.state.values.targeting_criteria.slice();
    return { targeting_criteria };
  }

  getFormData() {
    const { targeting_criteria, country_id, last_login_in_days, expected_incidence_rate, loi } = this.state.values;

    return {
      targeting_criteria,
      country_id,
      last_login_in_days,
      expected_incidence_rate,
      loi
    };
  }

  valueChanged(name, value) {
    const changed = super.valueChanged(name, value);

    if (name === 'country_id') {
      changed.targeting_criteria = [];
      changed.completes_estimate = null;
    }

    if (name === 'targeting_criteria') {
      changed.completes_estimate = null;
    }

    return changed;
  }

  showButton() {
    return Array.isArray(this.state.values.targeting_criteria) &&
      this.state.values.targeting_criteria.length > 0;
  }

  getCardBodyContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            label: 'Country',
            name: 'country_id',
            required: this.isRequired(),
            component: CountrySelect,
            partnerId: this.props.partnerId,
            isClearable: false,
            onChange: this.countryChangeHandler,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'ir',
            name: 'expected_incidence_rate',
            type: 'number',
            min: 0,
            max: 100,
            step: 1,
            postfix: '%',
            cssClass: 'fixwidth',
            style: { width: 100 },
            fieldWidth: { xs: 12, sm: 3 },
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 9 }
          })}

          {this.getFormRow({
            label: 'loi',
            name: 'loi',
            type: 'number',
            min: 1,
            step: 1,
            postfix: 'min',
            cssClass: 'fixwidth',
            style: { width: 100 },
            fieldWidth: { xs: 12, sm: 3 },
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 9 }
          })}
        </div>

        {this.showTargetCriteria() &&
          <div className="form-row">
            <ListGroup style={{ width: '100%' }}>
              <TargetCriteria
                questions={this.state.questions}
                onAdd={this.addTargetCriteria}
                onDelete={this.removeTargetCriteria}
                onChange={this.modifyTargetCriteria}
                allowCreate
              />
              {this.state.values.targeting_criteria.map((criteria, idx) =>
                <TargetCriteria
                  idx={idx}
                  key={idx}
                  criteria={criteria}
                  questions={this.state.questions}
                  onAdd={this.addTargetCriteria}
                  onDelete={this.removeTargetCriteria}
                  onChange={this.modifyTargetCriteria}
                  isDeletable
                />
              )}
            </ListGroup>
          </div>
        }

        {this.state.values.completes_estimate !== null &&
          <div>
            <div className="form-row">
              <FormDisabledRow
                  label="Available respondents"
                  name="completes_estimate"
                  value={this.state.values.completes_estimate}
                  fieldWidth={ { xs: 6, sm: 6} }
                  labelWidth={ {xs: 6, sm: 6} }
                  valueWidth={ {xs: 6, sm: 6} }
              />
            </div>
            <div className="form-row">
              <FormDisabledRow
                  label="CPI"
                  name="cpi"
                  value={`$${this.state.values.cpi}`}
                  fieldWidth={ { xs: 6, sm: 6} }
                  labelWidth={ {xs: 6, sm: 6} }
                  valueWidth={ {xs: 6, sm: 6} }
              />
            </div>
          </div>
        }
      </div>
    );
  }
}
