import { ProdegeCountrySelect } from '../prodege';

export default class LanguageSelect extends ProdegeCountrySelect {

  getOptionsFromArray() {
    if (!this.props.countryISOCode) {
      return [];
    }

    const country = this.state.data.find(row => row.isoCode === this.props.countryISOCode);
    return country === undefined ? [] : country.supportedLanguages.map(lang => ({
      value: lang.isoCode, label: lang.languageName
    }));
  }
}
