import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import { Filter, Grid } from 'emi-frontend';

export default class TagFilter extends Filter {

  constructor(props) {
    super(props);

    this.state.searchTitle = 'Search by Tag Name, Classification';
    Object.assign(this.state, TagFilter.clientsState());
  }

  static clientsState() {
    const gridState = Grid.getGridState('tagGrid', { storeState: true });
    const { classification = 'all' } = gridState.extraParams || {};
    return { classification };
  }

  get filterName() {
    return 'tagFilter';
  }

  initialize() {
    this.setFilter('classification', 'all');
  }

  getLeft() {
    return (
      <div className={this.getLeftClass()}>
        <ButtonGroup className="btn-block">
          <Button color="default" name="classification" className={this.getButtonClass('classification', 'all')} onClick={this.onFilterButton}>ALL</Button>{' '}
          <Button color="default" name="classification" value="B2B" className={this.getButtonClass('classification', 'B2B')} onClick={this.onFilterButton}>B2B</Button>{' '}
          <Button color="default" name="classification" value="Consumer" className={this.getButtonClass('classification', 'Consumer')} onClick={this.onFilterButton}>Consumer</Button>{' '}
          <Button color="default" name="classification" value="Healthcare" className={this.getButtonClass('classification', 'Healthcare')} onClick={this.onFilterButton}>Healthcare</Button>{' '}
          <Button color="default" name="classification" value="Ailments" className={this.getButtonClass('classification', 'Ailments')} onClick={this.onFilterButton}>Ailments</Button>
        </ButtonGroup>
      </div>
    );
  }

  getLeftClass() {
    return 'col-12 col-md-5 col-lg-4 mb-1';
  }

  onFilterButton = (event) => {
    const { name, value } = event.target;
    this.setFilter(name, value || 'all', value || undefined);
  }

  setFilter(prop, stateValue, filterValue) {
    this.setState({
      [prop]: stateValue
    });
    this.props.onFilter({
      [prop]: filterValue
    });
  }

  getButtonClass(prop, button) {
    return this.state[prop] === button ? 'selected' : '';
  }
}
