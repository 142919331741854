import React, { Component } from 'react';
import InvoiceFilter from './Filter';
import InvoiceGrid from './Grid';
import QuickbooksGrid from './QuickbooksGrid';
import { filterList } from 'emi-frontend';

const InvoiceFilterList = filterList(
  InvoiceFilter,
  [InvoiceGrid, QuickbooksGrid]
);

class List extends Component {

  render() {
    return (
      <div className="row">
        <div className="col">
          <InvoiceFilterList />
        </div>
      </div>
    );
  }
}

export default List;
