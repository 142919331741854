import React from 'react';
import { ListGroup } from 'reactstrap';
import { FormDisabledRow, CreatableReactSelect } from 'emi-frontend';
import FeasibilityTargetCriteria from './FeasibilityTargetCriteria';
import CountryLanguageSelect from './CountryLanguageSelect';
import { AbstractFeasibility } from '../abstract';

export default class Feasibility extends AbstractFeasibility {

  getDefaultValues() {
    return {
      loi: '',
      ir: '',
      dif: 0,
      age: [],
      //zipcode: [],
      completesRequired: 0,
      languageId: '',
      quals: []
    };
  }

  languageChangeHandler = (option) => {
    const changed = this.valueChanged('languageId', option ? option.value : '')
    this.updateValues(changed);

    this.loadQuestionsData(changed.languageId);
  }

  getTargetingCriteria() {
    const quals = this.state.values.quals.slice();
    return { quals };
  }

  getFormData() {
    const { loi, ir, dif, age, completesRequired, languageId, quals } = this.state.values;

    return {
      loi, ir, dif, age, completesRequired, languageId, quals
    };
  }

  valueChanged(name, value) {
    const changed = super.valueChanged(name, value);

    if (name === 'languageId') {
      changed.quals = [];
    }

    changed.feasibility = undefined;
    changed.cpi = undefined; 

    return changed;
  }

  successAction = (response) => {
    const { cpi, completes: feasibility } = response.data;
    this.updateValues({ cpi, feasibility });
  }

  showTargetCriteria() {
    return this.parseInt(this.state.values.languageId) > 0 && this.state.questions !== null;
  }

  showButton() {
    return this.state.values.loi > 0 && this.state.values.ir > 0
      && this.state.values.dif > 0 && this.state.values.completesRequired > 0
      && this.state.values.languageId > 0;
  }

  getCardBodyContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            label: 'language',
            name: 'languageId',
            required: true,
            component: CountryLanguageSelect,
            partnerId: this.props.partnerId,
            isClearable: false,
            onChange: this.languageChangeHandler,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            name: 'ir',
            type: 'number',
            min: 0,
            max: 100,
            step: 1,
            postfix: '%',
            cssClass: 'fixwidth',
            required: true,
            style: { width: 100 },
            fieldWidth: { xs: 12, sm: 3 },
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 9 }
          })}

          {this.getFormRow({
            name: 'loi',
            type: 'number',
            min: 1,
            step: 1,
            postfix: 'min',
            cssClass: 'fixwidth',
            required: true,
            style: { width: 100 },
            fieldWidth: { xs: 12, sm: 3 },
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'days in field',
            name: 'dif',
            required: true,
            type: 'number',
            min: 1,
            step: 1,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 6 }
          })}

          {this.getFormRow({
            label: 'completes required',
            name: 'completesRequired',
            type: 'number',
            min: 1,
            step: 1,
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'age',
            component: CreatableReactSelect,
            options: (this.state.values.age || []).map(value => ({ value, label: value })),
            value: this.state.values.age,
            onChange: this.selectChangeHandler('age'),
            isMulti: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 6 }
          })}

          {/*this.getFormRow({
            name: 'zipcode',
            component: CreatableReactSelect,
            options: (this.state.values.zipcode || []).map(value => ({ value, label: value })),
            value: this.state.values.zipcode,
            onChange: this.selectChangeHandler('zipcode'),
            isMulti: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })*/}
        </div>

        {this.showTargetCriteria() &&
          <div className="form-row">
            <ListGroup style={{ width: '100%' }}>
              <FeasibilityTargetCriteria
                questions={this.state.questions}
                onAdd={this.addTargetCriteria}
                onDelete={this.removeTargetCriteria}
                onChange={this.modifyTargetCriteria}
                identifier="q_Name"
                allowCreate
              />
              {this.state.values.quals.map((criteria, idx) =>
                <FeasibilityTargetCriteria
                  idx={idx}
                  key={idx}
                  criteria={criteria}
                  questions={this.state.questions}
                  onAdd={this.addTargetCriteria}
                  onDelete={this.removeTargetCriteria}
                  onChange={this.modifyTargetCriteria}
                  identifier="q_Name"
                  isDeletable
                />
              )}
            </ListGroup>
          </div>
        }

        {this.state.values.feasibility !== undefined &&
          <div>
            <div className="form-row">
              <FormDisabledRow
                label="Available respondents"
                name="completes"
                value={this.state.values.feasibility}
                fieldWidth={ { xs: 6, sm: 6} }
                labelWidth={ {xs: 6, sm: 6} }
                valueWidth={ {xs: 6, sm: 6} }
              />
            </div>
            <div className="form-row">
              <FormDisabledRow
                label="cpi"
                name="cpi"
                value={`$${this.state.values.cpi}`}
                fieldWidth={ { xs: 6, sm: 6} }
                labelWidth={ {xs: 6, sm: 6} }
                valueWidth={ {xs: 6, sm: 6} }
              />
            </div>
          </div>
        }
      </div>
    );
  }
}
