import React from 'react';
import { 
  Modal,
  ModalHeader,
  ModalBody,
  CardFooter
} from 'reactstrap';
import ClientCodeNotificationLimitForm from './ClientCodeNotificationLimitForm';
import ClientCodeDownload from './ClientCodeDownload';
import { client, AbstractForm, FormDisabledRow } from 'emi-frontend';

class ClientCodeForm extends AbstractForm {


  constructor(props) {
    super(props);

    this.state.total = 0;
    this.state.used = 0;
  }

  get modelName() {
    return 'client_code';
  }

  getDefaultValues() {
    return {
      csv_file: ''
    };
  }

  getApiPath = () => {
    return '/project';
  }

  getId = () => {
    return this.parseInt(this.props.id);
  }

  loadFormData(id) {
    this.setState({
      loadingData: true
    }, () =>
      client.api('GET', `${this.getApiPath()}/${id}/client_code_stat`)
        .done((resp) => this.loadDone(resp))
        .fail(this.loadFail)
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const id = this.parseInt(nextProps.id);
    if (id && id !== this.getId()) {
      this.loadFormData(id);
    }
  }

  loadDone = (response, state = {}, cb) => {
    const { total, used } = response.data;
    this.setState(Object.assign({
      loadingData: false,
      total,
      used
    }, state), cb);
  }

  sendFormData = () => {
    const fd = new FormData();

    if (this.upload) {
      const files = this.upload.files;
      for (let i = 0; i < files.length; i++) {
        fd.append('csv_file', files[i], files[i].name);
      }
    }

    return client.api('POST', `${this.getApiPath()}/${this.getId()}/client_code`, fd, false);
  }

  successAction = (response) => {
    this.loadDone(response, {
      message : 'Client codes have been upload',
      values: {
        csv_file: ''
      }
    });
  }

  getFormHeader() {
    return null;
  }

  removeClientCodes = () => {
    client
      .api('DELETE', `${this.getApiPath()}/${this.getId()}/client_code`)
      .done((resp) => this.loadDone(resp))
  }

  getActionButtons() {
    return (
      <CardFooter className="action-buttons d-none d-lg-block">
        <button type="submit" className="btn btn-success" disabled={this.state.sendingData}>
          <i className="fa fa-upload"></i> Upload
        </button>
        <button type="button" className="btn btn-danger" disabled={this.state.sendingData} onClick={this.removeClientCodes}>
          <i className="fa fa-trash"></i> Delete
        </button>
      </CardFooter>
    );
  }

  getFileName() {
    if (this.upload && this.upload.files && this.state.values.csv_file) {
      return this.upload.files[0].name;
    }

    return 'Choose file...';
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          <FormDisabledRow
            label="total"
            name="total"
            value={this.state.total}
            fieldWidth={{ xs: 4 }}
            labelWidth={{ xs: 6 }}
            valueWidth={{ xs: 6 }}
            component={ClientCodeDownload}
            projectId={this.props.id}
          />

          <FormDisabledRow
            label="used"
            name="used"
            value={this.state.used}
            fieldWidth={{ xs: 4 }}
            labelWidth={{ xs: 6 }}
            valueWidth={{ xs: 6 }}
            component={ClientCodeDownload}
            projectId={this.props.id}
            clientCodeType='used'
          />

          <FormDisabledRow
            label="left"
            name="left"
            value={this.state.total - this.state.used}
            fieldWidth={{ xs: 4 }}
            labelWidth={{ xs: 6 }}
            valueWidth={{ xs: 6 }}
            component={ClientCodeDownload}
            projectId={this.props.id}
            clientCodeType='left'
          />
        </div>

        <div className="form-row">
          <div className="col">
            <div className="form-group custom-file">
              <input
                type="file"
                value={this.state.values.csv_file}
                className="custom-file-input"
                accept=".csv"
                ref={(comp) => { this.upload = comp;}}
                name="csv_file"
                required
                onChange={this.handleInputChange}
              />
              <label className="custom-file-label" htmlFor="validatedCustomFile">{this.getFileName()}</label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle}>
          Client Codes
        </ModalHeader>
        <ModalBody>
          <ClientCodeNotificationLimitForm id={this.getId()} />
          {super.render()}
        </ModalBody>
      </Modal>
    );
  }
}

export default ClientCodeForm;