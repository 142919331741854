import React, { Component} from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from 'reactstrap';

export default class RangeModal extends Component {

  state = {
    start: '',
    end: ''
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  createRange = () => {
    if (this.state.start && this.state.end) {
      const range = [];
      const end = Number(this.state.end)
      for (let r = Number(this.state.start); r <= end; r++) {
        range.push(r);
      }

      this.setState({ start: '', end: '' }, () => this.props.onCreateRange(range));
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="sm">
        <ModalHeader toggle={this.props.toggle}>
            <span className="text-uppercase">Create Range</span>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col">
              <Input
                type="number" name="start"
                placeholder="Start" bsSize="sm"
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col">
              <Input
                type="number" name="end"
                placeholder="End" bsSize="sm"
                onChange={this.handleInputChange}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            size="sm" type="button"
            onClick={this.createRange}
          >Create</Button>
        </ModalFooter>
      </Modal>
    );
  }
}
