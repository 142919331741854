import React from 'react';
import { RemoteSelect } from 'emi-frontend';

export default class ProjectGroupSelect extends RemoteSelect {

  getApiPath() {
    return '/project_group'
  }

  getFirstOption() {
    return (
      <option key="_" value="">Select Group..</option>
    );
  }

  composeProps() {
    return { isDisabled: true, ...super.composeProps()};
  }

  getSelectedOptionText() {
    return this.getArrayOptionText();
  }

  getOptionText(row) {
    return `${row.name}${row.client_name ? [' ', `(Client: ${row.client_name}`, ')'].join('') : ''}`;
  }

  getOptionsFromArray() {
    return this.state.data.map((row) => {
      return {
        value: row.id,
        label: this.getOptionText(row)
      };
    });
  }
}
