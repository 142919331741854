import React from 'react';
import classNames from 'classnames';
import { Cell } from 'emi-frontend';

export default class MultiCell extends Cell {
  render() {
    let alignClass = '';
    if (this.props.column.align) {
      alignClass = `justify-content-${this.props.column.align}`;
    }

    const style = {
      width: this.props.column.width
    };

    if (this.props.column.exactWidth) {
      style.flexGrow = 0;
    }

    return (
      <div
        className={classNames(
          'grid-cell', 'd-flex', 'align-content-center', 'align-items-center', alignClass, this.props.column.className
        , {
          'overflow-visible' : this.props.column.name === 'custom_cost' && this.props.row.group === 'project',
          'partner-quota-summary-cell': this.props.column.name === 'quota' && !this.props.summary && (this.props.row.group === 'project' || this.props.row.rowType === 'partner') && this.props.row.enable_click_quota
        })}
        style={style}
      >
        {this.getContent()}
      </div>
    );
  }
}
