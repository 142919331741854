import React from 'react';
import PropTypes from 'prop-types';
import { CardFooter } from 'reactstrap';
import { AbstractForm } from 'emi-frontend';

export default class ReconciliationFrom extends AbstractForm {

  static propTypes = {
    values: PropTypes.object,
    acceptFileType: PropTypes.string
  };

  static defaultProps = {
    values: {},
    acceptFileType: '.csv',
    fileName: 'reconcile_file'
  };

  get modelName() {
    return 'reconciliation';
  }

  getDefaultValues(props) {
    return {
      [props.fileName]: ''
    };
  }

  getApiPath = () => {
    return '/panelapi';
  }

  getId = () => {
    return this.parseInt(this.props.id);
  }

  collectFormData() {
    const formData = new FormData();

    if (this.upload) {
      const files = this.upload.files;
      for (let i = 0; i < files.length; i++) {
        formData.append(this.props.fileName, files[i], files[i].name);
      }
    }

    return formData;
  }

  sendFormData = () => {
    return this.clientApi(
      'POST', `${this.getApiPath()}/${this.props.routeId}/project/reconcile`, this.collectFormData(), false
    );
  }

  successAction = (response) => {
    this.loadDone(response, {
      message : 'File successfully uploaded',
      values: {
        [this.props.fileName]: ''
      }
    });
  }

  getFormHeader() {
    return null;
  }

  getActionButtons() {
    return (
      <CardFooter className="action-buttons d-none d-lg-block">
        <button type="submit" className="btn btn-success" disabled={this.state.sendingData}>
          <i className="fa fa-upload"></i> Upload
        </button>
      </CardFooter>
    );
  }

  getFileName() {
    if (this.upload && this.upload.files && this.state.values[this.props.fileName]) {
      return this.upload.files[0].name;
    }

    return 'Choose file...';
  }

  getFormContent() {
    return (
      <div className="form-row">
        <div className="col">
          <div className="form-group custom-file">
            <input
              type="file"
              value={this.state.values[this.props.fileName]}
              className="custom-file-input"
              accept={this.props.acceptFileType}
              ref={(comp) => { this.upload = comp;}}
              name={this.props.fileName}
              required
              onChange={this.handleInputChange}
            />
            <label className="custom-file-label" htmlFor="validatedCustomFile">{this.getFileName()}</label>
          </div>
        </div>
      </div>
    );
  }
}
