import React from 'react';
import { RemoteSelect } from 'emi-frontend';

export default class ProjectSelect extends RemoteSelect {

  constructor(props) {
    super(props);

    this.state.inputValue = ''
  }

  onInputChange = (inputValue, { action }) => {
    switch (action) {
      case 'input-change':
        this.setState({ inputValue });
        return;
      case 'menu-close':
        this.setState({ inputValue: '' });
        return;
      default:
        return;
    }
  }

  getLoadParams() {
    const params = {
      sort: {
        id: true
      }
    };
    const { status } = this.props;

    if (status) {
      params.extraParams = { status };
    }

    return params;
  }

  composeProps() {
    const props = super.composeProps();
    if (props.isMulti && !props.closeMenuOnSelect) {
      Object.assign(props, {
        inputValue: this.state.inputValue,
        onInputChange: this.onInputChange,
      });
    }

    return props;
  }

  getApiPath() {
    return '/project'
  }

  getFirstOption() {
    return (
      <option key="_" value="">Select Project..</option>
    );
  }

  getSelectedOptionText() {
    return this.getArrayOptionText();
  }

  getOptionsFromArray() {
    return this.state.data.map((row) => {
      return { value: row.id, label: row.name, io_number: row.io_number };
    });
  }
}
