import { Filter } from 'emi-frontend';

export default class ClosedFilter extends Filter {
  constructor(props) {
    super(props);

    this.state.searchTitle = 'Search by Job Name, Project Manager, etc.';
  }

  get filterName() {
    return 'closedFilter';
  }
}