import React from 'react';
import PropTypes from 'prop-types';
import { AbstractTargetCriteria, CreatableReactSelect } from '../abstract';
import { AbstractForm, ReactSelect } from 'emi-frontend';
import OperatorHint from '../abstract/OperatorHint';

export default class TargetCriteria extends AbstractTargetCriteria {

  static propTypes = {
    ...TargetCriteria.propTypes,
    criteria: PropTypes.shape({
      equals: PropTypes.shape({
        question: PropTypes.string,
        values: PropTypes.arrayOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
          ])
        )
      }),
      range: PropTypes.shape({
        question: PropTypes.string,
        values: PropTypes.array
      })
    })
  };

  get name() {
    return 'disqo';
  }

  getDefaultCriteria() {
    return {
      equals: {
        values: [],
        question: ''
      },
      range: undefined
    };
  }

  getPredicateType() {
    return this.hasCondition('range') ? 'range' : 'equals';
  }

  getIdentifierValue() {
    const { equals, range } = this.state;
    return (equals || range)[this.props.identifier];
  }

  showOperator() {
    return this.getIdentifierValue() !== '';
  }

  getValues() {
    const { equals, range } = this.state;
    return (equals || range).values;
  }
  
  getCurrentTargetCriteria() {
    return AbstractForm.cloneData(this.state);
  }

  hasCondition(prop) {
    return Object.prototype.hasOwnProperty.call(this.state, prop) && this.state[prop] !== undefined;
    // return this.state[prop] && Array.isArray(this.state[prop]);
  }

  getQuestionSelectOptions() {
    const { questions } = this.props;
    return Object.keys(questions  || []).map((key) => {
      return { value: key, label: `${questions[key].key} (${questions[key].text})` };
    });
  }

  getPrecodesSelectOptions(question, values) {
    if (this.useCreatable(question)) {
      return this.hasCondition('range') ?
        values.map(({ gte, lte }) => ({ value: `${gte}-${lte}`, label: `${gte} - ${lte}` })) :
        values.map(value => ({ value, label: value }));
    }

    const answers = this.getQuestionAnswers(question);
    return Object.keys(answers).map(value => ({ value, label: answers[value] }));
  }

  createRangeValue = (range) => {
    if (/^\d+\s*-\s*\d+$/.test(range)) {
      const [gte, lte] = range.split(/\s*-\s*/);
      const values = this.getValues();
      values.push({ gte, lte });

      this.updateState(
        this.valueChanged('values', values)
      );
    }
  }

  createOptionValues = (value) => {
    this.updateState(
      this.valueChanged('values', this.getValues().concat(AbstractTargetCriteria.parseValue([value])))
    );
  }

  valueChanged(prop, value) {
    const changed = AbstractForm.cloneData(this.state);
    let predicateType = this.getPredicateType();

    // operator changed range <=> equals
    if (prop === 'predicate') {
      Object.assign(changed, { [predicateType]: undefined, [value]: changed[predicateType] });
    } else {
      changed[predicateType][prop] = value;
    }

    if (prop === this.props.identifier && this.getIdentifierValue()) {
      Object.assign(changed, { range: undefined, equals: { [prop]: value, values: [] } });
    }

    return changed;
  }

  getSelectedOptionValue(option, field) {
    if (field === 'values' && this.hasCondition('range')) {
      return Array.isArray(option) ? option.map(({ value }) => {
        const [gte, lte] = value.split('-');
        return { gte, lte };
      }) : [];
    }

    return super.getSelectedOptionValue(option);
  }

  getEmptyOptionValue() {
    return [];
  }

  operatorChangeHandler = (predicate) => {
    this.updateState(
      this.valueChanged('predicate', predicate)
    );
  }

  precodesCount() {
    return this.getValues().length;
  }

  static isQuestionType(question, type) {
    return question.type === type;
  }

  useCreatable(question) {
    return Object.keys(this.getQuestionAnswers(question)).length === 0;
  }

  getQuestionAnswers(question) {
    return (question && question.useCountry) ? question.answers[this.props.country] : question.answers;
  }

  getPrecodesSelect() {
    if (!this.getIdentifierValue()) {
      return null;
    }

    const question = this.getSelectedQuestion();
    const useCreatable = this.useCreatable(question);
    const value = this.getValues();
 
    const props = {
      options: this.getPrecodesSelectOptions(question, value),
      value,
      onChange: option => this.selectChangeHandler('values', option),
      isMulti: true,
      closeMenuOnSelect: false,
      isDisabled: this.props.readOnly
    };

    if (this.hasCondition('range')) {
      Object.assign(props, {
        value: value.map(({ gte, lte }) => `${gte}-${lte}`),
        onCreateOption: this.createRangeValue
      });
    } else if (useCreatable) {
      Object.assign(props, {
        onCreateOption: this.createOptionValues
      });
    }

    return useCreatable ? (
      <CreatableReactSelect {...props} />
    ) : (
      <ReactSelect {...props} closeOnSelect={false} />
    );
  }

  getButtonClass(selected, btnClass) {
    return `btn btn-${btnClass} btn-${selected ? 'warning' : 'default'}`;
  }

  getOperatorButton(label, btnClass = 'xs') {
    const selected = this.hasCondition(label);

    return (
      <button
        type="button"
        className={this.getButtonClass(selected, btnClass)}
        onClick={() => this.operatorChangeHandler(label)}
        disabled={selected || this.props.readOnly}
      >
        {label}
      </button>
    );
  }

  getOperatorSelect() {
    return (
      <div className="form-group col-md-2">
        {this.useCreatable(this.getSelectedQuestion()) ? (
          <div className="btn-group-vertical">
            {this.getOperatorButton('equals')}
            {this.getOperatorButton('range')}
          </div>
        ):(
          this.getOperatorButton('equals', 'sm')
        )}
        <OperatorHint {...this.operatorHintProps()} />
      </div>
    );
  }

  render() {
    if (typeof this.props.idx === 'number' && !this.getSelectedQuestion()) {
      return null;
    }

    return super.render();
  }
}
