import React from 'react';
import { ProjectForm } from '../abstract';
import CountrySelect from './CountrySelect';
import { ReactSelect, ButtonSelect } from 'emi-frontend';

const studyTypes = [
  'AD_HOC', 'DIARY', 'IHUT', 'TRACKING_WEEKLY', 'TRACKING_MONTHLY',
  'TRACKING_QUARTERLY', 'WAVE_STUDY', 'QUALITATIVE_SCREENING', 'RECONTACT'
];

export default class Project extends ProjectForm {

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (ProjectForm.valuesDidChange(this.state.values, nextProps.values)) {
      const { qualifications, ...changed } = this.state.values;
      const values = Object.assign({}, this.getDefaultValues(), nextProps.values, changed);
      this.setState({ values });
    }
  }

  getDefaultValues() {
    return {
      name: '',
      loi: 0,
      conversionRate: 0,
      cpi: 0,
      country: 'US',
      studyType: 'AD_HOC',
      devices: ['DESKTOP', 'PHONE', 'TABLET'],
      completesWanted: 0,
      clicksWanted: 0,
      enforceScreenOut: true,
      trackingField: 'COMPLETES'
    };
  }

  getId = () => {
    // Job ID - IO#
    return this.state.values.id;
  }

  isUpdate() {
    return Object.prototype.hasOwnProperty.call(this.props.values || {}, 'status');
  }

  sendMethod() {
    return this.isUpdate() ? 'PUT' : 'POST';
  }

  updateStatus(status) {
    this.clientApi(
      'PUT', this.resolveApiPath('status'), { id: this.getId(), status }
    )
      .done(this.successAction)
      .fail(this.deleteFail);
  }

  getStatusButton(status, btnClass, icon) {
    return (this.state.values.status !== status &&
      <button
        type="button" className={`btn btn-${btnClass} text-lowercase text-capitalize`}
        onClick={() => this.updateStatus(status)}
      >
        <i className={`fa fa-${icon}`}></i> {status}
      </button>
    );
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            name: 'name',
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'country',
            component: CountrySelect,
            partnerId: this.props.partnerId,
            required: true,
            isClearable: false,
            isDisabled: this.isUpdate(),
            onChange: this.selectChangeHandler('country'),
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 5 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'study type',
            name: 'studyType',
            component: ReactSelect,
            options: studyTypes.map(value => ({ value, label: value })),
            isClearable: false,
            onChange: this.selectChangeHandler('studyType'),
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 5 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'track by',
            name: 'trackingField',
            required: true,
            component: ButtonSelect,
            options: { COMPLETES: 'COMPLETES', CLICKS: 'CLICKS' },
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 9 }
          })}
        </div>
        
        <div className="form-row">
          {this.getFormRow({
            label: 'stop when filled',
            name: 'enforceScreenOut',
            component: ButtonSelect,
            required: true,
            options: { true: 'Yes', false: 'No' },
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'completes',
            name: 'completesWanted',
            type: 'number',
            step: 1,
            min: 0,
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}

          {this.getFormRow({
            label: 'clicks',
            name: 'clicksWanted',
            type: 'number',
            step: 1,
            min: 0,
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'loi',
            type: 'number',
            min: 1,
            step: 1,
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}

          {this.getFormRow({
            label: 'ir',
            name: 'conversionRate',
            type: 'number',
            min: 0,
            max: 100,
            step: 1,
            postfix: '%',
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'devices',
            required: true,
            component: ButtonSelect,
            options: { DESKTOP: 'DESKTOP', PHONE: 'PHONE', TABLET: 'TABLET' },
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 9 }
          })}
        </div>

        {this.isUpdate() && 
          <div className="form-row">
            <div className="alert alert-info" role="alert">
              Set status to CLOSED and send email to <a href="mailto:accounting@disqo.com">accounting@disqo.com</a> with the final Id file, 
              including completes and data quality removals for reconciliation.
            </div>
          </div>
        }

        {this.isUpdate() && 
          <div className="form-row">
            {this.getFormRow({
              name: 'status',
              type: 'select',
              disabled: true,
              fieldWidth: { xs: 12, sm: 6 },
              labelWidth: { xs: 12, sm: 6 },
              valueWidth: { xs: 12, sm: 4 },
              children: [
                <option key="open" value="OPEN">OPEN</option>,
                <option key="hold" value="HOLD">HOLD</option>,
                <option key="completed" value="COMPLETED">COMPLETED</option>,
                <option key="closed" value="CLOSED">CLOSED</option>
              ]
            })}

            {this.state.values.status !== 'COMPLETED' &&
              <div className="col-12 col-sm-6 action-buttons">
                {this.getStatusButton('OPEN', 'default', 'play')}
                {this.getStatusButton('HOLD', 'default', 'pause')}
                {this.getStatusButton('CLOSED', 'danger', 'stop')}
                {this.getStatusButton('COMPLETED', 'warning', 'warning')}
              </div>
            }
          </div>
        }

      </div>
    );
  }
}
