import DefinitionsSelect from './DefinitionsSelect';

export default class CountryLanguageSelect extends DefinitionsSelect {

  get definitionsPath() {
    return 'languages';
  }

  get valueProperty() {
    return 'languageId';
  }
}
