import React from 'react';
import { client, Grid, Button, formatCurrency } from 'emi-frontend';
import QuickbooksSelectMain from '../../lib/form/QuickbooksSelectMain';
import QuickbooksSelectSub from '../../lib/form/QuickbooksSelectSub';

export default class RouteLineItemGrid extends Grid {

  get gridName() {
    return 'routeLineItemGrid';
  }

  getApiPath = () => {
    return '/routelineitem';
  }

  getBasePath = () => {
    return '/';
  }

  FormatCost = ({ data, row }) => {
    if (!data) {
      return '-';
    }

    return formatCurrency(data);
  }

  QuickbooksMainField = ({ data, row }) => {
    if (!data) {
      return data;
    }

    return (
      <QuickbooksSelectMain value={data} textOnly />
    );
  }

  QuickbooksSubField = ({ data, row }) => {
    if (!data) {
      return data;
    }

    return (
      <QuickbooksSelectSub value={data} textOnly />
    );
  }

  gridButtonRenderer = (props) => {
    const {className, btnClassName, ...rest} = props; 
    rest.className = btnClassName;
    return (
      <div className={className}>
        <Button {...rest} />
      </div>
    );
  }

  deleteItem = (id) => {
    client.api('DELETE', `${this.getApiPath()}/${id}`)
      .done(() => {
        this.reload();
        this.props.onRowDelete(id);
      });
  }

  getColumns() {
    return [{
      name: 'cpi',
      title: 'cpi',
      width: 80,
      exactWidth: true,
      renderer: this.FormatCost
    }, {
      name: 'completed',
      title: 'compl.',
      width: 80,
      exactWidth: true
    }, {
      name: 'quickbooks_main',
      title: 'QB Category',
      renderer: this.QuickbooksMainField,
      width: 100
    }, {
      name: 'quickbooks_sub',
      title: 'QB List',
      renderer: this.QuickbooksSubField,
      width: 100
    }, {
      name: 'description',
      title: 'Desc',
      width: 100
    }, {
      name: 'total',
      title: 'total',
      renderer: this.FormatCost,
      summarize: true,
      width: 150,
      exactWidth: true
    }, {
      name: 'delete',
      dataField: 'id',
      title: '',
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderer: this.gridButtonRenderer,
      params: {
        icon: 'trash',
        className: 'left-border',
        btnClassName: 'text-danger',
        clickHandler: this.deleteItem
      }
    }];
  }

  onRowSelection = (rowData) => {
    this.props.onSelect(rowData);
  }

  getMobilColumns() {
    return Object.fromEntries(
      Object.entries(this.state.columns).filter(([prop]) => !prop.startsWith('quickbooks'))
    );
  }

  getMobileHeader() {
    return <this.components.Head columns={this.getMobilColumns()} onSort={this.onSort} />;
  }

  getMobileBody() {
    return <this.components.Body
      columns={this.getMobilColumns()}
      data={this.state.data}
      recordCount={this.state.recordCount}
      currentPage={this.state.currentPage}
      pageSize={this.state.pageSize}
      onClick={this.onRowClick}
      loading={this.state.loadingData}
      rowStyler={this.getRowStyler()}
      adjustTotal={this.props.adjustTotal}
      extraParams={this.state.extraParams}
    />;
  }
}
