import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import ReconciliationForm from './ReconciliationForm';
import AttachmentGrid from './AttachmentGrid';

export default class ReconciliationModal extends Component {

  reloadGrid = () => {
    if (this.grid) {
      this.grid.reload();
    }
  }

  render() {
    const { project_id, io_number } = this.props.values;
    const gridExtraParams = Object.assign(
      { type: 'reconciliation'},
      project_id ? { project_id } : null,
      !project_id && io_number ? { io_number } : null
    );

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle}>
          Reconciliation
        </ModalHeader>
        <ModalBody>
          <ReconciliationForm
            invoice_id={this.props.invoice_id}
            values={this.props.values}
            onSuccess={this.reloadGrid}
          />
          <AttachmentGrid
            extraParams={gridExtraParams}
            storeState={false}
            ref={(comp) => { this.grid = comp; }}
          />
        </ModalBody>
      </Modal>
    );
  }
}
