import React from 'react';
import { Nav, TabContent, TabPane } from 'reactstrap';
import { ApiTabs } from '../abstract';
import Project from './Project';
import LineItemDetailedReport from './LineItemDetailedReport';
import Events from './Events';
import Report from './Report';
import Feasibility from './Feasibility';
import Reconciliation from './Reconciliation';

export default class ResearchNow extends ApiTabs {
  constructor(props) {
    super(props);

    this.state.lineItem = null;
  }

  updateApiData = (api_data, isCreateProject = false) => {
    const state = Object.assign({ api_data }, isCreateProject ? { lineItem: null, activeTab: 3 } : null);
    this.setState(state)
  }

  hasProject() {
    return this.state.api_data !== null && Object.prototype.hasOwnProperty.call(this.state.api_data.project || {}, 'state');
  }

  showReconciliation() {
    return this.hasProject() && ['CLOSED', 'INVOICED'].includes(this.state.api_data.project.state);
  }
  
  getLineItem() {
    const [ lineItem ] = this.state.api_data.project.lineItems;
    return Object.assign({}, lineItem);
  }

  getTabs() {
    const hasProject = this.hasProject();

    return (
      <Nav tabs>
        {this.getTab('Project', 1, 'project')}
        {this.getTab('Line Item', 2, this.hasProject() ? 'lineItem' : undefined)}
        {hasProject && this.getTab('Feasibility', 3, 'feasibility')}
        {hasProject && this.getTab('Events', 4, 'events')}
        {hasProject && this.getTab('Report', 5, 'report')}
        {this.showReconciliation() && this.getTab('Reconciliation', 6)}
      </Nav>
    );
  }

  getTabContent() {
    const hasProject = this.hasProject();

    return (
      <TabContent activeTab={this.state.activeTab}>
        <TabPane tabId={1}>
          <Project
            routeId={this.props.routeId}
            partnerId={this.props.partnerId}
            values={this.getProjectValues()}
            lineItem={this.state.lineItem}
            onChange={this.assingApiData}
            onUpdate={this.updateApiData}
            emptyLineItem={() => this.setState({ lineItem: null })}
            ref={(c) => this.setComponentRef('project', c)}
          />
        </TabPane>
        <TabPane tabId={2}>
          <LineItemDetailedReport
            routeId={this.props.routeId}
            partnerId={this.props.partnerId}
            partnerProjectId={this.state.api_data.project.extProjectId}
            values={this.getLineItem()}
            onCreate={lineItem => this.setState({ lineItem, activeTab: 1 })}
            ref={(c) => this.setComponentRef('lineItem', c)}
            onUpdate={this.updateApiData}
          />
        </TabPane>
        {hasProject &&
          <TabPane tabId={3}>
            <Feasibility
               routeId={this.props.routeId}
               partnerId={this.props.partnerId}
               partnerProjectId={this.state.api_data.project.extProjectId}
               lineItem={this.getLineItem()}
               ref={(c) => this.setComponentRef('feasibility', c)}
               onUpdate={this.updateApiData}
            />
          </TabPane>
        }
        {hasProject &&
          <TabPane tabId={4}>
            <Events
              routeId={this.props.routeId}
              partnerId={this.props.partnerId}
              partnerProjectId={this.state.api_data.project.extProjectId}
              ref={(c) => this.setComponentRef('events', c)}
            />
          </TabPane>
        }
        {hasProject &&
          <TabPane tabId={5}>
            <Report
               routeId={this.props.routeId}
               partnerProjectId={this.state.api_data.project.extProjectId}
               ref={(c) => this.setComponentRef('report', c)}
            />
          </TabPane>
        }
        {this.showReconciliation() &&
          <TabPane tabId={6}>
            <Reconciliation
              routeId={this.props.routeId}
              partnerId={this.props.partnerId}
              partnerProjectId={this.state.api_data.project.extProjectId}
            />
          </TabPane>
        }
      </TabContent>
    );
  }
}
