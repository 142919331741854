import PropTypes from 'prop-types';
import { RemoteSelect } from 'emi-frontend';

export default class UserSelect extends RemoteSelect {

  static propTypes = {
    ...RemoteSelect.propTypes,
    type: PropTypes.oneOf(['admin', 'partner', 'client']),
    department: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.string)
    ]),
    active: PropTypes.bool,
    sales_rule: PropTypes.oneOf(['CSE', 'AE'])
  };

  getApiPath() {
    return '/user'
  }

  getLoadParams() {
    const params = {
      sort: {
        name: true
      }
    };
    const extraParams = {};
    const { type: user_type, department, active, sales_rule } = this.props;

    if (user_type) {
      Object.assign(extraParams, { user_type });
    }

    if (department) {
      Object.assign(extraParams, { department });
    }

    if (active !== undefined) {
      Object.assign(extraParams, { active });
    }

    if (Object.keys(extraParams).length > 0) {
      Object.assign(params, { extraParams });
    }

    if (sales_rule) {
      Object.assign(extraParams, { sales_rule });
    }

    return params;
  }

  getSelectedOptionText() {
    return this.getArrayOptionText();
  }

  getOptionsFromArray() {
    return this.state.data.map((row) => {
      return { value: row.id, label: row.name };
    });
  }
}
