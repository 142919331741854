import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { RemoteSelect } from 'emi-frontend';

const visibleTags = 2;

export default class QuotaTag extends RemoteSelect {

  getApiPath() {
    return '/quota_tag';
  }

  render () {
    const showTags = this.state.data.slice(0, visibleTags);
    const restTags = this.state.data.slice(visibleTags);

    return (
      <div className="float-right">
        {showTags.map(tag =>
          <ButtonGroup key={tag.tag_id} size="sm" className="mr-2">
            <Button color="primary" type="button">{tag.tag_name}</Button>
          </ButtonGroup>
        )}
        {restTags.length > 0 &&
          <div className="btn-group mr-2">
            <button className="btn btn-primary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false" disabled>
              <span className="badge badge-light">{restTags.length}</span> More tag
            </button>
        </div>
        }
      </div>
    );
  }
}
