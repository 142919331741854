import React from 'react';
import { 
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import QuickbooksSelectMain from '../../lib/form/QuickbooksSelectMain';
import QuickbooksSelectSub from '../../lib/form/QuickbooksSelectSub';
import MultiProjectSelect from '../multiview/MultiProjectSelect';
import { formatCurrency, AbstractForm, ButtonSelect } from 'emi-frontend';

export default class LineItemForm extends AbstractForm {

  getApiPath = () => {
    return '/projectlineitem';
  }

  get modelName() {
    return 'item';
  }

  getId = () => {
    return this.parseInt(this.props.id);
  }

  getFormData() {
    // Remove invoice_id if called from multiview
    const { invoice_id, ...data } = super.getFormData();
    return data;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const id = this.parseInt(nextProps.id);
    if (id && id !== this.getId()) {
      this.loadFormData(id);
    } else if (nextProps.values.project_id !== this.props.values.project_id || !id) {
      this.resetForm(nextProps);
    }
  }

  getDefaultValues(props) {
    return Object.assign({
      project_id: 0,
      cpi: '',
      completed: '',
      quickbooks_main: (this.props.firstItem && this.props.firstItem.quickbooks_main) ? this.props.firstItem.quickbooks_main : '',
      quickbooks_sub: (this.props.firstItem && this.props.firstItem.quickbooks_sub)
        ? this.props.firstItem.quickbooks_sub
        : (this.props.connectorProject ? 17 : ''),
      description: '',
      discount: '',
      discount_type: 'amount'
    }, props.values);
  }

  deleteDone = (response) => {
    this.props.onDelete();
  }

  successAction = (response) => {
    if (this.isNew()) {
      this.props.onSuccess();
    } else {
      this.loadDone(response, { edit: {} }, this.props.reload);
    }
  }

  getFormHeader() {
    return null;
  }

  formatDiscount(discount) {
    if (!discount) {
      return '-';
    }

    return formatCurrency(discount);
  }

  getFormContent() {
    return (
      <div>
        {this.props.values.project_id === undefined &&
          <div className="form-row">
            {this.getFormRow({
              label: 'project',
              name: 'project_id',
              component: MultiProjectSelect,
              onChange: this.selectChangeHandler('project_id'),
              required: true,
              invoice_id: this.props.values.invoice_id,
              fieldWidth: { xs: 12 },
              labelWidth: { xs: 12, lg: 3 },
              valueWidth: { xs: 12, lg: 9 }
            })}
          </div>
        }

        <div className="form-row">
          {this.getFormRow({
            name: 'cpi',
            type: 'number',
            step: 0.01,
            min: 0,
            formatter: formatCurrency,
            prefix: '$',
            required: true,
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 5 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'completed',
            type: 'number',
            step: 1,
            min: 0,
            required: true,
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 5 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'quickbooks_main',
            label: 'QB Category',
            component: QuickbooksSelectMain,
            required: true,
            onChange: this.selectChangeHandler('quickbooks_main'),
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'quickbooks_sub',
            label: 'QB List',
            component: QuickbooksSelectSub,
            required: true,
            onChange: this.selectChangeHandler('quickbooks_sub'),
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'description',
            type: 'textarea',
            required: true,
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 9 }
          })}
        </div>

        {this.state.values.discount_type === 'amount' ? (
          <div className="form-row">
            {this.getFormRow({
              name: 'discount',
              type: 'number',
              step: 0.01,
              min: 0,
              formatter: this.formatDiscount,
              prefix: '$',
              fieldWidth: { xs: 12 },
              labelWidth: { xs: 12, lg: 3 },
              valueWidth: { xs: 12, lg: 5 }
            })}
          </div>
        ) : (
          <div className="form-row">
            {this.getFormRow({
              name: 'discount',
              type: 'number',
              step: 1,
              min: 0,
              max: 100,
              postfix: '%',
              fieldWidth: { xs: 12 },
              labelWidth: { xs: 12, lg: 3 },
              valueWidth: { xs: 12, lg: 5 }
            })}
          </div>
        )}
 
        <div className="form-row">
          {this.getFormRow({
            name: 'discount_type',
            label: 'discount type',
            component: ButtonSelect,
            options: {
              amount: '$',
              percentage: '%'
            },
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 5 }
          })}
        </div>

      </div>
    );
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle}>
          {this.isNew() ? (
            <span className="text-uppercase">New Item</span>
          ) : (
            <span className="text-uppercase">Item</span>
          )}
        </ModalHeader>
        <ModalBody>
          {super.render()}
        </ModalBody>
      </Modal>
    );
  }
}
