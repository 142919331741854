import { client, RemoteSelect } from 'emi-frontend';

export default class ResearchDefenderQuestionSelect extends RemoteSelect {

  getApiPath(lng) {
    return `/research_defender/question/EN`
  }

  shouldLoadData() {
    return this.props.lng !== null && this.props.lng !== undefined;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.lng && nextProps.lng !== this.props.lng) {
      this.loadApiData(nextProps.lng);
    }
  }

  loadData = () => {
    if (this.shouldLoadData()) {
      this.loadApiData(this.props.lng);
    }
  }

  loadApiData(lng) {
    client.loadGridData(this.getApiPath(lng), this.getLoadParams())
      .done(this.loadDone)
      .fail(this.showError);
  }

  generateOptions() {
    if (this.shouldLoadData()) {
      return Object
        .keys(this.state.data)
        .map((value) => {
          return { value, label: this.state.data[value] };
        })
        .filter((value, index) => (this.props.lng === 'EN' || index < 10) );
    }
    return [];
  }

  getSelectedOptionText() {
    return this.getObjectOptionText();
  }
}
