import QuickbooksSelectMain from './QuickbooksSelectMain';

class QuickbooksSelectSub extends QuickbooksSelectMain {

  getApiPath() {
    return '/quickbooks_sub'
  }

  getDisabled() {
    return [];
  }
}

export default QuickbooksSelectSub;
