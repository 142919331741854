import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import QuickSearchForm from './QuickSearchForm';
import QuickSearchGrid from './QuickSearchGrid';

export default class QuickSearchModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lookup: '',
      matchby: ''
    };
  }

  searchGrid = (params) => {
    this.setState(params);
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="xl">
        <ModalHeader toggle={this.props.toggle}>
          Quick ID lookup
        </ModalHeader>
        <ModalBody>
          <QuickSearchForm
            onSubmit={this.searchGrid}
          />
          {this.state.lookup && this.state.matchby && <QuickSearchGrid
            extraParams={{ project_id: this.props.project_id, ...this.state }}
            storeState={false}
          />}
        </ModalBody>
      </Modal>
    );
  }
}
