import { prodege, prodege_partner } from './prodege';
import { tapresearch, tapresearch_partner } from './tapresearch';
import { purespectrum, purespectrum_partner } from './purespectrum';
import { researchnow, researchnow_partner } from './researchnow';
import { disqo, disqo_partner } from './disqo';
import { marketcube, marketcube_partner } from './marketcube';

export default {
  prodege,
  prodege_partner,
  tapresearch,
  tapresearch_partner,
  purespectrum,
  purespectrum_partner,
  researchnow,
  researchnow_partner,
  disqo,
  disqo_partner,
  marketcube,
  marketcube_partner
};
