import { AbstractQuotas } from '../abstract';
import Quota from './Quota';

export default class Quotas extends AbstractQuotas {

  setQuotaComponent() {
    this.components = { Quota };
  }

  getDefaultValues() {
    return {
      surveyId: this.props.partnerProjectId
    };
  }

  reloadData = () => {
    this.setState({
      loadingData: true
    }, () =>
      this.clientApi('POST', `/panelapi/${this.props.routeId}/quota/refresh`, this.getDefaultValues())
        .done((resp) => {
          this.setState({ loadingData: false });
          this.props.onUpdate(resp.data);
        })
        .fail(this.showError)
    );
  }
}
