import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { client } from 'emi-frontend';

export default class TargetQuotaCell extends Component {
  static propTypes = {
    row: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  
    this.state = {
      quota: props.row.group === 'project' ? props.row.target_quota : props.row.quota,
      total_clicks: props.row.total_clicks,
      saved: true
    };
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      this.updateTargetQuota();
    }
  }

  inputOnClick = (event) => {
    event.stopPropagation();
  }

  isProject() {
    return this.props.row.group === 'project';
  }

  updateTargetQuota = () => {
    if (this.state.saved) {
      return;
    }
    const isProject = this.isProject();

    const url = `/${isProject ? 'project' : 'route' }/${this.props.row[isProject ? 'id' : 'route_id']}`;
    const data = {
      [isProject ? 'target_quota' : 'quota']: this.state.quota,
      total_clicks: this.state.total_clicks
    };

    client.api('PUT', url, data)
      .done((resp) => {
        this.setState({ saved: true });
        this.props.onDataChange();
      })
      .fail(console.error);
  }

  render() {
    if (this.props.summary || this.props.row.group === 'partner' || this.props.row.rowType === 'project') {
      return (
        <span>{this.props.data}</span>
      );
    }

    return (
      <React.Fragment>
        <InputGroup className="target-quota-cell" size="sm">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>Q</InputGroupText>
          </InputGroupAddon>
          <Input
            className={classNames('form-control', 'form-control-sm', { 'text-info': !this.state.saved })}
            type="number"
            step={1}
            min={0}
            value={this.state.quota}
            onChange={e => this.setState({ quota: e.target.value, saved: false })}
            onBlur={this.updateTargetQuota}
            onKeyPress={this.handleKeyPress}
            onClick={this.inputOnClick}
            style={{ width: 50 }}
          />
          {this.isProject() &&
            <InputGroupAddon addonType="append">
              <InputGroupText>{' / '}{this.props.data}</InputGroupText>
            </InputGroupAddon>
          }
        </InputGroup>
        {this.props.row.enable_click_quota &&
          <InputGroup className="target-quota-cell" size="sm">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>C</InputGroupText>
            </InputGroupAddon>
            <Input
              className={classNames('form-control', 'form-control-sm', { 'text-info': !this.state.saved })}
              type="number"
              step={1}
              min={0}
              value={this.state.total_clicks}
              onChange={e => this.setState({ total_clicks: e.target.value, saved: false })}
              onBlur={this.updateTargetQuota}
              onKeyPress={this.handleKeyPress}
              onClick={this.inputOnClick}
              style={{ width: 50 }}
            />
            {this.isProject() ? (
              <InputGroupAddon addonType="append">
                <InputGroupText>{' / '}{this.props.row.route_total_clicks}</InputGroupText>
              </InputGroupAddon>
            ) : (
              <InputGroupAddon addonType="append">
                <InputGroupText>{' / '}{this.props.row.entry}</InputGroupText>
              </InputGroupAddon>
            )}
          </InputGroup>
        }
      </React.Fragment>
    );
  }
}
