import React from 'react';
import { 
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import {
  AbstractForm,
  ButtonSelect,
  hasOwnProperty,
  FormDisabledRow,
  ReactSelect
} from 'emi-frontend';
import ClientSelect from '../../lib/form/ClientSelect';

class FeedbackForm extends AbstractForm {

  static mapOptions(arrayOfValues) {
    return arrayOfValues.map(value => ({ value, label: value }))
  }

  get modelName() {
    return 'feedback';
  }

  getDefaultValues() {
    return {
      client_id: '',
      io_number: '',
      feedback_date: '',
      reported_by: '',
      concerning: [],
      issue_reported: '',
      emi_action: '',
      feedback_flag: false
    };
  }

  getApiPath = () => {
    return '/client_feedback';
  }

  getId = () => {
    return this.parseInt(this.props.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const id = this.parseInt(nextProps.id);
    if (id && id !== this.getId()) {
      this.loadFormData(id);
    } else if (hasOwnProperty(nextProps.values || {}, 'client_name') &&
      this.state.values.client_name !== nextProps.values.client_name) {
      
      const { client_name } = nextProps.values;
      this.updateValues({ client_name });
    }
  }

  deleteDone = (response) => {
    this.successAction(response);
  }

 successAction = (response) => {
    if (this.props.onSuccess) {
      this.resetForm(this.props);
      this.props.onSuccess();
    }
  }

  getFormHeader() {
    return null;
  }

  showClientSelect() {
    return !hasOwnProperty(this.props, 'values') || !hasOwnProperty(this.props.values, 'client_id');
  }

  onClientChange = (option) => {
    const values = { client_id: option.id };
    if (this.isNew()) {
      Object.assign(values, { client_name: option.client_name });
    }

    this.updateValues(values);
  }

  onConcerningSelect = (options) => {
    this.updateObjectValues(
      'concerning', Array.isArray(options) ? options.map(option => option.value) : []
    );
  }

  getFormContent() {
    return (
      <div>
        {this.showClientSelect() &&
        <div className="form-row">
          {this.getFormRow({
            label: 'client',
            name: 'client_id',
            component: ClientSelect,
            required: true,
            autoFocus: true,
            onValueChange: this.onClientChange,
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 7 }
          })}
        </div>
        }

        <div className="form-row">
          {this.getFormRow({
            label: 'io#',
            name: 'io_number',
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 5 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'date',
            name: 'feedback_date',
            required: true,
            type: 'date',
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 5 }
          })}
        </div>

        {this.isUpdate() &&
          <div className="form-row">
            <FormDisabledRow
              label="created by"
              name="user_name"
              value={this.state.values.user_name}
              labelWidth={{ xs: 12, lg: 3 }}
              valueWidth={{ xs: 12, lg: 5 }}
            />
          </div>
        }

        <div className="form-row">
          {this.getFormRow({
            label: 'reported by',
            name: 'reported_by',
            required: true,
            type: 'select',
            children: [ 
              <option key="plz_select" value="">-- please select --</option>,
              <option key="network_manager" value="Network Manager">Network Manager</option>,
              <option key="executive" value="Executive">Executive</option>,
              <option key="accounting" value="Accounting">Accounting</option>,
              <option key="sales" value="Sales">Sales</option>,
              <option key="pm" value="PM">RM</option>,
              <option key="insights" value="Insights">Insights</option>
            ],
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 5 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'concerning',
            isMulti: true,
            required: true,
            component: ReactSelect,
            isClearable: false,
            onChange: this.selectChangeHandler('concerning'),
            options: FeedbackForm.mapOptions(['Partner Department', 'Executive', 'Accounting', 'Sales', 'PM', 'Insights']),
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 7 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'feedback reported',
            name: 'issue_reported',
            required: true,
            type: 'textarea',
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'emi action',
            name: 'emi_action',
            type: 'textarea',
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'flag',
            name: 'feedback_flag',
            component: ButtonSelect,
            options: { true: 'Yes', false: 'No' },
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 9 }
          })}
        </div>
      </div>
    );
  }

  toggle = () => {
    this.resetForm(this.props);
    this.props.toggle();
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.toggle} size="lg">
        <ModalHeader toggle={this.toggle}>
          Feedback Log
        </ModalHeader>
        <ModalBody>
          {super.render()}
        </ModalBody>
      </Modal>
    );
  }
}

export default FeedbackForm;