import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class RangeOption extends Component {
  state = { from: '', to: '' };

  static propTypes = {
    onAdd: PropTypes.func.isRequired
  };

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  addRange = () => {
    this.props.onAdd(`${this.state.from} - ${this.state.to}`);
    this.setState({ from: '', to: '' });
  }

  isButtonDisabled() {
    return this.state.from === '' || this.state.to === '';
  }

  stopEventPropagation = (event) => {
    event.stopPropagation();
  }

  render() {
    return (
      <div className="input-group input-group-sm">
        <div className="input-group-prepend">
          <span className="input-group-text">from</span>
        </div>
        <input
          type="number" name="from"
          className="form-control" value={this.state.from}
          style={{flexGrow: 1}}
          onChange={this.handleInputChange}
          onClick={this.stopEventPropagation}
          onMouseDown={this.stopEventPropagation}
          onMouseUp={this.stopEventPropagation}
        />
        <div className="input-group-append">
          <span className="input-group-text">to</span>
        </div>
        <input
          type="number" name="to"
          className="form-control" value={this.state.to}
          style={{flexGrow: 1}}
          onChange={this.handleInputChange}
          onClick={this.stopEventPropagation}
          onMouseDown={this.stopEventPropagation}
          onMouseUp={this.stopEventPropagation}
        />
        <div className="input-group-append">
          <button
            className="btn btn-default" type="button"
            onClick={this.addRange} disabled={this.isButtonDisabled()}
          >
            <i className="fa fa-plus-circle" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    );
  }
}
