import React, { Component } from 'react';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils.js';
import { Input } from 'reactstrap';

export default class Phone extends Component {

  componentDidMount() {
    const input = document.querySelector("#phone");
    if (input) {
      intlTelInput(input, {
        nationalMode: false,
        initialCountry: 'us',
        preferredCountries: ['us', 'ca', 'gb']
      });
    }
  }

  handleInputChange = (event) => {
    event.target.value = window.intlTelInputUtils.formatNumber(event.target.value, null, window.intlTelInputUtils.numberFormat.INTERNATIONAL);
    this.props.onChange(event);
  }

  composeProps() {
    const { onValueChange, textOnly, type, clientSideValidated, ...props } = this.props;
    return props;
  }

  render() {
    const props = this.composeProps();

    if (this.props.textOnly) {
      return window.intlTelInputUtils.formatNumber(props.value, null, window.intlTelInputUtils.numberFormat.INTERNATIONAL);
    }

    if (this.props.onChange) {
      Object.assign(props, { onChange: this.handleInputChange });
    }

    return <Input type="tel" key={`input${this.props.name}`} {...props} />;
  }
}
