import React from 'react';
import { InputGroup, InputGroupAddon, Input, Button } from 'reactstrap';

export default function EditField({ name, value, inputProps, onChange, onSave, onCancel }) {
  return (
    <InputGroup size="sm">
      <Input {...inputProps} value={value} name={name}
        onChange={e => onChange(name, e.target.value)}
      />
      <InputGroupAddon addonType="append">
        <Button color="secondary" outline type="button" onClick={onSave}>
          <i className="fa fa-check" />
        </Button>
      </InputGroupAddon>
      <InputGroupAddon addonType="append">
        <Button color="secondary" outline type="button" onClick={onCancel}>
          <i className="fa fa-times" />
        </Button>
      </InputGroupAddon>
    </InputGroup>
  );
}
