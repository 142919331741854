import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListGroup, Card, CardBody, CardTitle } from 'reactstrap';
import QuotaGroup from './QuotaGroup';

export default class QuotaGroups extends Component {

  static propTypes = {
    quotaGroups: PropTypes.array.isRequired,
    questions: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
  };

  render() {

    return (
      <Card className="top-margin-10">
        <CardBody>
          <CardTitle>
            Quota Groups{' '}
            <span className="quotaplan-subtitle">
              define the allocated targeting attributes for panelists within this line item
            </span>
          </CardTitle>
          <ListGroup style={{ width: '100%' }} className="top-margin-10">
            <QuotaGroup
              path={['quotaPlan', 'quotaGroups']}
              questions={this.props.questions}
              onChange={this.props.onChange}
            />
            {this.props.quotaGroups.map((quotaGroup, idx) =>
              <QuotaGroup
                idx={idx}
                key={idx}
                values={quotaGroup}
                path={['quotaPlan', 'quotaGroups']}
                questions={this.props.questions}
                onChange={this.props.onChange}
                exists
              />
            )}
          </ListGroup>
        </CardBody>
      </Card>
    );
  }
}