import { Link } from 'react-router-dom';

const apps = {
  Vision: 'https://vision.emi-rs.com/',
  Estimator: 'https://estimator.emi-rs.com/'
};

export default function AppSelector() {
  return (
    <div className="dropdown">
      <button className="btn bg-white dropdown-toggle" type="button" id="app-selector" data-toggle="dropdown" aria-expanded="false">
        <i className="fa fa-th" aria-hidden="true" />
      </button>
      <div className="dropdown-menu" aria-labelledby="app-selector">
        <h6 className="dropdown-header">Switch to</h6>
        {Object.keys(apps).map(app =>
          <li key={app}><a className="dropdown-item" href={apps[app]}>{app}</a></li>
        )}
        <li>
          <Link className="dropdown-item" to="/esearch">Esearch</Link>
        </li>
      </div>
    </div>
  );
}
