import React from 'react';
import { Nav, TabContent, TabPane } from 'reactstrap';
import { ApiTabs } from '../abstract';
import Project from './Project';
import Quotas from './Quotas';
import Reconciliation from './Reconciliation';
import Exclusion from './Exclusion';

export default class TapResearch extends ApiTabs {

  hasProject() {
    return this.state.api_data !== null && Object.prototype.hasOwnProperty.call(this.state.api_data.project || {}, 'id');
  }

  getTabs() {
    const hasProject = this.hasProject();

    return (
      <Nav tabs>
        {this.getTab('Project', 1, 'project')}
        {hasProject && this.getTab('Quotas', 2)}
        {hasProject && this.getTab('Reconciliation', 3)}
        {hasProject && this.getTab('Exclusion', 4)}
      </Nav>
    );
  }

  getTabContent() {
    const hasProject = this.hasProject();

    return (
      <TabContent activeTab={this.state.activeTab}>
        <TabPane tabId={1}>
          <Project
            routeId={this.props.routeId}
            partnerId={this.props.partnerId}
            values={this.getProjectValues()}
            onUpdate={this.updateApiData}
            ref={(c) => this.setComponentRef('project', c)}
          />
        </TabPane>
        {hasProject &&
          <TabPane tabId={2}>
            <Quotas
              routeId={this.props.routeId}
              partnerId={this.props.partnerId}
              countryId={this.state.api_data.project.country_language_id}
              partnerProjectId={this.state.api_data.project.id}
              quotas={this.getQuotas()}
              onUpdate={this.updateApiData}
            />
          </TabPane>
        }
        {hasProject &&
          <TabPane tabId={3}>
            <Reconciliation
              routeId={this.props.routeId}
              partnerId={this.props.partnerId}
              partnerProjectId={this.state.api_data.project.id}
              ioNumber={this.props.ioNumber}
            />
          </TabPane>
        }
        {hasProject &&
          <TabPane tabId={4}>
            <Exclusion
              routeId={this.props.routeId}
              partnerId={this.props.partnerId}
              partnerProjectId={this.state.api_data.project.id}
              ioNumber={this.props.ioNumber}
            />
          </TabPane>
        }
      </TabContent>
    );
  }
}
