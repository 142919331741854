import React from 'react';
import {
  Button,
  CardFooter
} from 'reactstrap';
import { AbstractForm, ButtonSelect } from 'emi-frontend';

export default class QuickSearchForm extends AbstractForm {

  getDefaultValues() {
    return {
      lookup: '',
      matchby: 'redirect_id'
    };
  }

  getID = () => {
    return null;
  }

  getFormHeader() {
    return null;
  }

  get modelName() {
    return 'project';
  }

  submitForm = () => {
    this.props.onSubmit(this.getFormData());
  }

  cancelAction = () => {
    this.resetForm(this.props);
  }

  getActionButtons = () => {
    return (
      <CardFooter className="action-buttons d-none d-lg-block">
        <Button type="submit" color="success" disabled={this.state.sendingData}>
          <i className="fa fa-search"></i> Lookup
        </Button>
      </CardFooter>
    );
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            name: 'lookup',
            label: 'ID',
            required: true,
            type: 'text',
            labelWidth: { xs: 3 },
            valueWidth: { xs: 9 },
            fieldWidth: { xs: 12 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'Match by',
            name: 'matchby',
            options: { redirect_id: 'REDIRECT ID', client_code: 'CLIENT CODE', respondent_id: 'PARTNER ID'},
            component: ButtonSelect,
            type: 'text',
            required: true,
            labelWidth: { xs: 3 },
            valueWidth: { xs: 9 },
            fieldWidth: { xs: 12 }
          })}
        </div>
      </div>
    );
  }
}
