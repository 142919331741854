import React from 'react';
import { ListGroup } from 'reactstrap';
import { hasOwnProperty, ButtonSelect } from 'emi-frontend';
import { AbstractQuota, Clicks } from '../abstract';
import TargetCriteria from './TargetCriteria';

export default class Quota extends AbstractQuota {

  getDefaultValues() {
    return {
      prodege_project_id: 0,
      number_of_respondents: 1,
      quota_name: '',
      calculation_type: 1,
      targeting_criteria: [
        { question_id: 1, operator: 'BETWEEN', precodes: [18, 99] },
        { question_id: 3, operator: 'OR', precodes: [1, 2] }
      ]
    };
  }

  resetValues() {
    return {
      quota_name: '',
      number_of_respondents: 1,
      targeting_criteria: [
        { question_id: 1, operator: 'BETWEEN', precodes: [18, 99] },
        { question_id: 3, operator: 'OR', precodes: [1, 2] }
      ]
    };
  }

  getId = () => {
    return this.parseInt(this.state.values.quota_id);
  }

  getTargetingCriteria() {
    const targeting_criteria = this.state.values.targeting_criteria.slice();

    return {
      targeting_criteria
    };
  }

  getDeleteParams() {
    const { prodege_project_id, quota_id } = this.state.values;

    return { prodege_project_id, quota_id };
  }

  cloneTargetCriteria(data) {
    // remove default criterias before clone
    this.updateValues({ targeting_criteria: [] }, 'values', () => super.cloneTargetCriteria(data));
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            label: 'name',
            name: 'quota_name',
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, sm: 2 },
            valueWidth: { xs: 12, sm: 10 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'quota',
            name: 'number_of_respondents',
            type: 'number',
            required: true,
            prefix: hasOwnProperty(this.state.values, 'quota_completes') ? `${this.state.values.quota_completes} /` : null,
            postfix: hasOwnProperty(this.state.values, 'quota_clicks') ? <Clicks value={this.state.values.quota_clicks} /> : null,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {hasOwnProperty(this.state.values, 'quota_completes') && 
            this.getFormRow({
              name: 'active',
              component: ButtonSelect,
              options: { true: 'Yes', false: 'No' },
              fieldWidth: { xs: 12, sm: 6 },
              labelWidth: { xs: 12, sm: 4 },
              valueWidth: { xs: 12, sm: 8 }
            })
          }
        </div>
        
        <div className="form-row">
          <ListGroup style={{ width: '100%' }}>
            <TargetCriteria
              questions={this.props.questions}
              onAdd={this.addTargetCriteria}
              onDelete={this.removeTargetCriteria}
              onChange={this.modifyTargetCriteria}
              allowCreate
            />
            {this.state.values.targeting_criteria.map((criteria, idx) =>
              <TargetCriteria
                idx={idx}
                key={criteria.question_id}
                criteria={criteria}
                questions={this.props.questions}
                onAdd={this.addTargetCriteria}
                onDelete={this.removeTargetCriteria}
                onChange={this.modifyTargetCriteria}
                isDeletable
              />
            )}
          </ListGroup>
        </div>
      </div>
    );
  }
}