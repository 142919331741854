import React, { useRef, useState } from 'react';
import { 
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import PartnerSelect from '../../lib/form/PartnerSelect';
import RouteLineItemForm from './RouteLineItemForm';
import RouteLineItemGrid from './RouteLineItemGrid';

export default function RouteLineItem(props) {
  const [lineItemId, setLineItemId] = useState();
  const gridRef = useRef();

  function reloadGrid() {
    setLineItemId(undefined);

    if (gridRef.current) {
      gridRef.current.reload();
    }
  }

  function toggleModal() {
    setLineItemId(undefined);
    props.toggle();
  }

  function onRowDelete(id) {
    if (lineItemId === id) {
      setLineItemId(undefined);
    }
  }

  return (
    <Modal isOpen={props.isOpen} toggle={toggleModal} size="lg">
      <ModalHeader toggle={toggleModal}>
        <PartnerSelect value={props.partnerId} textOnly />
        {!!props.projectName &&
          <span> - {props.projectName}</span>
        }
        {' '} - Costs
      </ModalHeader>
      <ModalBody>
        <RouteLineItemForm
          id={lineItemId}
          values={{ route_id: props.id }}
          projectId={props.projectId}
          invoiceId={props.invoiceId}
          reload={reloadGrid}
          connectorProject={props.connectorProject}
          reconciliationEmail={props.reconciliationEmail}
          ioNumber={props.ioNumber}
        />
        <RouteLineItemGrid
          extraParams={{ route_id: props.id }}
          ref={gridRef}
          storeState={false}
          onSelect={row => setLineItemId(row.id)}
          onRowDelete={onRowDelete}
        />
      </ModalBody>
    </Modal>
  );
}
