import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { AbstractForm } from 'emi-frontend';
import FieldsGroup from './FieldsGroup';
import TargetCriteria from './TargetCriteria';
import { ClipboardButton } from '../abstract';

export default class QuotaCell extends FieldsGroup {

  static defaultProps = {
    path: ['quotaCells'],
    values: {
      perc: 100,
      quotaNodes: []
    },
    exists: false
  };

  resetValues() {
    return {
      perc: 100,
      quotaNodes: []
    };
  }

  showAddButton() {
    return this.isNew() && this.state.perc > 0 && this.state.quotaNodes.length > 0;
  }

  addCriteria = (criteria) => {
    this.updateQuotaNodes('push', criteria);
  }

  deleteCriteria = (idx) => {
    this.updateQuotaNodes('splice', idx, 1);
  }

  modifyCriteria = (idx, criteria) => {
    this.updateQuotaNodes('splice', idx, 1, criteria);
  }

  updateQuotaNodes = (...args) => {
    if (this.isNew() && !this.props.exists) {
      this.modifyValues(['quotaNodes'], ...args);
    } else {
      this.props.onChange(this.props.path.concat(this.props.idx, 'quotaNodes'), ...args);
    }
  }

  pasteFilters = (data) => {
    this.updateQuotaNodes(
      'push', ...AbstractForm.cloneData(data).map(({attributeId, options}) => ({attributeId, options}))
    );
  }

  render() {
    return (
      <ListGroupItem className="prodege-target-criteria researchnow-quota-cell">
        <div className="form-row">
          <div className="col">
            <div className="row form-group">
              <label htmlFor="perc" className="text-uppercase col-12 col-sm-7 col-form-label">
                {this.props.idx !== undefined ? `Quota cell #${this.props.idx + 1}` : 'New Quota Cell'}
              </label>
              <div className="col-12 col-sm-3">
                <div className="input-group">
                  <input
                    type="number" step="1" min="0" max="100"
                    name="perc"
                    className="form-control"
                    value={this.getValue('perc')}
                    onChange={this.handleInputChange}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">%</span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-2">
                {this.isNew() &&
                  <ClipboardButton
                    className="mr-1"
                    dataType="filters"
                    readClipboardFn={this.pasteFilters}
                    isCopy={false}
                  />
                }
                {this.showAddButton() &&
                  <button
                    type="button" className="btn btn-sm btn-default"
                    onClick={this.addValues}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button>
                }
                {!this.isNew() &&
                  <button type="button" className="btn btn-sm btn-danger" onClick={this.deleteValues}>
                    <i className="fa fa-trash-o" aria-hidden="true"></i>
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
        <ListGroup style={{ width: '100%' }} className="top-margin-10 researchnow-quota-cell-criteria">
          <TargetCriteria
            questions={this.props.questions}
            onAdd={this.addCriteria}
            onDelete={this.emptyFn}
            onChange={this.emptyFn}
            filterProp="isAllowedInQuotas"
            identifier="attributeId"
            filterDeprecated
            allowCreate
          />
          {this.getValue('quotaNodes').map((criteria, key) =>
            <TargetCriteria
              idx={key}
              key={key}
              criteria={criteria}
              questions={this.props.questions}
              onAdd={this.emptyFn}
              onDelete={() => this.deleteCriteria(key)}
              onChange={(i, criteria) => this.modifyCriteria(key, criteria)}
              filterProp="isAllowedInQuotas"
              identifier="attributeId"
              isDeletable
            />
          )}
        </ListGroup>
      </ListGroupItem>
    );
  }
}