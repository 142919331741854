import React from 'react';
import PropTypes from 'prop-types';
import { client, Filter, Grid, Excel, Desktop, Tablet, Mobile } from 'emi-frontend';
import {
  ButtonGroup,
  Button
} from 'reactstrap';
import ExportMatchForm from './ExportMatchForm';
import FraudExportModal from './FraudExportModal';
import { getUserIds } from '../../lib/utils';
import { coverageTypes } from '../coverage/constants';

export default class JobFilter extends Filter {

  constructor(props) {
    super(props);

    this.state.searchTitle = 'Search by Job Name, Project Manager, etc.';
    this.state.projects = 'my';
    this.state.exportMatchModal = false;
    this.state.exportFraudModal = false;
  }

  get filterName() {
    return 'jobFilter';
  }

  initialize() {
    this.setFilter('my');
  }

  getLeftButtons() {
    return (
      <ButtonGroup className="btn-block">
        <Button color="default" className={this.getButtonClass('my')} onClick={this.myProjects}>
          <Desktop>My Projects</Desktop>
          <Tablet>My</Tablet>
          <Mobile>My</Mobile>
        </Button>{' '}
        <Button color="default" className={this.getButtonClass('all')} onClick={this.allProjects}>
          <Desktop>All Projects</Desktop>
          <Tablet>All</Tablet>
          <Mobile>All</Mobile>
        </Button>
        <Button color="default" className={this.getButtonClass('coverage')} onClick={this.coverageProjects}>
          <Desktop>Coverage Projects</Desktop>
          <Tablet>Coverage</Tablet>
          <Mobile>Coverage</Mobile>
        </Button>
        <Button color="default" onClick={() => this.toggleModal('exportMatchModal')}>
          <Desktop>Export Partner IDs</Desktop>
          <Tablet>Export</Tablet>
          <Mobile>Export</Mobile>
        </Button>
      </ButtonGroup>
    );
  }

  getRightButtons() {
    return [
      <Button key="fraud" color="default" onClick={() => this.toggleModal('exportFraudModal')} style={{ marginRight: 5 }}>
        <i className="fa fa-tags"></i> Fraud Export
      </Button>,
      <Button key="coverage" color="default" onClick={this.downloadCoverageExport} style={{ marginRight: 5 }}>
        <i className="fa fa-file-excel-o text-success"></i> Coverage
      </Button>,
      <Button key="assocrm" color="default" onClick={this.downloadAssociateRmExport} style={{ marginRight: 5 }}>
        <i className="fa fa-file-excel-o text-success"></i> Associate RM
      </Button>,
      <Button key="create" color="default" onClick={this.newJob}>
        <i className="fa fa-plus" aria-hidden="true"></i> New Job
      </Button>
    ];
  }

  getLeftClass() {
    return 'col-12 col-sm-6 col-md-6 col-lg-6';
  }

  getRightClass() {
    return 'col-sm-6 col-md-6 col-lg-6 d-none d-sm-block';
  }

  myProjects = () => {
    this.setFilter('my');
  }

  allProjects = () => {
    this.setFilter('all');
  }

  coverageProjects = () => {
    this.setFilter('coverage');
  }

  setFilter(type) {
    this.setState({
      projects: type
    });
    this.props.onFilter({
      user_id: type === 'my' ? getUserIds(this.context.user): undefined,
      enable_coverage: type === 'coverage' ? 'us' : undefined,
      coverage_us_pm: type === 'coverage' ? getUserIds(this.context.user) : undefined,
    });
  }

  getButtonClass(button) {
    return this.state.projects === button ? 'selected' : '';
  }

  newJob = () => {
    this.context.history.replace('/jobs/0');
  }

  downloadCoverageExport = () => {
    client
      .api('GET', '/project/coverageexport/us')
      .done((response) => {
        const excel = new Excel();
        excel.addJsonData(
          response.data.map((row) => {
            Object.keys(coverageTypes).forEach((type) => {
              if (row[type] && Array.isArray(row[type])) {
                row[type] = row[type].map((val) => coverageTypes[type].values[val]).join(', ');
              }
            });
            return row;
          }),
          this.getCoverageExportHeaders(),
          'Sheet 1'
        );
        excel.saveAs(`OOO_export_${Grid.getTimeStamp()}.xlsx`);
      })
  }

  downloadAssociateRmExport = () => {
    client
      .api('GET', '/project/associaterm')
      .done((response) => {
        const excel = new Excel();
        excel.addJsonData(response.data, this.getAssociateRmExportHeaders(), 'Sheet 1');
        excel.saveAs(`associate_rm_export_${Grid.getTimeStamp()}.xlsx`);
      })
  }

  getCoverageExportHeaders() {
    return {
      pm_lead: 'Lead RM',
      pm_covering: 'RM Covering',
      sales: 'Sales',
      project_id: 'Project ID',
      project_status: 'Status',
      project_name: 'Name',
      client_name: 'Client',
      io_number: 'IO#',
      close_date: 'Deadline',
      reporting_link: 'Reporting link',
      notes: 'Notes',
      suppliers: 'Suppliers',
      pm_notes: 'Covering RM Notes',
      priority: 'Priority',
      setup: 'Set up',
      monitor: 'Monitor',
      updates: 'Updates',
      close: 'Close',
      additional: 'Additional detail'
    };
  }

  getAssociateRmExportHeaders() {
    return {
      pm_lead: 'Lead RM',
      associate_rm: 'Associate RM',
      sales: 'Sales',
      project_id: 'Project ID',
      project_status: 'Status',
      project_name: 'Name',
      client_name: 'Client',
      io_number: 'IO#',
      close_date: 'Deadline',
      suppliers: 'Suppliers'
    };
  }

  toggleModal(name) {
    this.setState({
      [name]: !this.state[name]
    });
  }

  render() {
    return (
      <div>
        {super.render()}
        <ExportMatchForm
          isOpen={this.state.exportMatchModal}
          toggle={() => this.toggleModal('exportMatchModal')}
        />
        <FraudExportModal
         isOpen={this.state.exportFraudModal}
         toggle={() => this.toggleModal('exportFraudModal')}
       />
      </div>
    );
  }
}

JobFilter.contextTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
