import React from 'react';
import { Card, CardBody, ListGroup, ListGroupItem } from 'reactstrap';
import { AbstractQuotas, ClipboardButton } from '../abstract';
import Qualification from './Qualification';

export default class Qualifications extends AbstractQuotas {

  setQuotaComponent() {
  }

  saveQualification = (qualification) => {
    this.clientApi(
      qualification.surveyQualificationId ? 'PUT' : 'POST',
      `/panelapi/${this.props.routeId}/qualification`, {
        surveyId: this.props.partnerProjectId,
        qualifications: [ qualification ]
      })
    .done(response => this.props.onUpdate(response.data))
    .fail(this.showError);
  }

  copyQualifications = () => {
    return Object.values(this.props.quotas);
  }

  renderContent() {
    const { quotas } = this.props;
    const quotaKeys = Object.keys(quotas);

    return (
      <Card className="top-margin-10">
        <CardBody>
          <ListGroup style={{ width: '100%' }}>
            {quotaKeys.length > 0 &&
              <ListGroupItem className="prodege-target-criteria">
                <ClipboardButton
                  title="Copy Qualifications"
                  className="float-right"
                  dataType="qualifications"
                  writeClipboardFn={this.copyQualifications}
                  readClipboardFn={this.emptyFn}
                />
              </ListGroupItem>
            }
            <Qualification
              questions={this.state.questions}
              onAdd={this.saveQualification}
              onDelete={this.emptyFn}
              onChange={this.emptyFn}
              identifier="qualificationId"
              allowCreate
            />
            {quotaKeys.map(key =>
              <Qualification
                key={key}
                criteria={quotas[key]}
                questions={this.state.questions}
                onAdd={this.saveQualification}
                onDelete={this.emptyFn}
                onChange={this.emptyFn}
                identifier="qualificationId"
                allowCreate
              />
            )}
          </ListGroup>
        </CardBody>
      </Card>
    );
  }
}
