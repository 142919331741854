import React from 'react';
import PropTypes from 'prop-types';
import { AbstractTargetCriteria, CreatableReactSelect } from '../abstract';
import { ReactSelect } from 'emi-frontend';

export default class TargetCriteria extends AbstractTargetCriteria {

  static propTypes = {
    ...TargetCriteria.propTypes,
    criteria: PropTypes.shape({
      qualificationId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      answers: PropTypes.array
    })
  };

  getDefaultCriteria() {
    return {
      qualificationId: '',
      answers: []
    };
  }

  getEmptyOptionValue() {
    return [];
  }

  shouldParseValue(prop) {
    return prop === 'answers' && !this.hasAnswers(this.getSelectedQuestion());
  }

  getQuestionSelectOptions() {
    const { questions } = this.props;
    return Object.keys(questions).map((key) => {
      return { value: key, label: `${questions[key].name} (${questions[key].text})` };
    });
  }

  getPrecodesSelectOptions() {
    const question = this.getSelectedQuestion();
    const options = this.hasAnswers(question) ?
      Object.keys(question.answers).map(value => ({ value, label: question.answers[value] })) :
      this.state.answers.map(value => ({ value, label: value }));

    return options;
  }

  valueChanged(prop, value) {    
    const changed = super.valueChanged(prop, value);

    if (prop === 'qualificationId') {
      Object.assign(changed, { answers: [] });
    }

    return changed;
  }

  precodesCount() {
    return this.state.answers.length;
  }

  hasAnswers(question) {
    return question.answers !== null;
  }

  useCreatable() {
    return !this.hasAnswers(this.getSelectedQuestion());
  }

  getPrecodesSelect() {
    if (!this.state.qualificationId) {
      return null;
    }

    const props = {
      options: this.getPrecodesSelectOptions(),
      value: this.state.answers,
      onChange: option => this.selectChangeHandler('answers', option),
      isMulti: true,
      closeMenuOnSelect: false,
      isDisabled: this.props.readOnly
    };

    return this.useCreatable() ? (
      <CreatableReactSelect {...props} />
    ) : (
      <ReactSelect {...props} closeOnSelect={false} />
    );
  }
}
