import ProjectSelect from '../../lib/form/ProjectSelect';

export default class MultiProjectSelect extends ProjectSelect {
  getLoadParams() {
    return {
      sort: {
        name: true
      },
      extraParams: {
        invoice_id: this.props.invoice_id
      }
    };
  }
}
