import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import { Filter, IconButton, Grid } from 'emi-frontend';
import HubspotIcon from '../../lib/HubspotIcon';

export default class ClientFilter extends Filter {

  constructor(props) {
    super(props);

    this.state.searchTitle = 'Search by Client Name, Location, etc.';
    Object.assign(this.state, ClientFilter.clientsState());
  }

  static clientsState() {
    const gridState = Grid.getGridState('clientGrid', { storeState: true });
    const { connector = 'all', prospect = false } = gridState.extraParams || {};
    return { connector, prospect };
  }

  get filterName() {
    return 'clientFilter';
  }

  initialize() {
    this.setFilter('all');
  }

  getLeft() {
    return (
      <React.Fragment>
        <div className={this.getLeftClass()}>
          <ButtonGroup className="btn-block">
            <Button color="default" className={this.getButtonClass('connector', 'all')} onClick={this.allClients}>ALL</Button>{' '}
            <Button color="default" className={this.getButtonClass('connector', true)} onClick={this.enabledClients}>Connector Enabled</Button>{' '}
            <Button color="default" className={this.getButtonClass('connector', false)} onClick={this.disabledClients}>Connector Disabled</Button>
          </ButtonGroup>
        </div>
        <div className={this.getLeftClass()}>
          <ButtonGroup className="btn-block">
            <Button
              color="default" className={this.getButtonClass('prospect', false)}
              onClick={() => this.setFilter('prospect', false, false)}
            >
              Client
            </Button>{' '}
            <Button
              color="default" className={this.getButtonClass('prospect', true)}
              onClick={() => this.setFilter('prospect', true, true)}
            >
              Prospect
            </Button>
          </ButtonGroup>
        </div>
      </React.Fragment>
    );
  }

  getRightButtons() {
    return [(
      <IconButton
        key="export_feedback" icon="fa fa-download"
        label="Export Feedback Log" onClick={this.props.onExportFeedback}
        style={{ marginRight: 5 }}
      />
    ), (
      <IconButton
        key="feedback" icon="fa fa-plus"
        label="Feedback Log" onClick={this.props.onToggleFeedback}
        style={{ marginRight: 5 }}
      />
    ),(
      <Button color="default" key="newclient" className="mr-1" onClick={this.newClient}>
        <HubspotIcon color="#ff7a59" width={14} /> New Client
      </Button>
    ), (
      <Button color="default" key="newcontact" onClick={this.newContact}>
        <HubspotIcon color="#ff7a59" width={14} /> New Contact
      </Button>
    )];
  }

  newClient = (event) => {
    this.props.onCreate(event, 'client', 0, true);
    //this.context.history.replace('/clients/0');
  }

  newContact = (event) => {
    this.props.onCreate(event, 'contact', 0, true);
    //this.context.history.replace('/clients/0/contact/0');
  }

  getLeftClass() {
    return 'col-12 col-md-4 col-lg-3 mb-1';
  }

  allClients = () => {
    this.setFilter('connector', 'all', undefined);
  }

  enabledClients = () => {
    this.setFilter('connector', true, true);
  }

  disabledClients = () => {
    this.setFilter('connector', false, false);
  }

  setFilter(prop, stateValue, filterValue) {
    this.setState({
      [prop]: stateValue
    });
    this.props.onFilter({
      [prop]: filterValue
    });
  }

  getButtonClass(prop, button) {
    return this.state[prop] === button ? 'selected' : '';
  }
}
