import React, { Component } from 'react';
import { CollapseCard, ButtonSelect, FormRow } from 'emi-frontend';
import { Col, InputGroup, InputGroupAddon, InputGroupText, Input, Button as StrapButton } from 'reactstrap';
import RouteGrid from './RouteGrid';
import MultiRouteForm from './MultiRouteForm';
import RouteForm from '../job/RouteForm';
import RedirectGrid from '../job/RedirectGrid';
import RouteLineItem from '../job/RouteLineItem';
import PanelApiModal from '../job/PanelApiModal';

function emptyFn() {}

const statusMap = {
  live: 'Live Projects',
  paused: 'Paused Projects',
  test: 'Test Projects',
  kickoff: 'Kick-Off Projects',
  closed: 'Closed Projects'
};

export default class PartnerInformation extends Component {
  constructor(props) {
    super(props);

    this.grids = {};
    this.state = {
      group: 'project',
      multiRouteModal: false,
      redirectModal: false,
      redirectParams: null,
      routeModal: false,
      routeId: 0,
      routeLineItemModal: false,
      partnerId: 0,
      reconciliationEmail: null,

      connectorProject: false,
      projectId: 0,
      project_name: '',

      panelApiModal: false,
      panelApi: null,
      apiRouteId: 0,
      routeQuota: 0,
      // caller grid
      status: null,

      // Date filters for Route grids
      from_date: '',
      to_date: ''
    };
  }

  changeGroup(value) {
    this.setState(
      { group: value },
      () => this.callGridFunc('setExtraParam', 'group', this.state.group)
    );
  }

  toggleRouteModal = (routeId = 0, status = null, project_name = '') => {
    this.setState({
      routeModal: !this.state.routeModal,
      routeId, status, project_name
    });
  }

  toggleMultiRouteModal = (status = null) => {
    this.setState({
      multiRouteModal: !this.state.multiRouteModal,
      status
    });
  }

  toggleRedirectModal = (redirectParams = null, status = null) => {
    this.setState({
      redirectModal: !this.state.redirectModal,
      redirectParams, status 
    });
  }

  toggleRouteLineItemModal = (params = {}) => {
    this.setState({
      routeLineItemModal: !this.state.routeLineItemModal,
      routeId: params.routeId || 0,
      partnerId: params.partnerId || 0,
      reconciliationEmail: params.email,
      status: null,
      project_name: params.projectName || ''
    });
  }

  togglePanelApiModal = (params) => {
    this.setState(Object.assign({
      panelApiModal: !this.state.panelApiModal,
      panelApi: null,
      apiRouteId: 0,
      routeQuota: 0,
      projectId: 0,
      status: null
    }, params));
  }

  updateStateAndReload(stateUpdate, gridToReload) {
    this.setState(stateUpdate, () => {
      if (gridToReload) {
        this.reloadGrid(gridToReload);
      } else {
        this.reloadAll();
      }
    });
  }

  reloadGrid = (gridToReload) => {
    if (gridToReload && this.grids[gridToReload]) {
      this.grids[gridToReload].reload();
    }
  }

  reloadAll() {
    this.callGridFunc('reload');
  }

  setGridExtraParams(params) {
    this.callGridFunc('setExtraParams', params);
  }

  callGridFunc(method, ...params) {
    Object.keys(this.grids).forEach(key => this.grids[key][method](...params));
  }

  showRedirectModal = (event, params) => {
    event.preventDefault();
    event.stopPropagation();
    this.toggleRedirectModal(params);
  }

  exportRedirects = (params) => {
    if (this.redirectGrid) {
      this.redirectGrid.exportData(params);
    }
  }

  handleFilterDateChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  clearDateFilter = () => {
    this.setState({ from_date: '', to_date: '' }, this.applyDateFilter);
  }

  applyDateFilter = () => {
    this.setGridExtraParams({
      from_date: this.state.from_date,
      to_date: this.state.to_date
    });
  }

  render() {
    return (
      <CollapseCard title="Partner Information">
        <div className="form-row">
          <FormRow
            value={this.state.group}
            clientSideValidated={this.state.clientSideValidated}
            label="Data grouped by"
            name="group"
            component={ButtonSelect}
            options={{ project: 'Project', partner: 'Partner' }}
            fieldWidth={{ xs: 12, xl: 4 }}
            labelWidth={{ xs: 12, lg: 6 }}
            valueWidth={{ xs: 12, lg: 6 }}
            onChange={e => this.changeGroup(e.target.value)}
          />

          <Col lg={{ size: "4" }} className="my-1">
            <InputGroup size="sm">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>From</InputGroupText>
              </InputGroupAddon>
              <Input placeholder="From date" type="datetime-local" name="from_date" value={this.state.from_date} onChange={this.handleFilterDateChange} />
              <InputGroupAddon addonType="append">
                <InputGroupText>To</InputGroupText>
              </InputGroupAddon>
              <Input placeholder="To date" type="datetime-local" name="to_date" value={this.state.to_date} onChange={this.handleFilterDateChange} />
              <InputGroupAddon addonType="append">
                <StrapButton color="secondary" type="button" onClick={this.clearDateFilter}>
                  <i className="fa fa-times" aria-hidden="true"/>
                </StrapButton>
              </InputGroupAddon>
              <InputGroupAddon addonType="append">
                <StrapButton color="secondary" type="button" disabled={!this.state.from_date && !this.state.to_date} onClick={this.applyDateFilter}>
                  Apply
                </StrapButton>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </div>
        {Object.entries(statusMap).map(([status, title]) =>
          <RouteGrid
            key={status} title={title}
            paging={false}
            ref={(comp) => this.grids[status] = comp}
            extraParams={{
              invoice_id: this.props.invoice_id,
              group: this.state.group, status,
              from_date: this.state.from_date || undefined,
              to_date: this.state.to_date || undefined
            }}
            storeState={false}
            showRedirectModal={this.showRedirectModal}
            toggleRouteModal={this.toggleRouteModal}
            toggleMultiRouteModal={this.toggleMultiRouteModal}
            toggleRouteLineItemModal={this.toggleRouteLineItemModal}
            togglePanelApiModal={this.togglePanelApiModal}
            reloadGrid={this.reloadGrid}
            exportRedirects={this.exportRedirects}
          />
        )}
        <RouteForm
          id={this.state.routeId}
          isOpen={this.state.routeModal}
          toggle={this.toggleRouteModal}
          onSuccess={emptyFn}
          onDelete={() => this.updateStateAndReload({ routeModal: false, routeId: 0 }, this.state.status)}
          reload={() => this.reloadGrid(this.state.status)}
          projectName={this.state.project_name}
        />
        <MultiRouteForm
          id={0}
          invoice_id={this.props.invoice_id}
          isOpen={this.state.multiRouteModal}
          toggle={this.toggleMultiRouteModal}
          onSuccess={() => this.updateStateAndReload({ multiRouteModal: false })}
          onDelete={emptyFn}
          reload={emptyFn}
        />
        <RouteLineItem
          id={this.state.routeId}
          projectName={this.state.project_name}
          reconciliationEmail={this.state.reconciliationEmail}
          connectorProject={this.state.connectorProject}
          ioNumber={this.props.ioNumber}
          isOpen={this.state.routeLineItemModal}
          toggle={this.toggleRouteLineItemModal}
          partnerId={this.state.partnerId}
          invoiceId={this.props.invoice_id}
          onSuccess={this.onPartnersChanged}
          onDelete={this.onPartnersChanged}
        />
        <PanelApiModal
          routeId={this.state.apiRouteId}
          routeQuota={this.state.routeQuota}
          partnerId={this.state.partnerId}
          panelApi={this.state.panelApi}
          projectId={this.state.projectId}
          isOpen={this.state.panelApiModal}
          ioNumber={this.props.ioNumber}
          toggle={() => this.togglePanelApiModal()}
        />
        <RedirectGrid
          preload={false}
          extraParams={this.state.redirectParams}
          isOpen={this.state.redirectModal}
          toggle={() => this.toggleRedirectModal()}
          ref={(comp) => { this.redirectGrid = comp;}}
        />
      </CollapseCard>
    );
  }
}