import TargetCriteria from './TargetCriteria';

export default class Qualification extends TargetCriteria {

  isQuestionSelectDisabled() {
    return this.props.idx !== undefined;
  }

  shouldResetCriteria() {
    return !this.isQuestionSelectDisabled();
  }
}
