import { useState, useEffect } from 'react';
import { client } from 'emi-frontend';
import PanelApiLogModal from './PanelApiLogModal';

export default function PanelApiLogs({ projectId }) {
  const [showLogs, setShowLogs] = useState(false);
  const [externalIds, setExternalIds] = useState({});
  const apis = Object.keys(externalIds);
  const hasApis = apis.length > 0;

  useEffect(() => {
    if (projectId) {
      client.api('GET', `/panelapilogs/${projectId}`)
        .done((resp) => setExternalIds(resp.data))
        .fail(console.error)
    }
  }, [projectId]);

  function toggleLogsModal() {
    setShowLogs(!showLogs);
  }

  if (!hasApis) {
    return null;
  }

  return (
    <div className="form-row d-none d-sm-block action-buttons">
      <button
        type="button" className="btn btn-info mb-3"
        onClick={toggleLogsModal}
      >
        Panel API Logs
      </button>
      <PanelApiLogModal
        isOpen={showLogs}
        toggle={toggleLogsModal}
        apis={apis}
        externalIds={externalIds}
      />
    </div>
  );
}
