import React from 'react';
import ValueRow from './ValueRow';
import NewValue from './NewValue';
import { AbstractForm, ButtonSelect, swapArrayElements } from 'emi-frontend';

export default class AttributeForm extends AbstractForm {

  getApiPath = () => {
    return '/attribute';
  }

  cancelPath = () => {
    return '/attributes';
  }

  get modelName() {
    return 'attribute';
  }

  redirectPath = () => {
    return this.cancelPath();
  }

  getDefaultValues() {
    return {
      random_order: false,
      display_type: 'r',
      attribute_values: []
    };
  }

  addValue = (value) => {
    if (value.trim() === '') {
      this.showError({ responseText: 'Please provide attribute value.' });
      return;
    }

    this.addAttributeValue(value);
  }

  addAttributeValue(value) {
    this.handleAttributeValue(value);
  }

  deleteValue = (index) => {
    if (!this.state.values.attribute_values[index]) {
      this.showError({ responseText: 'Invalid index.' });
      return;
    }

    this.handleAttributeValue(this.state.values.attribute_values[index]);
  }

  moveValue = (direction, index) => {
    const data = {
      attribute_values: swapArrayElements(
        this.state.values.attribute_values,
        index,
        direction === 'up' ? index - 1 : index + 1
      )
    };
    this.updateValues(data, 'values', () => {
      if (this.isUpdate()) {
        this.submitForm();
      }
    });
  }

  handleAttributeValue(value) {
    // Adds or removes the attribute value
    this.updateObjectValues(
      'attribute_values',
      value,
      () => {
        if (this.isUpdate()) {
          this.submitForm();
        }
      }
    );
  }

  validateForm() {
    if (this.state.values.attribute_values.length === 0) {
      this.showError({ responseText: 'Create at least one attribute value.' });
      return false;
    }

    return super.validateForm();
  };

  getValueList() {
    const { attribute_values } = this.state.values;
    if (Array.isArray(attribute_values)) {
      return (
        <table className="table table-sm" key="attr_value_list">
          <tbody>
          {this.state.values.attribute_values.map((value, index) =>
            <ValueRow
              key={index} index={index} seq={index}
              attributeCount={attribute_values.length}
              value={value}
              onMove={this.moveValue}
              onDelete={this.deleteValue}
            />
          )}
          </tbody>
        </table>
      );
    }

    return '';
  }

  getValues() {
    return [
      <NewValue
        key="add_new_value"
        rowColClass="col-12 col-lg-6"
        labelColClass="col-col-12 col-lg-4"
        inputColClass="col-12 col-lg-6"
        btnColClass="col-12 col-lg-2 mt-1 mt-lg-0"
        onAddValue={this.addValue}
      />,
      this.getValueList()
    ]
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            name: 'name',
            required: true,
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}

          {this.getFormRow({
            name: 'title',
            label: 'Question',
            required: true,
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'display type',
            name: 'display_type',
            required: true,
            component: ButtonSelect,
            options: {
              r: 'Radio Button',
              d: 'Drop-down List',
              c: 'Checkbox'
            },
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}

          {this.getFormRow({
            name: 'random_order',
            label: 'Random order',
            component: ButtonSelect,
            options: { true: 'Yes', false: 'No' },
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}
        </div>

        {this.getValues()}

      </div>
    );
  }
}
