import { AbstractForm } from 'emi-frontend';

export default class ProjectForm extends AbstractForm {

  constructor(props) {
    super(props);

    this.state.loadingData = false;
  }

  get modelName() {
    return 'project';
  }

  getApiPath = () => {
    return '/panelapi';
  }

  componentDidMount() {
  }

  isNew() {
    return true;
  }

  showDeleteAction() {
    return false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (AbstractForm.valuesDidChange(this.state.values, nextProps.values)) {
      const values = Object.assign({}, this.getDefaultValues(), nextProps.values);
      this.setState({ values });
    }
  }

  getFormHeader() {
    return null;
  }

  resolveApiPath(...args) {
    return [this.getApiPath(), this.props.routeId, 'project', ...args].join('/');
  }

  getDeleteData() {
    return {
      id: this.getId()
    };
  }

  onDelete = () => {
    this.clientApi('DELETE', this.resolveApiPath(), this.getDeleteData())
    .done(this.successAction)
    .fail(this.deleteFail);
  }

  sendMethod() {
    return this.getId() ? 'PUT' : 'POST';
  }

  sendFormData = () => {
    return this.clientApi(this.sendMethod(), this.resolveApiPath(), this.getFormData());
  }

  isCreateProject() {
    return this.getId() === 0;
  }

  successAction = (response) => {
    this.setState({ message: 'Data updated successfully' });
    this.props.onUpdate(response.data, this.isCreateProject());
  }
}
