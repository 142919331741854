import React from 'react';
import { ProjectForm } from '../abstract';
import { formatCurrency, ReactSelect } from 'emi-frontend';
import CountryLanguages from './CountryLanguages';

export default class Project extends ProjectForm {

  getDefaultValues() {
    return {
      name: '',
      incidence: 0,
      length_of_interview: 0,
      country_language_id: 0,
      supplier_link: '',
      cpi: 0,
      days_in_field: 5,
      supported_devices: [0, 1, 2], // tablet, mobile, desktop
      max_daily_completes: null,
      reentry_interval: null
    };
  }

  getId = () => {
    return this.parseInt(this.state.values.id);
  }

  reloadData = () => {
    this.clientApi('POST', this.resolveApiPath('refresh'), {
      id: this.getId()
    })
    .done(this.successAction)
    .fail(this.deleteFail);
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            name: 'name',
            required: true,
            labelWidth: { xs: 12, sm: 2 },
            valueWidth: { xs: 12, sm: 10 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'country',
            name: 'country_language_id',
            component: CountryLanguages,
            partnerId: this.props.partnerId,
            required: true,
            isClearable: false,
            isDisabled: this.getId() > 0,
            onChange: this.selectChangeHandler('country_language_id'),
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'days in field',
            name: 'days_in_field',
            type: 'number',
            step: 1,
            min: 0,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 7 },
            valueWidth: { xs: 12, sm: 5 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'ir',
            name: 'incidence',
            type: 'number',
            min: 0,
            max: 100,
            step: 1,
            postfix: '%',
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'max daily completes',
            name: 'max_daily_completes',
            type: 'number',
            step: 1,
            min: 0,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 7 },
            valueWidth: { xs: 12, sm: 5 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'loi',
            name: 'length_of_interview',
            type: 'number',
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'reentry interval',
            name: 'reentry_interval',
            type: 'number',
            step: 1,
            min: 0,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 7 },
            valueWidth: { xs: 12, sm: 5 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'cpi',
            type: 'number',
            step: 0.01,
            min: 0,
            formatter: formatCurrency,
            prefix: '$',
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'supported devices',
            name: 'supported_devices',
            component: ReactSelect,
            isMulti: true,
            closeMenuOnSelect: false,
            onChange: this.selectChangeHandler('supported_devices'),
            options: [
              { value: 0, label: 'tablet' },
              { value: 1, label: 'mobile' },
              { value: 2, label: 'desktop' }
            ],
            labelWidth: { xs: 12, sm: 2 },
            valueWidth: { xs: 12, sm: 5 }
          })}
        </div>

        {this.isUpdate() && 
          <div className="form-row">
            {this.getFormRow({
              name: 'status',
              type: 'select',
              fieldWidth: { xs: 12, sm: 6 },
              labelWidth: { xs: 12, sm: 4 },
              valueWidth: { xs: 12, sm: 8 },
              children: [
                <option key="active" value="2">ACTIVE</option>,
                <option key="complete" value="3">COMPLETE</option>,
                <option key="archived" value="4">ARCHIVED</option>,
                <option key="pause" value="5">PAUSED</option>,
                <option key="reconciled" value="6">RECONCILED</option>,
                <option key="system_paused" value="7">SYSTEM PAUSED</option>
              ]
            })}
          </div>
        }

      </div>
    );
  }
}
