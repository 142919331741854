import React from 'react';
import classNames from 'classnames';
import { Row, HeadCell } from 'emi-frontend';

export default class MultiRow extends Row {

  getBaseClasses() {
    return ['flex-column', 'd-flex', 'flex-nowrap', 'grid-row', 'content', 'rounded'];
  }

  getRowClick() {
    return null;
  }

  onEditPartnerRow = (row) => {
    if (typeof this.props.onClick === 'function') {
      this.props.onClick(row)
    }
  }

  getRowClasses() {
    if (this.props.summary) {
      return [this.getBaseClasses().slice(1), this.props.className, this.getExtraStyle()];
    }
    return [this.getBaseClasses(), this.props.className, this.getExtraStyle()];
  }

  composeRowProps(className, editPartnerRow = false, row) {
    return {
      className: classNames(className, { 'cursor-pointer': editPartnerRow }),
      onClick: editPartnerRow ? () => this.onEditPartnerRow(row) : null
    };
  }

  getDetailRow(row, index) {
    row[`${this.props.row.group}_id`] = this.props.row.id;
    row.rowType = this.props.row.group === 'partner' ? 'project' : 'partner';
    row[`${row.rowType}_id`] = row.id;
    return (
      <div 
        key={`partner${index}${row.id}`}
        {...this.composeRowProps('d-flex flex-row flex-nowrap grid-row', true, row)}
      >
        {
          Object
            .keys(this.props.columns)
            .map((columnIndex) =>
              <this.context.components.Cell
                key={columnIndex}
                row={row}
                column={this.props.columns[columnIndex]}
                summary={this.props.summary}
                rowIndex={this.props.rowIndex}
                rowCount={this.props.rowCount}
                currentPage={this.props.currentPage}
                pageSize={this.props.pageSize}
              />
            )
        }
      </div>
    );
  }

  isProjectRow() {
    return this.props.row.group === 'project';
  }

  getNameRow() {
    if (this.props.summary) {
      // Render the top Summary row
      return super.getRowContent();
    }

    const { data, ...row } = this.props.row;
    row.survey_url = [];

    // Calculate the summary for the group header line
    Object
      .keys(this.props.columns)
      .forEach((columnIndex) => {
        const column = this.props.columns[columnIndex];
        if (column.summarize) {
          if (row[column.dataField] === undefined) {
            row[column.dataField] = 0;
          }

          this.props.row.data.forEach((d) => {
            if (column.dataField === 'survey_url') {
              row.survey_url.push({
                name: d.name,
                url: d.survey_url,
                variables: d.variables,
                fixed_variables: d.fixed_variables
              });
            } else {
              row[column.dataField] += d[column.dataField];
            }
          });
        }
      });

    row.progress = 0;
    row[`${row.group}_id`] = row.id;
    if (row.quota > 0) {
      row.progress = Math.round(row.completed * 100 / row.quota);
    }

    return (
      <div
        {...this.composeRowProps('d-flex flex-row flex-nowrap grid-row font-weight-bold')}
        key="namerow"
      >
        {
          Object
            .keys(this.props.columns)
            .map((columnIndex) =>
              <this.context.components.Cell
                key={columnIndex}
                row={row}
                column={this.props.columns[columnIndex]}
                summary={this.props.summary}
                rowIndex={this.props.rowIndex}
                rowCount={this.props.rowCount}
                currentPage={this.props.currentPage}
                pageSize={this.props.pageSize}
              />
            )
        }
      </div>
    );
  }

  getDataRows() {
    if (!this.props.summary) {
      return (
        <div key="datarows" className="d-flex flex-column flex-nowrap grid-multiview-row">
          {this.props.row.data.map((row, index) => this.getDetailRow(row, index))}
        </div>
      );
    }
  }

  getHeaderLine() {
    if (this.props.rowIndex > 0) {
      return (
        <div key={`extrahead_${this.props.rowIndex}`} className="d-flex flex-row flex-nowrap grid-row header">
          {
            Object
              .keys(this.props.columns)
              .map((columnIndex) =>
                <HeadCell
                  key={columnIndex}
                  column={{ ...this.props.columns[columnIndex], sortable: false }}
                  onSort={() => ''}
                />
            )
          }
        </div>
      );
    }
  }

  getRowContent() {
    return [
      this.getHeaderLine(),
      this.getNameRow(),
      this.getDataRows()
    ];
  }
}
