import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListGroup } from 'reactstrap';
import QuotaCell from './QuotaCell';

export default class QuotaCells extends Component {

  static propTypes = {
    quotaCells: PropTypes.array.isRequired,
    path: PropTypes.array.isRequired,
    questions: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    exists: PropTypes.bool.isRequired
  };

  render() {
    return (
      <ListGroup style={{ width: '100%' }} className="top-margin-10">
        <QuotaCell
          path={this.props.path}
          questions={this.props.questions}
          onChange={this.props.onChange}
        />
        {this.props.quotaCells.map((quotaCell, idx) =>
          <QuotaCell
            idx={idx}
            key={idx}
            values={quotaCell}
            path={this.props.path}
            questions={this.props.questions}
            onChange={this.props.onChange}
            exists={this.props.exists}
          />
        )}
      </ListGroup>
    );
  }
}