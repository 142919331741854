import React from 'react';
import {
  Row, 
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Spinner
} from 'reactstrap';
import { CollapseCard, AlertMessage, AbstractForm } from 'emi-frontend';

export default class Feasibility extends AbstractForm {

  static defaultProps = {
    values: {},
    isPartnerForm: false
  };

  constructor(props) {
    super(props);

    Object.assign(this.state, {
      questions: null
    });
  }

  getId = () => {
    return this.parseInt(this.props.partnerId);
  }

  isNew() {
    return true;
  }

  shouldLoadData() {
    return false;
  }

  loadQuestionsData(country_id, feasibility = false) {
    this.setState({
      loadingData: true
    }, () =>
      this.clientApi('GET', `/panelapi/${this.props.partnerId}/questions/${country_id}?feasibility=${feasibility}`)
        .done((resp) => this.loadDone(resp))
        .fail(this.loadFail)
    );
  }

  loadDone = (response) => {
    this.setState({
      loadingData: false,
      questions: response.data
    });
  }

  getTargetingCriteria() {
    return {};
  }

  isRequired() {
    return !this.props.isPartnerForm;
  }

  addTargetCriteria = (criteria) => {
    this.updateTargetCriteria('push', criteria);
  }

  removeTargetCriteria = (idx) => {
    this.updateTargetCriteria('splice', idx, 1);
  }

  modifyTargetCriteria = (idx, criteria) => {
    this.updateTargetCriteria('splice', idx, 1, criteria);
  }

  updateTargetCriteria(method, ...args) {
    const [name, targeting_criteria] = Object.entries(this.getTargetingCriteria()).shift();
    targeting_criteria[method](...args);

    const values = this.valueChanged(name, targeting_criteria);

    this.updateValues(values);
  }

  sendFormData = () => {
    return this.clientApi('POST', `/panelapi/${this.props.partnerId}/feasibility`, this.getFormData());
  }

  successAction = (response) => {
    this.updateValues(response.data);
  }

  countryChangeHandler = (option) => {
    const changed = this.valueChanged('country_id', option ? option.value : '')
    this.updateValues(changed);

    this.loadQuestionsData(changed.country_id);
  }

  showTargetCriteria() {
    return this.parseInt(this.state.values.country_id) > 0 && this.state.questions !== null;
  }

  showButton() {
    return false;
  }

  getCardBodyContent() {
    return null;
  }

  getButton() {
    return this.state.sendingData ? (
      <Spinner color="info" />
    ) : (
      <Button color="info" type="button" onClick={this.submitForm}>
        <span className="text-capitalize">Run query</span>
      </Button>
    );
  }

  render() {
    return (
      <CollapseCard title="Feasibility Query">
        <Row className="emi-form prodege-feasibility">
          <Col md="12">
              <Card className="form-card">  
                <CardBody>
                  <AlertMessage
                    color={this.state.success ? 'info' : 'danger'}
                    message={this.state.message}
                    closeHandler={this.removeMessage}
                  />

                  {this.getCardBodyContent()}
                </CardBody>

                {this.showButton() &&
                  <CardFooter className="action-buttons d-none d-lg-block">
                    {this.getButton()}
                  </CardFooter>
                }
              </Card>
          </Col>
        </Row>
      </CollapseCard>
    );
  }
}