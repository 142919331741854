import React from 'react';
import { ListGroup } from 'reactstrap';
import { FormDisabledRow } from 'emi-frontend';
import TargetCriteria from './TargetCriteria';
import LocalizationSelect from './LocalizationSelect';
import { AbstractFeasibility } from '../abstract';

export default class Feasibility extends AbstractFeasibility {

  getDefaultValues() {
    return {
      criterias: [],
      survey_localization: '',
      completes_required: '',
      expected_loi: '',
      expected_ir: '',
      field_time: '',
      count: null,
      cpi: null
    };
  }

  countryChangeHandler = (option) => {
    const changed = this.valueChanged('survey_localization', option ? option.value : '')
    this.updateValues(changed);

    this.loadQuestionsData(0);
  }

  getTargetingCriteria() {
    const criterias = this.state.values.criterias.slice();
    return { criterias };
  }

  getFormData() {
    const { criterias, survey_localization, completes_required, expected_loi, expected_ir, field_time } = this.state.values;

    const quotas = [{
      'buyer_quota_id': 'fea',
      'required_count': completes_required,
      'criteria': criterias
    }];
    return {
      quotas,
      survey_localization,
      completes_required,
      expected_loi,
      expected_ir,
      field_time
    };
  }

  valueChanged(name, value) {
    const changed = super.valueChanged(name, value);

    if (name === 'survey_localization') {
      changed.criterias = [];
      changed.count = null;
      changed.cpi = null;
    }

    if (name === 'criterias') {
      changed.count = null;
      changed.cpi = null;
    }

    return changed;
  }

  showButton() {
    return Array.isArray(this.state.values.criterias) &&
      this.state.values.criterias.length > 0;
  }

  showTargetCriteria() {
    return this.state.values.survey_localization !== '' && this.state.questions !== null;
  }

  successAction = (response) => {
    // Convert response
    const [ quota ] = response.data.quotas;
    this.updateValues({
      count: quota.count,
      cpi: response.data.cpi
    });
  }

  getCardBodyContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            label: 'country',
            name: 'survey_localization',
            component: LocalizationSelect,
            partnerId: this.props.partnerId,
            required: true,
            isClearable: false,
            onChange: this.countryChangeHandler,
            fieldWidth: { xs: 12, sm: 4 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'completes',
            name: 'completes_required',
            type: 'number',
            min: 0,
            step: 1,
            cssClass: 'fixwidth',
            style: { width: 100 },
            fieldWidth: { xs: 12, sm: 4 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'ir',
            name: 'expected_ir',
            type: 'number',
            min: 0,
            max: 100,
            step: 1,
            postfix: '%',
            cssClass: 'fixwidth',
            style: { width: 100 },
            fieldWidth: { xs: 12, sm: 4 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'loi',
            name: 'expected_loi',
            type: 'number',
            min: 1,
            step: 1,
            postfix: 'min',
            cssClass: 'fixwidth',
            style: { width: 100 },
            fieldWidth: { xs: 12, sm: 4 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'field time',
            name: 'field_time',
            type: 'number',
            min: 1,
            step: 1,
            postfix: 'days',
            cssClass: 'fixwidth',
            style: { width: 100 },
            fieldWidth: { xs: 12, sm: 4 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}
        </div>

        {this.showTargetCriteria() &&
          <div className="form-row">
            <ListGroup style={{ width: '100%' }}>
              <TargetCriteria
                questions={this.state.questions}
                onAdd={this.addTargetCriteria}
                onDelete={this.removeTargetCriteria}
                onChange={this.modifyTargetCriteria}
                identifier="qualification_code"
                allowCreate
              />
              {this.state.values.criterias.map((criteria, idx) =>
                <TargetCriteria
                  idx={idx}
                  key={idx}
                  criteria={criteria}
                  questions={this.state.questions}
                  onAdd={this.addTargetCriteria}
                  onDelete={this.removeTargetCriteria}
                  onChange={this.modifyTargetCriteria}
                  identifier="qualification_code"
                  isDeletable
                />
              )}
            </ListGroup>
          </div>
        }

        {this.state.values.count !== null &&
          <div>
            <div className="form-row">
              <FormDisabledRow
                  label="Available respondents"
                  name="count"
                  value={this.state.values.count}
                  fieldWidth={ { xs: 6, sm: 6} }
                  labelWidth={ {xs: 6, sm: 6} }
                  valueWidth={ {xs: 6, sm: 6} }
              />
            </div>
            <div className="form-row">
              <FormDisabledRow
                  label="CPI"
                  name="cpi"
                  value={`$${this.state.values.cpi}`}
                  fieldWidth={ { xs: 6, sm: 6} }
                  labelWidth={ {xs: 6, sm: 6} }
                  valueWidth={ {xs: 6, sm: 6} }
              />
            </div>
          </div>
        }
      </div>
    );
  }
}
