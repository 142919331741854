import { client, Filter, Grid, Excel } from 'emi-frontend';
import {
  Button
} from 'reactstrap';
import { coverageTypes } from '../coverage/constants';

export default class CoverageFilter extends Filter {
  constructor(props) {
    super(props);

    this.state.searchTitle = 'Search by Job Name, Project Manager, etc.';
    this.state.exportMatchModal = false;
  }

  get filterName() {
    return 'coverageFilter';
  }

  getCoverageExportHeaders() {
    return {
      pm_lead: 'Lead RM',
      pm_covering: 'RM Covering',
      sales: 'Sales',
      project_id: 'Project ID',
      project_status: 'Status',
      project_name: 'Name',
      client_name: 'Client',
      io_number: 'IO#',
      close_date: 'Deadline',
      reporting_link: 'Reporting link',
      notes: 'Notes',
      suppliers: 'Suppliers',
      pm_notes: 'Covering RM Notes',
      priority: 'Priority',
      setup: 'Set up',
      monitor: 'Monitor',
      updates: 'Updates',
      close: 'Close',
      additional: 'Additional detail'
    };
  }

  downloadCoverageExport = () => {
    client
      .api('GET', '/project/coverageexport/bg')
      .done((response) => {
        const excel = new Excel();
        excel.addJsonData(
          response.data.map((row) => {
            Object.keys(coverageTypes).forEach((type) => {
              if (row[type] && Array.isArray(row[type])) {
                row[type] = row[type].map((val) => coverageTypes[type].values[val]).join(', ');
              }
            });
            return row;
          }),
          this.getCoverageExportHeaders(),
          'Sheet 1'
        );
        excel.saveAs(`coverage_export_${Grid.getTimeStamp()}.xlsx`);
      })
  }

  getRightButtons() {
    return [
      <Button key="coverage" color="default" onClick={this.downloadCoverageExport} style={{ marginRight: 5 }}>
        <i className="fa fa-file-excel-o text-success"></i> Export
      </Button>
    ];
  }
}
