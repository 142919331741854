import { Fragment } from 'react';
import { useForm } from '../../lib/hooks';
import QuotaQuotation from './QuotaQuotation';
import QuotaSummary from './QuotaSummary';
import OtherCosts from './OtherCosts';

export default function Quotas({ bid_revision_id }) {
  const { values: quotas } = useForm({ 
    apiPath: `/bid/${bid_revision_id}/quota`, defaultValues: [], shouldLoad: !!bid_revision_id 
  });
  const { values: otherCosts, loadData: loadOtherCosts } = useForm({ 
    apiPath: `/bid/${bid_revision_id}/othercost`, defaultValues: [], shouldLoad: !!bid_revision_id 
  });

  return (
    <>
      {quotas.map(quota =>
        <Fragment key={quota.id}>
          <QuotaQuotation
            quota={quota}
            hideSkipped={false}
          />
        </Fragment>
      )}

      <OtherCosts
        bid_revision_id={ bid_revision_id }
        otherCosts={otherCosts}
        loadOtherCosts={loadOtherCosts}
      />

      <QuotaSummary
        quotas={quotas}
        otherCosts={otherCosts}
      />
    </>
  );
}