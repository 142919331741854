import React from 'react';
import { FormGroup, Label, Input, Row, Col, Button } from 'reactstrap';
import { AbstractForm, SaveButton, IconButton, CollapseCard, UrlHints, ucFirst, Desktop, Mobile, LocalSelect, CountrySelect, ButtonSelect } from 'emi-frontend';
import panelApi from '../panel_api';
import FraudExportModal from '../job/FraudExportModal';
import FeedbackForm from './FeedbackForm';
import FeedbackGrid from './FeedbackGrid';
import { sampleType } from './constants';

const urls = {
  completion_url: 'Completion URL',
  disqualify_url: 'Disqualify URL',
  quotafull_url: 'Quotafull URL', 
  speeder_url: 'Quality Fail',
  duplicate_url: 'Duplicate URL'
};

class PartnerForm extends AbstractForm {
  constructor(props) {
    super(props);

    const { fid = null } = props.match.params;
    this.state.feedbackModal = fid !== null;
    this.state.exportFraudModal = false;
    this.state.feedbackId = fid;
  }
  
  getDefaultValues() {
    return {
      name: '',
      reconciliation_email: '',
      completion_url: '',
      disqualify_url: '',
      quotafull_url: '',
      speeder_url: '',
      duplicate_url: '',
      partner_contact: '',
      hubspot_id: '',
      project_minimum: '',
      sample_type: [],
      sample_country: [],
      bid_alias_1: '',
      bid_alias_2: '',
      bid_alias_3: '',
      disabled_in_bid: false
    };
  }

  getApiPath = () => {
    return '/partner';
  }

  cancelPath = () => {
    return '/partners';
  }

  get modelName() {
    return 'partner';
  }

  redirectPath = () => {
    return this.cancelPath();
  }

  toggleModal = () => {
    this.setState({
      feedbackId: null,
      feedbackModal: !this.state.feedbackModal
    });
  }

  toggleExportFraudModal = () => {
    this.setState({
      exportFraudModal: !this.state.exportFraudModal
    });
  }

  onEditFeedback = (row) => {
    this.setState({
      feedbackId: row.id,
      feedbackModal: true
    });
  }

  reloadFeedbackGrid = () => {
    if (this.feedbackGrid) {
      this.feedbackGrid.reload();
    }
  }

  onSuccessFeedback = () => {
    this.toggleModal();
    this.reloadFeedbackGrid();
  }

  getUpdateButtons() {
    return this.isUpdate() ? (
      <React.Fragment>
        <IconButton key="feedback" icon="fa fa-plus" label="Feedback Log" className="float-right" onClick={this.toggleModal} />
        <Button color="default" onClick={this.toggleExportFraudModal} className="float-right">
          <i className="fa fa-tags"></i> Fraud Export
        </Button>
      </React.Fragment>
    ) : null;
  }

  getHeaderButtons() {
    return (
      <React.Fragment>
        {this.isNew() && <SaveButton key="save" model={this.modelName} className="float-right" />}
        <Desktop>{this.getUpdateButtons()}</Desktop>
      </React.Fragment>
    );
  }

  getFormHeader() {
    return (
      <React.Fragment>
        {super.getFormHeader()}
        <Mobile>
          <div className="mt-1 d-flex justify-content-around">
            {this.getUpdateButtons()}
          </div>
        </Mobile>
      </React.Fragment>
    );
  }

  getCounter(field) {
    return (
      <Col key={field} xs="12" lg="3">
        <FormGroup className="row">
          <Label htmlFor={field} className="text-uppercase" xs="12" lg="7">{ucFirst(field)}</Label>
          <Col xs="12" lg="5">
            <Input className="form-control" id={field} plaintext value={this.state.values[field]} />
          </Col>
        </FormGroup>
      </Col>
    );
  }

  getEditFields() {
    return [
      this.getFormRow({
        key: 'partner_name',
        name: 'name',
        required: true,
        fieldWidth: { xs: 12, lg: 6 },
        labelWidth: { xs: 12, lg: 2 },
        valueWidth: { xs: 12, lg: 10 }
      }),
      this.getCounter('projects'),
      this.getCounter('completes')
    ];
  }

  getPanelApiComponent() {
    return this.state.values.panel_api && panelApi[`${this.state.values.panel_api}_partner`] ?
      panelApi[`${this.state.values.panel_api}_partner`] : undefined; 
  }

  showPartnerLinks() {
    return this.isNew() || this.state.values.panel_api === null;
  }

  getFormContent() {
    return (
      <div>
        <CollapseCard title="Partner Profile">
          <div className="form-row">
            {
              this.isNew()
                ? this.getFormRow({
                    name: 'name',
                    required: true,
                    labelWidth: { xs: 12, lg: 2 },
                    valueWidth: { xs: 12, lg: 10 }
                  })
                : this.getEditFields()
              
            }
          </div>

          <div className="form-row">
            {this.getFormRow({
              name: "reconciliation_email",
              label: "reconciliation email",
              labelWidth: { xs: 12, lg: 2 },
              valueWidth: { xs: 12, lg: 5 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              name: "partner_contact",
              label: "partner contact",
              type: "textarea",
              labelWidth: { xs: 12, lg: 2 },
              valueWidth: { xs: 12, lg: 5 }
            })}
          </div>
        </CollapseCard>

        <CollapseCard title="Estimator">
          <div className="form-row">
            {this.getFormRow({
              name: "project_minimum",
              label: "Minimum fee",
              type: "textarea",
              rows: 5,
              labelWidth: { xs: 12, lg: 3 },
              valueWidth: { xs: 12, lg: 9 },
              fieldWidth: { xs: 12, lg: 6 },
            })}

            {this.getFormRow({
              name: "sample_type",
              label: "sample type",
              component: LocalSelect,
              isMulti: true,
              options: sampleType.map((type) => ({ value: type, label: type })),
              onChange: this.selectChangeHandler('sample_type'),
              closeMenuOnSelect: false,
              labelWidth: { xs: 12, lg: 3 },
              valueWidth: { xs: 12, lg: 9 },
              fieldWidth: { xs: 12, lg: 6 },
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              name: "sample_country",
              label: "Sample country",
              onChange: this.selectChangeHandler('sample_country'),
              component: CountrySelect,
              isMulti: true,
              labelWidth: { xs: 12, lg: 3 },
              valueWidth: { xs: 12, lg: 9 },
              fieldWidth: { xs: 12, lg: 6 },
            })}

            {this.getFormRow({
              name: "bid_alias_1",
              label: "Bid alias 1",
              type: 'email',
              labelWidth: { xs: 12, lg: 3 },
              valueWidth: { xs: 12, lg: 9 },
              fieldWidth: { xs: 12, lg: 6 },
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              name: "bid_alias_2",
              label: "Bid alias 2",
              type: 'email',
              labelWidth: { xs: 12, lg: 3 },
              valueWidth: { xs: 12, lg: 9 },
              fieldWidth: { xs: 12, lg: 6 },
            })}

            {this.getFormRow({
              name: "bid_alias_3",
              label: "Bid alias 3",
              type: 'email',
              labelWidth: { xs: 12, lg: 3 },
              valueWidth: { xs: 12, lg: 9 },
              fieldWidth: { xs: 12, lg: 6 },
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              name: 'hubspot_id',
              label: 'hubspot id',
              type: 'number',
              step: 1,
              min: 0,
              labelWidth: { xs: 12, lg: 3 },
              valueWidth: { xs: 12, lg: 9 },
              fieldWidth: { xs: 12, lg: 6 },
            })}

            {this.getFormRow({
              name: 'disabled_in_bid',
              label: 'Disabled in Bids',
              component: ButtonSelect,
              options: { true: 'Yes', false: 'No' },
              labelWidth: { xs: 12, lg: 3 },
              valueWidth: { xs: 12, lg: 9 },
              fieldWidth: { xs: 12, lg: 6 },
            })}
          </div>
        </CollapseCard>

        {this.showPartnerLinks() &&
          <CollapseCard title="Partner Links">
            {Object.keys(urls).map((key, idx) =>
              (
                <div key={idx} className="form-row">
                  {this.getFormRow({
                    label: urls[key],
                    name: key,
                    labelWidth: { xs: 12, md: 3 },
                    valueWidth: { xs: 12, md: 9 }
                  })}
                </div>
              )
            )}

            <UrlHints
              title={'URL can contain...'}
              hints={{
                partner_id: 'original ID of the respondent',
                status: 'end status of the respondent in the survey (complete, disqualify, quotafull, dqfail, duplicate)',
                var: 'any variable appended to the start link'
              }}
            />
          </CollapseCard>
        }
      </div>
    );
  }

  render() {
    if (this.isNew()) {
      return super.render();
    }
    const PanelApiComponent = this.getPanelApiComponent();

    return (
      <div>
        {super.render()}
        {PanelApiComponent !== undefined &&
          <Row className="emi-form">
            <Col md="12">
              <PanelApiComponent partnerId={this.getId()} isPartnerForm />
            </Col>
          </Row>
        }
        <FeedbackGrid
          storeState={false}
          extraParams={{ partner_id: this.getId() }}
          ref={(c) => this.feedbackGrid = c}
          onDataChanged={this.reloadGridData}
          onRowSelection={this.onEditFeedback} 
        />
        <FeedbackForm 
          isOpen={this.state.feedbackModal} toggle={this.toggleModal}
          onSuccess={this.onSuccessFeedback} onDelete={this.reloadFeedbackGrid}
          id={this.state.feedbackId}
          values={{ partner_id: this.getId(), partner_name: this.state.values.name }}
        />
        {this.isUpdate() &&
          <FraudExportModal
            isOpen={this.state.exportFraudModal}
            toggle={this.toggleExportFraudModal}
            partner_id={this.getId()}
          />
        }
      </div>
    );
  }
}

export default PartnerForm;
