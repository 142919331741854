import React, { Component } from 'react';
import AttributeFilter from './Filter';
import AttributeGrid from './Grid';
import { filterList } from 'emi-frontend';

const AttributeFilterList = filterList(
  AttributeFilter,
  [AttributeGrid]
);

class List extends Component {

  render() {
    return (
      <div className="row">
        <div className="col">
          <AttributeFilterList />
        </div>
      </div>
    );
  }
}

export default List;
