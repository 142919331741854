import React from 'react';
import update from 'immutability-helper';
import { Card, CardBody } from 'reactstrap';
import { formatCurrency, FormDisabledRow } from 'emi-frontend';
import Quota from './Quota';
import CountryLanguages from './CountryLanguages';
import { AbstractFeasibility } from '../abstract';

export default class Feasibility extends AbstractFeasibility {

  getDefaultValues() {
    return {
      incidence: 0,
      length_of_interview: 1,
      campaign_quotas: [],
      country_id: '',
      days_in_field: 5,
      estimated_completes: null,
      cpi: null,
    };
  }

  getTargetingCriteria() {
    const campaign_quotas = this.state.values.campaign_quotas.slice();
    return { campaign_quotas };
  }

  getFormData() {
    const {
      incidence,
      length_of_interview,
      campaign_quotas,
      country_id: country_language_id,
      days_in_field
    } = this.state.values;

    return {
      incidence,
      length_of_interview,
      campaign_quotas,
      country_language_id,
      days_in_field
    };
  }

  valueChanged(name, value) {
    const changed = super.valueChanged(name, value);

    if (name === 'country_id') {
      changed.campaign_quotas = [];
      changed.estimated_completes = null;
      changed.cpi = null;
    }

    if (name === 'campaign_quotas') {
      changed.estimated_completes = null;
      changed.cpi = null;
    }

    return changed;
  }

  showButton() {
    return Array.isArray(this.state.values.campaign_quotas) &&
      this.state.values.campaign_quotas.length > 0;
  }

  successAction = (response) => {
    const { data } = response;
    const set = {};

    Object.keys(data).forEach((name) => {
      if (name === 'campaign_quotas') {
        if (Array.isArray(data.campaign_quotas)) {
          data.campaign_quotas.forEach((quota, idx) => {
            set.campaign_quotas = {
              [idx]: {
                $merge: quota
              }
            };
          });
        }
      } else {
        set[name] = {
          $set: data[name]
        };
      }
    });

    const values = update(this.state.values, set);

    this.setState({ values });
  }

  getCardBodyContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            label: 'Country',
            name: 'country_id',
            required: this.isRequired(),
            component: CountryLanguages,
            partnerId: this.props.partnerId,
            isClearable: false,
            onChange: this.countryChangeHandler,
            fieldWidth: { xs: 12, sm: 4 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'incidence',
            type: 'number',
            required: this.isRequired(),
            min: 0,
            max: 100,
            step: 1,
            postfix: '%',
            cssClass: 'fixwidth',
            style: { width: 100 },
            fieldWidth: { xs: 12, sm: 4 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'loi',
            name: 'length_of_interview',
            required: this.isRequired(),
            type: 'number',
            min: 1,
            step: 1,
            postfix: 'min',
            cssClass: 'fixwidth',
            style: { width: 100 },
            fieldWidth: { xs: 12, sm: 4 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
              label: 'days in field',
              name: 'days_in_field',
              type: 'number',
              min: 1,
              step: 1,
              postfix: 'days',
              cssClass: 'fixwidth',
              style: { width: 100 },
              fieldWidth: { xs: 12, sm: 4 },
              labelWidth: { xs: 12, sm: 4 },
              valueWidth: { xs: 12, sm: 8 }
            })}
        </div>

        {this.showTargetCriteria() &&
          <div className="form-row">
             <Card className="top-margin-10" style={{ width: '100%' }}>
              <CardBody>
                <Quota
                  questions={this.state.questions}
                  partnerId={this.props.partnerId}
                  countryId={this.state.values.country_id}
                  addQuota={this.addTargetCriteria}
                  feasibility
                />
              </CardBody>
            </Card> 
            {Object.keys(this.state.values.campaign_quotas).map(key =>
              <Card className="top-margin-10" key={key} style={{ width: '100%' }}>
                <CardBody>
                  <Quota
                    values={Object.assign(
                      { id: key }, this.state.values.campaign_quotas[key] 
                    )}
                    questions={this.state.questions}
                    partnerId={this.props.partnerId}
                    countryId={this.state.values.country_id}
                    deleteQuota={this.removeTargetCriteria}
                    updateQuota={this.modifyTargetCriteria}
                    feasibility
                  />
                </CardBody>
              </Card> 
            )}
          </div>
        }

        {this.state.values.estimated_completes !== null &&
          <div>
            <div className="form-row">
              <FormDisabledRow
                  label="estimated completes"
                  name="estimated_completes"
                  value={this.state.values.estimated_completes}
                  fieldWidth={ { xs: 12, sm: 6} }
                  labelWidth={ {xs: 12, sm: 6} }
                  valueWidth={ {xs: 12, sm: 6} }
              />
            </div>
            <div className="form-row">
              <FormDisabledRow
                  label="CPI"
                  name="cpi"
                  value={`${formatCurrency(this.state.values.cpi)}`}
                  fieldWidth={ { xs: 6, sm: 6} }
                  labelWidth={ {xs: 6, sm: 6} }
                  valueWidth={ {xs: 6, sm: 6} }
              />
            </div>
          </div>
        }
      </div>
    );
  }
}
