import JobTestGrid from '../job/TestGrid';

export default class TestGrid extends JobTestGrid {
  get gridName() {
    return 'esearchtestGrid';
  }

  getApiPath = () => {
    return '/esearch';
  }
}