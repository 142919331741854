import React from 'react';
import { AbstractForm, CollapseCard, FormDisabledRow } from 'emi-frontend';

class ConnectorUserForm extends AbstractForm {

  getId = () => {
    return this.props.id;
  }

  getApiPath = () => {
    return '/connector_user';
  }

  get modelName() {
    return 'connector user';
  }

  getFormHeader() {
    return null;
  }

  getActionButtons() {
    return null;
  }

  getFormContent() {
    return (
      <CollapseCard title="Connector User">
        <div className="form-row">
          <FormDisabledRow
            label="email"
            name="email"
            value={this.state.values.email}
            fieldWidth={ {xs: 12, sm: 6} }
          />

          {this.getFormRow({
            name: 'password',
            type: 'password',
            required: true,
            autoComplete: 'new-password',
            fieldWidth: { xs: 12, sm: 6 }
          })}
        </div>

        <div className="form-row">
          <FormDisabledRow
            label="last login"
            name="last_login"
            fieldWidth={ {xs: 12, sm: 6} }
          />
        </div>
      </CollapseCard>
    );
  }
}

export default ConnectorUserForm;
