import { ProdegeCountrySelect } from '../prodege';

export default class CountryLanguages extends ProdegeCountrySelect {
  
  getOptionsFromArray() {
    return this.state.data.map((row) => {
      return { value: row.id, label: row.abbr };
    });
  }
}
