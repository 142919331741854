import React from 'react';
import { CardFooter } from 'reactstrap';
import { AbstractForm } from 'emi-frontend';

export default class Exclusion extends AbstractForm {

  get modelName() {
    return 'exclusion';
  }

  getDefaultValues() {
    return {
      exclusion_file: ''
    };
  }

  getApiPath = () => {
    return '/panelapi';
  }

  getId = () => {
    return this.parseInt(this.props.id);
  }

  sendFormData = () => {
    const formData = new FormData();
    formData.append('campaign_id', this.props.partnerProjectId);
    // Pass IO# as Purchase Order
    formData.append('purchase_order', this.props.ioNumber);

    if (this.upload) {
      const files = this.upload.files;
      for (let i = 0; i < files.length; i++) {
        formData.append('exclusion_file', files[i], files[i].name);
      }
    }

    return this.clientApi(
      'POST', `${this.getApiPath()}/${this.props.routeId}/project/exclusion`, formData, false
    );
  }

  successAction = (response) => {
    // transaction_ids_excluded transaction_ids_not_found
    console.log('RESP', response);
    this.loadDone(response, {
      message : 'File successfully uploaded',
      values: {
        exclusion_file: ''
      }
    });
  }

  getFormHeader() {
    return null;
  }

  getActionButtons() {
    return (
      <CardFooter className="action-buttons d-none d-lg-block">
        <button type="submit" className="btn btn-success" disabled={this.state.sendingData}>
          <i className="fa fa-upload"></i> Upload
        </button>
      </CardFooter>
    );
  }

  getFileName() {
    if (this.upload && this.upload.files && this.state.values.exclusion_file) {
      return this.upload.files[0].name;
    }

    return 'Choose file...';
  }

  getFormContent() {
    return (
      <div>
        <div className="alert alert-info" role="alert">
          You can upload CSV file. 
          No header is needed in the first row. 
          The first column should contain the PARTNER IDs to be excluded from the project.<br />
        </div>
        <div className="form-row">
          <div className="col">
            <div className="form-group custom-file">
              <input
                type="file"
                value={this.state.values.exclusion_file}
                className="custom-file-input"
                accept=".csv"
                ref={(comp) => { this.upload = comp;}}
                name="exclusion_file"
                required
                onChange={this.handleInputChange}
              />
              <label className="custom-file-label" htmlFor="validatedCustomFile">{this.getFileName()}</label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
