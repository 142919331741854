import React from 'react';
import Qualification from './Qualification';

export default class ChannelQualification extends Qualification {
  getAddButton() {
    return (
      <button key="create" type="button" className="btn btn-sm btn-default mb-1" onClick={() => this.addCriteria()}>
        <i className="fa fa-plus" aria-hidden="true"></i>
      </button>
    );
  }
}
