import Grid from './Grid';

export default class ClosedGrid extends Grid {

  getInitialState(props) {
    const initialState = super.getInitialState(props);
    Object.assign(initialState, {
      title: 'Closed Projects',
    });

    Object.assign(initialState.extraParams, { status: 'closed' });

    return initialState;
  }

  get gridName() {
    return 'closedGrid';
  }

  getColumns() {
    return super
      .getColumns()
      .slice(1)
      .concat({
        name: 'archive_date',
        title: 'archive',
        width: 120
      });
  }
}
