import React from 'react';
import { fingerprints, fingerprintSettings } from '../job/constants';
import { Grid, ucFirst } from 'emi-frontend';

export default class ProjectGroupGrid extends Grid {

  get gridName() {
    return 'projectGroupGrid';
  }

  getApiPath = () => {
    return '/project_group';
  }

  getBasePath = () => {
    return '/groups';
  }

  getColumns() {
    return [{
      name: 'id',
      dataField: 'id',
      title: 'ID',
      width: 55,
      asc: false,
      exactWidth: true
    }, {
      name: 'name',
      title: 'Name',
      width: 250
    }, {
      name: 'projects',
      title: '# Of projects',
      width: 160
    }, {
      name: 'fingerprint',
      title: 'fingerprint',
      width: 150,
      renderer: ({ data }) => (
        <span>
          {Array.isArray(data) && data.length ? data.map(fp => fingerprints[fp]).join(', ') : 'N/A'}
        </span>
      )
    }, {
      name: 'policy',
      title: 'policy',
      width: 100,
      renderer: ({ data }) => (<span>{ucFirst(data)}</span>)
    }, {
      name: 'fingerprint_setting',
      title: 'validation',
      width: 100,
      renderer: ({ data }) => (<span>{data !== null ? fingerprintSettings[data] : 'N/A'}</span>)
    }, {
      name: 'block_completes_only',
      title: 'block completes only',
      width: 250,
      exactWidth: true,
      renderer: ({ data }) => (<span>{data ? 'Enabled' : 'Disabled'}</span>)
    }, {
      name: 'client_name',
      title: 'client name',
      width: 250,
      exactWidth: true,
      renderer: ({ data }) => (
        <span className="text-warning">
          {data !== null && <i className="fa fa-plug" aria-hidden="true" />}{' '}
          {data}
        </span>
      )
    }, {
      name: 'update_job_id',
      title: '',
      width: 50,
      sortable: false,
      exactWidth: true,
      renderer: ({ data }) =>
        <span className="text-warning m-2">
          {data !== null && <i className="fa fa-clock-o" aria-hidden="true" />}
        </span>
    }];
  }
}
