import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class NewValue extends Component {
  state = {
    value: ''
  };

  static propTypes = {
    rowColClass: PropTypes.string,
    labelColClass: PropTypes.string,
    inputColClass: PropTypes.string,
    btnColClass: PropTypes.string,
    onAddValue: PropTypes.func.isRequired
  };

  static defaultProps = {
    rowColClass: 'col-6',
    labelColClass: 'col-4',
    inputColClass: 'col-6',
    btnColClass: 'col-2'
  };

  addNewValue = () => {
    this.props.onAddValue(this.state.value);

    this.setState({ value: '' }, () => {
      this.valueField.focus();
    });
  }

  render() {
    return (
      <div className="form-row">
        <div className={this.props.rowColClass}>
          <div className="row form-group">
            <label
              htmlFor="value"
              className={`text-uppercase col-form-label ${this.props.labelColClass}`}
            >
              Values
            </label>
            <div className={this.props.inputColClass}>
              <input
                ref={(el) => {this.valueField = el;}}
                type="text" name="value"
                className="form-control" value={this.state.value}
                onChange={e => this.setState({ value: e.target.value })}
              />
            </div>
            <div className={this.props.btnColClass}>
              <button
                type="button" value="true"
                className="btn btn-primary"
                onClick={this.addNewValue}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}