export const jobType = {
  Growth: 'Growth',
  New: 'New',
  'New 2019': 'New 2019',
  'New 2020': 'New 2020',
  'New 2021': 'New 2021',
  'New 2022': 'New 2022',
  'New 2023': 'New 2023',
  'New 2024': 'New 2024',
  'New 2025': 'New 2025',
  AM: 'AM',
  RM: 'RM',
  'Growth:Transfer': 'Growth:Transfer',
  'New 2019:Transfer': 'New 2019:Transfer',
  'New 2020:Transfer': 'New 2020:Transfer',
  'New 2021:Transfer': 'New 2021:Transfer',
  'New2022:Transfer': 'New2022:Transfer',
  'AM:Transfer': 'AM:Transfer'
};

export const activeJobType = {
  Growth: 'Growth',
  'Growth:Transfer': 'Growth:Transfer',
  'New 2025': 'New 2025',
  AM: 'AM',
  'AM:Transfer': 'AM:Transfer'
};
