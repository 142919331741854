import React, { useState } from 'react';
import { components } from 'react-select';

export default function ValueContainer({ children, showValueCount = 20, ...props }) {
  const [showWholeList, setShowWholeList] = useState(false);
  const valuesLength = props.getValue().length;

  return (
    <components.ValueContainer {...props}>
      {!showWholeList && valuesLength > showValueCount ? (
        <React.Fragment>
          <span
            className="badge badge-info cursor-pointer"
            style={{ whiteSpace: 'normal' }}
            onClick={() => setShowWholeList(!showWholeList)}
          >
            {valuesLength} item selected
          </span>
          {React.Children.toArray(children).filter(child => child.type !== components.MultiValue)}
        </React.Fragment>
      ) : (
        children
      )}
    </components.ValueContainer>
  );
};
