import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

export default class RouteFixedVariables extends Component {

  state = {
    modal: false,
    name: '',
    value: ''
  };

  static propTypes = {
    variables: PropTypes.arrayOf(PropTypes.string),
    textOnly: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    textOnly: false
  };

  static hasVariables(variables) {
    return Object.keys(variables || {}).length > 0;
  }

  static getEntries(variables) {
    return Object.entries(variables || {});
  }

  hideVariableModal = () => {
    this.setState({ modal: false });
  }

  showVariableModal = () => {
    this.setState({ 
      modal: true,
      name: '',
      value: ''
    });
  }

  addVariable = (method) => {
    const name = this.state.name.trim();
    const value = this.state.value.trim();

    if (name && value) {
      const variables = this.props.variables.slice();
      variables[method](name);
      const fixed_variables = Object.assign({}, this.props.value || {}, { [name]: value });

      this.props.onValueChange(variables, fixed_variables);

      this.hideVariableModal();
    }
  }

  removeVariable = (key) => {
    const variables = this.props.variables.slice();
    const idx = variables.indexOf(key);
    if (idx !== -1) {
      variables.splice(idx, 1);
    }
    const fixed_variables = Object.assign({}, this.props.value || {});
    Reflect.deleteProperty(fixed_variables, key);

    this.props.onValueChange(
      variables, RouteFixedVariables.hasVariables(fixed_variables) ? fixed_variables : ''
    );
  }

  render() {
    const { value: fixed_variables, textOnly } = this.props;

    if (textOnly) {
      return RouteFixedVariables.getEntries(fixed_variables).map(([key, value]) =>
        <Button key={key} type="button" className="route-variable-btn" color="secondary" size="sm" style={{ marginRight: 3 }} disabled>
          {`${key} = ${value}`}
        </Button>
      );
    }
    
    return (
      <div className="form-control variables-field">
        <Modal isOpen={this.state.modal} toggle={this.hideVariableModal}>
          <ModalHeader toggle={this.hideVariableModal}>Fixed Variable</ModalHeader>
          <ModalBody>
            <FormGroup row>
              <Label for="name" sm={2}>Name</Label>
              <Col sm={10}>
                <Input
                  id="name"
                  type="text"
                  name="name"
                  value={this.state.name}
                  onChange={(event) => this.setState({ name: event.target.value })}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="value" sm={2}>Value</Label>
              <Col sm={10}>
                <Input
                  id="value"
                  type="text"
                  name="value"
                  value={this.state.value}
                  onChange={(event) => this.setState({ value: event.target.value })}
                  required
                />
              </Col>
            </FormGroup>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button color="primary" size="sm" onClick={() => this.addVariable('unshift')}>Add</Button>{' '}
            {/*<Button color="primary" size="sm" onClick={() => this.addVariable('push')}>Add to the end</Button>*/}
            <Button color="secondary" size="sm" onClick={this.hideVariableModal}>Cancel</Button>
          </ModalFooter>
        </Modal>
        {RouteFixedVariables.getEntries(fixed_variables).map(([key, value]) => { 
          return (
            <Button
              key={key} className="route-variable-btn" color="secondary"
              size="sm" style={{ marginRight: 3 }}
              onClick={() => this.removeVariable(key)}
            >
              {`${key} = ${value}`} | <i className="fa fa-close text-danger"></i>
            </Button>
          );
        })}
        <Button className="btn btn-success btn-sm route-variable-btn" onClick={this.showVariableModal}>
          <i className="fa fa-plus-circle"></i>
        </Button>
      </div>
    );
  }
}
