import React from 'react';
import { components } from 'react-select';
import { RemoteSelect } from 'emi-frontend';

function Option({ children, ...props }) {
  const { s2s = false } = props.data || {};

  return (
    <components.Option {...props}>
      {children}
      {s2s && <i className="fa fa-check-circle-o text-success float-right" aria-hidden="true"/>}
    </components.Option>
  );
}

export default class PartnerSelect extends RemoteSelect {

  getApiPath() {
    return '/partner'
  }

  getFirstOption() {
    return (
      <option key="_" value="">Select Partner..</option>
    );
  }

  getSelectedOptionText() {
    return this.getArrayOptionText();
  }

  getOptionsFromArray() {
    return this.state.data.map((row, idx) => {
      return { value: row.id, label: row.name, s2s: row.finish_callback };
    });
  }

  getLoadParams() {
    return {
      sort: {
        name: true
      }
    };
  }

  composeProps() {
    const props = super.composeProps();

    return Object.assign(props, { components: { Option } });
  }
}
