import React from 'react';
import { Grid, GridMobileBody } from 'emi-frontend';
import GridMobileRow from './GridMobileRow';
import { HubspotSyncModal } from '../hubspot';
import SalesforceIcon from '../../lib/SalesforceIcon';
import QuickbooksIcon from '../../lib/QuickbooksIcon';
import HubspotIcon from '../../lib/HubspotIcon';

export default class PartnerGrid extends Grid {

  getInitialState(props) {
    return Object.assign(
      super.getInitialState(props),
      { letterFilter: true, hubspotModal: false, partnerId: 0 }
    );
  }

  get gridName() {
    return 'partnerGrid';
  }

  getApiPath = () => {
    return '/partner';
  }

  getBasePath = () => {
    return '/partners';
  }

  CheckIcon = ({ data, row }) => {
    if (!data) {
      return null;
    }

    return <i className="fa fa-check-circle-o text-success" aria-hidden="true"></i>;
  }

  getLetterFilterHandler = (char) => {
    return () => {
      let newChar = char;
      const currentChar = this.getExtraParam('letterFilter');
      if (currentChar !== undefined && currentChar === char) {
        newChar = undefined;
      }
      this.setExtraParam('letterFilter', newChar);

      if (this.props.onFilterLetter) {
        this.props.onFilterLetter({ letterFilter: newChar });
      }
    }
  }

  toggleHubspotModal = () => {
    this.setState({
      hubspotModal: !this.state.hubspotModal,
    });
  }

  showHubspotModal = (event, partnerId) => {
    event.stopPropagation();

    this.setState({
      hubspotModal: true,
      partnerId
    });
  }

  SalesforceIcon({ data }) {
    return (
      <div style={{ width: '100%', padding: 2 }}>
        <SalesforceIcon color={data ? undefined : '#6c757d'} />
      </div>
    );
  }

  QuickbooksIcon({ data }) {
    return (
      <div style={{ width: '100%', padding: 6 }}>
        <QuickbooksIcon color={data ? undefined : '#6c757d'} />
      </div>
    );
  }

  HubspotIcon = ({ data, row }) => {
    return (
      <div style={{ width: '100%', padding: 6 }} onClick={e => this.showHubspotModal(e, row.id)}>
        <HubspotIcon color={data ? '#ff7a59' : undefined } />
      </div>
    );
  }

  BidIcon = ({ data }) => {
    return data === true
      ?  <i className="fa fa-times-rectangle-o text-danger" />
      : <i className="fa fa-check-circle-o text-success" />;
  }

  getColumns() {
    return [{
      name: 'id',
      dataField: 'id',
      title: 'ID',
      width: 55,
      exactWidth: true
    }, {
      name: 'name',
      title: 'Name',
      asc: true,
      width: 250
    }, {
      name: 'projects',
      title: '# Of projects',
      width: 160
    }, {
      name: 'completes',
      title: 'Completed interviews',
      width: 220
    }, {
      name: 'finish_callback',
      title: 'S2S',
      width: 70,
      exactWidth: true,
      align: 'center',
      renderer: this.CheckIcon
    }, {
      name: 'panel_api',
      title: 'api',
      width: 70,
      exactWidth: true,
      align: 'center',
      renderer: this.CheckIcon
    }, {
      name: 'disabled_in_bid',
      title: 'Bids',
      width: 50,
      exactWidth: true,
      renderer: this.BidIcon
    }, {
      name: 'hubspot_id',
      title: '',
      width: 50,
      exactWidth: true,
      renderer: this.HubspotIcon
    }, {
      name: 'salesforce_id',
      title: '',
      width: 50,
      exactWidth: true,
      renderer: this.SalesforceIcon
    }, {
      name: 'quickbooks_id',
      title: '',
      width: 50,
      exactWidth: true,
      renderer: this.QuickbooksIcon
    }];
  }

  getMobileHeader() {
    return '';
  }

  getMobileBody() {
    return (
      <GridMobileBody 
        RowClass={GridMobileRow}
        columns={this.state.columns}
        data={this.state.data}
        onClick={this.onRowClick}
        loading={this.state.loadingData}
      />
    );    
  }

  getPagination() {
    return (
      <div>
        <HubspotSyncModal
          model="partner"
          modelId={this.state.partnerId}
          isOpen={this.state.hubspotModal}
          toggle={this.toggleHubspotModal}
          onChange={this.reload}
        />
        {super.getPagination()}
      </div>
    );
  }
}
