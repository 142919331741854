import React from 'react';
import { Grid } from 'emi-frontend';

export default class FeedbackGrid extends Grid {

  getInitialState(props) {
    const initialState = super.getInitialState(props);
    Object.assign(initialState, {
      title: 'Feedback Log'
    });

    return initialState;
  }

  get gridName() {
    return 'clientFeedbackGrid';
  }

  getApiPath = () => {
    return '/client_feedback';
  }

  getBasePath = () => {
    return '/clients';
  }

  onRowSelection = (rowData) => {
    this.props.onRowSelection(rowData);
  }

  getExportHeaders() {
    return {
      id: 'ID',
      user_name: 'Created',
      client_id: 'Client ID',
      client_name: 'Client',
      feedback_date: 'Date',
      reported_by: 'Reported by',
      concerning: 'Concerning',
      issue_reported: 'Issue Reported',
      emi_action: 'EMI Action',
      feedback_flag: 'FLAG',
      io_number: 'IO#'
    };
  }

  getColumns() {
    return [{
      name: 'client_name',
      title: 'Client',
      asc: true,
      width: 250
    }, {
      name: 'feedback_date',
      title: 'date',
      width: 120
    }, {
      name: 'reported_by',
      title: 'reported by',
      width: 100
    }, {
      name: 'concerning',
      title: 'concerning',
      width: 100
    }, {
      name: 'feedback_flag',
      title: 'flag',
      width: 70,
      exactWidth: true,
      renderer: ({ data }) => (
        data ? <i className="fa fa-check text-success" /> : <i className="fa fa-times text-danger" />
      )
    }];
  }
}
