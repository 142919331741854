import React from 'react';
import PropTypes from 'prop-types';
import { 
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import { Grid, Button, client } from 'emi-frontend';
import { getUserIds } from '../../lib/utils';
import PubSub from 'pubsub-js';

export default class TaskGrid extends Grid {
  get gridName() {
    return 'taskGrid';
  }

  getApiPath = () => {
    return '/task';
  }

  getBasePath = () => {
    return '/task';
  }

  getInitialState(props, context) {
    const initialState = super.getInitialState(props);

    Object.assign(initialState.extraParams, {
      user_id: getUserIds(context.user)
    });

    return initialState;
  }

  isRowSelectable = () => {
    return false;
  }

  showDetails = ({ data, row: { type, downloaded} }) => {
    let text = '';
    let partner = '';
    if (data.partnerName) {
      partner = ` - ${data.partnerName}`;
    }
    if (type === 'fraud' || type === 'tag') {
      text = `${data.params.extraParams.start} - ${data.params.extraParams.end}${partner}`;
    } else if (type === 'job' || type === 'prescreening') {
      text = `#${data.params.extraParams.project_id}${partner}`;
    } else if (type === 'multi') {
      text = `IO#${data.ioNumber}${partner}`;
    }

    return this.formatDownloaded(text, downloaded);;
  }

  formatDownloaded(text, downloaded) {
    if (downloaded !== 'true') {
      text = <b>{text}</b>;
    }
    return text;
  }

  dateFormatter = ({ data, row: { downloaded } }) => {
    let text = '-';
    if (data !== null && data !== undefined) {
      let date;
      if (!(data instanceof Date)) {
        date = new Date(data.substring(0, 23));
      }
  
      text = date.toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' });
    }
    return this.formatDownloaded(text, downloaded);
  }

  typeRenderer = ({ data, row: { downloaded } }) => {
    let text = data;
    if (data === 'fraud') {
      text = 'Fraud Export';
    } else if (data === 'tag') {
      text = 'Targeting tags report';
    } else if (data === 'job') {
      text = 'Job';
    } else if (data === 'multi') {
      text = 'Multi-View';
    } else if (data === 'prescreening') {
      text = 'Prescreening';
    }

    return this.formatDownloaded(text, downloaded);
  }


  ExportButton = (props) => {
    const {data, className, btnClassName, ...rest} = props;

    if (!data) return '';

    return (
      <div className={className}>
        <Button
          className={btnClassName} {...rest} row={props.row} 
          clickHandler={() => client
            .fileDownload(`${this.getApiPath()}/${data}`)
            .done(() => {
              this.reload();
              PubSub.publish(`background_job_update`, true);
            })
          }
        />
      </div>
    );
  }

  getColumns() {
    return [{
      name: 'type',
      title: 'Type',
      width: 170,
      renderer: this.typeRenderer,
      exactWidth: true
    }, {
      name: 'details',
      title: 'Details',
      width: 200,
      renderer: this.showDetails
    }, {
      name: 'created',
      title: 'Created',
      width: 180,
      exactWidth: true,
      asc: false,
      renderer: this.dateFormatter
    }, {
      name: 'diff',
      title: 'time',
      width: 100,
      exactWidth: true,
      renderer: ({ data }) => `${Math.round(data)}s`
    }, {
      name: 'status',
      title: 'Status',
      width: 130,
      exactWidth: true,
      renderer: ({ data }) => data.toUpperCase()
    }, {
     name: 'file',
     title: '',
     sortable: false,
     width: 46,
     exactWidth: true,
     className: 'button-cell',
     renderer: this.ExportButton,
     params: {
       icon: 'download',
       className: 'left-border'
     }
    }];
  }

  getGridHeaderLetterFilter() {
    return (
      <div className="row">
        <div className="col-auto mr-auto"></div>
        <div className="col-auto">
          <button
            type="button" className="btn btn-warning"
            onClick={() => this.reload()}
          >
            <i className="fa fa-recycle" aria-hidden="true"></i>{' '}
            Reload
          </button>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="xl">
        <ModalHeader toggle={this.props.toggle}>Background Jobs</ModalHeader>
        <ModalBody>
          {super.render()}
        </ModalBody>
      </Modal>
    );
  }
}

TaskGrid.contextTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
