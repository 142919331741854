import React, { Component } from 'react';
import JobFilter from './Filter';
import KickoffGrid from './KickoffGrid';
import TestGrid from './TestGrid';
import LiveGrid from './LiveGrid';
import PausedGrid from './PausedGrid';
import ClosedButton from './ClosedButton';
import FileButton from './FileButton';
import { filterList } from 'emi-frontend';

const ProjectFilterList = filterList(
  JobFilter,
  [LiveGrid, PausedGrid, TestGrid, KickoffGrid],
  [ClosedButton, FileButton]
);

class List extends Component {

  componentDidMount() {
    this.filterList.getFilter().initialize();
  }

  render() {
    return (
      <div className="row">
        <div className="col">
          <ProjectFilterList ref={(c) => this.filterList = c}/>
          <br /><br />
        </div>
      </div>
    );
  }
}

export default List;
