import { Fragment, useState } from 'react';
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';
import { ButtonSelect, formatCurrency, calcPercentage, toNumber } from 'emi-frontend';
import { getTabColor } from './colors.js';

function PartnerSummaryTable({ quotas, otherCosts }) {

  const partnerSummary = {};
  const irList = new Set();
  const summaryTotal = {};

  quotas.forEach((quota) => {
    if (Array.isArray(quota.partners) && quota.partners.length) {
      const { partners, ir, cpi } = quota;
      partners.forEach((partner) => {
        const { id, name, minimum_fee, irs, note } = partner;
        partnerSummary[id] ??= {
          name,
          min: minimum_fee,
          note,
          irs: {},
          other: []
        };

        ir.forEach((i) => {
          irs[i] ??= {};
          summaryTotal[i] ??= {
            awarded: 0,
            cost: 0,
            revenue: 0
          };
          partnerSummary[id].irs[i] ??= {
            awarded: 0,
            cost: 0,
            revenue: 0
          };

          if (parseInt(irs[i].priority) === 1) {
            partnerSummary[id].irs[i].awarded += toNumber(irs[i].n_awarded) || 0;
            partnerSummary[id].irs[i].revenue += (toNumber(irs[i].n_awarded) || 0) * (cpi[i] || 0);
            partnerSummary[id].irs[i].cost += (toNumber(irs[i].n_awarded) || 0) * (irs[i].cpi || 0);
          }
        });
      });
      ir.forEach((ir) => irList.add(ir));
    }
  });

  otherCosts.forEach((other) => {
    partnerSummary[other.partner_id] ??= {
      name: other.name,
      min: 0,
      note: '',
      irs: {},
      other: []
    };
    partnerSummary[other.partner_id].other.push(other);

  });

  const irs = [...irList.values()];
  irs.sort((a, b) => parseInt(a) - parseInt(b));

  return (
    <Row>
      <Col className="overflow-auto">
        <table className="table table-hover quota-table">
          <thead className="thead-dark">
            <tr>
              <th scope="col" rowSpan={2} className="align-middle">Partner</th>
              {irs.map((percent) =>
                <th
                  key={percent} scope="col"
                  style={getTabColor(percent)}
                  className={classNames('text-center')}
                  colSpan={7}
                >
                  {percent}%
                </th>
              )}
            </tr>
            <tr className="text-center">
              {irs.map((percent) =>
                <Fragment key={percent}>
                  <th scope="col">Awarded</th>
                  <th scope="col">Cost</th>
                  <th scope="col">Proj.&nbsp;Min</th>
                  <th scope="col">Total</th>
                  <th scope="col">CPI</th>
                  <th scope="col">Revenue</th>
                  <th scope="col">Margin</th>
                </Fragment>
              )}
            </tr>
          </thead>
          {
            Object.keys(partnerSummary).length && (
              <tbody>
              {
                Object.keys(partnerSummary).map((partnerId) => {
                  const partnerDetails = partnerSummary[partnerId];
                  let partnerRowSpan = 1;
                  if (partnerDetails.note) {
                    partnerRowSpan += 1;
                  }
                  partnerRowSpan += partnerDetails.other.length;
                  return (
                    <Fragment key={partnerId}>
                      <tr key={`summary${partnerId}`}>
                        <th scope="row" rowSpan={partnerRowSpan} className="text-nowrap align-middle">{partnerDetails.name}</th>
                        {irs.map((percent) => {
                          const partnerDetail = partnerDetails.irs[percent];
                          if (!partnerDetail) {
                            return <td colSpan="7" key={percent}>&nbsp;</td>;
                          }
                          const total = Math.max(partnerDetails.min, partnerDetail.cost);
                          const margin = calcPercentage(partnerDetail.revenue, partnerDetail.revenue - total, 2);
                          summaryTotal[percent].awarded += partnerDetail.awarded;
                          summaryTotal[percent].cost += total;
                          summaryTotal[percent].revenue += partnerDetail.revenue;
                          return (
                            <Fragment key={percent}>
                              <td className='text-right'>{partnerDetail.awarded}</td>
                              <td className='text-right'>{formatCurrency(partnerDetail.cost)}</td>
                              <td className='text-right'>{formatCurrency(partnerDetails.min)}</td>
                              <td className='text-right'>{formatCurrency(total)}</td>
                              <td className='text-right'>{formatCurrency(partnerDetail.awarded ? (partnerDetail.revenue / partnerDetail.awarded) : 0)}</td>
                              <td className='text-right'>{formatCurrency(partnerDetail.revenue)}</td>
                              <td className={classNames('text-right', { 'text-danger': margin < 0, 'text-success': margin > 0 })}>{margin}%</td>
                            </Fragment>
                          );
                        })}
                      </tr>
                      {partnerDetails.other.length > 0 && partnerDetails.other.map((other) => {
                        return (<tr key={other.id}>
                          {irs.map((percent) => {
                            const margin = calcPercentage(other.client_cost, other.client_cost - other.partner_cost, 2);
                            summaryTotal[percent].cost += other.partner_cost;
                            // summaryTotal[percent].revenue += other.client_cost;
                            return (
                              <Fragment key={`${percent}_other`}>
                                <td colSpan={3}><b>Other cost</b> - {other.type}</td>
                                <td className='text-right'>{formatCurrency(other.partner_cost)}</td>
                                <td>&nbsp;</td>
                                <td className='text-right'>{formatCurrency(other.client_cost)}</td>
                                <td className={classNames('text-right', { 'text-danger': margin < 0, 'text-success': margin > 0 })}>{margin}%</td>
                              </Fragment>
                            );
                          })}
                        </tr>);})}
                      {partnerDetails.note && <tr>
                        <td colSpan={irs.length * 7}><b>Partner note:</b> {partnerDetails.note}</td>
                      </tr>}
                    </Fragment>
                  );
                })
              }
                <tr className="table-secondary" key={`summarytotal`}>
                  <th scope="row" className="text-nowrap align-middle">Total</th>
                  {irs.map((percent) => {
                    const margin = calcPercentage(summaryTotal[percent].revenue, summaryTotal[percent].revenue - summaryTotal[percent].cost, 2);
                    return (
                      <Fragment key={percent}>
                        <td className='text-right'>{summaryTotal[percent].awarded}</td>
                        <td className='text-right'>&nbsp;</td>
                        <td className='text-right'>&nbsp;</td>
                        <td className='text-right'>{formatCurrency(summaryTotal[percent].cost)}</td>
                        <td className='text-right'>{formatCurrency(summaryTotal[percent].awarded ? (summaryTotal[percent].revenue / summaryTotal[percent].awarded) : 0 )}</td>
                        <td className='text-right'>{formatCurrency(summaryTotal[percent].revenue)}</td>
                        <td className={classNames('text-right', { 'text-danger': margin < 0, 'text-success': margin > 0 })}>{margin}%</td>
                      </Fragment>
                    );
                  })}
                </tr>
              </tbody>
            )
          }
        </table>
      </Col>
    </Row>
  );
}

function QuotaSummaryTable({ quotas, otherCosts }) {

  const quotaSummary = {};
  const irList = new Set();
  const summaryTotal = {};

  quotas.forEach((quota) => {
    if (Array.isArray(quota.partners) && quota.partners.length) {
      const { id, partners, ir, cpi } = quota;
      quotaSummary[id] ??= {
        irs: {}
      };
      partners.forEach((partner) => {
        const { irs } = partner;
        ir.forEach((i) => {
          irs[i] ??= {};
          summaryTotal[i] ??= {
            awarded: 0,
            cost: 0,
            revenue: 0
          };
          quotaSummary[id].irs[i] ??= {
            awarded: 0,
            cost: 0,
            revenue: 0
          };

          if (parseInt(irs[i].priority) === 1) {
            quotaSummary[id].irs[i].awarded += toNumber(irs[i].n_awarded) || 0;
            quotaSummary[id].irs[i].revenue += (toNumber(irs[i].n_awarded) || 0) * (cpi[i] || 0);
            quotaSummary[id].irs[i].cost += (toNumber(irs[i].n_awarded) || 0) * (irs[i].cpi || 0);
          }
        });
      });
      ir.forEach((ir) => irList.add(ir));
    }
  });

  const irs = [...irList.values()];
  irs.sort((a, b) => parseInt(a) - parseInt(b));

  return (
    <Row>
      <Col className="overflow-auto">
        <table className="table table-hover quota-table">
          <thead className="thead-dark">
            <tr>
              <th scope="col" rowSpan={2} className="align-middle">Quota</th>
              {irs.map((percent) =>
                <th
                  key={percent} scope="col"
                  className={classNames('text-center')}
                  style={getTabColor(percent)}
                  colSpan={5}
                >
                  {percent}%
                </th>
              )}
            </tr>
            <tr className="text-center">
              {irs.map((percent) =>
                <Fragment key={percent}>
                  <th scope="col">Awarded</th>
                  <th scope="col">Cost</th>
                  <th scope="col">CPI</th>
                  <th scope="col">Revenue</th>
                  <th scope="col">Margin</th>
                </Fragment>
              )}
            </tr>
          </thead>
          <tbody>
            {
              quotas.map((quota) => {
                const { id, description, partners, loi } = quota;
                return (
                  <tr key={`summary${id}`}>
                    <th scope="row" className="text-nowrap align-middle">{description} (LOI = {loi} mins)</th>
                    {irs.map((percent) => {
                      const quotaDetails = quotaSummary[id].irs[percent] ?? { cost: 0, awarded: 0, revenue: 0};
                      const margin = calcPercentage(quotaDetails.revenue, quotaDetails.revenue - quotaDetails.cost, 2);
                      summaryTotal[percent].awarded += quotaDetails.awarded;
                      summaryTotal[percent].cost += quotaDetails.cost;
                      summaryTotal[percent].revenue += quotaDetails.revenue;
                      return (
                        <Fragment key={percent}>
                          <td className='text-right'>{quotaDetails.awarded}</td>
                          <td className='text-right'>{formatCurrency(quotaDetails.cost)}</td>
                          <td className='text-right'>{formatCurrency(quotaDetails.awarded ? (quotaDetails.revenue / quotaDetails.awarded) : 0)}</td>
                          <td className='text-right'>{formatCurrency(quotaDetails.revenue)}</td>
                          <td className={classNames('text-right', { 'text-danger': margin < 0, 'text-success': margin > 0 })}>{margin}%</td>
                        </Fragment>
                      );
                    })}
                  </tr>
                );
              })
            }
            {
              quotas.length && (
                <tr className="table-secondary" key={`summarytotal`}>
                  <th scope="row" className="text-nowrap align-middle">Total</th>
                    {irs.map((percent) => {
                      const margin = calcPercentage(summaryTotal[percent].revenue, summaryTotal[percent].revenue - summaryTotal[percent].cost, 2);
                      return (
                        <Fragment key={percent}>
                          <td className='text-right'>{summaryTotal[percent].awarded}</td>
                          <td className='text-right'>{formatCurrency(summaryTotal[percent].cost)}</td>
                          <td className='text-right'>{formatCurrency(summaryTotal[percent].awarded ? (summaryTotal[percent].revenue / summaryTotal[percent].awarded) : 0 )}</td>
                          <td className='text-right'>{formatCurrency(summaryTotal[percent].revenue)}</td>
                          <td className={classNames('text-right', { 'text-danger': margin < 0, 'text-success': margin > 0 })}>{margin}%</td>
                        </Fragment>
                      );
                    })}
                  </tr>
              )
            }
          </tbody>
        </table>
      </Col>
    </Row>
  );
}

export default function QuotaSummary({ quotas, otherCosts }) {
  const [groupBy, setGroupBy] = useState('quota');

  function hasPartner() {
    return quotas.every((quota) => Array.isArray(quota.partners) && quota.partners.length);
  }

  function getSummaryTable() {
    if (groupBy === 'partner') {
      return <PartnerSummaryTable quotas={quotas} otherCosts={otherCosts} />;
    }

    return <QuotaSummaryTable quotas={quotas} otherCosts={otherCosts}/>;
  }

  if (!quotas.length) {
    return '';
  }

  if (!hasPartner()) {
    return '';
  }

  return (
    <Fragment>
      <Row className="justify-content-center my-3">
        <Col lg={8} className="text-center font-weight-bold">
          <h4>Summary</h4>
        </Col>
      </Row>
      <Row className="justify-content-center my-3">
        <Col className="text-center">
          <ButtonSelect
            name="groupBy"
            value={groupBy}
            options={{ quota: 'Quota', partner: 'Partner' }}
            onChange={e => setGroupBy(e.target.value)}
          />
        </Col>
      </Row>
      {getSummaryTable()}
    </Fragment>
  );
}
