import AttachmentGrid from './AttachmentGrid';

export default class FileGrid extends AttachmentGrid {

  get gridName() {
    return 'fileGrid';
  }

  getApiPath = () => {
    return `/project/0/attachment`;
  }

  getBasePath = () => {
    return '/jobs';
  }

  getColumns() {
    const [
      select, fileName, userName, created, , downloadAction
    ] = super.getColumns();

    return [
      select, fileName, {
        name: 'project_name',
        title: 'project',
        width: 200
      }, userName, created, downloadAction
    ];
  }
}
