import React, { useMemo } from 'react';
import { Button, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { generateHash } from '../../../lib/utils';

function extractData({ name, criteria, ...props }) {
  let operator;
  switch (name) {
    case 'disqo':
      operator = criteria.range ? 'range': 'equals';
      break;
    case 'prodege':
      operator = criteria.operator.toLowerCase();
      break;
    case 'tapresearch':
      operator = 'or';
      break;
    case 'purespectrum':
      operator = criteria.range_sets ? 'range' : 'condition';
      break;
    case 'researchnow':
      operator = props.isRange ? 'range' : 'or';
      break;
    default:
      //
  }

  return [name, operator];
}

export default function OperatorHint(props) {
  // generate a unique id
  const target = useMemo(() => `popover-${generateHash(10)}`, []);
  const [name, operator] = extractData(props);
  if (!operator) {
    return null;
  }

  return (
    <React.Fragment>
      <Button id={target} className="border-0" type="button" color="info" outline size="sm">
        <i className="fa fa-info-circle" aria-hidden="true" />
      </Button>
      <UncontrolledPopover className="operator-hint" placement="top" trigger="hover" target={target}>
        <PopoverHeader>Operator example</PopoverHeader>
        <PopoverBody>
          <img src={`/panelapi/${name}_${operator}.png`} alt="Operator hint" />
        </PopoverBody>
      </UncontrolledPopover>
    </React.Fragment>
  );
}
