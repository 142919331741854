import React from 'react';
import PropTypes from 'prop-types';
import { LoadServerData } from '../abstract';
import FeasibilityInfo from './FeasibilityInfo';

const buyState = [
  'PROVISIONED', 'AWAITING_APPROVAL', 'QA_APPROVED', 'REJECTED', 'AWAITING_CLIENT_APPROVAL'
];

export default class Feasibility extends LoadServerData {

  constructor(props) {
    super(props, 'feasibility');
  }

  static propTypes = {
    routeId: PropTypes.number.isRequired,
    partnerId: PropTypes.number.isRequired,
    partnerProjectId: PropTypes.string.isRequired,
    lineItem: PropTypes.shape({
      extLineItemId: PropTypes.string,
      surveyURL: PropTypes.string,
      surveyTestURL: PropTypes.string,
      state: PropTypes.string
    }).isRequired,
    onUpdate: PropTypes.func.isRequired
  };

  static defaultProps = {
    lineItem: {}
  };

  getApiUrl() {
    return `/panelapi/${this.props.routeId}/project/feasibility`;
  }

  getApiMethod() {
    return 'POST';
  }

  getLoadParams(props) {
    return {
      extProjectId: props.partnerProjectId
    };
  }

  getApiData() {
    return this.getLoadParams(this.props);
  }

  buyProject = () => {
    const { routeId, partnerProjectId: extProjectId, lineItem } = this.props;
    const { extLineItemId, surveyURL, surveyTestURL } = lineItem;

    this.clientApi('PUT', `/panelapi/${routeId}/project/status`, {
      extProjectId,
      status: 'buy',
      lineItems: [{ extLineItemId, surveyURL, surveyTestURL }]
    })
      .done((resp) => this.props.onUpdate(resp.data))
      .fail(this.showError);
  }

  showBuyButton() {
    if (this.state.feasibility === null) {
      return false;
    }
    const [lineItem] = this.state.feasibility;

    return buyState.includes(this.props.lineItem.state) && lineItem.feasibility.feasible;
  }

  renderContent() {
    const [lineItem] = this.state.feasibility;
    return lineItem.feasibility.status === 'PROCESSING' ? (
      <div className="alert alert-warning top-margin-10" role="alert">
        Feasibility calculation in progress (takes 20 - 120 seconds)
      </div>
    ) : (
      <div>
        <FeasibilityInfo feasibility={lineItem.feasibility} />
        {this.showBuyButton() && 
          <button className="btn btn-info" type="button" onClick={this.buyProject}>
            Buy Project
          </button>
        }
      </div>
    );
  }
}