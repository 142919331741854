import { Body } from 'emi-frontend';

export default class MultiBody extends Body {
  calulcateSummarize(summarize, sumRow, row) {
    if (row.data && Array.isArray(row.data)) {
      const urls = [];
      summarize.forEach((dataField) => {
        row.data.forEach((dataRow) => {
          if (dataField === 'survey_url') {
            urls.push({
              name: dataRow.name,
              url: dataRow.survey_url,
              variables: dataRow.variables,
              fixed_variables: dataRow.fixed_variables
            });
          } else {
            sumRow[dataField] += dataRow[dataField] ? Number(dataRow[dataField]) : 0;
          }
        });
      });  
      sumRow.survey_url[row.name] = urls;
    }
  }

  initSummarize(sumRow) {
    const summarize = super.initSummarize(sumRow);
    sumRow['survey_url'] = {};
    return summarize;
  }
}
