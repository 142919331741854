import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import LoadServerData from './LoadServerData';

export default class Quotas extends LoadServerData {

  constructor(props) {
    super(props, 'questions');

    this.setQuotaComponent();
  }

  setQuotaComponent() {
    this.components = {
      Quota: () => {
        console.error('No Quota Component');
        return null;
      }
    };
  }

  static propTypes = {
    routeId: PropTypes.number.isRequired,
    partnerId: PropTypes.number.isRequired,
    countryId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    partnerProjectId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    quotas: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array
    ]),
    onUpdate: PropTypes.func.isRequired
  };

  getApiUrl() {
    return `/panelapi/${this.props.partnerId}/questions/${this.props.countryId}`;
  }

  getDefaultValues() {
    return {};
  }

  composeProps() {
    return this.props;
  }

  emptyFn = () => {}

  renderContent() {
    const { quotas, ...props } = this.composeProps();

    return (
      <div>
        <Card className="top-margin-10">
          <CardBody>
            <this.components.Quota
              {...props}
              values={this.getDefaultValues()}
              questions={this.state.questions}
              cloneData={this.state.cloneData}
            />
          </CardBody>
        </Card> 
        {Object.keys(this.props.quotas)
          .filter((key) => quotas[key] !== null)
          .map(key =>
          <Card className="top-margin-10" key={key}>
            <CardBody>
              { console.log(quotas[key]) }
              <this.components.Quota
                {...props}
                values={quotas[key]}
                questions={this.state.questions}
                cloneQuota={cloneData => this.setState({ cloneData })}
              />
            </CardBody>
          </Card> 
        )}
      </div>
    );
  }
}