import DefinitionsSelect from './DefinitionsSelect';

export default class SampleTypeSelect extends DefinitionsSelect {

  get definitionsPath() {
    return 'sample_types';
  }

  get valueProperty() {
    return 'sampleTypeId';
  }
}
