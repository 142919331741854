import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { Excel } from 'emi-frontend';

export function saveDataAsXlsx(data, headers, fileName, sheetName = 'Sheet 1') {
  const excel = new Excel();
  excel.addJsonData(data, headers, sheetName);
  excel.saveAs(fileName || `export_${moment().format('YYYYMMDDHHmm')}` + '.xlsx');
}

export function filterDataProps(data, props) {
  return data.map(obj =>
    Object.fromEntries(
      Object.entries(obj).filter(([key]) => props.includes(key))
    )
  );
}

export function ExportButton({ title = 'Export', showIcon = true, className, ...props }) {
  return (
    <button
      className={classNames('btn', 'btn-default', 'btn-sm', className)}
      type="button" {...props}
    >
      {showIcon && <i className="fa fa-download" />} {title}
    </button>
  );
}
