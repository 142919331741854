import React from 'react';
import TargetCriteria from './TargetCriteria';

export default class Qualification extends TargetCriteria {

  getDefaultCriteria() {
    return {
      name: '',
      qualificationId: '',
      answers: []
    };
  }

  getCurrentTargetCriteria() {
    const criteria = Object.assign({}, this.state);
    if (!criteria.name) {
      const { name } = this.getSelectedQuestion();
      Object.assign(criteria, { name: `${name}_${criteria.qualificationId}` });
    }

    return criteria;
  }

  isExists() {
    return this.state.surveyQualificationId !== undefined;
  }
  
  shouldResetCriteria() {
    return !this.isExists();
  }

  getAddButton() {
    return (
      <button key="create" type="button" className="btn btn-sm btn-success mb-1" onClick={() => this.addCriteria()}>
        <i className="fa fa-save" aria-hidden="true"></i>
      </button>
    );
  }
}
