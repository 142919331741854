import React from 'react';
import eol from 'eol';
import { Row, Col, InputGroup, InputGroupAddon, InputGroupText, Input, Button as StrapButton } from 'reactstrap';
import { Grid, Button, ProgressRing, Head, formatCurrency, composeStartUrl, CopyClipboard } from 'emi-frontend';
import RouteForm from './RouteForm';
import RedirectGrid from './RedirectGrid';
import PanelApiModal from './PanelApiModal';
import GridMobileBody from './RouteGridMobileBody';
import GridMobileRow from './RouteGridMobileRow';
import RouteLineItem from './RouteLineItem';
import Cell from './RouteGridCell';
import classNames from 'classnames';

function getPartnersStartUrl(rows) {
  return rows.map((row) => 
    `${row.partner_name}${eol.auto}` +
    `\tLive: ${composeStartUrl(row.survey_url, row.variables, row.fixed_variables)}${eol.auto}` +
    `\tTest: ${composeStartUrl(row.survey_url, row.variables, row.fixed_variables)}/?test=1${eol.auto}`
  ).join('');
}

export default class RouteGrid extends Grid {

  getInitialState(props) {
    return Object.assign(
      super.getInitialState(props),
      {
        routeModal: false,
        routeLineItemModal: false,
        routeId: 0,
        partnerId: 0,
        reconciliationEmail: null,
        className: 'route-grid',
        redirectModal: false,
        redirectParams: null,
        panelApiModal: false,
        panelApi: null,
        apiRouteId: 0,
        routeQuota: 0,
        from_date: '',
        to_date: ''
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.enable_click_quota !== this.props.enable_click_quota) {
      this.setColumns();
    }
  }

  get gridName() {
    return 'routeGrid';
  }

  getApiPath = () => {
    return '/route';
  }

  getBasePath = () => {
    return '/jobs';
  }

  FormatCost = ({ data, row }) => {
    if (!data) {
      return '-';
    }

    return formatCurrency(data);
  }

  toggleRedirectModal = (params) => {
    this.setState({
      redirectModal: !this.state.redirectModal,
      redirectParams: params || null
    });
  }

  togglePanelApiModal = (params) => {
    this.setState(Object.assign({
      panelApiModal: !this.state.panelApiModal,
      panelApi: null,
      apiRouteId: 0,
      routeQuota: 0
    }, params));
  }

  showRedirectModal = (event, params) => {
    event.preventDefault();
    event.stopPropagation();
    this.toggleRedirectModal(params);
  }

  ExportField = ({ data, row, column, summary }) => {
    if (!data) {
      return data;
    }

    const params = {
      project_id: this.props.extraParams.project_id,
      status: column.name,
      partner_id: summary ? undefined : row.partner_id,
      from_date: this.state.from_date || undefined,
      to_date: this.state.to_date || undefined
    };

    return (
      <a href="#" onClick={(e) => this.showRedirectModal(e, params)}>{data}</a>
    );
  }

  exportRedirects = (params) => {
    if (this.redirectGrid) {
      this.redirectGrid.exportData(
        Object.assign({ project_id: this.props.extraParams.project_id }, params || {})
      );
      this.setState({ message: 'Background job created', success: true });
    }
  }

  ExportButton = (props) => {
    const {data, className, btnClassName, summary, ...rest} = props;
    const params = Object.assign({
      from_date: this.state.from_date || undefined,
      to_date: this.state.to_date || undefined
    }, summary ? null : { partner_id: data });

    return (
      <div className={summary ? null : className}>
        <Button
          className={btnClassName} {...rest} 
          clickHandler={() => this.exportRedirects(params)} 
        />
      </div>
    );
  }

  PanelApiButton = (props) => {
    const {data, className, btnClassName, summary, ...rest} = props;
    if (!rest.row.use_panel_api) {
      return null;
    }

    const params = {
      partnerId: data,
      panelApi: rest.row.panel_api,
      apiRouteId: rest.row.id,
      routeQuota: rest.row.quota
    };

    // Check if Partner has API and the project is LIVE
    let statusClass = '';
    if (rest.row.api_data && rest.row.api_data.project) {
      if (
        (rest.row.panel_api === 'prodege' && rest.row.api_data.project.status === 'LIVE') ||
        (rest.row.panel_api === 'tapresearch' && rest.row.api_data.project.status === 2) ||
        (rest.row.panel_api === 'researchnow' && rest.row.api_data.project.status === 'LAUNCHED') ||
        (rest.row.panel_api === 'purespectrum' && rest.row.api_data.project.ps_survey_status === 22) ||
        (rest.row.panel_api === 'disqo' && rest.row.api_data.project.status === 'OPEN') ||
        (rest.row.panel_api === 'marketcube' && rest.row.api_data.project.status === 5)
      ) {
        statusClass = 'text-success';
      }
    }

    return (
      <div className={summary ? null : className}>
        <Button
          className={classNames(btnClassName, statusClass)} {...rest} 
          clickHandler={() => this.togglePanelApiModal(params)} 
        />
      </div>
    );
  }

  RouteLineItemButton = (props) => {
    const {data, className, btnClassName, summary, ...rest} = props;
    const params = {
      partnerId: data,
      routeId: rest.row.id,
      email: rest.row.reconciliation_email
    };

    return (
      <div className={summary ? null : className}>
        <Button
          className={btnClassName} {...rest} 
          clickHandler={() => this.toggleRouteLineItemModal(params)} 
        />
      </div>
    );
  }

  CopyUrlButton = (props) => {
    const {data, className, btnClassName, summary, ...rest} = props;

    return (
      <div className={summary ? null : className}>
        <CopyClipboard
          className={btnClassName}
          title="Copy start URL"
          {...rest}
          getText={() =>
            summary ? getPartnersStartUrl(this.state.data) : 
            composeStartUrl(rest.row.survey_url, rest.row.variables, rest.row.fixed_variables)
          }
        />
      </div>
    );
  }

  CheckIcon = ({ data, row }) => {
    if (!data) {
      return <span/>;
    }

    return <i className="fa fa-check-circle-o text-success" aria-hidden="true"/>;
  }

  setDefaultComponents() {
    super.setDefaultComponents();

    Object.assign(this.components, { Cell });
  }

  getColumns() {
    return [{
      name: 'progress',
      title: '',
      sortable: false,
      width: 60,
      exactWidth: true,
      renderer: ProgressRing,
      params: {
        size: 40,
        stroke: 4
      }
    }, {
      name: 'partner_name',
      title: 'Partner',
      asc: true,
      width: 200
    }, {
      name: 'incidence',
      title: 'IR',
      width: 100,
      exactWidth: true,
      renderer: (props) => props.data === null ? '' : `${props.data}%`
    }, {
      name: 'test',
      title: 'test',
      renderer: this.ExportField,
      summarize: true,
      width: 100
    }, {
      name: 'incomplete',
      title: 'incomplete',
      renderer: this.ExportField,
      summarize: true,
      width: 100
    }, {
      name: 'screenout',
      title: 'screenout',
      renderer: this.ExportField,
      summarize: true,
      width: 100
    }, {
      name: 'speeder',
      title: 'Quality Fail',
      renderer: this.ExportField,
      summarize: true,
      width: 100
    }, {
      name: 'duplicated',
      title: 'duplicated',
      renderer: this.ExportField,
      summarize: true,
      width: 100
    }, {
      name: 'quotafull',
      title: 'over quota',
      renderer: this.ExportField,
      summarize: true,
      width: 100
    }, {
      name: 'completed',
      title: 'completed',
      renderer: this.ExportField,
      summarize: true,
      width: 100
    }, {
      name: 'quota',
      title: 'quota',
      summarize: true,
      width: 140,
      params: {
        target_quota: this.props.target_quota,
        total_clicks: this.props.total_clicks,
        enable_click_quota: this.props.enable_click_quota,
        project_id: this.props.extraParams.project_id,
        route_total_clicks: this.state.data && this.state.data[0] ? this.state.data[0].route_total_clicks : 0,
        onDataChange: () => {
          this.reload();
        }
      }
    }, {
      name: 'route_total_clicks',
      title: '',
      summarize: true,
      width: 0,
      exactWidth: true
    }, {
      name: 'cpi',
      title: 'cpi',
      width: 80,
      renderer: this.FormatCost
    }, {
      name: 'cost',
      title: 'cost',
      summarize: true,
      width: 90,
      renderer: this.FormatCost
    }, {
      name: 'finish_callback',
      title: 's2s',
      summarize: false,
      sortable: false,
      width: 46,
      exactWidth: true,
      renderer: this.CheckIcon,
    }, {
      name: 'use_panel_api',
      title: '',
      dataField: 'partner_id',
      summarize: false,
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderer: this.PanelApiButton,
      params: {
        icon: 'random',
        className: 'left-border',
        apiHandler: (params) => {
          this.togglePanelApiModal(params);
        }
      }
    }, {
      name: 'copy_url',
      dataField: 'start_url',
      title: '',
      summarize: true,
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderer: this.CopyUrlButton,
      params: {
        icon: 'clipboard',
        className: 'left-border'
      }
    }, {
      name: 'custom_cost',
      dataField: 'partner_id',
      title: '',
      summarize: false,
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderer: this.RouteLineItemButton,
      params: {
        icon: 'usd',
        className: 'left-border',
        costHandler: (partnerId, routeId) => {
          this.toggleRouteLineItemModal({ partnerId, routeId });
        }
      }
    }, {
      name: 'export',
      dataField: 'partner_id',
      title: '',
      summarize: true,
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderer: this.ExportButton,
      params: {
        icon: 'download',
        className: 'left-border',
        mobileExportHandler: (partner_id) => {
          this.exportRedirects({ partner_id });
        }
      }
    }];
  }

  onPartnersChanged = () => {
    this.setState({
      routeModal: false,
      routeLineItemModal: false,
      routeId: 0
    }, this.reload);
  }

  isRowSelectable = () => {
    return !this.props.readOnly;
  }

  render() {
    if (!Object.keys(this.state.columns).length) {
      return null;
    }

    return (
      <Row>
        <Col>
          {this.getAlertMessage()}
          {this.getGrid()}
          <RedirectGrid
            preload={false}
            extraParams={this.state.redirectParams}
            isOpen={this.state.redirectModal}
            toggle={() => this.toggleRedirectModal()}
            ref={(comp) => { this.redirectGrid = comp;}}
          />
        </Col>
      </Row>
    );
  }

  getModals() {
    return [(
      <RouteForm
        key="route_form"
        id={this.state.routeId}
        isOpen={this.state.routeModal}
        toggle={this.toggleRouteModal}
        values={this.props.routeValues}
        onSuccess={this.onPartnersChanged}
        onDelete={this.onPartnersChanged}
        reload={this.reload}
      />
    ), (
      <RouteLineItem
        key="route_line_items"
        id={this.state.routeId}
        projectId={this.props.extraParams.project_id}
        reconciliationEmail={this.state.reconciliationEmail}
        connectorProject={this.props.connectorProject}
        ioNumber={this.props.ioNumber}
        isOpen={this.state.routeLineItemModal}
        toggle={this.toggleRouteLineItemModal}
        partnerId={this.state.partnerId}
        onSuccess={this.onPartnersChanged}
        onDelete={this.onPartnersChanged}
        reload={this.reload}
      />
    ), (
      <PanelApiModal
        key="panel_api"
        routeId={this.state.apiRouteId}
        routeQuota={this.state.routeQuota}
        partnerId={this.state.partnerId}
        panelApi={this.state.panelApi}
        projectId={this.props.extraParams.project_id}
        isOpen={this.state.panelApiModal}
        ioNumber={this.props.ioNumber}
        toggle={() => this.togglePanelApiModal()}
      />
    )];
  }

  getPagination() {
    return (
      <div>
        {
          !this.props.readOnly
            ? this.getModals()
            : ''
        }

        {
          !this.props.readOnly
            ? (
              <div
                className="d-flex flex-row flex-nowrap grid-row content rounded job-add-partner"
                onClick={this.toggleRouteModal}
              >
                <span className="text-success"><i className="fa fa-plus"></i> Add a Partner</span>
              </div>
            )
            : ''
        }
        {super.getPagination()}
      </div>
    );
  }

  toggleRouteModal = (id) => {
    this.setState({
      routeModal: !this.state.routeModal,
      routeId: id || 0
    });
  }

  toggleRouteLineItemModal = (params = {}) => {
    this.setState({
      routeLineItemModal: !this.state.routeLineItemModal,
      routeId: params.routeId || 0,
      partnerId: params.partnerId || 0,
      reconciliationEmail: params.email
    });
  }

  onRowSelection = (rowData) => {
    this.toggleRouteModal(rowData.id);
  }

  getMobileHeader() {
    const columns = this.convertColumns([{
      name: 'partner_name',
      title: 'partner',
      width: 100,
      sortable: false,
    }, {
      name: 'test',
      title: 'test',
      align: 'center',
      width: 50,
      sortable: false,
    }, {
      name: 'completed',
      title: 'comp',
      align: 'center',
      width: 50,
      sortable: false,
    }, {
      name: 'quota',
      title: 'quota',
      align: 'center',
      width: 50,
      sortable: false,
    }, {
      name: 'route_total_clicks',
      title: '',
      summarize: true,
      width: 0,
      exactWidth: true
    }, {
      name: 'cost',
      title: 'cost',
      align: 'center',
      width: 70,
      sortable: false,
    }]);
    return <Head columns={columns} onSort={this.onSort} />;
  }

  getMobileBody() {
    return (
      <GridMobileBody
        RowClass={GridMobileRow}
        columns={this.state.columns}
        data={this.state.data}
        onClick={this.onRowClick}
        loading={this.state.loadingData}
        extraParams={this.state.extraParams}
        modalFnc={this.showRedirectModal}
      />
    );    
  }

  handleFilterDateChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  clearDateFilter = () => {
    this.setState({ from_date: '', to_date: '' }, this.applyDateFilter);
  }

  applyDateFilter = () => {
    this.setExtraParams({
      from_date: this.state.from_date || undefined,
      to_date: this.state.to_date || undefined
    });
  }

  getGridHeaderLetterFilter() {
    if (!this.props.showDateFilter) {
      return null;
    }

    return (
      <Row>
        <Col lg={{ size: "4", offset: "4" }} className="my-1">
          <InputGroup size="sm">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>From</InputGroupText>
            </InputGroupAddon>
            <Input placeholder="From date" type="datetime-local" name="from_date" value={this.state.from_date} onChange={this.handleFilterDateChange} />
            <InputGroupAddon addonType="append">
              <InputGroupText>To</InputGroupText>
            </InputGroupAddon>
            <Input placeholder="To date" type="datetime-local" name="to_date" value={this.state.to_date} onChange={this.handleFilterDateChange} />
            <InputGroupAddon addonType="append">
              <StrapButton color="secondary" type="button" onClick={this.clearDateFilter}>
                <i className="fa fa-times" aria-hidden="true"/>
              </StrapButton>
            </InputGroupAddon>
            <InputGroupAddon addonType="append">
              <StrapButton color="secondary" type="button" disabled={!this.state.from_date && !this.state.to_date} onClick={this.applyDateFilter}>
                Apply
              </StrapButton>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </Row>
    )
  }
}
