import React from 'react';
import { RemoteSelect, ButtonSelect } from 'emi-frontend';

export default class TermSelect extends RemoteSelect {

  getApiPath() {
    return '/term'
  }

  getFirstOption() {
    return (
      <option key="_" value="">Select Term..</option>
    );
  }

  getSelectOptions() {
    const options = {};

    if (this.state.recordCount > 0) {
      this.state.data.forEach((title, idx) => {
        options[idx] = Number.isInteger(title) ? `${title} days` : title;
      });
    }

    return options;
  }

  render() {
    const { onValueChange, ...props } = this.props;

    return (
      <ButtonSelect
        {...props}
        onChange={onValueChange ? this.handleInputChange : this.props.onChange}
        options={this.getSelectOptions()}
      />
    );
  }
}
