export const departments = {
  rm: 'RM',
  sales: 'Sales',
  operations: 'Operations',
  admin: 'Admin'
};

export const userStatus = {
  true: 'Active',
  false: 'Disabled'
};

export const alertTypes = {
  click_balance_cell_closed: 'Click balance cell closed',
  click_quota_reached: 'Click quota reached',
  client_code_notification_limit: 'Client code limit',
  target_quota_reached: 'Target quota reached',
  partner_quota_reached: 'Partner quota reached',
  test_activity_notification: 'Test activity',
  geoip_notification: 'GEO IP',
  client_contact: 'Client contact',
  client_project_ready: 'Cient project ready',
  client_quotation_ready: 'Client quotation ready',
  client_feedback_notification: 'Client feedback',
  partner_feedback_notification: 'Partner feedback',
  new_proposal_notification: 'New proposal',
  pm_quotation_ready: 'PM quotation ready',
  coverage_request: 'Coverage request',
  connector_project_alert: 'Connector project alert',
  ghost_complete_notification: 'Ghost complete',
};
