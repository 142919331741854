import React from 'react';
import PropTypes from 'prop-types';
import Event from './Event';
import { LoadServerData } from '../abstract';

export default class Events extends LoadServerData {

  constructor(props) {
    super(props, 'events');
  }

  static propTypes = {
    routeId: PropTypes.number.isRequired,
    partnerId: PropTypes.number.isRequired,
    partnerProjectId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired
  };

  getApiUrl() {
    return `/panelapi/${this.props.routeId}/project/events`;
  }

  getApiMethod() {
    return 'POST';
  }

  getLoadParams(props) {
    return {
      extProjectId: props.partnerProjectId
    };
  }

  getApiData() {
    return this.getLoadParams(this.props);
  }

  updateEvent = (eventId, action) => {
    this.setState({
      loadingData: true
    }, () =>
      this.clientApi('PUT', this.getApiUrl(), { eventId, action })
        .done(this.reloadData)
        .fail(this.showError)
    );
  }

  renderContent() {
    return this.state.events.length === 0 ? (
      <div className="alert alert-warning top-margin-10" role="alert">
        There are no events for this project
      </div>
    ) : (
      <div>
        {this.state.events.map((event, idx) => (
          <Event
            key={event.eventId}
            event={event}
            updateEvent={this.updateEvent}
            showButtons={idx===0}
          />
        ))}
      </div>
    );
  }
}