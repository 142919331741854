import React from 'react';
import { ProjectForm } from '../abstract';
import SurveyCategorySelect from './SurveyCategorySelect';
import LocalizationSelect from './LocalizationSelect';
import { ButtonSelect, CreatableReactSelect } from 'emi-frontend'

export default class Survey extends ProjectForm {

  getDefaultValues() {
    return {
      survey_title: '',
      survey_category_code: '',
      survey_localization: '',
      completes_required: 0,
      click_balancing: 0,
      expected_ir: 0,
      expected_loi: 0,
      field_time: 0,
      offer_price: 0,
      live_url: '',
      buyer_message: '',
      billing_id: '',
      subscriber_emails: []
    };
  }

  getId = () => {
    return this.parseInt(this.state.values.ps_survey_id);
  }

  getDeleteData() {
    return {
      ps_survey_id: this.getId()
    };
  }

  showDeleteAction() {
    return this.isUpdate();
  }

  updateStatus(ps_survey_status) {
    this.clientApi(
      'PUT', this.resolveApiPath('status'), { ps_survey_id: this.getId(), ps_survey_status }
    )
      .done(this.successAction)
      .fail(this.deleteFail);
  }

  getStatusButton(show, btnClass, icon, label, status) {
    return (show.includes(this.state.values.ps_survey_status) && 
      <button type="button" className={`btn btn-${btnClass}`} onClick={() => this.updateStatus(status)}>
        <i className={`fa fa-${icon}`}></i> {label}
      </button>
    );
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            label: 'title',
            name: 'survey_title',
            required: true,
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'category',
            name: 'survey_category_code',
            component: SurveyCategorySelect,
            partnerId: this.props.partnerId,
            required: true,
            isClearable: false,
            onChange: this.selectChangeHandler('survey_category_code'),
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 5 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'localization',
            name: 'survey_localization',
            component: LocalizationSelect,
            partnerId: this.props.partnerId,
            required: true,
            isClearable: false,
            isDisabled: this.getId() > 0,
            onChange: this.selectChangeHandler('survey_localization'),
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 5 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'completes',
            name: 'completes_required',
            type: 'number',
            step: 1,
            min: 0,
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}

          {this.getFormRow({
            label: 'ir',
            name: 'expected_ir',
            type: 'number',
            min: 0,
            max: 100,
            step: 1,
            postfix: '%',
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'days in field',
            name: 'field_time',
            type: 'number',
            step: 1,
            min: 1,
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}

          {this.getFormRow({
            label: 'loi',
            name: 'expected_loi',
            type: 'number',
            min: 1,
            step: 1,
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'cpi',
            name: 'offer_price',
            type: 'number',
            min: 0,
            step: 0.01,
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}

          {this.getFormRow({
            name: 'click_balancing',
            label: 'click balancing',
            component: ButtonSelect,
            textOnly: this.isUpdate() && this.state.values.ps_survey_status !== 11,
            options: { 1: 'Yes', 0: 'No' },
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'billing id',
            name: 'billing_id',
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'message',
            name: 'buyer_message',
            type: 'textarea',
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'emails',
            name: 'subscriber_emails',
            component: CreatableReactSelect,
            isMulti: true,
            options: this.state.values.subscriber_emails.map(value => ({ value, label: value })),
            onChange: this.selectChangeHandler('subscriber_emails'),
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 9 }
          })}
        </div>

        {this.isUpdate() && 
          <div className="form-row">
            {this.getFormRow({
              label: 'status',
              name: 'ps_survey_status',
              type: 'select',
              disabled: true,
              fieldWidth: { xs: 12, sm: 6 },
              labelWidth: { xs: 12, sm: 6 },
              valueWidth: { xs: 12, sm: 4 },
              children: [
                <option key="drafts" value="11">DRAFTS</option>,
                <option key="live" value="22">LIVE</option>,
                <option key="paused" value="33">PAUSED</option>,
                <option key="closed" value="44">CLOSED</option>
              ]
            })}

            <div className="col-12 col-sm-6 action-buttons">
              {this.getStatusButton([11, 33], 'default', 'play', 'Live', 22)}
              {this.getStatusButton([22], 'default', 'pause', 'Pause', 33)}
              {this.getStatusButton([11, 22, 33], 'danger', 'stop', 'Close', 44)}
            </div>
          </div>
        }

      </div>
    );
  }
}
