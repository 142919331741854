import React from 'react';
import { ProjectForm } from '../abstract';
import CategorySelect from './CategorySelect';
import { ReactSelect, CreatableReactSelect, FormRow } from 'emi-frontend';

const devices = ['mobile', 'desktop', 'tablet'];

export default class Project extends ProjectForm {

  getDefaultValues() {
    return {
      extProjectId: '',
      title: '',
      notificationEmails: [],
      devices: devices.slice(),
      category: { surveyTopic: [] },
      lineItems: []
    };
  }

  getId = () => {
    return this.state.values.extProjectId;
  }

  isCreateProject() {
    return !this.isUpdate();
  }

  isUpdate() {
    return Object.prototype.hasOwnProperty.call(this.props.values || {}, 'state');
  }

  isNew() {
    return true;
  }

  showSaveButton() {
    return this.isUpdate();
  }

  valueChanged(name, value) {
    if (name !== 'category') {
      return super.valueChanged(name, value);
    }

    return {
      [name]: { surveyTopic: value }
    };
  }

  updateValues(values) {
    this.props.onChange('project', values);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (ProjectForm.valuesDidChange(this.state.values, nextProps.values)) {
      const values = Object.assign({}, this.getDefaultValues(), nextProps.values);
      this.setState({ values });
    } else if (nextProps.lineItem !== null) {
      const { lineItem } = nextProps;
      this.setState(
        this.getStateUpdate({ lineItems: [lineItem] }, 'values'),
        this.submitForm
      );
    }
  }

  failedAction = (error) => {
    console.error(error);
    if (this.isCreateProject()) {
      this.props.emptyLineItem();
    }
  }

  sendFormData = () => {
    const method = this.isUpdate() ? 'PUT' : 'POST';

    return this.clientApi(method, this.resolveApiPath(), this.getFormData());
  }

  reloadData = () => {
    this.clientApi('POST', this.resolveApiPath('refresh'), {
      extProjectId: this.getId()
    })
    .done(this.successAction)
    .fail(this.deleteFail);
  }

  closeProject = () => {
    this.clientApi('PUT', this.resolveApiPath('status'), {
      extProjectId: this.getId(),
      status: 'close'
    })
    .done(this.successAction)
    .fail(this.deleteFail);
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            name: 'title',
            required: true,
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, sm: 2 },
            valueWidth: { xs: 12, sm: 10 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'emails',
            name: 'notificationEmails',
            required: true,
            component: CreatableReactSelect,
            isMulti: true,
            isClearable: false,
            options: this.state.values.notificationEmails.map(value => ({ value, label: value })),
            onChange: this.selectChangeHandler('notificationEmails'),
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, sm: 2 },
            valueWidth: { xs: 12, sm: 10 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'devices',
            required: true,
            component: ReactSelect,
            isMulti: true,
            closeMenuOnSelect: false,
            isClearable: false,
            options: devices.map(value => ({ value, label: value })),
            onChange: this.selectChangeHandler('devices'),
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, sm: 2 },
            valueWidth: { xs: 12, sm: 5 }
          })}
        </div>

        <div className="form-row">
          <FormRow
            errors={this.state.errors}
            value={this.state.values.category.surveyTopic}
            clientSideValidated={this.state.clientSideValidated}
            label="topics"
            name="category"
            partnerId={this.props.partnerId}
            required
            component={CategorySelect}
            isMulti
            closeMenuOnSelect={false}
            isClearable={false}
            onChange={this.selectChangeHandler('category')}
            fieldWidth={{ xs: 12 }}
            labelWidth={{ xs: 12, sm: 2 }}
            valueWidth={{ xs: 12, sm: 8 }}
          />
        </div>

        {this.isUpdate() && 
          <div className="form-row">
            {this.getFormRow({
              label: 'status',
              name: 'state',
              type: 'select',
              disabled: true,
              fieldWidth: { xs: 12, sm: 6 },
              labelWidth: { xs: 12, sm: 4 },
              valueWidth: { xs: 12, sm: 8 },
              children: [
                <option key="provisioned" value="PROVISIONED">PROVISIONED</option>,
                <option key="canceled" value="CANCELLED">CANCELLED</option>,
                <option key="launched" value="LAUNCHED">LAUNCHED</option>,
                <option key="closed" value="CLOSED">CLOSED</option>,
                <option key="invoiced" value="INVOICED">INVOICED</option>
              ]
            })}

            {['PROVISIONED', 'LAUNCHED'].includes(this.state.values.state) &&
              <div className="col-12 col-sm-2">
                <button
                  type="button" className="btn btn-danger"
                  onClick={this.closeProject}
                >
                  <i className="fa fa-stop" /> CLOSE
                </button>
              </div>
            }
          </div>
        }

      </div>
    );
  }
}
