import React from 'react';
import {
  Button,
  CardFooter
} from 'reactstrap';
import { AbstractForm } from 'emi-frontend';

export default class BlocklistForm extends AbstractForm {

  getDefaultValues() {
    return {
      attachment: ''
    };
  }

  getID = () => {
    return null;
  }

  getFormHeader() {
    return null;
  }

  get modelName() {
    return 'blocklist';
  }

  getApiPath = () => {
    return '/blocklist/1';
  }

  sendFormData = () => {
    const fd = new FormData();

    if (this.upload) {
      const files = this.upload.files;
      for (let i = 0; i < files.length; i++) {
        fd.append('attachment', files[i], files[i].name);
      }
    }

    return this.clientApi('PUT', this.getApiPath(), fd, false);
  }

  successAction = (response) => {
    this.resetForm(this.props);
    this.setState({ message : `Uploaded ${response.data.rowCount} Research Defender UDs` });
    this.props.onSuccess();
  }

  cancelAction = () => {
    this.resetForm(this.props);
  }

  getFileName() {
    if (this.upload && this.upload.files && this.state.values.attachment) {
      return this.upload.files[0].name;
    }

    return 'Choose file...';
  }

  getActionButtons = () => {
    return (
      <CardFooter className="action-buttons d-none d-lg-block">
        <Button type="submit" color="success" disabled={this.state.sendingData}>
          <i className="fa fa-upload"></i> Upload
        </Button>
      </CardFooter>
    );
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          <div className="col">
            <div className="form-group custom-file">
              <input
                type="file"
                accept=".xlsx"
                value={this.state.values.attachment}
                className="custom-file-input"
                ref={(comp) => { this.upload = comp; }}
                name="attachment"
                required
                onChange={this.handleInputChange}
              />
              <label className="custom-file-label" htmlFor="validatedCustomFile">{this.getFileName()}</label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
