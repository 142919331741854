import React from 'react';
import { Button } from 'emi-frontend';
import AttributeGrid from '../attribute/Grid';

export default class AttributeSelectGrid extends AttributeGrid {

  get gridName() {
    return 'attributeSelectGrid';
  }

  gridButtonRenderer = (props) => {
    const {className, btnClassName, ...rest} = props; 
    rest.className = btnClassName;
    return (
      <div className={className}>
        <Button {...rest} />
      </div>
    );
  }

  getColumns() {
    return super
      .getColumns()
      .concat({
        name: 'add',
        title: '',
        dataField: 'id',
        sortable: false,
        width: 46,
        exactWidth: true,
        className: 'button-cell',
        renderer: this.gridButtonRenderer,
        params: {
          icon: 'plus',
          className: 'left-border',
          btnClassName: 'text-success',
          clickHandler: this.props.assignAttribute
        }
      });
  }
}
