import { RemoteSelect } from 'emi-frontend';

class QuickbooksSelectMain extends RemoteSelect {

  getApiPath() {
    return '/quickbooks_main'
  }

  getDisabled() {
    return [3, 6, 9];
  }

  composeProps() {
    const props = super.composeProps();
    Object.assign(props, { isOptionDisabled: (option) => this.getDisabled().includes(parseInt(option.value, 10)) });
    return props;
  }

  generateOptions() {
    return this.getOptionsFromObject();
  }

  getSelectedOptionText() {
    return this.getObjectOptionText();
  }

  getObjectOptionText() {
    if (!this.props.value) {
      return this.noOptionSelected();
    }

    return this.state.data[this.props.value]
      ? `${this.state.data[this.props.value]}`
      : this.noOptionSelected();
  }
}

export default QuickbooksSelectMain;
