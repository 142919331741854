import React from 'react';
import { CardFooter } from 'reactstrap';
import { AbstractForm } from 'emi-frontend';

export default class Reconciliation extends AbstractForm {

  get modelName() {
    return 'reconciliation';
  }

  getDefaultValues() {
    return {
      reconcile_file: '',
      message: ''
    };
  }

  getApiPath = () => {
    return '/panelapi';
  }

  getId = () => {
    return this.parseInt(this.props.id);
  }

  sendFormData = () => {
    const formData = new FormData();
    formData.append('extProjectId', this.props.partnerProjectId);
    formData.append('message', this.state.values.message);

    if (this.upload) {
      const files = this.upload.files;
      for (let i = 0; i < files.length; i++) {
        formData.append('reconcile_file', files[i], files[i].name);
      }
    }

    return this.clientApi(
      'POST', `${this.getApiPath()}/${this.props.routeId}/project/reconcile`, formData, false
    );
  }

  successAction = (response) => {
    this.loadDone(response, {
      message : ' Data quality request file has been upload',
      values: {
        reconcile_file: '',
        message: ''
      }
    });
  }

  getFormHeader() {
    return null;
  }

  getActionButtons() {
    return (
      <CardFooter className="action-buttons d-none d-lg-block">
        <button type="submit" className="btn btn-success" disabled={this.state.sendingData}>
          <i className="fa fa-upload"></i> Upload
        </button>
      </CardFooter>
    );
  }

  getFileName() {
    if (this.upload && this.upload.files && this.state.values.reconcile_file) {
      return this.upload.files[0].name;
    }

    return 'Choose file...';
  }

  getFormContent() {
    return (
      <div>
         <div className="form-row">
          <div className="col">
            <div className="form-group custom-file">
              To send reconciliation request use the following template: {' '} 
              <a
                href="/researchnow/DataQualityRequestTemplate.xlsx"
                className="badge badge-primary" download
              >
                DataQualityRequestTemplate.xlsx
              </a>
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col">
            <div className="form-group custom-file">
              <input
                type="file"
                value={this.state.values.reconcile_file}
                className="custom-file-input"
                accept=".xlsx"
                ref={(comp) => { this.upload = comp;}}
                name="reconcile_file"
                required
                onChange={this.handleInputChange}
              />
              <label className="custom-file-label" htmlFor="validatedCustomFile">{this.getFileName()}</label>
            </div>
          </div>
        </div>

        <div className="form-row">
            {this.getFormRow({
              name: 'message',
              type: 'textarea',
              labelWidth: { xs: 2 },
              valueWidth: { xs: 10 }
            })}
          </div>
      </div>
    );
  }
}
