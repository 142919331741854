import React from 'react';
import TermSelect from '../../lib/form/TermSelect';

export default function SyncClientTerm({ state, field, label, create, onSelect, onEdit }) {
  const isEmpty = Object.keys(state.hubspot).length === 0;

  function onInputChange(event) {
    const { value } = event.target;
    onEdit('hubspot', { ...state.hubspot, [field]: value });
  }

  return (
    <div className="form-group row align-items-center">
      <label htmlFor="objectId" className="col-sm-3 col-form-label font-weight-bold text-uppercase">{label || field}</label>
      {!create &&
        <div className="col-sm-4">
          <TermSelect
            name={`${field}_old`}
            value={state.data[field]} textOnly
          />
        </div>
      }
      <div className={create ? 'col-sm-8': 'col-sm-4'}>
        <TermSelect
          name={field}
          value={state.hubspot[field] ?? ''}
          textOnly={!create || !state.selected.includes(field)}
          onChange={onInputChange}
        />
      </div>
      <div className="col-sm-1 align-self-center">
        <div className="form-check">
          <input
            className="form-check-input position-static"
            type="checkbox"
            value={field}
            aria-label="..."
            onChange={onSelect}
            disabled={isEmpty}
          />
        </div>
      </div>
    </div>
  );
}
