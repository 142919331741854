import React from 'react';
import TermSelect from '../../lib/form/TermSelect';
import { jobType, activeJobType } from './constants';
import FeedbackForm from './FeedbackForm';
import FeedbackGrid from './FeedbackGrid';
import UserSelect from '../../lib/form/UserSelect';
import PanelapiSelect from '../../lib/form/PanelapiSelect';
import { 
  AbstractForm, CountrySelect, StateSelect, CollapseCard, Desktop,
  ButtonSelect, hasOwnProperty, SaveButton, IconButton
} from 'emi-frontend';
import Phone from './Phone';

class ClientForm extends AbstractForm {
  constructor(props) {
    super(props);

    const { fid = null } = props.match.params;
    this.state.feedbackModal = fid !== null;
    this.state.feedbackId = fid;
  }
  
  getDefaultValues() {
    return {
      name: '',
      address: '',
      city: '',
      state: '',
      usa_state: '',
      zip: '',
      country: 'United States',
      country_code: 'US',
      phone: '',
      fax: '',
      term: '',
      job_type: 'New 2025',
      notes: '',
      connector: false,
      prospect: false,
      cpi_markup: 40,
      pm: 58,
      annual_revenue_goal: 0,
      nps: '',
      cse_id: '',
      sales_id: '',
      hubspot_id: '',
    };
  }

  getApiPath = () => {
    return '/client';
  }

  cancelPath = () => {
    return '/clients';
  }

  get modelName() {
    return 'client';
  }

  isReadonlyForm() {
    // Used to make the whole form read-only
    return true;
  }

  showDeleteAction() {
    return false;
  }

  redirectPath = () => {
    return this.cancelPath();
  }

  valueChanged(name, value) {
    const changed = {
      [name]: value
    }

    if (name === 'country_code' && value !== this.state.values.country_code && this.isNew()) {
      Object.assign(changed, { state: '', usa_state: '' });
    }

    return changed;
  }

  isUnitedStates() {
    return this.state.values.country_code === 'US';
  }

  toggleModal = () => {
    this.setState({
      feedbackId: null,
      feedbackModal: !this.state.feedbackModal
    });
  }

  onEditFeedback = (row) => {
    this.setState({
      feedbackId: row.id,
      feedbackModal: true
    });
  }

  reloadFeedbackGrid = () => {
    if (this.feedbackGrid) {
      this.feedbackGrid.reload();
    }
  }

  onSuccessFeedback = () => {
    this.toggleModal();
    this.reloadFeedbackGrid();
  }

  getHeaderButtons() {
    return (
      <React.Fragment>
        {this.isNew() && <SaveButton key="save" model={this.modelName} className="float-right" />}
        {this.isUpdate() && 
        <Desktop>
          <IconButton key="feedback" icon="fa fa-plus" label="Feedback Log" className="float-right" onClick={this.toggleModal} />
        </Desktop>
        }
      </React.Fragment>
    );
  }

  getFormContent() {
    const jobTypes = this.isNew() ? activeJobType : jobType;

    return (
      <div>
        <CollapseCard title="Client Profile">
          <div className="form-row">
            {this.getFormRow({
              label: 'company name',
              name: 'name',
              required: true,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.getFormRow({
              name: 'phone',
              component: Phone,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              name: 'fax',
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.getFormRow({
              name: 'term',
              component: TermSelect,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              name: 'job_type',
              label: 'Job Type',
              type: 'select',
              required: true,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 },
              children: [
                <option key="plz_select" value="">-- please select --</option>
              ].concat(Object.keys(jobTypes).map(key =>
                <option key={key} value={key} disabled={!hasOwnProperty(activeJobType, key)}>{jobTypes[key]}</option>
              ))
            })}

            {this.getFormRow({
              name: 'prospect',
              required: true,
              component: ButtonSelect,
              options: { true: 'Yes', false: 'No' },
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>

          <div className='form-row'>
            {this.getFormRow({
                label: 'annual revenue goal',
                name: 'annual_revenue_goal',
                type: 'number',
                step: 1,
                min: 0,
                required: true,
                formatter: (value) => `$${value}`,
                prefix: '$',
                fieldWidth: { xs: 12, lg: 6 },
                labelWidth: { xs: 12, lg: 4 },
                valueWidth: { xs: 12, lg: 8 }
              })
            }

            {this.getFormRow({
              name: 'nps',
              required: false,
              type: 'number',
              step: 1,
              min: 0,
              max: 100,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>

          <div className='form-row'>
            {this.getFormRow({
              label: 'Sales',
              name: 'sales_id',
              component: UserSelect,
              type: 'admin',
              department: 'sales',
              sales_rule: 'AE',
              active: true,
              isClearable: true,
              onChange: this.selectChangeHandler('sales_id'),
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.getFormRow({
              label: 'CSE',
              name: 'cse_id',
              component: UserSelect,
              type: 'admin',
              department: 'sales',
              sales_rule: 'CSE',
              active: true,
              isClearable: true,
              onChange: this.selectChangeHandler('cse_id'),
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>

          <div className='form-row'>
            {this.getFormRow({
              name: 'hubspot_id',
              label: 'hubspot id',
              type: 'number',
              step: 1,
              min: 0,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>
        </CollapseCard>

        <CollapseCard title="Address">
          <div className="form-row">
            {this.getFormRow({
              label: 'country',
              name: 'country_code',
              component: CountrySelect,
              required: true,
              onChange: this.selectChangeHandler('country_code', 'country'),
              clearable: false,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.getFormRow({
              name: 'address',
              type: 'textarea',
              required: true,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              name: 'city',
              required: true,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.isUnitedStates() ? (
              this.getFormRow({
                label: 'state',
                name: 'usa_state',
                errorField: 'state',
                component: StateSelect,
                required: true,
                onChange: this.selectChangeHandler('usa_state', 'state'),
                fieldWidth: { xs: 12, lg: 6 },
                labelWidth: { xs: 12, lg: 4 },
                valueWidth: { xs: 12, lg: 8 }
              })
            ) : (
              this.getFormRow({
                name: 'state',
                fieldWidth: { xs: 12, lg: 6 },
                labelWidth: { xs: 12, lg: 4 },
                valueWidth: { xs: 12, lg: 8 }
              })
            )}
          </div>

          <div className="form-row">
            {this.getFormRow({
              label: 'zip code',
              name: 'zip',
              required: true,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              name: 'notes',
              type: 'textarea',
              fieldWidth: { xs: 12 },
              labelWidth: { xs: 12, lg: 2 },
              valueWidth: { xs: 12, lg: 10 }
            })}
          </div>
        </CollapseCard>

        <CollapseCard title="Connector">
          <div className="form-row">
            {this.getFormRow({
              name: 'connector',
              label: 'Enable Connector',
              required: true,
              component: ButtonSelect,
              options: { true: 'Yes', false: 'No' },
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.getFormRow({
                name: 'cpi_markup',
                label: 'CPI Markup',
                type: 'number',
                required: true,
                step: 1,
                min: 0,
                max: 100,
                formatter: (value) => `${value}%`,
                postfix: '%',
                fieldWidth: { xs: 12, lg: 6 },
                labelWidth: { xs: 12, lg: 4 },
                valueWidth: { xs: 12, lg: 8 }
              })
            }
          </div>

          <div className="form-row">
            {this.getFormRow({
              label: 'RM',
              name: 'pm',
              component: UserSelect,
              required: true,
              type: 'admin',
              department: 'rm',
              onChange: this.selectChangeHandler('pm'),
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.getFormRow({
              label: 'Panel API',
              name: 'panels',
              required: true,
              component: PanelapiSelect,
              onChange: this.selectChangeHandler('panels'),
              isMulti: true,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>
        </CollapseCard>

      </div>
    );
  }

  render() {
    if (this.isNew()) {
      return super.render();
    }

    return (
      <div>
        {super.render()}
        <FeedbackGrid
          storeState={false}
          extraParams={{ client_id: this.getId() }}
          ref={(c) => this.feedbackGrid = c}
          onDataChanged={this.reloadGridData}
          onRowSelection={this.onEditFeedback} 
        />
        <FeedbackForm 
          isOpen={this.state.feedbackModal} toggle={this.toggleModal}
          onSuccess={this.onSuccessFeedback} onDelete={this.reloadFeedbackGrid}
          id={this.state.feedbackId}
          values={{ client_id: this.getId(), client_name: this.state.values.name }}
        />
      </div>
    );
  }
}

export default ClientForm;
