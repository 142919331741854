import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import EditField from '../../../lib/form/EditField';
import ReadOnlyField from '../../../lib/form/ReadOnlyField';
import PercentageField from './PercentageField';

function count(row, track) {
  return track === 'click' ? row.clicks : row.completes;
}

function remaining(target, row, track) {
  const current = count(row, track)
  return current > target ? 0 : target - current;
}

function progress(target, row, track) {
  return percentage(target, count(row, track)); 
}

function percentage(total, value) {
  return total && value ? (value / total) * 100 : 0; 
}

function calcPercentage(value, percent) {
  return Math.ceil(value * (percent / 100));
}

export default class AttributeQuota extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    clickbalance_quota: PropTypes.number.isRequired,
    edit: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    clickbalance_track: PropTypes.oneOf(['click', 'complete']),
    nested: PropTypes.bool
  };

  static defaultProps = {
    nested: false
  };

  state = {
    target: 0,
    target_percentage: 0.00
  };

  handleInputChange = (name, value) => {
    const changed = {
      [name]: value
    };

    if (name === 'target') {
      Object.assign(changed, { target_percentage: (value / this.props.clickbalance_quota) * 100 });
    } else {
      Object.assign(changed, { target: calcPercentage(this.props.clickbalance_quota, value) });
    }

    this.setState(changed);
  }

  editField = (id, field, currentValue) => {
    this.handleInputChange(field, currentValue);
    this.props.onEdit(id, field);
  }

  editingField(id, field) {
    return this.props.edit.id === id && this.props.edit.field === field;
  }

  render() {
    const [{ attribute }] = this.props.data;
    const attributes = Object.keys(attribute);
    let sumTarget = 0;
    let sumTargetPercentage = 0;
    let sumClicks = 0;
    let sumCompletes = 0;

    this.props.data.forEach((row) => {
      sumClicks += row.clicks;
      sumCompletes += row.completes;
    });

    const rows = this.props.data.map(row => {
      const editingTarget = this.editingField(row.id, 'target');
      const editingTargetPercentage = this.editingField(row.id, 'target_percentage');
      const target = editingTarget || editingTargetPercentage ? this.state.target : calcPercentage(this.props.clickbalance_quota, row.target_percentage); // calculate this
      const { target_percentage } = this.state;
      const isOpen = row.status === 'O';
      const remainingCount = remaining(target, row, this.props.clickbalance_track);
      sumTarget += Number(target);
      sumTargetPercentage += Number(editingTarget || editingTargetPercentage ? target_percentage : row.target_percentage);
      const varianceTotal = this.props.clickbalance_track === 'click' ? sumClicks : sumCompletes;
      const varianceCurrent = this.props.clickbalance_track === 'click' ? row.clicks : row.completes;
      const variance = varianceTotal === 0 ? 0 : (varianceCurrent * 100 / varianceTotal) - row.target_percentage;
      const current = progress(this.props.clickbalance_track === 'click' ? sumClicks : sumCompletes, row, this.props.clickbalance_track);

      return (
        <tr key={row.id}>
          {attributes.map(id =>
            <td key={id}>{row.attribute_value[id]}</td>
          )}
          <td>
            {editingTarget ? (
              <EditField
                name="target"
                inputPros={{
                  max: this.props.clickbalance_quota,
                  min: 0, type: 'number', step: 1
                }}
                value={this.state.target}
                onCancel={() => this.props.onEdit(null, null)}
                onChange={this.handleInputChange}
                onSave={() => this.props.onSave(row.id, { target_percentage })}
              />
            ) : (
              <ReadOnlyField onEdit={() => this.editField(row.id, 'target', target)}>
                {target}
              </ReadOnlyField>
            )}
          </td>
          <td>
            {editingTargetPercentage ? (
              <EditField
                name="target_percentage"
                inputPros={{
                  max: 100, min: 0, type: 'number', step: 0.01
                }}
                value={target_percentage}
                onCancel={() => this.props.onEdit(null, null)}
                onChange={this.handleInputChange}
                onSave={() => this.props.onSave(row.id, { target_percentage })}
              />
            ) : (
              <ReadOnlyField onEdit={() => this.editField(row.id, 'target_percentage', row.target_percentage)}>
                <PercentageField value={editingTarget ? target_percentage : row.target_percentage} />
              </ReadOnlyField>
            )}
          </td>
          <td className="text-center">{row.clicks}</td>
          <td className="text-center">{row.completes}</td>
          <td className="text-center">
            <PercentageField value={current} colored />
          </td>
          <td className="text-center">{remainingCount}</td>
          <td className={`text-center text-${variance >= 0 ? 'success' : 'danger'}`}>
            <PercentageField value={variance} />
          </td>
          <td className={`text-center text-${isOpen ? 'success' : 'danger'}`}>
            {isOpen ? 'Open' : 'Close'}
          </td>
          <td className="text-center">
            <Button
              size="sm" type="button"
              color={isOpen ? 'danger' : 'success'}
              onClick={() => this.props.onSave(row.id, { status: isOpen ? 'C' : 'O' })}
            >
              {isOpen ? 'Close' : 'Open'}
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <tbody>
        <tr>    
          {attributes.map(id =>
            <th key={id} scope="col" className="table-secondary">
              {attribute[id]}
            </th>
          )}
          <th scope="col" className={classNames('table-secondary', 'text-center', sumTarget < this.props.clickbalance_quota ? 'text-danger' : 'text-success')}>
            {sumTarget}
          </th>
          <th scope="col" className="table-secondary text-center">
            <PercentageField value={sumTargetPercentage} colored />
          </th>
          <th scope="col" className="table-secondary text-center">
            {sumClicks}
          </th>
          <th scope="col" className="table-secondary text-center">
            {sumCompletes}
          </th>
          <th scope="col" colSpan="7" className="table-secondary" />
        </tr>
        {rows}
      </tbody>
    );
  }
}