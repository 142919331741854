import Grid from './Grid';

export default class TestGrid extends Grid {

  get showStatusUpdateModal() {
    return true;
  }

  get statusUpdateModalId() {
    return 'test-job-status';
  }

  getInitialState(props) {
    const initialState = super.getInitialState(props);
    Object.assign(initialState, {
      title: 'Test Projects',
      className: 'job-grid',
      preload: false
    });

    Object.assign(initialState.extraParams, { status: 'test' });

    return initialState;
  }

  get gridName() {
    return 'testGrid';
  }

  updateProjectStatus = ({ id, group, ...update }) => {
    Object.assign(update, !group ? { policy: 'block' } : null);
    this.updateProject(id, update, 0);
  }

  getColumns() {
    return super
      .getColumns()
      .concat({
        name: 'play',
        dataField: 'id',
        title: '',
        sortable: false,
        width: 40,
        exactWidth: true,
        className: 'job-button',
        renderer: this.statusButton,
        params: {
          icon: 'play',
          status: 'live',
          btnClassName: 'left-border'
        }
      });
  }
}
