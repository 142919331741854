import React from 'react';
import { ReactSelect } from 'emi-frontend';
import ResetIDStatusModal from './ResetIDStatusModal';

export default class ChangeStatusModal extends ResetIDStatusModal {
  getDefaultValues() {
    return {
      attachment: '',
      type: ''
    };
  }

  getApiPath = () => {
    return `/project/${this.props.project_id}/changestatus`;
  }

  sendFormData = () => {
    const fd = new FormData();
    fd.append('type', this.state.values.type);

    if (this.upload) {
      const files = this.upload.files;
      for (let i = 0; i < files.length; i++) {
        fd.append('attachment', files[i], files[i].name);
      }
    }

    return this.clientApi('POST', this.getApiPath(), fd, false);
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          <div className="col">
            <div className="form-group custom-file">
              <input
                type="file"
                accept=".xlsx"
                value={this.state.values.attachment}
                className="custom-file-input"
                ref={(comp) => { this.upload = comp; }}
                name="attachment"
                required
                onChange={this.handleInputChange}
              />
              <label className="custom-file-label" htmlFor="validatedCustomFile">{this.getFileName()}</label>
            </div>
          </div>
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'Change status',
            name: 'type',
            required: true,
            component: ReactSelect,
            options: [{ value: 'mr2c', label: 'Manualy removal => Complete' }],
            onChange: this.selectChangeHandler('type'),
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 5 }
          })}
        </div>
      </div>
    );
  }
}
