import React from 'react';
import PropTypes from 'prop-types';
import { AbstractTargetCriteria, CreatableReactSelect } from '../abstract';
import { AbstractForm, ReactSelect } from 'emi-frontend';
import OperatorHint from '../abstract/OperatorHint';

export default class TargetCriteria extends AbstractTargetCriteria {

  static propTypes = {
    ...TargetCriteria.propTypes,
    filterProp: PropTypes.string.isRequired,
    filterDeprecated: PropTypes.bool,
    criteria: PropTypes.shape({
      attributeId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      options: PropTypes.array
    })
  };

  static defaultProps = {
    ...AbstractTargetCriteria.defaultProps,
    filterDeprecated: false,
  };

  get name() {
    return 'researchnow';
  }

  getDefaultCriteria() {
    return {
      attributeId: null,
      options: []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.idx !== undefined && AbstractForm.valuesDidChange(this.state, nextProps.criteria)) {
      const criteria = Object.assign({}, nextProps.criteria);
      this.setState(criteria);
    }
  }

  getQuestionSelectOptions() {
    const { questions } = this.props;
    return Object
      .keys(questions)
      .filter(key => (questions[key][this.props.filterProp] && (!this.props.filterDeprecated || questions[key].state === 'ACTIVE')))
      .map((key) => {
        return { value: key, label: `${questions[key].name} (${questions[key].text})` };
    });
  }

  getPrecodesSelectOptions(question) {
    const options = TargetCriteria.isSimpleSelect(question) ?
      Object.keys(question.options).map(value => ({ value, label: question.options[value] })) :
      this.state.options.map(value => ({ value, label: value }));

    return options;
  }

  shouldParseValue(prop) {
    const question = this.getSelectedQuestion();

    return prop === 'options' && !TargetCriteria.isSimpleSelect(question);
  }

  valueChanged(prop, value) {
    const changed = super.valueChanged(prop, value);

    if (prop === this.props.identifier && this.getIdentifierValue()) {
      Object.assign(changed, { options: [] });
    }

    return changed;
  }

  operatorChangeHandler = (event) => {
    this.updateState({ operator: event.target.value });
  }

  precodesCount() {
    return this.state.options.length;
  }

  static isSimpleSelect(question) {
    return question.type === 'LIST';
  }

  isRange() {
    return this.getSelectedQuestion().type === 'INTEGER_RANGE';
  }

  getPrecodesSelect() {
    if (!this.getIdentifierValue()) {
      return null;
    }

    const question = this.getSelectedQuestion();
    const props = {
      options: this.getPrecodesSelectOptions(question),
      value: this.state.options,
      onChange: option => this.selectChangeHandler('options', option),
      isMulti: true,
      closeMenuOnSelect: false,
      isDisabled: this.props.readOnly
    };

    return TargetCriteria.isSimpleSelect(question) ? (
      <ReactSelect {...props} closeOnSelect={false} />
    ) : (
      <CreatableReactSelect {...props} />
    );
  }

  operatorHintProps() {
    return {
      ...super.operatorHintProps(),
      isRange: this.isRange()
    };
  }

  getOperatorSelect() {
    const isRange = this.isRange();
    return (
      <div className="form-group col-md-2">
        <select
          name="operator" className="form-control"
          value={isRange ? 'RANGE' : 'OR'}
          disabled
        >
          {isRange ? (
            <option value="RANGE">RANGE</option>
          ) : (
            <option value="OR">OR</option>
          )}
        </select>
        <OperatorHint {...this.operatorHintProps()} />
      </div>
    );
  }
}
