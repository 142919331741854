import React from 'react';
import PropTypes from 'prop-types';
import { AbstractForm, CollapseCard, ButtonSelect, client } from 'emi-frontend';
import { CardFooter, Button } from 'reactstrap';
import { hasOwnProperty, getUserIds } from '../../lib/utils';
import UserSelect from '../../lib/form/UserSelect';
import { coverageTypes } from './constants';

const coverageFields = [
  'start', 'end', 'notes', 'pm_notes', 'supplier', 'feedback', 'priority'
];

export default class CoverageSettingForm extends AbstractForm {
  
  getDefaultValues() {
    return {
      enable_coverage: null,
      ...this.getDefaultCoverage()
    };
  }

  getDefaultCoverage() {
    const coverage = {};
    coverageFields.forEach((field) => {
      coverage[`coverage.${field}`] = '';
    });

    Object.keys(coverageTypes).forEach((field) => {
      coverage[`coverage.type.${field}`] = [];
    });
    return coverage;
  }

  convertResponse(data) {
    const { coverage: coverageData, ...restData} = data;

    // Coverage enabled, convert object
    const coverage = {};
    coverageFields.forEach((field) => {
      coverage[`coverage.${field}`] = coverageData[field] || '';
    });
    Object.keys(coverageTypes).forEach((field) => {
      coverage[`coverage.type.${field}`] = (coverageData.type && coverageData.type[field]) ? coverageData.type[field] : [];
    });
    return {...restData, ...coverage};
  }

  valueChanged(name, value, isEdit) {
    if (name === 'enable_coverage') {
      const coverage = [].concat(value);
      const update = super.valueChanged(name, coverage.length === 0 ? null : coverage, isEdit);
      if ((!update.enable_coverage || (Array.isArray(update.enable_coverage) && !update.enable_coverage.includes('us'))) && 
          this.state.values.coverage_us_pm) {
        Object.assign(update, { coverage_us_pm: null });
      }

      return update;
    }

    if (name.startsWith('coverage.type.')) {
      const coverage = [].concat(value);
      return super.valueChanged(name, coverage.length === 0 ? null : coverage, isEdit);
    }

    return super.valueChanged(name, value, isEdit);
  }

  getFormData() {
    const data = super.getFormData();

    // Convert coverage object
    const coverage = {};
    coverageFields.forEach((field) => {
      if (hasOwnProperty(data, `coverage.${field}`)) {
        coverage[field] = data[`coverage.${field}`];
        delete data[`coverage.${field}`];
      }
    });

    Object.keys(coverageTypes).forEach((field) => {
      if (hasOwnProperty(data, `coverage.type.${field}`)) {
        if (!coverage.type) {
          coverage.type = {};
        }
        coverage.type[field] = data[`coverage.type.${field}`];
        delete data[`coverage.type.${field}`];
      }
    });

    if (Object.keys(coverage).length) {
      data.coverage = coverage;
    }

    return data;
  }

  getApiPath = () => {
    return '/project/coverage';
  }

  get modelName() {
    return 'coverage';
  }

  getId = () => {
    return this.parseInt(this.props.id);
  }

  getFormHeader() {
    return '';
  }

  showDeleteAction() {
    return false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const id = this.parseInt(nextProps.id);
    if (id && id !== this.getId()) {
      this.loadFormData(id);
    }
  }

  successAction = (response) => {
    if (this.isNew()) {
      this.props.onSuccess();
    } else {
      this.loadDone(response, { edit: {} }, this.props.reload);
    }
  }

  getFormFields() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            name: 'enable_coverage',
            label: 'coverage',
            required: true,
            component: ButtonSelect,
            options: { bg: 'EMI PM', us: 'US RM' },
            fieldWidth: { xs: 12, lg: 12 },
            labelWidth: { xs: 12, xl: 2 },
            valueWidth: { xs: 11, xl: 10 }
          })}
        </div>

        {this.state.values.enable_coverage && this.state.values.enable_coverage.includes('us') &&
          <div className="form-row">
            {this.getFormRow({
              name: 'coverage_us_pm',
              label: 'coverage us rm',
              component: UserSelect,
              type: 'admin',
              department: 'rm',
              active: true,
              onChange: this.selectChangeHandler('coverage_us_pm'),
              fieldWidth: { xs: 12, lg: 12 },
              labelWidth: { xs: 12, xl: 2 },
              valueWidth: { xs: 11, xl: 10 }
            })}
          </div>
        }

        <div>Coverage type</div>

        {
          Object.keys(coverageTypes).map((type, idx) => (
            <div key={`covtype_${idx}`} className="form-row">
              {this.getFormRow({
                name: `coverage.type.${type}`,
                label: coverageTypes[type].label || type,
                component: ButtonSelect,
                options: coverageTypes[type].values,
                fieldWidth: { xs: 12, lg: 12 },
                labelWidth: { xs: 12, lg: 2 },
                valueWidth: { xs: 12, lg: 10 }
              })}
            </div>
          ))
        }

        <div>Settings</div>

        <div className="form-row">
          {this.getFormRow({
            label: 'start date',
            name: 'coverage.start',
            type: 'date',
            labelWidth: { xs: 12, xl: 3 },
            valueWidth: { xs: 12, xl: 9 },
            fieldWidth: { xs: 12, xl: 6 }
          })}

          {this.getFormRow({
            label: 'end date',
            name: 'coverage.end',
            type: 'date',
            labelWidth: { xs: 12, xl: 3 },
            valueWidth: { xs: 12, xl: 9 },
            fieldWidth: { xs: 12, xl: 6 }
          })}
        </div>
      
        <div className="form-row">
          {this.getFormRow({
            label: 'Notes',
            name: 'coverage.notes',
            type: 'textarea',
            rows: 5,
            labelWidth: { xs: 12, lg: 2 },
            valueWidth: { xs: 12, lg: 10 },
            fieldWidth: { xs: 12, lg: 12 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'Feedback',
            name: 'coverage.feedback',
            type: 'textarea',
            rows: 5,
            labelWidth: { xs: 12, lg: 2 },
            valueWidth: { xs: 12, lg: 10 },
            fieldWidth: { xs: 12, lg: 12 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'Covering RM Notes',
            name: 'coverage.pm_notes',
            type: 'textarea',
            rows: 5,
            labelWidth: { xs: 12, lg: 2 },
            valueWidth: { xs: 12, lg: 10 },
            fieldWidth: { xs: 12, lg: 12 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'Backup suppliers',
            name: 'coverage.supplier',
            type: 'textarea',
            rows: 5,
            labelWidth: { xs: 12, lg: 2 },
            valueWidth: { xs: 12, lg: 10 },
            fieldWidth: { xs: 12, lg: 12 }
          })} 
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'Priority',
            name: 'coverage.priority',
            component: ButtonSelect,
            options: { low: 'Low', medium: 'Medium', high: 'High' },
            labelWidth: { xs: 12, xl: 3 },
            valueWidth: { xs: 12, xl: 9 },
            fieldWidth: { xs: 12, xl: 6 }
          })}
        </div>
      </div>
    );
  }

  getFormContent() {
    return (
      <div>
        <CollapseCard title={`${this.state.values.name} (#${this.state.values.id})`}>
          {this.getFormFields()}
        </CollapseCard>
      </div>
    );
  }

  sendRequest() {
    client
      .api('PUT', `/project/coverage/${this.getId()}/send`, { coverage: { user: getUserIds(this.context.user) }})
      .done(() => {
        this.setState({ message: 'Message sent', success: true });
      })
      .fail(() => {
        this.setState({ message: 'Failed to send message', success: false });
      });
  }

  getActionButtons() {
    return (
      <CardFooter className="action-buttons d-lg-block">
        <Button type="button" color="success" onClick={() => this.sendRequest()}>
          <i className="fa fa-envelope-o" aria-hidden="true"></i>{' '}
          Send Request
        </Button>
      </CardFooter>
    );
  }
}

CoverageSettingForm.contextTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
