
export default function SalesforceIcon({ color = '#00A1E0' }) {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 262 184">
      <path fill={color} d="M109.2,20.9c8.4-8.7,20.1-14.2,33-14.2c17.2,0,32.1,9.6,40.1,23.8c6.9-3.1,14.6-4.8,22.7-4.8
        c31,0,56,25.3,56,56.5s-25.1,56.5-56,56.5c-3.8,0-7.5-0.4-11-1.1c-7,12.5-20.4,21-35.8,21c-6.4,0-12.5-1.5-17.9-4.1
        c-7.1,16.7-23.7,28.5-43,28.5c-20.1,0-37.3-12.7-43.9-30.6c-2.9,0.6-5.9,0.9-8.9,0.9c-24,0-43.4-19.6-43.4-43.9
        c0-16.2,8.7-30.4,21.7-38c-2.7-6.1-4.2-12.9-4.2-20.1C18.5,23.6,41.2,1,69,1C85.4,1,100,8.8,109.2,20.9"/>
      <path fill="#FFFFFF" d="M38.7,95.4l1.1-2.9c0.2-0.5,0.5-0.3,0.7-0.2c0.3,0.2,0.5,0.3,0.9,0.6c3.1,2,6,2,6.9,2
        c2.3,0,3.8-1.2,3.8-2.9v-0.1c0-1.8-2.2-2.5-4.8-3.3l-0.6-0.2c-3.5-1-7.3-2.5-7.3-6.9v-0.1c0-4.2,3.4-7.2,8.3-7.2l0.5,0
        c2.9,0,5.6,0.8,7.6,2.1c0.2,0.1,0.4,0.3,0.3,0.6c-0.1,0.3-1,2.6-1.1,2.9c-0.2,0.5-0.7,0.2-0.7,0.2c-1.8-1-4.5-1.7-6.8-1.7
        c-2.1,0-3.4,1.1-3.4,2.6v0.1c0,1.7,2.3,2.5,4.9,3.3l0.5,0.1c3.5,1.1,7.2,2.6,7.2,6.9v0.1c0,4.6-3.3,7.4-8.6,7.4
        c-2.6,0-5.1-0.4-7.8-1.8c-0.5-0.3-1-0.5-1.5-0.9C38.7,95.9,38.5,95.8,38.7,95.4z M116.7,95.4l1.1-2.9c0.2-0.5,0.6-0.3,0.7-0.2
        c0.3,0.2,0.5,0.3,0.9,0.6c3.1,2,6,2,6.9,2c2.3,0,3.8-1.2,3.8-2.9v-0.1c0-1.8-2.2-2.5-4.8-3.3l-0.6-0.2c-3.5-1-7.3-2.5-7.3-6.9
        v-0.1c0-4.2,3.4-7.2,8.3-7.2l0.5,0c2.9,0,5.6,0.8,7.6,2.1c0.2,0.1,0.4,0.3,0.3,0.6c-0.1,0.3-1,2.6-1.1,2.9
        c-0.2,0.5-0.7,0.2-0.7,0.2c-1.8-1-4.5-1.7-6.8-1.7c-2.1,0-3.4,1.1-3.4,2.6v0.1c0,1.7,2.3,2.5,4.9,3.3l0.5,0.1
        c3.5,1.1,7.2,2.6,7.2,6.9v0.1c0,4.6-3.3,7.4-8.6,7.4c-2.6,0-5.1-0.4-7.8-1.8c-0.5-0.3-1-0.5-1.5-0.9
        C116.8,95.9,116.6,95.8,116.7,95.4z M174.5,81.7c0.4,1.5,0.7,3.1,0.7,4.8s-0.2,3.3-0.7,4.8c-0.4,1.5-1.1,2.8-2,3.9
        c-0.9,1.1-2.1,2-3.4,2.6c-1.4,0.6-3,0.9-4.8,0.9c-1.8,0-3.4-0.3-4.8-0.9c-1.4-0.6-2.5-1.5-3.4-2.6c-0.9-1.1-1.6-2.4-2-3.9
        c-0.4-1.5-0.7-3.1-0.7-4.8c0-1.7,0.2-3.3,0.7-4.8c0.4-1.5,1.1-2.8,2-3.9c0.9-1.1,2.1-2,3.4-2.6c1.4-0.6,3-1,4.8-1
        c1.8,0,3.4,0.3,4.8,1c1.4,0.6,2.5,1.5,3.4,2.6C173.4,78.9,174.1,80.2,174.5,81.7z M170,86.4c0-2.6-0.5-4.6-1.4-6
        c-0.9-1.4-2.4-2.1-4.3-2.1c-2,0-3.4,0.7-4.3,2.1c-0.9,1.4-1.4,3.4-1.4,6c0,2.6,0.5,4.6,1.4,6.1c0.9,1.4,2.3,2.1,4.3,2.1
        c2,0,3.4-0.7,4.3-2.1C169.6,91.1,170,89,170,86.4z M211.1,93.9l1.1,3c0.1,0.4-0.2,0.5-0.2,0.5c-1.7,0.7-4,1.1-6.3,1.1
        c-3.9,0-6.8-1.1-8.8-3.3c-2-2.2-3-5.2-3-8.9c0-1.7,0.2-3.3,0.7-4.8c0.5-1.5,1.2-2.8,2.2-3.9c1-1.1,2.2-2,3.6-2.6
        c1.4-0.6,3.1-1,5-1c1.3,0,2.4,0.1,3.3,0.2c1,0.2,2.4,0.5,3,0.8c0.1,0,0.4,0.2,0.3,0.5c-0.4,1.2-0.7,2-1.1,3
        c-0.2,0.5-0.5,0.3-0.5,0.3c-1.5-0.5-2.9-0.7-4.7-0.7c-2.2,0-3.9,0.7-4.9,2.2c-1.1,1.4-1.7,3.3-1.7,5.9c0,2.8,0.7,4.8,1.9,6.1
        c1.2,1.3,2.9,1.9,5.1,1.9c0.9,0,1.7-0.1,2.4-0.2c0.7-0.1,1.4-0.3,2.1-0.6C210.5,93.6,210.9,93.5,211.1,93.9z M233.8,80.8
        c1,3.4,0.5,6.3,0.4,6.5c0,0.4-0.4,0.4-0.4,0.4l-15.1,0c0.1,2.3,0.6,3.9,1.8,5c1.1,1.1,2.8,1.8,5.2,1.8c3.6,0,5.1-0.7,6.2-1.1
        c0,0,0.4-0.1,0.6,0.3l1,2.8c0.2,0.5,0,0.6-0.1,0.7c-0.9,0.5-3.2,1.5-7.6,1.5c-2.1,0-4-0.3-5.5-0.9c-1.5-0.6-2.8-1.4-3.8-2.5
        c-1-1.1-1.7-2.4-2.2-3.8c-0.5-1.5-0.7-3.1-0.7-4.8c0-1.7,0.2-3.3,0.7-4.8c0.4-1.5,1.1-2.8,2-3.9c0.9-1.1,2.1-2,3.5-2.6
        c1.4-0.7,3.1-1,5-1c1.6,0,3.1,0.3,4.3,0.9c0.9,0.4,1.9,1.1,2.9,2.2C232.5,77.9,233.4,79.4,233.8,80.8z M218.8,84h10.7
        c-0.1-1.4-0.4-2.6-1-3.6c-0.9-1.4-2.2-2.2-4.2-2.2c-2,0-3.4,0.8-4.3,2.2C219.4,81.3,219.1,82.5,218.8,84z M113.1,80.8
        c1,3.4,0.5,6.3,0.5,6.5c0,0.4-0.4,0.4-0.4,0.4l-15.1,0c0.1,2.3,0.6,3.9,1.8,5c1.1,1.1,2.8,1.8,5.2,1.8c3.6,0,5.1-0.7,6.2-1.1
        c0,0,0.4-0.1,0.6,0.3l1,2.8c0.2,0.5,0,0.6-0.1,0.7c-0.9,0.5-3.2,1.5-7.6,1.5c-2.1,0-4-0.3-5.5-0.9c-1.5-0.6-2.8-1.4-3.8-2.5
        c-1-1.1-1.7-2.4-2.2-3.8c-0.5-1.5-0.7-3.1-0.7-4.8c0-1.7,0.2-3.3,0.7-4.8c0.4-1.5,1.1-2.8,2-3.9c0.9-1.1,2.1-2,3.5-2.6
        c1.4-0.7,3.1-1,5-1c1.6,0,3.1,0.3,4.3,0.9c0.9,0.4,1.9,1.1,2.9,2.2C111.8,77.9,112.8,79.4,113.1,80.8z M98.1,84h10.8
        c-0.1-1.4-0.4-2.6-1-3.6c-0.9-1.4-2.2-2.2-4.2-2.2c-2,0-3.4,0.8-4.3,2.2C98.7,81.3,98.4,82.5,98.1,84z M71.6,83.2
        c0,0,1.2,0.1,2.5,0.3v-0.6c0-2-0.4-3-1.2-3.6c-0.8-0.6-2.1-1-3.7-1c0,0-3.7,0-6.6,1.5c-0.1,0.1-0.2,0.1-0.2,0.1
        s-0.4,0.1-0.5-0.2l-1.1-2.9c-0.2-0.4,0.1-0.6,0.1-0.6c1.4-1.1,4.6-1.7,4.6-1.7c1.1-0.2,2.9-0.4,4-0.4c3,0,5.3,0.7,6.9,2.1
        c1.6,1.4,2.4,3.6,2.4,6.7l0,13.8c0,0,0,0.4-0.3,0.5c0,0-0.6,0.2-1.1,0.3c-0.5,0.1-2.3,0.5-3.8,0.7c-1.5,0.3-3,0.4-4.6,0.4
        c-1.5,0-2.8-0.1-4-0.4c-1.2-0.3-2.2-0.7-3.1-1.3c-0.8-0.6-1.5-1.4-2-2.4c-0.5-0.9-0.7-2.1-0.7-3.4c0-1.3,0.3-2.5,0.8-3.5
        c0.5-1,1.3-1.8,2.2-2.5c0.9-0.7,2-1.1,3.1-1.5c1.2-0.3,2.4-0.5,3.7-0.5C70.2,83.2,71,83.2,71.6,83.2z M65.6,93.8
        c0,0,1.4,1.1,4.4,0.9c2.2-0.1,4.1-0.5,4.1-0.5v-6.9c0,0-1.9-0.3-4.1-0.3c-3.1,0-4.4,1.1-4.4,1.1c-0.9,0.6-1.3,1.6-1.3,2.9
        c0,0.8,0.2,1.5,0.5,2C64.9,93.2,65,93.4,65.6,93.8z M193.1,75.5c-0.1,0.4-0.9,2.5-1.1,3.2c-0.1,0.3-0.3,0.4-0.6,0.4
        c0,0-0.9-0.2-1.7-0.2c-0.5,0-1.3,0.1-2,0.3c-0.7,0.2-1.3,0.6-1.9,1.1c-0.6,0.5-1,1.3-1.3,2.2c-0.3,0.9-0.5,2.4-0.5,4v11.2
        c0,0.3-0.2,0.5-0.5,0.5h-4c-0.3,0-0.5-0.2-0.5-0.5V75.2c0-0.3,0.2-0.5,0.4-0.5h3.9c0.3,0,0.4,0.2,0.4,0.5V77
        c0.6-0.8,1.6-1.5,2.5-1.9c0.9-0.4,2-0.7,3.9-0.6c1,0.1,2.3,0.3,2.5,0.4C193,75,193.2,75.1,193.1,75.5z M156,65.1
        c0.1,0,0.4,0.2,0.3,0.5l-1.2,3.2c-0.1,0.2-0.2,0.4-0.7,0.2c-0.1,0-0.3-0.1-0.8-0.2c-0.3-0.1-0.8-0.1-1.2-0.1
        c-0.6,0-1.1,0.1-1.6,0.2c-0.5,0.1-0.9,0.4-1.3,0.8c-0.4,0.4-0.8,0.9-1.1,1.6c-0.6,1.6-0.8,3.3-0.8,3.4h4.8
        c0.4,0,0.5,0.2,0.5,0.5l-0.6,3.1c-0.1,0.5-0.5,0.4-0.5,0.4h-5L143.6,98c-0.4,2-0.8,3.7-1.3,5.1c-0.5,1.4-1.1,2.4-2,3.4
        c-0.8,0.9-1.7,1.6-2.8,1.9c-1,0.4-2.3,0.6-3.7,0.6c-0.7,0-1.4,0-2.2-0.2c-0.6-0.1-0.9-0.2-1.4-0.4c-0.2-0.1-0.3-0.3-0.2-0.6
        c0.1-0.3,1-2.7,1.1-3.1c0.2-0.4,0.5-0.2,0.5-0.2c0.3,0.1,0.5,0.2,0.8,0.3c0.4,0.1,0.8,0.1,1.2,0.1c0.7,0,1.3-0.1,1.8-0.3
        c0.6-0.2,1-0.6,1.4-1.1c0.4-0.5,0.7-1.2,1.1-2.1c0.3-0.9,0.6-2.2,0.9-3.7l3.4-18.9h-3.3c-0.4,0-0.5-0.2-0.5-0.5l0.6-3.1
        c0.1-0.5,0.5-0.4,0.5-0.4h3.4l0.2-1c0.5-3,1.5-5.3,3-6.8c1.5-1.5,3.7-2.3,6.4-2.3c0.8,0,1.5,0.1,2.1,0.2
        C155,64.8,155.5,64.9,156,65.1z M88.6,97.6c0,0.3-0.2,0.5-0.4,0.5h-4c-0.3,0-0.4-0.2-0.4-0.5V65.5c0-0.2,0.2-0.5,0.4-0.5h4
        c0.3,0,0.4,0.2,0.4,0.5V97.6z"/>
    </svg>
  );
}
