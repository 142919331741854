import React, { Component } from 'react';
import CoverageFilter from './Filter';
import CoverageGrid from './Grid';
import { filterList } from 'emi-frontend';

const CoverageFilterList = filterList(
  CoverageFilter,
  [CoverageGrid]
);

class List extends Component {

  render() {
    return (
      <div className="row">
        <div className="col">
          <CoverageFilterList />
        </div>
      </div>
    );
  }
}

export default List;
