import React from 'react';
import { CardFooter } from 'reactstrap';
import { AbstractForm, formatCurrency, SaveButton, DeleteAction } from 'emi-frontend';
import QuickbooksSelectMain from '../../lib/form/QuickbooksSelectMain';
import QuickbooksSelectSub from '../../lib/form/QuickbooksSelectSub';

function EmailButton({ email, cc, subject, body, className = 'btn-outline-secondary', label = 'Email', showIcon = true }) {
  const link = `mailto:${email}?${Object.entries({ cc, subject, body }).filter(([, val]) => !!val).map(([key, val]) => `${key}=${val.replaceAll("\n", '%0D%0A')}`).join('&')}`;
  // new line \n => %0D%0A
  function writeEmail() {
    window.open(link, '_self');
  }

  return (
    <button type="button" className={`btn ${className}`} onClick={writeEmail}>
      {showIcon && <i className="fa fa-envelope-o mr-2" aria-hidden="true"/>}
      {label}
    </button>
  );
}

export default class RouteLineItemForm extends AbstractForm {

  getApiPath = () => {
    return '/routelineitem';
  }

  get modelName() {
    return 'item';
  }

  getId = () => {
    return this.parseInt(this.props.id);
  }

  isNew() {
    return true;
  }

  getDefaultValues(props) {
    return Object.assign({
      route_id: 0,
      cpi: '',
      completed: '',
      quickbooks_main: '',
      quickbooks_sub: this.props.connectorProject ? 17 : '',
      description: ''
    }, props.values);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const id = this.parseInt(nextProps.id);
    if (id !== this.getId()) {
      if (id) {
        this.loadFormData(id);
      } else {
        this.resetForm(nextProps);
        this.loadQuickbooks();
      }
    }
  }

  loadQuickbooks() {
    // Load the Quickbooks fields of the first line item in the job / invoice
    let url = `${this.getApiPath()}/quickbooks/project/${this.props.projectId}`;
    if (this.props.invoiceId) {
      url = `${this.getApiPath()}/quickbooks/invoice/${this.props.invoiceId}`;
    }
    this.clientApi('GET', url)
      .done((response) => {
        if (response.data) {
          this.setState({
            values: { ...this.state.values, ...response.data }
          });
        }
      });
  }

  componentDidMount() {
    if (this.shouldLoadData()) {
      this.loadFormData(this.getId());
    } else {
      this.loadQuickbooks();
    }
  }

  successAction = () => {
    this.props.reload();
    this.resetForm(this.props);
    this.loadQuickbooks();
  }

  deleteDone = () => {
    this.successAction();
  }

  getFormHeader() {
    return null;
  }

  getActionButtons() {
    return (
      <CardFooter className="action-buttons d-lg-block">
        {this.showSaveButton() && <SaveButton model={this.modelName} disabled={this.state.sendingData} />}
        {this.showDeleteAction() && <DeleteAction model={this.modelName} onDelete={this.onDelete} />}
        {this.props.reconciliationEmail !== null &&
          <EmailButton
            label="Email Reconciliation"
            email={this.props.reconciliationEmail}
            subject={"Reconciliation - EMI"}
            body={`Hello,\n\nPlease let us know if you have any questions on final IDs.\n\nIO# ${this.props.ioNumber || 'XXXXXX'}\n\nCOMPLETES XXXX\n\nREMOVALS XXXX\n\nThank you!`}
          />
        }
      </CardFooter>
    );
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            name: 'cpi',
            type: 'number',
            step: 0.01,
            min: 0,
            formatter: formatCurrency,
            prefix: '$',
            required: true,
            fieldWidth: { xs: 12, md: 12 },
            labelWidth: { xs: 12, xl: 3 },
            valueWidth: { xs: 12, xl: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'completed',
            type: 'number',
            step: 1,
            min: 0,
            required: true,
            fieldWidth: { xs: 12, md: 12 },
            labelWidth: { xs: 12, xl: 3 },
            valueWidth: { xs: 12, xl: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'quickbooks_main',
            label: 'QB Category',
            component: QuickbooksSelectMain,
            required: true,
            onChange: this.selectChangeHandler('quickbooks_main'),
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, xl: 3 },
            valueWidth: { xs: 12, xl: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'quickbooks_sub',
            label: 'QB List',
            component: QuickbooksSelectSub,
            required: true,
            onChange: this.selectChangeHandler('quickbooks_sub'),
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, xl: 3 },
            valueWidth: { xs: 12, xl: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'description',
            type: 'textarea',
            required: false,
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, xl: 3 },
            valueWidth: { xs: 12, xl: 9 }
          })}
        </div>
      </div>
    );
  }
}
