import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { LoadServerData } from '../abstract';

export default class BillingStats extends LoadServerData {
  constructor(props) {
    super(props, 'stats');
  }

  static propTypes = {
    routeId: PropTypes.number.isRequired,
    prodegeProjectId: PropTypes.number.isRequired
  };

  getApiUrl() {
    return `/panelapi/${this.props.routeId}/project/billing`;
  }

  getApiMethod() {
    return 'POST';
  }

  getApiData() {
    return {
      prodege_project_id: this.props.prodegeProjectId
    };
  }

  renderContent() {
    if (this.state.stats === null) {
      return (
        <Alert color="warning" className="mt-3">No Data</Alert>
      );
    }

    return (
      <table className="table table-sm table-hover top-margin-10">
        <tbody>
          <tr>
            <th scope="row">Completion datetime</th>
            <td className="text-center">{this.state.stats.completion_datetime}</td>
          </tr>
          <tr>
            <th scope="row">Total completes</th>
            <td className="text-center">{this.state.stats.total_completes}</td>
          </tr>
          <tr>
            <th scope="row">Total cost</th>
            <td className="text-center">{this.state.stats.total_cost}</td>
          </tr>
        </tbody>
        <tbody className="thead-light">
          <tr>
            <th scope="row" colSpan="2">Panelists</th>
          </tr>
        </tbody>
        {this.state.stats.panelists.map(panelis =>
          <tbody key={panelis.panelist_name}>
            <tr>
              <th scope="row">Name</th>
              <td className="text-center">{panelis.panelist_name}</td>
            </tr>
            <tr>
              <th scope="row">Total completes</th>
              <td className="text-center">{panelis.total_completes}</td>
            </tr>
            <tr>
              <th scope="row">AVG CPI</th>
              <td className="text-center">{panelis.avg_cpi}</td>
            </tr>
            <tr>
              <th scope="row">Total cost</th>
              <td className="text-center">{panelis.total_cost}</td>
            </tr>
          </tbody>
        )}
      </table>
    );
  }
}
