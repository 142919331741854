import { LoadServerData } from '../abstract';
import Channel from './Channel';

const typeMap = {
  REP: 'Replica',
  TL: 'Targeted List',
  SPA: 'Specific Audience',
};

const statusMap = {
  11: 'DRAFTS',
  22: 'LIVE',
  33: 'PAUSED',
  44: 'CLOSED',
};

export default class Channels extends LoadServerData {
  constructor(props) {
    super(props, 'channels', []);
  }

  getApiUrl() {
    return `/panelapi/${this.props.routeId}/channels`;
  }

  getApiMethod() {
    return 'POST';
  }

  getApiData() {
    return {
      ps_survey_id: this.props.partnerProjectId
    };
  }

  renderContent() {
    return (
      <div>
        <Channel
          routeId={this.props.routeId}
          partnerId={this.props.partnerId}
          countryId={0}
          partnerProjectId={this.props.partnerProjectId}
          onSuccess={this.reloadData}
        />
        {this.state.channels.length === 0 ? (
          <div className="alert alert-warning mt-3" role="alert">
            No Channels found
          </div>
        ) : (
          this.state.channels.map((channel, idx) =>
          <div key={idx} className="card my-2">
            <div className="card-body p-0">
              <table className="table table-sm mb-0">
                <tbody>
                  <tr>
                    <th scope="row">Survey title</th>
                    <td>{channel.survey_title}</td>
                  </tr>
                  <tr>
                    <th scope="row">Type</th>
                    <td>{typeMap[channel.type]}</td>
                  </tr>
                  <tr>
                    <th scope="row">Survey status</th>
                    <td>{statusMap[channel.ps_survey_status]}</td>
                  </tr>
                  <tr>
                    <th scope="row">Survey creation</th>
                    <td>{channel.survey_creation}</td>
                  </tr>
                  <tr>
                    <th scope="row">Created</th>
                    <td>{channel.created_at}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
