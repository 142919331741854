import React, { useRef } from 'react';
import AttributeFilter from './AttributeFilter';
import AttributeSelectGrid from './AttributeSelectGrid';

export default function AttributeSelectList(props) {
  const grid = useRef();

  function filterGrid(params) {
    if (grid.current) {
      grid.current.setExtraParams(params);
    }
  }

  return (
    <div className="row">
      <div className="col">
        <AttributeFilter onFilter={filterGrid} />
        <AttributeSelectGrid {...props} ref={grid} />
      </div>
    </div>
  );
}
