import React from 'react';
import PropTypes from 'prop-types';
import TargetCriteria from './TargetCriteria';
import { ReactSelect, CreatableReactSelect } from 'emi-frontend';

export default class FeasibilityTargetCriteria extends TargetCriteria {

  static propTypes = {
    ...TargetCriteria.propTypes,
    criteria: PropTypes.shape({
      q_Name: PropTypes.string,
      answer_Ids: PropTypes.array
    })
  };

  getDefaultCriteria() {
    return {
      q_Name: '',
      answer_Ids: []
    };
  }

  getQuestionSelectOptions() {
    const { questions } = this.props;
    return Object.keys(questions).map((key) => {
      return { value: questions[key].name, label: `${questions[key].name} (${questions[key].text})` };
    });
  }

  getSelectedQuestion() {
    return Object
      .values(this.props.questions)
      .find(question => (question.name === this.getIdentifierValue()));
  }

  showOperator() {
    return this.getIdentifierValue() !== '';
  }

  getPrecodesSelectOptions() {
    const question = this.getSelectedQuestion();
    const options = this.hasAnswers(question) ?
      Object.keys(question.answers).map(value => ({ value, label: question.answers[value] })) :
      this.state.answer_Ids.map(value => ({ value, label: value }));

    return options;
  }

  valueChanged(prop, value) {    
    const changed = super.valueChanged(prop, value);

    if (prop === 'q_Name') {
      Object.assign(changed, { answer_Ids: [] });
    }

    return changed;
  }

  precodesCount() {
    return this.state.answer_Ids.length;
  }

  getPrecodesSelect() {
    if (!this.state.q_Name) {
      return null;
    }

    const props = {
      options: this.getPrecodesSelectOptions(),
      value: this.state.answer_Ids,
      onChange: option => this.selectChangeHandler('answer_Ids', option),
      isMulti: true,
      closeMenuOnSelect: false,
      isDisabled: this.props.readOnly
    };

    return this.useCreatable() ? (
      <CreatableReactSelect {...props} />
    ) : (
      <ReactSelect {...props} closeOnSelect={false} />
    );
  }
}
