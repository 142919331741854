import React from 'react';
import TargetCriteria from './TargetCriteria';

export default class Qualification extends TargetCriteria {

  isQuestionSelectDisabled() {
    return this.props.idx !== undefined;
  }

  shouldResetCriteria() {
    return !this.isQuestionSelectDisabled();
  }

  /*
    Targeting for age and gender of child { "qualification_code" : 220 & 230 } will only appear in the criteria for the quota. 
    It will not appear in the qualifications array. 
    This is because that the survey may target for the people who have children as well as for people who don't have children in the same survey.
  */
  filterQuestion(key) {
    // key is string
    return key != 220 && key != 230;
  }

  getAddButton() {
    return (
      <button key="create" type="button" className="btn btn-sm btn-success mb-1" onClick={() => this.addCriteria()}>
        <i className="fa fa-save" aria-hidden="true"></i>
      </button>
    );
  }
}
