import React from 'react';
import { Button } from 'reactstrap';
import { AbstractForm, CollapseCard, FormDisabledRow, precisionRound } from 'emi-frontend';
import ClientSelect from '../../lib/form/ClientSelect';
import ContactSelect from '../../lib/form/ContactSelect';
import StatusField from '../invoice/StatusField';
import { getInvoiceStatus } from '../invoice/utils';
import PartnerInformation from './PartnerInformation';
import ExportMatchForm from '../job/ExportMatchForm';
import AttachmentModal from '../job/AttachmentModal';
import ReconciliationModal from '../job/ReconciliationModal';
import CloneProjectForm from '../job/CloneProjectForm';
import LineItemGrid from './LineItemGrid';
import CoverageSettingModal from '../coverage/SettingModal';
import ResetIDStatusModal from './ResetIDStatusModal';
import ChangeStatusModal from './ChangeStatusModal';

export default class MultiViewForm extends AbstractForm {
  constructor(props) {
    super(props);

    this.state.group = 'project';
    this.state.exportMatchModal = false;
    this.state.attachmentModal = false;
    this.state.cloneProjectModal = false;
    this.state.reconciliationModal = false;
    this.state.coveragenModal = false;
    this.state.resetModal = false;
    this.state.changeModal = false;
  }

  getApiPath = () => {
    return '/invoice';
  }

  get modelName() {
    return 'multi-link';
  }

  showDeleteAction() {
    return false;
  }

  getHeaderButtons() {
    return (
      <span className="action-buttons float-right d-none d-sm-block">
        <Button 
          type="button" className="project-button"
          color="default"
          onClick={() => this.context.history.replace(`/invoices/${this.state.values.id}`)}
        >
          View Invoice
        </Button>
      </span>
    );
  }

  toggleModal = (prop) => {
    this.setState({
      [prop]: !this.state[prop]
    });
  }

  convertResponse(data) {
    if (data.projects !== null && Object.keys(data.projects).length) {
      const project = data.projects[Object.keys(data.projects)[0]];
      return {
        ...data,
        accounting_id: project.accounting_id,
        client_id: project.client_id,
        contact_id: project.contact_id,
        job_type: project.client_job_type,
        job_number: project.job_number,
        qclass: `${project.sales}:${project.pm}`
      }
    } else {
      data.projects = {};
    }

    return data;
  }

  changeGroup(value) {
    this.setState(
      { group: value },
      () => this.routeGrid.setExtraParam('group', this.state.group)
    );
  }

  getFormContent() {
    return (
      <div>
        <CollapseCard title="General details">
          <div className="form-row">
            <FormDisabledRow
              label="IO#"
              name="io_number"
              id="io_number"
              value={this.state.values.io_number}
              fieldWidth={{ xs: 12, xl: 4 }}
              labelWidth={{ xs: 12, lg: 4 }}
              valueWidth={{ xs: 12, lg: 8 }}
            />

            {this.getFormRow({
              label: 'client',
              name: 'client_id',
              component: ClientSelect,
              onChange: this.selectChangeHandler('client_id'),
              required: true,
              fieldWidth: { xs: 12, xl: 5 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.getFormRow({
              label: 'Start Date',
              name: 'start_date',
              type: 'date',
              fieldWidth: { xs: 12, xl: 3 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              label: 'project contact',
              name: 'contact_id',
              component: ContactSelect,
              client: this.state.values.client_id,
              type: 'project',
              onChange: this.selectChangeHandler('contact_id'),
              fieldWidth: { xs: 12, xl: 4 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.getFormRow({
              label: 'accounting contact',
              name: 'accounting_id',
              component: ContactSelect,
              client: this.state.values.client_id,
              type: 'accounting',
              onChange: this.selectChangeHandler('accounting_id'),
              fieldWidth: { xs: 12, xl: 5 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.getFormRow({
              label: 'Close Date',
              name: 'close_date',
              type: 'date',
              fieldWidth: { xs: 12, xl: 3 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              label: 'Customer PO#',
              name: 'po_number',
              fieldWidth: { xs: 12, xl: 4 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.getFormRow({
              label: 'Customer Job#',
              name: 'job_number',
              fieldWidth: { xs: 12, xl: 5 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            <FormDisabledRow
              label="Job Type"
              name="job_type"
              id="job_type"
              value={this.state.values.job_type}
              fieldWidth={{ xs: 12, sm: 3 }}
              labelWidth={{ xs: 12, lg: 4 }}
              valueWidth={{ xs: 12, lg: 8 }}
            />
          </div>

          <div className="form-row">
            {this.getFormRow({
              label: 'Misc',
              name: 'misc',
              fieldWidth: { xs: 12, xl: 4 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            <FormDisabledRow
              label="status"
              name="status"
              component={StatusField}
              value={getInvoiceStatus(this.state.values.status)}
              fieldWidth={{ xs: 12, xl: 5 }}
              labelWidth={{ xs: 12, lg: 4 }}
              valueWidth={{ xs: 12, lg: 8 }}
            />

            <FormDisabledRow
              label="Class"
              name="class"
              id="class"
              value={this.state.values.qclass}
              fieldWidth={{ xs: 12, sm: 3 }}
              labelWidth={{ xs: 12, lg: 4 }}
              valueWidth={{ xs: 12, lg: 8 }}
            />
          </div>

          <div className="form-row">
            <FormDisabledRow
              label="IR"
              name="ir"
              id="ir"
              value={this.state.values.incidence === null ? '' : `${this.state.values.ir}%` }
              fieldWidth={{ xs: 12, xl: 4 }}
              labelWidth={{ xs: 12, lg: 4 }}
              valueWidth={{ xs: 12, lg: 8 }}
            />

            <FormDisabledRow
              label="LOI"
              name="loi"
              id="loi"
              value={this.state.values.loi === null ? '' : `${precisionRound(this.state.values.loi / 60, 2)}m`}
              fieldWidth={{ xs: 12, xl: 5 }}
              labelWidth={{ xs: 12, lg: 4 }}
              valueWidth={{ xs: 12, lg: 8 }}
            />
          </div>

          <div className="form-row">
            {this.getFormRow({
              label: 'reporting link',
              name: 'reporting_link',
              fieldWidth: { xs: 12, xl: 6 },
              labelWidth: { xs: 12, xl: 3 },
              valueWidth: { xs: 12, xl: 9 },
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              label: 'notes',
              name: 'notes',
              type: 'textarea',
              rows: 5,
              fieldWidth: { xs: 12, xl: 6 },
              labelWidth: { xs: 12, xl: 3 },
              valueWidth: { xs: 12, xl: 9 },
            })}
          </div>

          <div className="form-row d-none d-sm-block action-buttons">
            <button
              type="button" className="btn btn-info"
              onClick={() => this.toggleModal('exportMatchModal')}
            >
              Export Partner IDs
            </button>
            <button
              type="button" className="btn btn-info"
              onClick={() => this.toggleModal('attachmentModal')}
            >
              <i className="fa fa-files-o" aria-hidden="true"></i>{' '}
              Project Files
            </button>
            <button
              type="button" className="btn btn-info"
              onClick={() => this.toggleModal('reconciliationModal')}
            >
              <i className="fa fa-file-excel-o" aria-hidden="true"></i>{' '}
              Reconciliation
            </button>
            <button
              type="button" className="btn btn-info"
              onClick={() => this.toggleModal('cloneProjectModal')}
            >
              <i className="fa fa-clone" aria-hidden="true"></i>{' '}
              Clone Project
            </button>
            <button
              type="button" className="btn btn-info"
              onClick={() => this.toggleModal('coveragenModal')}
            >
              Coverage Requested
            </button>
            <button
              type="button" className="btn btn-warning"
              onClick={() => this.toggleModal('resetModal')}
            >
              <i className="fa fa-recycle" aria-hidden="true"></i>{' '}
              Reset ID Status
            </button>
            <button
              type="button" className="btn btn-warning"
              onClick={() => this.toggleModal('changeModal')}
            >
              <i className="fa fa-exchange" aria-hidden="true"></i>{' '}
                Change Status
            </button>
          </div>
        </CollapseCard>

        <PartnerInformation invoice_id={this.getId()} ioNumber={this.state.values.io_number} />

        <LineItemGrid 
          title="Line Items" paging={false}
          extraParams={{ invoice_id: this.getId() }}
          storeState={false}
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        {super.render()}
        {this.isUpdate() &&
          <ExportMatchForm
            values={{ id: this.getId(), type: 'invoice' }}
            isOpen={this.state.exportMatchModal}
            toggle={() => this.toggleModal('exportMatchModal')}
          />
        }
        {this.isUpdate() &&
          <AttachmentModal
            invoice_id={this.getId()} 
            values={{
              io_number: this.state.values.io_number,
            }}
            isOpen={this.state.attachmentModal}
            toggle={() => this.toggleModal('attachmentModal')}
          />
        }
        {this.isUpdate() &&
          <ReconciliationModal
            invoice_id={this.getId()}
            values={{
              io_number: this.state.values.io_number,
            }}
            isOpen={this.state.reconciliationModal}
            toggle={() => this.toggleModal('reconciliationModal')}
          />
        }
        {this.isUpdate() && this.state.values.projects &&
          <CoverageSettingModal
            projects={Object.keys(this.state.values.projects)}
            isOpen={this.state.coveragenModal}
            toggle={() => this.toggleModal('coveragenModal')}
          />
        }
        {this.isUpdate() &&
          <ResetIDStatusModal
            invoice_id={this.state.values.id}
            isOpen={this.state.resetModal}
            toggle={() => this.toggleModal('resetModal')}
            onSuccess={() => {
              // if (this.routeGrid) this.routeGrid.reload();
            }}
          />
        }
        {this.isUpdate() &&
          <ChangeStatusModal
            invoice_id={this.state.values.id}
            isOpen={this.state.changeModal}
            toggle={() => this.toggleModal('changeModal')}
            title="Change status"
            onSuccess={() => {
              // if (this.routeGrid) this.routeGrid.reload();
            }}
          />
        }
        <CloneProjectForm
          id={this.state.values.id}
          values={{
            io_number: this.state.values.io_number,
            source_io_number: this.state.values.io_number
          }}
          isOpen={this.state.cloneProjectModal}
          toggle={() => this.toggleModal('cloneProjectModal')}
          secure_callback={this.state.values.secure_callback}
          isMultiView
        />
      </div>
    );
  }
}
