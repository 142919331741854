import React from 'react';
import { components } from 'react-select';

export default function SelectAllMenuList(selectAllFn) {
  return function SelectAll(props) {
    const showSelectAll = Array.isArray(props.children) && props.children.length;

    return (
      <components.MenuList {...props}>
        {showSelectAll &&
          <button
            type="button"
            className="btn btn-outline-info btn-sm btn-block"
            onClick={() => selectAllFn(props.options)}
          >
            Select all
          </button>
        }
        {props.children}
      </components.MenuList>
    );
  }
};