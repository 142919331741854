import React from 'react';
import { Body } from 'emi-frontend';

import 'bootstrap/js/dist/dropdown';

export default class AttributeGridBody extends Body {
  render() {
    if (this.props.loading && !this.props.data.length) {
      return '';
    }

    if (!Array.isArray(this.props.data) || !this.props.data.length) {
      return <this.context.components.NoRecord />;
    }

    /*
<this.context.components.Row
  key={idx}
  row={row}
  className={classNames({ loading: this.props.loading })}
  columns={this.props.columns}
  onClick={this.props.onClick}
  rowStyler={this.props.rowStyler}
  rowCount={this.props.recordCount}
  currentPage={this.props.currentPage}
  pageSize={this.props.pageSize}
  rowIndex={idx}
/>
    */
    const rows = this.props.data.map((row, idx) => {
      let iconClass = '-h';
      if (row.nested) {
        iconClass = '-alt';
      }
      return (
        <div key={`pa_list${row.id}`} className="btn-group">
          <button type="button" className="btn btn-success mt-1 mt-lg-0" onClick={() => this.props.onClick(row)}>
            <i className={`fa fa-arrows${iconClass}`} />&nbsp;
            {row.name}
          </button>&nbsp;
        </div>
      );
    });


    return rows;
  }
}
