// Similar to the Lib Job grid (buttons, styles are removed)
import JobGrid from '../job/Grid';
import { ProgressRing, precisionRound } from 'emi-frontend'

export default class ProjectGrid extends JobGrid {

  getInitialState(props) {
    const initialState = super.getInitialState(props);
    Object.assign(initialState, {
      title: 'Projects',
      className: 'job-grid'
    });

    Object.assign(initialState.extraParams, { connector: true });

    return initialState;
  }

  get gridName() {
    return 'projectGrid';
  }

  getColumns() {
    const columns = super.getColumns();
    columns.splice(2, 0, {
      name: 'progress',
      title: '',
      sortable: false,
      width: 60,
      exactWidth: true,
      renderer: ProgressRing,
      params: {
        size: 40,
        stroke: 4,
        showCompleted: true
      }
    });

    columns.splice(3, 0, {
      name: 'redirects',
      title: 'clicks',
      sortable: false,
      width: 100,
      exactWidth: true
    });

    return columns
      .concat([{
        name: 'incidence',
        title: 'IR',
        width: 100,
        exactWidth: true,
        renderer: (props) => props.data === null ? '' : `${props.data}%`
      }, {
        name: 'loi',
        title: 'LOI',
        width: 100,
        exactWidth: true,
        renderer: (props) => props.data === null ? '' : `${precisionRound(props.data / 60, 2)}m`
      }]);
  }
}
