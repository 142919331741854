import React from 'react';
import GridMobileRow from './GridMobileRow';
import { client, Grid, CollapseCard, formatDate, formatCurrency, GridMobileBody, Button } from 'emi-frontend';
import jobSummary from './JobSummary';
import { getInvoiceStatus } from './utils';

export default class InvoiceGrid extends Grid {

  getInitialState(props) {
    return Object.assign(
      super.getInitialState(props),
      { className: 'invoice-grid' }
    );
  }

  get gridName() {
    return 'invoiceGrid';
  }

  getApiPath = () => {
    return '/invoice';
  }

  getBasePath = () => {
    return '/invoices';
  }

  projectNameRenderer = ({ data, row }) => {
    const projects = data.filter(projectName => projectName !== null);
    if (projects.length === 0) {
      return '-';
    }

    return (
      <CollapseCard
        collapsed
        title={`${projects.length} project${projects.length > 1 ? 's' : ''}`}
        className="invoice-project-name"
      >
        <ul className="list-group list-group-flush">
          {
            projects.map((project, idx) => (
              <li key={`ppli_${row.id}_${idx}`} className="list-group-item">{project}</li>
            ))
          }
        </ul>
      
      </CollapseCard>
    );
  }

  statusRenderer = ({ data }) => getInvoiceStatus(data)

  dateFormatter = ({ data }) => {
    let date = data;
    if (Array.isArray(data)) {
      if (data.length) {
        date = data[0];
      } else {
        date = null;
      }
    }
    return formatDate(date);
  }

  priceFormater = ({ data }) => {
    if (data === null) {
      return 'N/A';
    }

    return formatCurrency(data);
  }

  getRowStyler() {
    return function (row) {
      // Mark finalized invoices
      if (row.finalized) {
        return 'finalized-invoice';
      }
    }
  }

  gridButtonRenderer = (props) => {
    const {className, btnClassName, tooltip, ...rest} = props; 
    rest.className = btnClassName;
    rest.title = tooltip;
    if (props.row.finalized === true) {
      return (
        <div className={className}>
          <Button {...rest} />
        </div>
      );
    }
    
    return null;
  }

  downloadJobSummary = (id, row) => {
    client
      .api('GET', `${this.getApiPath()}/${id}`)
      .done((response) => jobSummary(response.data));
  }

  downloadInvoice = (id, row) => {
    client
      .fileDownload(`${this.getApiPath()}/${id}/invoice`, `invoice_${row.io_number}.pdf`);
  }

  getColumns() {
    return [{
      name: 'client_name',
      title: 'client name',
      width: 250
    }, {
      name: 'project_name',
      title: 'Job name',
      width: 250,
      renderer: this.projectNameRenderer
    }, {
      name: 'invoice_date',
      title: 'Finalized date',
      width: 100,
      renderer: this.dateFormatter
    }, {
      name: 'close_date',
      title: 'Invoice date',
      width: 100,
      renderer: this.dateFormatter
    }, {
      name: 'invoice_amount',
      title: 'Invoice amount',
      width: 120,
      renderer: this.priceFormater
    }, {
      name: 'status',
      title: 'status',
      renderer: this.statusRenderer,
      width: 100
    }, {
      name: 'io_number',
      title: 'IO #',
      width: 80,
      asc: false
    }, {
      name: 'job_summary',
      dataField: 'id',
      title: '',
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderer: this.gridButtonRenderer,
      params: {
        icon: 'credit-card',
        className: 'left-border',
        clickHandler: this.downloadJobSummary,
        tooltip: 'Job Summary'
      }
    }, {
      name: 'invoice',
      dataField: 'id',
      title: '',
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderer: this.gridButtonRenderer,
      params: {
        icon: 'file-pdf-o',
        className: 'left-border',
        clickHandler: this.downloadInvoice,
        tooltip: 'Invoice PDF'
      }
    }];
  }

  getMobileHeader() {
    return '';
  }

  getMobileBody() {
    return <GridMobileBody RowClass={GridMobileRow} columns={this.state.columns} data={this.state.data} onClick={this.onRowClick} loading={this.state.loadingData} />;    
  }
}
