import React, { Component } from 'react';
import PartnerFilter from './Filter';
import PartnerGrid from './Grid';
import FeedbackGrid from './FeedbackGrid';
import FeedbackForm from './FeedbackForm';
import BlocklistModal from './BlocklistModal';

class List extends Component {

  constructor(props) {
    super(props);

    this.grids = [];
    this.state = {
      feedbackModal: false,
      feedbackId: null,
      blocklistModal: false
    };
  }

  toggleFeedbackModal = () => {
    this.setState({
      feedbackId: null,
      feedbackModal: !this.state.feedbackModal
    });
  }

  toggleBlocklistkModal = () => {
    this.setState({
      blocklistModal: !this.state.blocklistModal
    });
  }

  reloadGridData = (grid) => {
    if (this.grids[grid]) {
      this.grids[grid].reload();
    }
  }

  getFilter() {
    return this.filter;
  }

  filterGrids = (params) => {
    this.grids.forEach((grid) =>
      grid.setExtraParams(params)
    );
  }

  exportFeedback = () => {
    if (this.grids[1]) {
      this.grids[1].exportData();
    }
  }

  onEditFeedback = (row) => {
    this.setState({
      feedbackId: row.id,
      feedbackModal: true
    });
  }

  onSuccessFeedback = () => {
    this.toggleFeedbackModal();
    this.reloadGridData(1);
  }

  filterFeedback = (params) => {
    if (this.grids[1]) {
      this.grids[1].setExtraParams(params);
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col">
          <PartnerFilter
            onFilter={this.filterGrids}
            onToggleFeedback={this.toggleFeedbackModal}
            onToggleBlocklist={this.toggleBlocklistkModal}
            onExportFeedback={this.exportFeedback}
          />
          <PartnerGrid
            ref={(c) => this.grids[0] = c}
            onDataChanged={this.reloadGridData}
            onFilterLetter={this.filterFeedback}
          />
          <FeedbackGrid 
            ref={(c) => this.grids[1] = c}
            onDataChanged={this.reloadGridData}
            onRowSelection={this.onEditFeedback} 
          />
          <FeedbackForm 
            isOpen={this.state.feedbackModal} toggle={this.toggleFeedbackModal}
            onSuccess={this.onSuccessFeedback} onDelete={() => this.reloadGridData(1)}
            id={this.state.feedbackId}
          />
          <BlocklistModal
            isOpen={this.state.blocklistModal}
            toggle={this.toggleBlocklistkModal}
          />
        </div>
      </div>
    );
  }
}

export default List;
