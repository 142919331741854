import React from 'react';
import PropTypes from 'prop-types';
import { AbstractTargetCriteria, CreatableReactSelect } from '../abstract';
import { ReactSelect } from 'emi-frontend';
import OperatorHint from '../abstract/OperatorHint';

const operatorMap = {
  Numeric: ['OR', 'NOT', 'BETWEEN'],
  Text: ['OR', 'NOT', 'BETWEEN'],
  'Single-Select': ['OR', 'NOT'],
  'Multi-Select': ['AND', 'OR', 'NOT']
};

export default class TargetCriteria extends AbstractTargetCriteria {

  static propTypes = {
    ...TargetCriteria.propTypes,
    criteria: PropTypes.shape({
      question_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      operator: PropTypes.string,
      precodes: PropTypes.array
    })
  };

  get name() {
    return 'prodege';
  }

  getDefaultCriteria() {
    return {
      question_id: null,
      operator: 'AND',
      precodes: []
    };
  }

  getQuestionSelectOptions() {
    const { questions } = this.props;
    return Object.keys(questions).map((key) => {
      return { value: key, label: `${questions[key].question_name} (${questions[key].question_text})` };
    });
  }

  getPrecodesSelectOptions() {
    const question = this.getSelectedQuestion();
    const options = this.isNumericOrText(question) ? 
      this.state.precodes.map(value => ({ value, label: value })) :
      Object.keys(question.options).map(value => ({ value, label: question.options[value] }));

    return options;
  }

  shouldParseValue(prop) {
    return prop === 'precodes' && this.useCreatable() && this.state.operator === 'OR';
  }

  valueChanged(prop, value) {
    const changed = super.valueChanged(prop, value);

    if (prop === 'question_id') {
      const operator = this.getOperators(value)[0];
      Object.assign(changed, { operator, precodes: [] });
    }

    return changed;
  }

  getEmptyOptionValue() {
    return [];
  }

  operatorChangeHandler = (event) => {
    this.updateState({ operator: event.target.value });
  }

  getOperators(question_id) {
    return operatorMap[this.getSelectedQuestion(question_id).question_type];
  }

  precodesCount() {
    return this.state.precodes.length;
  }

  isNumericOrText(question) {
    return question.question_type === 'Numeric' || question.question_type === 'Text';
  }

  useCreatable() {
    return this.isNumericOrText(this.getSelectedQuestion());
  }

  getPrecodesSelect() {
    if (!this.state.question_id) {
      return null;
    }

    const props = {
      options: this.getPrecodesSelectOptions(),
      value: this.state.precodes,
      onChange: option => this.selectChangeHandler('precodes', option),
      isMulti: true,
      closeMenuOnSelect: false,
      isDisabled: this.props.readOnly
    };

    return this.useCreatable() ? (
      <CreatableReactSelect {...props} />
    ) : (
      <ReactSelect {...props} closeOnSelect={false} />
    );
  }

  getOperatorSelect() {
    return (
      <div className="form-group col-md-2">
        <select
          name="operator" className="form-control"
          value={this.state.operator}
          onChange={this.operatorChangeHandler}
          disabled={this.props.readOnly}
        >
          {this.getOperators(this.state.question_id).map(operator =>
            <option key={operator} value={operator}>{operator}</option>
          )}
        </select>
        <OperatorHint {...this.operatorHintProps()} />
      </div>
    );
  }

  isDeletable() {
    const question = this.getSelectedQuestion();
    return this.props.isDeletable && !['Age', 'Gender'].includes((question || {}).question_name);
  }
}
