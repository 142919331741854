import { Nav, NavItem } from 'reactstrap';
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { client, useAuth } from 'emi-frontend';
import PubSub from 'pubsub-js';
import TaskGrid from './TaskGrid';

const REFRESH_INTERVALL = 10000;
const NUM_CALLS = 12;

export default function NavRightItems() {
  const [showModal, setShowModal] = useState(false);
  const [tasks, setTasks] = useState(0);
  const [refreshing, setRefresh] = useState(false);
  const [calls, setCalls] = useState(0);
  const { user } =  useAuth();

  const callsRef = useRef();
  const refreshRef = useRef();

  callsRef.current = calls;
  refreshRef.current = refreshing;

  useEffect(() => {
    loadData();
    PubSub.subscribe('background_job_update', pullCounter);
    return () => {
      PubSub.unsubscribe('background_job_update');
    };
  }, []);

  function loadData() {
    client.api('GET', `/task/count/${user.id}`)
      .done((resp) => {
        setTasks(resp.data.num);
        setCalls((calls) => calls + 1);
        if (refreshRef.current) {
          if (callsRef.current < NUM_CALLS) {
            setTimeout(loadData, REFRESH_INTERVALL);
          } else {
            // Stop continues pull
            setCalls(0);
            setRefresh(false);
          }
        }
      })
      .fail(error => {
        console.error(error);
      });
  }

  function pullCounter(name, once) {
    if (once) {
      // Refresh counter just one time
      loadData();
    } else {
      // Start continuous counter refresh
      if (!refreshing) {
        setRefresh(true);
        setCalls(0);
        setTimeout(loadData, REFRESH_INTERVALL);
      }
    }
  }

  function toggleModal() {
    setShowModal(!showModal);
  }
  
  return (
    <Nav navbar className="ml-auto">
      <NavItem>
        <div className="task-notification">
          <button type="button" className="btn btn-info" onClick={() => toggleModal()}>
            <i className="fa fa-bell-o" />
          </button>
          {tasks > 0 && <span className="badge badge-light">{tasks}</span>}
        </div>
        {showModal && <TaskGrid isOpen={showModal} toggle={toggleModal} />}
      </NavItem>
      <NavItem>
        <Link className="nav-link" to="/logout">
          <i className="fa fa-lock"></i> Logout
        </Link>
      </NavItem>
    </Nav>
  );
}
