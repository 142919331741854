import React from 'react';
import { ReconciliationForm } from '../abstract';

export default class RecontactPanelist extends ReconciliationForm {
  collectFormData() {
    const formData = super.collectFormData();
    formData.append('prodege_project_id', this.props.partnerProjectId);

    return formData;
  }

  sendFormData = () => {
    return this.clientApi(
      'POST', `${this.getApiPath()}/${this.props.routeId}/project/recontact`,
      this.collectFormData(), false
    );
  }

  getFormContent() {
    return this.props.isRecontact ? (
      <div>
        <div className="alert alert-info" role="alert">
          You can upload XLSX file. 
          No header is needed in the first row. 
          The Excel file has to contain two columns, the Panelist ID and the Survey URL.
        </div>
        {super.getFormContent()}
      </div>
    ) : (
      <div>
        <div className="alert alert-warning" role="alert">
          Recontact is available only for project with type recontact.
        </div>
      </div>
    );
  }
}
