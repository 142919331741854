import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AlertMessage } from 'emi-frontend';
import AttributeGroup from './AttributeGroup';

export default class ClickBalancing extends Component {

  static propTypes = {
    project_id: PropTypes.number.isRequired,
    project: PropTypes.object.isRequired
  };

  state = {
    edit: {},
    message: '',
    success: true
  };

  showMessage = (message, success, cb) => {
    this.setState({ message, success }, cb);
  }

  hasMessage() {
    return this.state.message !== null && this.state.message !== undefined && this.state.message !== '';
  }

  onEdit = (id, field) => {
    this.setState({ edit: { id, field } });
  }

  reloadData() {
    ['normalAttributes', 'nestedAttributes'].forEach(prop => {
      if (this[prop]) {
        this[prop].loadData();
      }
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.hasMessage() &&
          <AlertMessage
            color={this.state.success ? 'info' : 'danger'}
            message={this.state.message}
            closeHandler={() => this.showMessage('', true)}
          />
        }
        <AttributeGroup
          {...this.props} showMessage={this.showMessage}
          edit={this.state.edit} onEdit={this.onEdit}
          ref={comp => { this.normalAttributes = comp; }}
        />
        <AttributeGroup
          {...this.props} showMessage={this.showMessage} nested
          edit={this.state.edit} onEdit={this.onEdit}
          ref={comp => { this.nestedAttributes = comp; }}
        />
      </React.Fragment>
    );
  }
}

