import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import PubSub from 'pubsub-js';
import {
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import { client, Grid, Desktop, Tablet, Mobile } from 'emi-frontend';

export default class RedirectGrid extends Grid {

  get gridName() {
    return 'redirectGrid';
  }

  getApiPath = () => {
    return '/redirect';
  }

  ShortDate({ data }) {
    if (!data) {
      return null;
    }
    const [year, month, day] = data.substring(2, 10).split('-');

    return (
      <span>{[month, day, year].join('/')}</span>
    );
  }

  getColumns() {
    return [{
      name: 'partner_name',
      title: 'Partner',
      width: 100
    }, {
      name: 'respondent_id',
      title: 'partner id',
      width: 100
    }, {
      name: 'redirect_id',
      title: 'redirect id',
      width: 100
    }, {
      name: 'client_code',
      title: 'client code',
      width: 100
    }, {
      name: 'started',
      title: 'start time',
      renderer: this.ShortDate,
      width: 100
    }, {
      name: 'completed',
      title: 'end time',
      renderer: this.ShortDate,
      asc: false,
      width: 100
    }, {
      name: 'status',
      title: 'status',
      width: 100
    }];
  }

  isRowSelectable = () => {
    return false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.extraParams) {
      this.setExtraParams(nextProps.extraParams);
    }
  }

  getPagination() {
    return this.props.paging && (
      <div>
        <Desktop>{this.getDesktopPagination()}</Desktop>
        <Tablet>{this.getTabletPagination()}</Tablet>
        <Mobile>{this.getMobilePagination()}</Mobile>

        {this.state.recordCount > 0 && this.getExportButton('float-right btn-sm')}
      </div>
    );
  }

  getExportFileName(params) {
    const { project_id } = params.extraParams || {};
    return `export_${project_id ? `${project_id}_` : ''}${Grid.getTimeStamp()}.xlsx`;
  }

  exportData = (extraParams) => {
    const params =  {
      sort: this.state.sortProperties
    };

    if (extraParams) {
      Object.assign(params, { extraParams });
    } else {
      this.applyExtraParams(params);
    }

    params.extraParams.user_id = this.context.user.id;

    // filter out empty values
    if (params.extraParams) {
      params.extraParams = Object.fromEntries(
        Object.entries(params.extraParams).filter(([key, val]) => !!val)
      );
    }

    client
      .api('GET', `${this.getApiPath()}/export`, params)
      .done(() => {
        this.setState({ message: 'Background job created', success: true });
        PubSub.publish(`background_job_update`, false);
      })
      .fail(console.error);
  }

  getExportHeaders() {
    return {
      id: 'ID',
      project_id: 'Job ID',
      project_group_id: 'Project Group ID',
      io_number: 'IO #',
      project_name: 'Project Name',
      client_code: 'Client code',
      redirect_id: 'Redirect ID',
      fixed_values: 'Fixed variables',
      partner_name: 'Partner',
      respondent_id: 'Partner ID',
      started: 'Start time',
      completed: 'End time',
      loi: 'LOI',
      status: 'Status',
      href: 'Start URL',
      survey_url: 'Survey URL',
      end_href: 'End URL',
      is_mobile: 'isMobile',
      is_tablet: 'isTablet',
      device_check: 'Device check',
      device_type: 'Device Type',
      device: 'Device',
      device_version: 'Device ver.',
      operating_system: 'Operating System',
      operating_system_version: 'Operating System ver.',
      browser: 'Browser',
      browser_version: 'Browser ver.',
      ip_address: 'IP address',
      country: 'Country',
      test: 'isTest',
      captcha: 'Captcha',
      ip: 'IP',
      internal: 'Internal Fingerprint',
      samplechain: 'Research Defender',
      internal_data: 'Internal data',
      samplechain_data: 'Research Defender data',
      samplechain_acitvity: 'Research Defender acitvity',
      samplechain_threat_potential: 'Research Defender threat potential',
      samplechain_failure_reason: 'Research Defender failure reason',
      samplechain_threat_potential_score: 'Research Defender threat potential score',
      manual_removal: 'Manual Removal',
      ghost_complete: 'Ghost Complete',
      prescreen_status: 'Prescreen status',
      clickbalance_status: 'Clickbalance status',
      resuming: 'Resuming',
      rd_review: 'Research Defender REVIEW'
    };
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle}>
          Respondent Data
        </ModalHeader>
        <ModalBody>
          {super.render()}
        </ModalBody>
      </Modal>
    );
  }
}

RedirectGrid.contextTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
