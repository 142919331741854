export const fingerprints = {
  internal: 'Internal',
  samplechain: 'Research Defender'
};

export const fingerprintSettings = ['IP', 'Device ID', 'Both'];

export const deviceList = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktop: 'desktop',
  other: 'other'
};
