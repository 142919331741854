import React from 'react';
import { Nav, TabContent, TabPane } from 'reactstrap';
import { ApiTabs } from '../abstract';
import Project from './Project';
import ProjectQuotas from './ProjectQuotas';
import ProjectStats from './ProjectStats';
import BillingStats from './BillingStats';
import ProjectSettings from './ProjectSettings';
import Reconciliation from './Reconciliation';
import RecontactPanelist from './RecontactPanelist';

export default class Prodege extends ApiTabs {

  hasProject() {
    return this.state.api_data !== null && Object.prototype.hasOwnProperty.call(this.state.api_data.project || {}, 'prodege_project_id');
  }

  showReconciliation() {
    return this.hasProject() && ['LIVE', 'PAUSED'].includes(this.state.api_data.project.status);
  }

  showBillingStats() {
    return this.hasProject() && this.state.api_data.project.status === 'COMPLETE';
  }

  getTabs() {
    const hasProject = this.hasProject();

    return (
      <Nav tabs>
        {this.getTab('Project', 1, 'project')}
        {hasProject && this.getTab('Quotas', 2)}
        {hasProject && this.getTab('Settings', 3)}
        {hasProject && this.getTab('Stats', 4)}
        {this.showReconciliation() && this.getTab('Reconciliation', 5)}
        {hasProject && this.getTab('Recontact', 6)}
        {this.showBillingStats() && this.getTab('Billing Summary', 7, 'billing')}
      </Nav>
    );
  }

  getTabContent() {
    const hasProject = this.hasProject();

    return (
      <TabContent activeTab={this.state.activeTab}>
        <TabPane tabId={1}>
          <Project
            routeId={this.props.routeId}
            partnerId={this.props.partnerId}
            values={this.getProjectValues()}
            onUpdate={this.updateApiData}
            ref={(c) => this.setComponentRef('project', c)}
          />
        </TabPane>
        {hasProject &&
          <TabPane tabId={2}>
            <ProjectQuotas
              routeId={this.props.routeId}
              partnerId={this.props.partnerId}
              countryId={this.state.api_data.project.country_id}
              partnerProjectId={this.state.api_data.project.prodege_project_id}
              quotas={this.getQuotas()}
              onUpdate={this.updateApiData}
            />
          </TabPane>
        }
        {hasProject &&
          <TabPane tabId={3}>
            <ProjectSettings
              routeId={this.props.routeId}
              routeQuota={this.props.routeQuota}
              prodegeProjectId={this.state.api_data.project.prodege_project_id}
            />
          </TabPane>
        }
        {hasProject &&
          <TabPane tabId={4}>
            <ProjectStats
              routeId={this.props.routeId}
              prodegeProjectId={this.state.api_data.project.prodege_project_id}
            />
          </TabPane>
        }
        {this.showReconciliation() &&
          <TabPane tabId={5}>
            <Reconciliation
              routeId={this.props.routeId}
              partnerId={this.props.partnerId}
              partnerProjectId={this.state.api_data.project.extProjectId}
              acceptFileType=".xlsx"
            />
          </TabPane>
        }
        {hasProject &&
          <TabPane tabId={6}>
            <RecontactPanelist
              routeId={this.props.routeId}
              partnerId={this.props.partnerId}
              partnerProjectId={this.state.api_data.project.prodege_project_id}
              isRecontact={this.state.api_data.project.project_type_id === '22'}
              acceptFileType=".xlsx"
              fileName="recontact"
            />
          </TabPane>
        }
        {this.showBillingStats() &&
          <TabPane tabId={7}>
            <BillingStats
              routeId={this.props.routeId}
              partnerId={this.props.partnerId}
              prodegeProjectId={this.state.api_data.project.prodege_project_id}
              ref={(c) => this.setComponentRef('billing', c)}
            />
          </TabPane>
        }
      </TabContent>
    );
  }
}
