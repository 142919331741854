import React from 'react';
import { RemoteSelect } from 'emi-frontend';

class ClientSelect extends RemoteSelect {

  getApiPath() {
    return '/client'
  }

  getLoadParams() {
    return {
      sort: {
        client_name: true
      },
      extraParams: {
        prospect: false
      }
    };
  }

  getFirstOption() {
    return (
      <option key="_" value="">Select Client..</option>
    );
  }

  getSelectedOptionText() {
    return this.getArrayOptionText();
  }

  getOptionText(row) {
    return row.client_name;
  }

  getOptionsFromArray() {
    return this.state.data.map((row) => {
      return { value: row.id, label: row.client_name };
    });
  }
}

export default ClientSelect;
