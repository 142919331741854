import { Button, Grid } from 'emi-frontend';
import ProjectGrid from './ProjectGrid';
import ExportModal from './ExportModal';

export default class TagGrid extends Grid {

  getInitialState(props) {
    return Object.assign(
      super.getInitialState(props),
      {
        exportModal: false,
        projectModal: false,
        tagId: 0,
      }
    );
  }

  toggleProjectModal = (tagId = null) => {
    this.setState({
      projectModal: !this.state.projectModal,
      tagId
    });
  }

  toggleExportModal = (tagId = null) => {
    this.setState({
      exportModal: !this.state.exportModal,
      tagId
    });
  }

  onRowSelection = (rowData) => {
    this.toggleProjectModal(rowData.id);
  }

  get gridName() {
    return 'tagGrid';
  }

  getApiPath = () => {
    return '/tag';
  }

  getBasePath = () => {
    return '/tags';
  }

  ExportButton = (props) => {
    const {className, btnClassName, ...rest} = props;
  
    return (
      <div className={className}>
        <Button
          className={btnClassName} {...rest} 
          clickHandler={this.toggleExportModal} 
        />
      </div>
    );
  }

  getColumns() {
    return [{
      name: 'tag_name',
      title: 'targeting tag',
      width: 300
    }, {
      name: 'project_count',
      title: '# of projects',
      width: 180,
      exactWidth: true
    }, {
      name: 'completed_count',
      title: '# of completes',
      width: 180,
      exactWidth: true
    }, {
     name: 'classification',
     title: 'classification',
     width: 300,
     exactWidth: true
    }, {
      name: 'export',
      dataField: 'id',
      title: '',
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderer: this.ExportButton,
      params: {
        icon: 'download',
        className: 'left-border'
      }
    }];
  }

  getPagination() {
    return (
      <div>
        {super.getPagination()}
        <ProjectGrid
          preload={false}
          tagId={this.state.tagId}
          isOpen={this.state.projectModal}
          toggle={() => this.toggleProjectModal()}
        />
        <ExportModal
          tagId={this.state.tagId}
          isOpen={this.state.exportModal}
          toggle={() => this.toggleExportModal()}
        />
      </div>
    )
  }
}
