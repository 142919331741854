import React from 'react';
import { Filter, NewButton, IconButton } from 'emi-frontend';

export default class PartnerFilter extends Filter {

  constructor(props) {
    super(props);

    this.state.searchTitle = 'Search by Partner Name';
  }

  get filterName() {
    return 'partnerFilter';
  }

  getRightButtons() {
    return (
      <React.Fragment>
        <IconButton
          key="blocklist" icon="fa fa-ban"
          label="Blocklist" onClick={this.props.onToggleBlocklist}
          style={{ marginRight: 5 }}
        />
        <IconButton
          key="export_feedback" icon="fa fa-download"
          label="Export Feedback Log" onClick={this.props.onExportFeedback}
          style={{ marginRight: 5 }}
        />
        <IconButton
          key="feedback" icon="fa fa-plus"
          label="Feedback Log" onClick={this.props.onToggleFeedback}
          style={{ marginRight: 5 }}
        />
        <NewButton key="partner" model="partner" onClick={this.newPartner} />
      </React.Fragment>
    );
  }

  newPartner = () => {
    this.context.history.replace('/partners/0');
  }
}
