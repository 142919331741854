import React from 'react';
import select from 'select';
import eol from 'eol';
import { Modal, ModalHeader, ModalBody, CardFooter } from 'reactstrap';
import { AbstractForm, SaveButton, CopyClipboard } from 'emi-frontend';

const endStatus = {
  complete: 'completed',
  disqualify: 'screenout',
  quotafull: 'quotafull',
  dqfail: 'quality fail',
  duplicate: 'duplicated'
};

export default class EndUrlModal extends AbstractForm {

  constructor(props) {
    super(props);

    this.state.loadingData = false;
  }

  get modelName() {
    return 'project';
  }

  getApiPath = () => {
    return `/project/${this.getId()}/securehash`;
  }

  getId = () => {
    return this.parseInt(this.props.id);
  }

  isNew() {
    return true;
  }

  showDeleteAction() {
    return false;
  }

  shouldLoadData() {
    return false;
  }

  getFormHeader() {}

  sendFormData = () => {
    return this.clientApi('PUT', this.getApiPath(), this.getFormData());
  }

  copyToClipboard = (str) => {
    try {
      const modalBody = document.getElementById('end-url-body');
      const tmpInput = document.createElement('input');
      tmpInput.style = "position: absolute; left: -1000px; top: -1000px";
      tmpInput.value = str;
      modalBody.appendChild(tmpInput);
      select(tmpInput);
      document.execCommand('copy');
      modalBody.removeChild(tmpInput);
    } catch (error) {
      console.error(error);
    }
  }

  CopyUrl = (url) => {
    return (
      <CopyClipboard
        icon="clipboard"
        title="Copy URL"
        target="end-url-body"
        getText={() => url}
      />
    );
  }

  successAction = () => {
    this.props.toggle();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (AbstractForm.valuesDidChange(this.state.values, nextProps.values)) {
      this.updateValues(nextProps.values);
    }
  }

  getFormContent() {
    return (
      <React.Fragment>
        <div className="form-row">
          <div className="col-12">
            <CopyClipboard
              icon="clipboard"
              title="Copy URLs"
              className="btn-default float-right mb-2"
              target="end-url-body"
              btnText="Copy all"
              getText={() => Object
                .keys(endStatus)
                .map(key =>
                  `${endStatus[key]} - ${this.props.endUrl}${key}/?scb=${this.state.values[key]} `
                )
                .join(eol.auto)
              }
            />
          </div>
        </div>
        {Object.keys(endStatus).map(key =>
          <div className="form-row" key={key}>
            {this.getFormRow({
              label: endStatus[key],
              name: key,
              required: true,
              maxLength: 12,
              minLength: 12,
              prefix: `${this.props.endUrl}${key}/?scb=`,
              fieldWidth: { xs: 12, xl: 11 },
              labelWidth: { xs: 12 },
              valueWidth: { xs: 12 }
            })}
            {this.CopyUrl(`${this.props.endUrl}${key}/?scb=${this.state.values[key]}`)}
          </div>
        )}
      </React.Fragment>
    );
  }

  getActionButtons() {
    return (
      <CardFooter className="action-buttons d-lg-block">
        <SaveButton disabled={this.state.sendingData} />
      </CardFooter>
    );
  }
                  
  render() {
    const { isOpen, toggle } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          <span className="text-uppercase">End URL</span>
        </ModalHeader>
        <ModalBody id="end-url-body">
          {super.render()}
        </ModalBody>
      </Modal>
    );
  }
}
