import DefinitionsSelect from './DefinitionsSelect';

export default class QuotaCalculationTypeSelect extends DefinitionsSelect {

  get definitionsPath() {
    return 'quota_calculation_types';
  }

  get valueProperty() {
    return 'quotaCalculationTypeId';
  }
}
