import React from 'react';
import classNames from 'classnames';
import { Modal, ModalHeader, ModalBody, Row, Col, InputGroup, InputGroupAddon, InputGroupText, Input, Button } from 'reactstrap';
import { Grid, Desktop, Tablet, Mobile  } from 'emi-frontend';
import BlocklistForm from './BlocklistForm';
import { formatDate } from '../../lib/utils';

export default class BlocklistModal extends Grid {
  constructor(props) {
    super(props);

    this.state.ud = '';
  }
  get gridName() {
    return 'blocklistGrid';
  }

  getApiPath = () => {
    return '/blocklist';
  }

  deleteBlocklist(id) {
    this
      .clientApi('DELETE', `${this.getApiPath()}/${id}`)
      .done(() => this.reload())
      .fail((error) => this.showError(error, { loadingData: false }));;
  }

  addBlocklist = () => {
    return this
      .clientApi('POST', this.getApiPath(), { id: this.state.ud })
      .done(() => {
        this.reload();
        this.setState({ ud: '' });
      });
  }

  statusButton = ({ className, btnClassName, data, icon }) => {
    return <div className={className}>
      <button
        type="button"
        className={classNames('btn', 'grid-button', btnClassName)}
        onClick={e => { this.deleteBlocklist(data); }}
      >
        <i className={classNames('fa', `fa-${icon}`, 'text-danger')} />
      </button>
    </div> ;
  } 

  getColumns() {
    return [{
      name: 'id',
      title: 'Research Defender UD',
      asc: true,
      width: 250
    }, {
      name: 'blocked',
      title: 'Blocked',
      exactWidth: true,
      width: 110
    }, {
      name: 'created',
      title: 'Created',
      exactWidth: true,
      renderer: ({data}) => formatDate(data),
      width: 120
    }, {
      name: 'del',
      dataField: 'id',
      title: '',
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderer: this.statusButton,
      params: {
        icon: 'trash',
        className: 'left-border'
      }
    }];
  }

  isRowSelectable = () => {
    return false;
  }

  getGridHeaderLetterFilter() {
    return (
      <div className="row">
        <div className="col-auto mr-auto">
          <Row>
            <Col className="my-1">
              <InputGroup size="sm">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>RD UD</InputGroupText>
                </InputGroupAddon>
                <Input type="text" size={50} name="ud" value={this.state.ud} onChange={(e) => this.setState({ud: e.target.value})} />
                <InputGroupAddon addonType="append">
                  <Button color="secondary" type="button" disabled={!this.state.ud} onClick={() => this.addBlocklist()}>
                    Add
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
        </div>
        <div className="col-auto">
          <button
            type="button" className="btn btn-warning"
            onClick={() => this.reload()}
          >
            <i className="fa fa-recycle" aria-hidden="true"></i>{' '}
            Reload
          </button>
        </div>
      </div>
    )
  }

  getPagination() {
    return this.props.paging && (
      <div>
        <Desktop>{this.getDesktopPagination()}</Desktop>
        <Tablet>{this.getTabletPagination()}</Tablet>
        <Mobile>{this.getMobilePagination()}</Mobile>

        {this.state.recordCount > 0 && this.getExportButton('float-right btn-sm')}
      </div>
    );
  }

  getExportFileName() {
    return `export_blocklist_${Grid.getTimeStamp().substring(0, 8)}.xlsx`;
  }

  getExportHeaders() {
    return {
      id: 'Research Defender UD',
      blocked: 'Blocked',
      created: 'Created'
    };
  }

  render() {
    const { isOpen, toggle } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          <span className="text-uppercase">Blocklist</span>
        </ModalHeader>
        <ModalBody id="end-url-body">
          <BlocklistForm
            onSuccess={this.reload}
          />
          {super.render()}
        </ModalBody>
      </Modal>
    );
  }
}
