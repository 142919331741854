import React from 'react';
import classNames from 'classnames';
import { GridMobileBody } from 'emi-frontend';

export default class RouteGridMobileBody extends GridMobileBody {

  getRows(RowClass) {
    const summarize = ['completed', 'quota', 'cost', 'route_total_clicks'];

    const sumRow = {
      partner_name: '',
      completed: 0,
      quota: 0,
      cost: 0,
      route_total_clicks: 0
    };

    const rows = this.props.data.map((row, idx) => {
      summarize.forEach((dataField) => {
        sumRow[dataField] += row[dataField] ? Number(row[dataField]) : 0;
      });

      return (
        <RowClass
          key={idx}
          row={row}
          className={classNames({ loading: this.props.loading })}
          columns={this.props.columns}
          onClick={this.props.onClick}
          rowStyler={this.props.rowStyler}
          extraParams={this.props.extraParams}
          modalFnc={this.props.modalFnc}
        />
      );
    });

    rows.unshift(
      <RowClass
        key={this.props.data.length}
        row={sumRow}
        className={classNames('grid-sum-row', { loading: this.props.loading })}
        columns={this.props.columns}
        summary
        extraParams={this.props.extraParams}
      />
    );

    return rows;
  }
}
