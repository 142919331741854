import React from 'react';
import classNames from 'classnames';
import { Row, RouterContext } from 'emi-frontend';
import HubspotIcon from '../../lib/HubspotIcon';

export default class ClientRow extends Row {

  getContentSelector = (contactId, router) => {
    return () => {
      router.history.push(`/clients/${this.props.row.id}/contact/${contactId}`);
    }
  }

  onHubspot = (event, id) => {
    this.context.components.onHubspot(event, 'contact', id);
  }

  getBaseClasses() {
    return ['d-flex', 'flex-column', 'flex-nowrap', 'grid-row', 'content', 'rounded'];
  }

  getRowClick() {
    return () => {};
  }

  getClientRow(contact, router) {
    const style = { width: 300 };
    return (
      <div key={`contact${contact.id}`} className="d-flex flex-row flex-nowrap grid-row" onClick={this.getContentSelector(contact.id, router)}>
        <div className="grid-cell d-flex align-content-center align-items-center" style={style}>
          <div className="cell">{contact.name}</div>
        </div>
        <div className="grid-cell d-flex align-content-center align-items-center" style={style}>
          <div className="cell">{contact.email || '-'}</div>
        </div>
        <div className="grid-cell d-flex align-content-center align-items-center" style={style}>
          <div className="cell">{contact.contact_type.join(', ')}</div>
        </div>
        <div
          className="grid-cell d-flex align-content-center align-items-center button-cell"
          style={{ width: 46, flexGrow: 0 }} onClick={e => this.onHubspot(e, contact.id)}
        >
          <HubspotIcon color={contact.hubspot_id ? '#ff7a59' : undefined } width={20} />
        </div>
        <div className="grid-cell d-flex align-content-center align-items-center button-cell" style={{ width: 46, flexGrow: 0 }}>
          <i className={`fa fa-plug text-${contact.connector ? 'success' : 'danger'}`} />
        </div>
      </div>
    );
  }

  getClientRows() {
    if (!this.props.row.opened) {
      return '';
    }

    return (
      <RouterContext.Consumer key="1">
        {(router) => (
          <div key="clients" className="d-flex flex-column flex-nowrap grid-client-row">
            {this.props.row.contacts.map((contact) => this.getClientRow(contact, router))}
          </div>
        )}
      </RouterContext.Consumer>
    )
  }

  getContactRow() {
    return (
      <div
        className={classNames('d-flex', 'flex-row', 'flex-nowrap', 'grid-row')}
        onClick={super.getRowClick()}
        key="contact"
      >
        {super.getRowContent()}
      </div>
    );
  }

  getRowContent() {
    return [
      this.getContactRow(),
      this.getClientRows()
    ];
  }
}
