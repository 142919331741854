import React from 'react';
import { Alert } from 'reactstrap';

const fields = {
  costPerInterview: 'Amount will be paid for each delivered complete',
  feasible: 'Can the system deliver the required completes',
  totalCount: 'The total completes that we can deliver for the line item within the requested days in field'
};

function getValue(feasibility, field) {
  switch (field) {
    case 'costPerInterview':
      return (Number(feasibility[field])).toFixed(2);
    case 'feasible':
      return feasibility[field] ? 'YES' : 'NO';
    default:
      return feasibility[field];
  }
}

export default function FeasibilityInfo({ feasibility }) {
  if (feasibility.status === 'FAILED') {
    return (
      <Alert color="warning" className="mt-2">
        Dynata are having trouble computing feasibility. Please try again or reach out to Samplify support
      </Alert>
    );  
  }

  return (
    <table className="table table-hover top-margin-10">
      <thead className="thead-dark">
        <tr className="text-center">
          <th scope="col" colSpan="2">{'Pricing & Feasibility'}</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(fields).map(key => 
          <tr key={key}>
            <th scope="row">{fields[key]}</th>
            <td className="text-center">{getValue(feasibility, key)}</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}