import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { RouterContext } from 'emi-frontend';

export default class FileButton extends Component {
  static contextType = RouterContext;

  goProjectsFiles = () => {
    this.context.history.push('/jobs/files');
  }

  render() {
    return (
      <Button color="default" style={{ marginLeft: 10 }} onClick={this.goProjectsFiles}>
        <i className="fa fa-files-o" aria-hidden="true"></i>{' '}
        Project Files
      </Button>
    );
  }
}
