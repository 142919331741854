import React from 'react';
import { AbstractForm, ButtonSelect } from 'emi-frontend';
import { createFilter } from 'react-select';
import ProjectSelect from '../../lib/form/ProjectSelect';
import { fingerprints } from '../job/constants';

export default class ProjectGroupForm extends AbstractForm {
  
  getDefaultValues() {
    return {
      name: '',
      fingerprint: ['internal', 'samplechain'],
      fingerprint_setting: 2,
      block_completes_only: false,
      policy: 'block',
      projects: [],
      update_job_id: null,
    };
  }

  getApiPath = () => {
    return '/project_group';
  }

  cancelPath = () => {
    return '/groups';
  }

  get modelName() {
    return 'group';
  }

  redirectPath = () => {
    return this.cancelPath();
  }

  valueChanged(name, value, isEdit) {
    if (name === 'projects' && value.length === 0) {
      return super.valueChanged(name, null, isEdit);
    }

    if (name === 'fingerprint') {
      const changed = {
        [name]: [].concat(value)
      };

      if (value.length === 0) {
        Object.assign(changed, { fingerprint: null, fingerprint_setting: null });
      } else if (!this.state[isEdit ? 'edit': 'values'].fingerprint || this.state[isEdit ? 'edit': 'values'].fingerprint.length === 0) {
        Object.assign(changed, { fingerprint_setting: 2 });
      }

      return changed;
    }

    return super.valueChanged(name, value, isEdit);
  }

  isReadonlyForm() {
    // Used to make the whole form read-only
    return this.state.values.update_job_id !== null;
  }

  getFormContent() {
    return (
      <div>
          <div className="form-row">
            {this.getFormRow({
              name: 'name',
              required: true,
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 },
              fieldWidth: { xs: 12, lg: 6 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              name: 'policy',
              component: ButtonSelect,
              required: true,
              options: { monitor: 'Monitor', block: 'Block'},
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 },
              fieldWidth: { xs: 12, lg: 6 }
            })}

            {this.getFormRow({
              name: 'fingerprint',
              component: ButtonSelect,
              options: fingerprints,
              multi: true,
              labelWidth: { xs: 12, lg: 5 },
              valueWidth: { xs: 12, lg: 7 },
              fieldWidth: { xs: 12, xl: 6 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              label: 'validation',
              name: 'fingerprint_setting',
              component: ButtonSelect,
              options: ['IP', 'Device ID', 'Both'],
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 },
              fieldWidth: { xs: 12, xl: 6 }
            })}

            {this.getFormRow({
              name: 'block_completes_only',
              label: 'block completes only',
              required: true,
              component: ButtonSelect,
              options: { true: 'Yes', false: 'No' },
              labelWidth: { xs: 12, lg: 5 },
              valueWidth: { xs: 12, lg: 7 },
              fieldWidth: { xs: 12, xl: 6 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              name: 'projects',
              component: ProjectSelect,
              onChange: this.selectChangeHandler('projects'),
              isMulti: true,
              closeMenuOnSelect: false,
              status: ['test','live','paused','closed','kickoff'],
              filterOption: createFilter({ stringify: option => `${option.label} ${option.value} ${option.data.io_number}` }),
              labelWidth: { xs: 12, lg: 2 },
              valueWidth: { xs: 12, lg: 10 }
            })}
          </div>
      </div>
    );
  }
}
