import { RemoteSelect } from 'emi-frontend';

export default class Precodes extends RemoteSelect {

  getApiPath() {
    return `/panelapi/${this.props.partnerId}/question`;
  }

  loadDone = (response) => {
    if (response.data && Array.isArray(response.data.qualification_answers)) {
      this.setState({
        data: response.data.qualification_answers,
        recordCount: response.data.qualification_answers.length,
      });
    }
  }

  getLoadParams(props) {
    return {
      question_id: props.question_id,
      country_language_id: props.countryId
    };
  }

  componentDidMount() {
    this.loadData(
      this.getLoadParams(this.props)
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.question_id !== this.props.question_id) {
      this.loadData(
        this.getLoadParams(nextProps)
      );
    }
  }


  loadData = (params) => {
    this.clientApi('POST', this.getApiPath(), params)
      .done(this.loadDone)
      .fail(this.showError);
  }

  composeProps() {
    const {
      onValueChange, textOnly, partnerId, question_id, countryId, ...props
    } = this.props;
    const options = this.getOptions();

    return Object.assign(props, { options });
  }

  getOptionsFromArray() {
    return this.state.data.map((row) => {
      return { value: row.pre_code, label: row.en_translation };
    });
  }

  getSelectedOptionText() {
    return this.getArrayOptionText();
  }
}
