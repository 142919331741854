import { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { formatCurrency, calcPercentage } from 'emi-frontend';

export default function OtherCost({ bid_revision_id, otherCosts, loadOtherCosts }) {

  return (
    <Fragment>
      <Row className="justify-content-center my-3">
        <Col lg={8} className="text-center font-weight-bold">
          <h4>Other Costs</h4>
        </Col>
      </Row>
      <Row className="justify-content-center my-3">
        <Col className="text-center">
          <table className="table table-hover quota-table">
            <thead className="thead-dark">
              <tr className='text-center'>
                <th scope="col">Partner</th>
                <th scope="col">Type</th>
                <th scope="col" className='text-right'>Partner Cost</th>
                <th scope="col" className='text-right'>Client Cost</th>
                <th scope="col" className='text-right'>Margin</th>
              </tr>
            </thead>
            {otherCosts.length && (
              <tbody>
                {otherCosts.map((cost) => (
                  <tr key={`other${cost.id}`}>
                    <th scope="row" className="text-nowrap align-middle">{cost.name}</th>
                    <td className='text-center'>{cost.type}</td>
                    <td className='text-right'>{formatCurrency(cost.partner_cost)}</td>
                    <td className='text-right'>{formatCurrency(cost.client_cost)}</td>
                    <td className='text-right'>{calcPercentage(cost.client_cost, cost.client_cost - cost.partner_cost, 2)}%</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </Col>
      </Row>
    </Fragment>
  );
}