import React from 'react';
import PropTypes from 'prop-types';
import { LoadServerData } from '../abstract';

export default class ProjectStats extends LoadServerData {
  constructor(props) {
    super(props, 'stats');
  }

  static propTypes = {
    routeId: PropTypes.number.isRequired,
    prodegeProjectId: PropTypes.number.isRequired
  };

  getApiUrl() {
    return `/panelapi/${this.props.routeId}/project/stats`;
  }

  getApiMethod() {
    return 'POST';
  }

  getApiData() {
    return {
      prodege_project_id: this.props.prodegeProjectId
    };
  }

  getStats(type, field) {
    if (!this.state.stats) {
      return '-';
    }

    const numbers = this.state.stats[type] || {};
    return Object.prototype.hasOwnProperty.call(numbers, field) ? numbers[field] : '-';
  }

  renderContent() {
    const fields = [
      ['Number of entries', 'survey_entries', 'entries'],
      ['Number of clicks', 'clicks', 'clicks'],
      ['Number of completes', 'completes', 'completes'],
      ['Number of terminates', 'fails', 'dqs'],
      ['Number of overquotas', 'overquotas', 'oqs'],
      ['Calculated incidence rate', 'current_ir', 'ir'],
      ['Calculated median length of interview', 'median_loi', 'loi'],
      ['Billable completes', 'billable_completes', ''],
      ['Total cost', 'total_cost', '']
    ];

    return (
      <table className="table top-margin-10">
        <thead className="thead-dark">
          <tr className="text-center">
            <th scope="col"></th>
            <th scope="col">lifetime</th>
            <th scope="col">daily (last 24 hours)</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) => 
            <tr key={index}>
              <th scope="row">{field[0]}</th>
              <td className="text-center">{this.getStats('lifetime_stats', field[1])}</td>
              <td className="text-center">{this.getStats('last_24hr_stats', field[2])}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}
