import React from 'react';
import ContactSelect from '../../lib/form/ContactSelect';

export default class AccountingContactSelect extends ContactSelect {
  onEmail(email, event) {
    event.preventDefault();
    event.stopPropagation();
    window.location.href = `mailto: ${email}`;
  }

  getOptionText(row) {
    return <div>
      {row.first_name} {row.last_name}
      <div className="float-right"><a className="accounting-email" href="1" onClick={this.onEmail.bind(this, row.email)}>{row.email}</a></div>
    </div>;
  }
}