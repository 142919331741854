import React from 'react';
import { 
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import {
  formatCurrency, composeStartUrl, hasOwnProperty,
  AbstractForm, FormDisabledRow, ButtonSelect, CountrySelect
} from 'emi-frontend';
import RouteVariables from './RouteVariables';
import RouteFixedVariables from './RouteFixedVariables';
import select from 'select';
import PartnerSelect from '../../lib/form/PartnerSelect';

const urlFields = {
  completion_url: 'Completion URL',
  disqualify_url: 'Disqualify URL',
  quotafull_url: 'Quotafull URL',
  speeder_url: 'Quality Fail',
  duplicate_url: 'Duplicate URL'
};

export default class RouteForm extends AbstractForm {

  getApiPath = () => {
    return '/route';
  }

  get modelName() {
    return 'partner';
  }

  getId = () => {
    return this.parseInt(this.props.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const id = this.parseInt(nextProps.id);
    if (id && id !== this.getId()) {
      this.loadFormData(id);
    } else if (nextProps.values.project_id !== this.props.values.project_id || !id) {
      this.resetForm(nextProps);
    }
  }

  getDefaultValues(props) {
    return Object.assign({
      project_id: 0
    }, this.getDefaultPartnerValues(), props.values);
  }

  getDefaultPartnerValues() {
    return {
      partner_id: '',
      quota: '',
      cpi: '',
      use_panel_api: null,
      completion_url: '',
      disqualify_url: '',
      quotafull_url: '',
      speeder_url: '',
      duplicate_url: '',
      base_costs: 0,
      variables: ['PARTNER_ID'],
      fixed_variables: {},
      finish_params: null,
      country: '',
      notes: '',
      live_test: true
    };
  }

  onPartnerChange = (partner) => {
    let values;
    if (!partner) {
      values = this.getDefaultPartnerValues();
    } else {
      values = {
        partner_id: partner.id,
        use_panel_api: partner.panel_api ? true : null,
        completion_url: partner.completion_url,
        disqualify_url: partner.disqualify_url,
        quotafull_url: partner.quotafull_url,
        speeder_url: partner.speeder_url,
        duplicate_url: partner.duplicate_url,
        variables: partner.api_variables || ['PARTNER_ID'],
        finish_params: partner.finish_params,
        notes: ''
      };
    }

    this.updateValues(values);
  }

  onVariablesChange = (variables, fixed_variables) => {
    const isEdit = this.editingInProgress();
    const values = this.valueChanged('variables', variables, isEdit);
    if (fixed_variables !== undefined) {
      Object.assign(values, this.valueChanged('fixed_variables', fixed_variables, isEdit));
    }

    this.updateValues(values, isEdit ? 'edit' : 'values');
  }

  deleteDone = (response) => {
    this.props.onDelete();
  }

  successAction = (response) => {
    if (this.isNew()) {
      this.props.onSuccess();
    } else {
      this.loadDone(response, { edit: {} }, this.props.reload);
    }
  }

  partnerStartUrl() {
    return composeStartUrl(
      this.state.values.survey_url, this.state.values.variables, this.state.values.fixed_variables
    );
  }

  getFormHeader() {
    return null;
  }

  copyClipboard = () => {
    try {
      select(this.surveyUrlText);
      document.execCommand('copy');
    }
    catch (err) {
      console.error(err);
    }
  }

  getDummySurveyUrl() {
    return (
      <textarea
        style={{
          fontSize: 12,
          border: 0,
          padding: 0,
          margin: 0,
          position: 'absolute',
          left: -9999,
          top: window.pageYOffset || document.documentElement.scrollTop
        }}
        value={this.partnerStartUrl()}
        ref={(comp) => { this.surveyUrlText = comp; }}
      />
    );
  }

  showUrls() {
    return this.state.values.use_panel_api === null || this.state.values.use_panel_api === false;
  }

  static getFinishParamLabel(prop) {
    return prop.split(/(?=[A-Z])/).join(' ');
  }

  isEditHasFinishParams() {
    return hasOwnProperty(this.state.edit, 'finish_params');
  }

  hasFinishParam(field) {
    return this.isEditHasFinishParams() &&
      hasOwnProperty(this.state.edit.finish_params, field);
  }

  isEdit(field) {
    return this.isUpdate() && (hasOwnProperty(this.state.edit, field) || this.hasFinishParam(field));
  }

  editFinishParams = () => {
    const { finish_params } = this.state.values;
    this.setState({ edit: { finish_params }});
  }

  finishParamChange = (event) => {
    const { name, value } = event.target;
    const { finish_params } = this.getFormData();
    const params = Object.assign({}, finish_params, { [name]: value });

    this.updateObjectValues('finish_params', params);
  }

  finishParamValue(prop) {
    const { finish_params } = this.isEditHasFinishParams() ? this.state.edit : this.state.values;

    return finish_params[prop];
  }

  hasFinishParams() {
    return this.state.values.finish_params !== null && this.state.values.finish_params !== undefined;
  }

  getProjectsField() {
    return null;
  }

  getFormContent() {
    return (
      <div>
        {this.isNew() && 
          <div className="form-row">
            {this.getFormRow({
              label: 'partner',
              name: 'partner_id',
              component: PartnerSelect,
              required: true,
              autoFocus: true,
              onValueChange: this.onPartnerChange,
              fieldWidth: { xs: 12 },
              labelWidth: { xs: 12, lg: 3 },
              valueWidth: { xs: 12, lg: 9 }
            })}
          </div>
        }

        {this.getProjectsField()}

        <div className="form-row">
          {this.getFormRow({
            name: 'quota',
            type: 'number',
            step: 1,
            min: 0,
            required: true,
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 6 },
            valueWidth: { xs: 12, lg: 6 }
          })}

          {this.getFormRow({
            label: 'cpi',
            name: 'cpi',
            type: 'number',
            step: 0.01,
            min: 0,
            formatter: formatCurrency,
            prefix: '$',
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'base costs',
            name: 'base_costs',
            type: 'number',
            step: 0.01,
            min: 0,
            formatter: formatCurrency,
            prefix: '$',
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 6 },
            valueWidth: { xs: 12, lg: 6 }
          })}

          {this.state.values.use_panel_api === true &&
            this.getFormRow({
              label: 'country',
              name: 'country',
              component: CountrySelect,
              onChange: this.selectChangeHandler('country'),
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 },
            })
          }

          {!(this.state.values.use_panel_api === true) &&
            this.getFormRow({
              name: 'live_test',
              label: 'Test data',
              required: true,
              component: ButtonSelect,
              options: { true: 'Yes', false: 'No' },
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })
          }
        </div>

        {this.state.values.use_panel_api === true &&
          <div className="form-row">
            <FormDisabledRow
              label="use panel api"
              name="use_panel_api"
              value={this.state.values.use_panel_api}
              component={ButtonSelect}
              options={{ true: 'Yes', false: 'No' }}
              fieldWidth={{ xs: 12, lg: 6 }}
              labelWidth={{ xs: 12, lg: 6 }}
              valueWidth={{ xs: 12, lg: 6 }}
            />
            {this.getFormRow({
              name: 'live_test',
              label: 'Test data',
              required: true,
              component: ButtonSelect,
              options: { true: 'Yes', false: 'No' },
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>
        }

        <div className="form-row">
          {this.getFormRow({
            label: 'notes',
            name: 'notes',
            required: false,
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 9 }
          })}
        </div>

        {this.isUpdate() && 
          <div className="form-row">
            <FormDisabledRow
              label="start url"
              name="survey_url"
              id="survey_url"
              value={this.partnerStartUrl()}
              fieldWidth={{ xs: 11 }}
              labelWidth={{ xs: 12, lg: 3 }}
              valueWidth={{ xs: 12, lg: 9 }}
            />

            <div className="col-1">
              <div className="row form-group">
                <div className="col">
                  <button id="#copy" className="btn" type="button" onClick={this.copyClipboard}>
                    <i className="fa fa-clipboard"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        }

        <div className="form-row">
          {this.getFormRow({
            name: 'variables',
            component: RouteVariables,
            onValueChange: this.onVariablesChange,
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'fixed variables',
            name: 'fixed_variables',
            component: RouteFixedVariables,
            onValueChange: this.onVariablesChange,
            variables: this.state.values.variables,
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 9 }
          })}
        </div>

        {this.hasFinishParams() &&
          <div>
            {Object.keys(this.state.values.finish_params).map(prop =>
              <div className="form-row" key={prop}>
                {this.getFormRow({
                  name: prop,
                  label: RouteForm.getFinishParamLabel(prop),
                  value: this.finishParamValue(prop),
                  display: this.finishParamValue(prop),
                  onEditField: this.editFinishParams,
                  onChange: this.finishParamChange,
                  required: true,
                  fieldWidth: { xs: 12 },
                  labelWidth: { xs: 12, sm: 3 },
                  valueWidth: { xs: 12, sm: 8 }
                })}
              </div>
            )}
          </div>
        }

        {this.showUrls() &&
          Object.keys(urlFields).map((key) => {
            return (
              <div className="form-row" key={key}>
                {this.getFormRow({
                  label: urlFields[key],
                  name: key,
                  required: true,
                  fieldWidth: { xs: 12 },
                  labelWidth: { xs: 12, lg: 3 },
                  valueWidth: { xs: 12, lg: 9 }
                })}
              </div>
            );
          })
        }
        {this.getDummySurveyUrl()}
      </div>
    );
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle}>
          {this.isNew() ? (
            <span className="text-uppercase">New Partner</span>
          ) : (
            <PartnerSelect value={this.state.values.partner_id} textOnly />
          )}
          {!!this.props.projectName &&
            <span>{' '}-{' '}{this.props.projectName}</span>
          }
        </ModalHeader>
        <ModalBody>
          {super.render()}
        </ModalBody>
      </Modal>
    );
  }
}
