import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import ClipboardContext from './ClipboardContext';

export default function ClipboardButton({
  title, dataType, writeClipboardFn, readClipboardFn, isCopy = true, ...props
}) {
  const { copyToClipboard, pasteFromClipboard } = useContext(ClipboardContext);
  const data = pasteFromClipboard(dataType);

  function onButtonClick() {
    if (isCopy) {
      copyToClipboard(dataType, writeClipboardFn());
    } else {
      readClipboardFn(data);
    }
  }

  return (
    <Button
      type="button" outline color="secondary" size="sm"
      {...props}
      onClick={onButtonClick} disabled={!isCopy && !data}
    >
      <i className="fa fa-clipboard" aria-hidden="true" />
      {' '}{title !== undefined && title}
    </Button>
  );
}
