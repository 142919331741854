import React from 'react';
import { Grid, Desktop, Tablet, Mobile } from 'emi-frontend';

const messageClass = {
  info: 'table-secondary',
  error: 'table-danger',
  success: 'table-success',
  warning: 'table-warning',
  none: 'table-secondary'
};

const messageIcon = {
  info: 'info-circle',
  error: 'minus-circle',
  success: 'check-square-o',
  warning: 'exclamation-triangle',
  none: 'ban'
};

const transactionTypes = {
  customerQueryByName: 'Get Customer Job by Name from QuickBooks',
  customerQueryById: 'Get Customer Job by ID from QuickBooks',
  createCustomerXml: 'Create new Customer Job in QuickBooks',
  deleteBillsXml: 'Deleting old bill(s) from QuickBooks',
  createBillsXml: 'Creating bill(s) in QuickBooks',
  deleteInvoiceXml: 'Deleting old invoice from QuickBooks',
  createInvoiceXml: 'Creating invoice in QuickBooks',
  billQueryXml: 'Query existing bills to check payment status',
  invoiceQueryXml: 'Query existing invoice to check payment status'
};

export default class QuickbooksGrid extends Grid {

  getInitialState(props) {
    return Object.assign(
      super.getInitialState(props),
      { title: 'QuickBooks Syncronizations' }
    );
  }

  get gridName() {
    return 'quickbooksGrid';
  }

  getApiPath = () => {
    return '/quickbooks_session';
  }

  getBasePath = () => {
    return '/invoices';
  }

  getRowStyler() {
    return function (row) {
      // Mark finalized invoices
      if (row.finalized) {
        return 'finalized-invoice';
      }
    }
  }

  boolFormatter = ({data}) => {
    return data
      ? <div className="cell text-success">Created</div>
      : <div className="text-danger">Not created</div>
  }

  getTransactionParam(transaction) {
    switch (transaction.type) {
      case 'customerQueryByName':
      case 'createCustomerXml':
        if (transaction.params && transaction.params.name) {
          return (<span className="qb_transaction_param">&nbsp;({transaction.params.name})</span>);
        }
        return '';
      case 'customerQueryById':
      case 'deleteInvoiceXml':
        if (transaction.params && transaction.params.txnId) {
          return (<span className="qb_transaction_param">&nbsp;(ListID&nbsp;{transaction.params.txnId})</span>);
        }
        break;
      case 'deleteBillsXml':
        if (transaction.params && transaction.params.txnId && Array.isArray(transaction.params.txnId) && transaction.params.txnId.length) {
          return (<span className="qb_transaction_param">&nbsp;(TxnID&nbsp;{transaction.params.txnId.join(', ')})</span>);
        }
        break;
      default:
        return '';
    }
  }

  getTransactionIcon(transaction) {
    let color = '';
    let icon = 'none';
    if (transaction.result) {
      icon = messageIcon[transaction.result.type];
      switch (transaction.result.type) {
        case 'warning':
          color = 'text-warning';
          break;
        case 'error':
          color = 'text-danger';
          break;
        case 'success':
          color = 'text-success';
          break;
        case 'info':
          color = 'text-secondary';
          break;
        default:
          color = '';
      }
    }
    return (<i className={`fa ${color} fa-${icon}`}/>);
  }

  getTransactionResult(rowId, transaction) {
    if (transaction.result) {
      let result = transaction.result.message;
      let list = '';
      if (
        (transaction.type === 'deleteBillsXml' || transaction.type === 'createBillsXml' || transaction.type === 'billQueryXml') &&
        transaction.result.id && Array.isArray(transaction.result.id)
      ) {
        list = (
          <ul>
            {transaction.result.id.map((msg, id) => (
              <li key={`${rowId}_${id}`} className={msg[0] === 'Bill paid' ? 'text-danger': ''}>
                {msg[0]}{msg[1] ? <span className="qb_transaction_param">&nbsp;(TxnID&nbsp;{msg[1]})</span> : ''} 
              </li>
            ))}
          </ul>
        );
      } else if (
        (transaction.type === 'deleteBillsXml' || transaction.type === 'createInvoiceXml') &&
        transaction.result.id
      ) {
        list = <span className="qb_transaction_param">&nbsp;(TxnID&nbsp;{transaction.result.id})</span>;
      } else if (transaction.type === 'customerQueryById' && result.includes('<=>')) {
        const parts = result.split('<=>');
        result = (<span>
          {parts[0]}&nbsp;
          <i className="fa fa-lg fa-arrows-h text-danger" />&nbsp;
          {parts[1]}
          </span>
        );
      } else if (transaction.type === 'createCustomerXml' && transaction.result.id) {
        list = <span className="qb_transaction_param">&nbsp;(TxnID&nbsp;{transaction.result.id.txnId})</span>;
      }
      return <span>{result}{list}</span>;
    }
    return <span><i className="fa text-secondary fa-info-circle"/>&nbsp;Not executed</span>;
  }

  qbMessageRenderer = ({data, row}) => {
    if (data && Array.isArray(data)) {
      const rows = [];
      data.forEach((transaction, id) => {
        rows.push((
          <tr style={{whiteSpace: 'pre-wrap'}} key={`qblog_${row.id}_${id}_1`}>
            <td rowSpan="2" width="40" className="text-center align-middle table-dark">#{id + 1}</td>
            <td className="table-primary">
              <i className="fa fa-sign-in text-primary"></i>&nbsp;{transactionTypes[transaction.type]}{this.getTransactionParam(transaction)}
            </td>
          </tr>), (
          <tr style={{whiteSpace: 'pre-wrap'}} key={`qblog_${row.id}_${id}_2`}>
            <td className={messageClass[transaction.result ? transaction.result.type : 'none']}>
              {this.getTransactionIcon(transaction)}&nbsp;{this.getTransactionResult(row.id, transaction)}
            </td>
          </tr>)
        );
      });

      return (<table className="table table-bordered table-sm">
        <tbody>
          {rows}
        </tbody>
      </table>);
    }
    return null;
  }

  isRowSelectable = () => {
    return false;
  }

  getColumns() {
    const columns = [{
      name: 'created',
      title: 'Created',
      width: 180,
      exactWidth: true,
      renderer: this.dateFormatter,
      asc: false
    }, {
      name: 'finished',
      title: 'Sync\'ed',
      width: 180,
      exactWidth: true,
      renderer: this.dateFormatter
    }, {
      name: 'io_number',
      title: 'IO#',
      width: 100,
      exactWidth: true
    }, {
      name: 'job_created',
      title: 'Customer job',
      width: 150,
      exactWidth: true,
      renderer: this.boolFormatter
    }, {
      name: 'bills_created',
      title: 'Bills',
      width: 120,
      exactWidth: true,
      renderer: this.boolFormatter
    }, {
      name: 'invoice_created',
      title: 'Invoice',
      width: 120,
      exactWidth: true,
      renderer: this.boolFormatter
    }, {
      name: 'qb_xml',
      title: 'message',
      width: 400,
      renderer: this.qbMessageRenderer
    }];

    // Called from Invoice form, remove IO#
    if (this.props.extraParams && this.props.extraParams.invoice_id) {
      columns.splice(2, 1);
    }

    return columns;
  }

  getPagination() {
    return this.props.paging && (
      <div>
        <Desktop>{this.getDesktopPagination()}</Desktop>
        <Tablet>{this.getTabletPagination()}</Tablet>
        <Mobile>{this.getMobilePagination()}</Mobile>

        {this.state.recordCount > 0 && this.getExportButton('float-right btn-sm')}
      </div>
    );
  }

  getExportHeaders() {
    return {
      created: 'Created',
      finished: 'Sync\'ed',
      io_number: 'IO#',
      job_created: 'Customer job',
      bills_created: 'Partner costs (Bills)',
      invoice_created: 'Invoice',
      error_log: 'Error',
      id: 'Session'
    };
  }
}
