export const coverageTypes = {
  setup: {
    label: 'Set up',
    values: {
      testing: 'Testing',
      update_redirects: 'Update Redirects',
      partner_links: 'Partner Links',
      send_ids: 'Send IDs to Client',
      lanuch: 'Launch accordingly'
    }
  },
  monitor: {
    label: 'Monitor',
    values: {
      keep_eye: 'Keep an eye out for client email and have partners adjust accordingly'
    }
  },
  updates: {
    label: 'Updates',
    values: {
      report: 'Reporting link',
      completes: 'Needs X completes',
      update: 'Request update from client'
    }
  },
  close: {
    label: 'Close',
    values: {
      ids: 'Received final IDs',
      reconcile: 'Reconcile IDs',
      cost: 'Enter costs in SWIFT',
      send: 'Send IDs and costs'
    }
  },
  additional: {
    label: 'Additional detail',
    values: {
      click: 'Click Balancing',
      multi: 'Multilinks',
      blend: 'Blend targets',
      program: 'Programming',
      new: 'New client'
    }
  }
};