import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Desktop } from 'emi-frontend';

export default class ProjectButton extends Component {

  static propTypes = {
    type: PropTypes.oneOf(['start', 'stop', 'pause']).isRequired,
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string
  };

  getColor() {
    return this.props.type === 'stop' ? 'danger' : 'default';
  }

  getIcon() {
    return this.props.type === 'start' ? 'fa fa-play' : `fa fa-${this.props.type}`;
  }

  render() {
    const { type, className, ...props } = this.props;

    return (
      <Button 
        type="button" className={classNames('project-button', className)}
        color={this.getColor()}
        {...props}
      >
        <i className={this.getIcon()} aria-hidden="true"></i>
        <span className="text-capitalize">{this.props.text || type}</span>&nbsp;
        <Desktop>Project</Desktop>
      </Button>
    );
  }

}
