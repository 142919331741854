import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import { Filter, Grid } from 'emi-frontend';

const statusMap = {
  active: ['live','paused', 'kickoff','test'],
  closed: ['closed','archived']
};

export default class ProjectFilter extends Filter {
  constructor(props) {
    super(props);

    this.state.searchTitle = 'Search by Job Name, Project Manager, etc.';
    this.state.status = ProjectFilter.clientsState();
  }

  static clientsState() {
    const gridState = Grid.getGridState('projectGrid', { storeState: true });
    return gridState.extraParams && gridState.extraParams.status ? gridState.extraParams.status : statusMap.active;
  }

  get filterName() {
    return 'projectFilter';
  }

  initialize() {
    this.setFilter('active');
  }

  getLeftButtons() {
    return (
      <ButtonGroup className="btn-block">
        <Button
          color="default" className={this.getButtonClass('active')}
          onClick={() => this.setFilter('active')}
        >
          Active
        </Button>
        <Button
          color="default" className={this.getButtonClass('closed')}
          onClick={() => this.setFilter('closed')}
        >
          Closed
        </Button>
      </ButtonGroup>
    );
  }

  getLeftClass() {
    return 'col-12 col-sm-6 col-md-5 col-lg-4';
  }

  setFilter(type) {
    const filter = { status: statusMap[type] };
    this.setState(filter);
    this.props.onFilter(filter);
  }

  getButtonClass(type) {
    return (this.state.status || []).filter(v => statusMap[type].includes(v)).length > 0 ? 'selected' : '';
  }
}
