import React from 'react';
import { Filter, NewButton, Grid } from 'emi-frontend';
import { ButtonGroup, Button } from 'reactstrap';

export default class UserFilter extends Filter {

  constructor(props) {
    super(props);

    this.state.searchTitle = 'Search by User Name';
    Object.assign(this.state, UserFilter.userState());
  }

  static userState() {
    const gridState = Grid.getGridState('userGrid', { storeState: true });
    const { active = true, department = null } = gridState.extraParams || {};
    return { active, department };
  }

  get filterName() {
    return 'userFilter';
  }

  getRightButtons() {
    return (
      <NewButton model="user" onClick={this.newUser} />
    );
  }

  newUser = () => {
    this.context.history.replace('/users/0');
  }

  getLeft() {
    return (
      <React.Fragment>
        <div className="col-12 col-md-5 col-lg-3 mb-1">
          <ButtonGroup className="btn-block">
            <Button color="default" className={this.getButtonClass('active', true)} onClick={() => this.setFilter('active', true)}>Active</Button>{' '}
            <Button color="default" className={this.getButtonClass('active', false)} onClick={() => this.setFilter('active', false)}>Disabled</Button>{' '}
          </ButtonGroup>
        </div>
        <div className="col-12 col-md-5 col-lg-3 mb-1">
          <ButtonGroup className="btn-block">
            <Button
              color="default" className={this.getButtonClass('department', 'rm')}
              onClick={() => this.setFilter('department', 'rm')}
            >
              RM
            </Button>{' '}
            <Button
              color="default" className={this.getButtonClass('department', 'sales')}
              onClick={() => this.setFilter('department', 'sales')}
            >
              Sales
            </Button>
            <Button
              color="default" className={this.getButtonClass('department', 'operations')}
              onClick={() => this.setFilter('department', 'operations')}
            >
              Operations
            </Button>{' '}
            <Button
              color="default" className={this.getButtonClass('department', 'admin')}
              onClick={() => this.setFilter('department', 'admin')}
            >
              Admin
            </Button>{' '}
          </ButtonGroup>
        </div>
      </React.Fragment>
    );
  }
  
  setFilter(prop, stateValue) {
    const val = this.state[prop] === stateValue ? undefined : stateValue;
    this.setState({
      [prop]: val
    });
    this.props.onFilter({
      [prop]: val
    });
  }

  getButtonClass(prop, button) {
    return this.state[prop] === button ? 'selected' : '';
  }
}
