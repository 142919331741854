import JobLiveGrid from '../job/LiveGrid';

export default class LiveGrid extends JobLiveGrid {
  get gridName() {
    return 'esearchliveGrid';
  }

  getApiPath = () => {
    return '/esearch';
  }
}