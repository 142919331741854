import React from 'react';
import { 
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import BaseAttributeForm from '../attribute/Form';
import NewValue from '../attribute/NewValue';
import { ButtonSelect } from 'emi-frontend';
import AttributeValueGrid from './AttributeValueGrid';

export default class AttributeForm extends BaseAttributeForm {

  getApiPath = () => {
    return '/project_attribute';
  }

  get modelName() {
    return 'project_attribute';
  }

  getId = () => {
    return this.parseInt(this.props.id);
  }

  valueChanged(name, value, isEdit) {
    const changed = super.valueChanged(name, value, isEdit);
    if (isEdit) {
      changed.project_id = this.state.values.project_id;
    }

    return changed;
  }

  validateForm() {
    return true;
  }

  reloadValueGrid = () => {
    if (this.valueGrid) {
      this.valueGrid.reload();
    }
  }

  addAttributeValue(attribute_value) {
    const project_attribute_id = this.getId();

    this.clientApi('POST', '/project_attribute_value', { attribute_value, project_attribute_id })
      .done(this.reloadValueGrid)
      .fail(error => this.showError(error));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const id = this.parseInt(nextProps.id);
    if (id && id !== this.getId()) {
      this.loadFormData(id);
    }
  }

  deleteDone = (response) => {
    this.props.onDelete();
  }

  successAction = (response) => {
    this.loadDone(response, { edit: {} }, this.props.reload);
  }

  getFormHeader() {
    return null;
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            name: 'name',
            required: true,
            fieldWidth: { xs: 12},
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'title',
            label: 'Question',
            required: true,
            fieldWidth: { xs: 12},
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'display type',
            name: 'display_type',
            required: true,
            component: ButtonSelect,
            options: {
              r: 'Radio Button',
              d: 'Drop-down List',
              c: 'Checkbox'
            },
            fieldWidth: { xs: 12},
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'random_order',
            label: 'Random order',
            component: ButtonSelect,
            options: { true: 'Yes', false: 'No' },
            fieldWidth: { xs: 12},
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 9 }
          })}
        </div>

        {
        <div className="form-row">
          {this.getFormRow({
            name: 'nested',
            label: 'Nested',
            component: ButtonSelect,
            options: { true: 'Yes', false: 'No' },
            fieldWidth: { xs: 12},
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 9 }
          })}
        </div>
        }

        <NewValue
          onAddValue={this.addValue}
          rowColClass="col-12"
          labelColClass="col-12 col-lg-3"
          inputColClass="col-12 col-lg-7"
          btnColClass="col-12 col-lg-2 mt-1 mt-lg-0"
        />

        <AttributeValueGrid
          ref={(comp) => { this.valueGrid = comp; }}
          extraParams={{ project_attribute_id: this.getId() }}
          paging={false}
        />

      </div>
    );
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle}>Attribute Editor</ModalHeader>
        <ModalBody>
          {super.render()}
        </ModalBody>
      </Modal>
    );
  }
}
