import React from 'react';
import { ButtonSelect } from 'emi-frontend';
import { ProjectForm } from '../abstract';
import IndustrySelect from './IndustrySelect';
import StudyTypeSelect from './StudyTypeSelect';
import SampleTypeSelect from './SampleTypeSelect';
import SurveyStatusSelect from './SurveyStatusSelect';
import CountryLanguageSelect from './CountryLanguageSelect';
import QuotaCalculationTypeSelect from './QuotaCalculationTypeSelect';
import AllowedDevicesButtons from './AllowedDevicesButtons';

export default class Survey extends ProjectForm {

  getDefaultValues() {
    return {
      surveyName: '',
      projectId: 0,
      clientCPI: 0,
      quota: 1,
      loi: 0,
      ir: 0,
      collectsPII: false,
      isDesktopAllowed: true,
      isMobileAllowed: true,
      isTabletAllowed: true,
      surveyStatusId: '',
      startDateTime: '',
      endDateTime: '',
      quotaCalculationTypeID: '',
      sampleTypeID: '',
      countryLanguageID: '',
      industryID: '',
      studyTypeID: ''
    };
  }

  getId = () => {
    return this.parseInt(this.state.values.surveyId);
  }

  getAllowedDevices() {
   const { isDesktopAllowed, isMobileAllowed, isTabletAllowed } = this.state.values;

   return { isDesktopAllowed, isMobileAllowed, isTabletAllowed };
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            label: 'name',
            name: 'surveyName',
            required: true,
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, sm: 2 },
            valueWidth: { xs: 12, sm: 10 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'quota',
            type: 'number',
            required: true,
            step: 1,
            min: 1,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'quota type',
            name: 'quotaCalculationTypeID',
            component: QuotaCalculationTypeSelect,
            partnerId: this.props.partnerId,
            required: true,
            isClearable: false,
            onChange: this.selectChangeHandler('quotaCalculationTypeID'),
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 5 },
            valueWidth: { xs: 12, sm: 7 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'CPI',
            name: 'clientCPI',
            type: 'number',
            step: 0.01,
            min: 0,
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'sample type',
            name: 'sampleTypeID',
            component: SampleTypeSelect,
            partnerId: this.props.partnerId,
            required: true,
            isClearable: false,
            onChange: this.selectChangeHandler('sampleTypeID'),
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 5 },
            valueWidth: { xs: 12, sm: 7 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'loi',
            type: 'number',
            required: true,
            step: 1,
            min: 0,
            max: 40,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'study type',
            name: 'studyTypeID',
            component: StudyTypeSelect,
            partnerId: this.props.partnerId,
            required: true,
            isClearable: false,
            onChange: this.selectChangeHandler('studyTypeID'),
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 5 },
            valueWidth: { xs: 12, sm: 7 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'ir',
            type: 'number',
            step: 1,
            min: 1,
            max: 100,
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'collects PII',
            name: 'collectsPII',
            component: ButtonSelect,
            required: true,
            options: { true: 'Yes', false: 'No' },
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 5 },
            valueWidth: { xs: 12, sm: 7 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'start time',
            name: 'startDateTime',
            type: 'datetime-local',
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'end time',
            name: 'endDateTime',
            type: 'datetime-local',
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'devices',
            name: 'allowed_devices',
            value: this.getAllowedDevices(),
            component: AllowedDevicesButtons,
            required: true,
            onChange: changed => this.updateValues(changed),
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, sm: 2 },
            valueWidth: { xs: 12, sm: 6 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'industry',
            name: 'industryID',
            component: IndustrySelect,
            partnerId: this.props.partnerId,
            required: true,
            isClearable: false,
            onChange: this.selectChangeHandler('industryID'),
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, sm: 2 },
            valueWidth: { xs: 12, sm: 6 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'language',
            name: 'countryLanguageID',
            component: CountryLanguageSelect,
            partnerId: this.props.partnerId,
            required: true,
            isClearable: false,
            isDisabled: this.isUpdate(),
            onChange: this.selectChangeHandler('countryLanguageID'),
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, sm: 2 },
            valueWidth: { xs: 12, sm: 5 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'status',
            name: 'surveyStatusId',
            component: SurveyStatusSelect,
            partnerId: this.props.partnerId,
            required: true,
            isClearable: false,
            onChange: this.selectChangeHandler('surveyStatusId'),
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, sm: 2 },
            valueWidth: { xs: 12, sm: 4 }
          })}
        </div>

      </div>
    );
  }
}
