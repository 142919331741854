import React from 'react';
import { 
  Card, CardBody, CardTitle, CardSubtitle, CardFooter,
  Button
} from 'reactstrap';

const resources = [
  'costPerInterview', 'estimatedCost', 'lengthOfInterview', 'status', 'incidenceRate'
];

function EventButton(props) {
  const { event, prop, color, action } = props;
  if (!Object.prototype.hasOwnProperty.call(event.actions, prop)) {
    return null;
  }

  return (
    <Button
      color={color} className="text-capitalize"
      onClick={() => props.updateEvent(event.eventId, action)}>
      {action}
    </Button>
  );
}

function showEventButtons(props, event) {
  return props.showButtons && Object.keys(event.actions || {}).length > 0;
}

export default function Event(props) {
  const { event } = props;

  return (
    <Card className="top-margin-10">
      <CardBody>
        <CardTitle>{event.eventType} (reason: {event.resource.reason})</CardTitle>
        <CardSubtitle>{event.createdAt}</CardSubtitle>
      </CardBody>
      <CardBody>
        <table className="table table-sm table-hover">
          <thead>
            <tr>
              <th scope="col">Resource</th>
              <th scope="col">Previous Value</th>
              <th scope="col">New Value</th>
            </tr>
          </thead>
          <tbody>
            {resources.filter(resource => event.resource[resource] !== undefined).map(resource =>
              <tr key={resource}>
                <td>{resource}</td>
                <td>{event.resource[resource].previousValue}</td>
                <td>{event.resource[resource].newValue}</td>
              </tr>
            )}
          </tbody>
        </table>
      </CardBody>
      {showEventButtons(props, event) &&
        <CardFooter className="text-center">
          <EventButton
            event={event} prop="acceptURL"
            color="primary" action="accept"
            updateEvent={props.updateEvent}
          />
          <EventButton
            event={event} prop="rejectURL"
            color="danger" action="reject"
            updateEvent={props.updateEvent}
          />
        </CardFooter>
      }
    </Card>
  );
}

Event.defaultProps = {
  showButtons: false
};
