import React from 'react';
import { AbstractForm, ButtonSelect, FormDisabledRow } from 'emi-frontend';
import { client } from 'emi-frontend';

export default class AlertForm extends AbstractForm {

  getDefaultValues() {
    return {
      project_alert: '',
      system_alert: ''
    };
  }

  componentDidMount() {
    super.componentDidMount();

    client
      .api('GET', `/user/${this.props.user.id}`)
      .done((resp) => {
        const values = Object.assign({}, this.state.values, { system_alert: resp.data.system_alerts });
        this.setState({ values });
      });
  }

  getId = () => {
    return this.props.projectId;
  }

  getFormHeader() {
    return null;
  }

  get modelName() {
    return 'alert';
  }

  getApiPath = () => {
    return '/project_alert';
  }

  convertResponse(data) {
    return {
      project_alert: data !== null
    };
  }

  sendFormData = () => {
    const method = this.state.edit.project_alert === 'true' ? 'POST' : 'DELETE';
    return client.api(method, `${this.getApiPath()}/${this.getId()}`);
  }

  showDeleteAction() {
    return false;
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            name: 'project_alert',
            label: 'Project Alert',
            required: true,
            component: ButtonSelect,
            options: { true: 'Enabled', false: 'Use System setting' },
            fieldWidth: { xs: 12, md: 12, xl: 12 },
            labelWidth: { xs: 12, xl: 3 },
            valueWidth: { xs: 12, xl: 9 }
          })}
        </div>
        <div className="form-row">
          <FormDisabledRow
            label="System Alert"
            name="system_alert"
            id="system_alert"
            value={this.state.values.system_alert ? 'Enabled' : 'Disabled'}
            fieldWidth={{ xs: 12, md: 12, xl: 12 }}
            labelWidth={{ xs: 12, xl: 3 }}
            valueWidth={{ xs: 12, xl: 9 }}
          />
        </div>
      </div>
    );
  }
}
