import { useState } from 'react';

export function useModal(defaultOpen = false) {
  const [isModalOpen, setModalOpen] = useState(defaultOpen);
  
  function toggleModal() {
    setModalOpen(!isModalOpen);
  }

  return {
    isModalOpen, setModalOpen, toggleModal
  };
}