import DefinitionsSelect from './DefinitionsSelect';

export default class StudyTypeSelect extends DefinitionsSelect {

  get definitionsPath() {
    return 'study_types';
  }

  get valueProperty() {
    return 'studyTypeId';
  }
}
