import { ProdegeCountrySelect } from '../prodege';

export default class CountrySelect extends ProdegeCountrySelect {

  getOptionsFromArray() {
    return this.state.data.map((row) => {
      return { value: row.isoCode, label: row.countryName };
    });
  }
}
