import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import AttachmentGrid from './AttachmentGrid';

export default class AttachmentModal extends Component {

  reloadGrid = () => {
    if (this.grid) {
      this.grid.reload();
    }
  }

  render() {
    const { bid_id } = this.props.values;

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle}>
          Bid Files
        </ModalHeader>
        <ModalBody>
          <AttachmentGrid
            extraParams={{ bid_id }}
            storeState={false}
            ref={(comp) => { this.grid = comp; }}
          />
        </ModalBody>
      </Modal>
    );
  }
}
