import React, { useState } from 'react';
import { client } from 'emi-frontend';
import { Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ClickBalancingTemplateSelect from './ClickBalancingTemplateSelect';

export default function LoadClickBalancingTemplate({ project_id, onSuccess }) {
  const [template, setTemplate] = useState(null);
  const [showModal, setShowModal] = useState(false);

  function onTemplateChange(option) {
    const { id = null } = option || {};
    setTemplate(id);
  }

  function loadTemplate() {
    client.api('PUT', `/click_balancing_template/${template}`, { project_id })
      .done(() => {
        setTemplate(null);
        toggleModal();
        onSuccess();
      })
      .fail(console.error);
  }

  function toggleModal() {
    setShowModal(!showModal);
  }

  return (
    <React.Fragment>
      <Col xs="12" lg="4">
        <ClickBalancingTemplateSelect
          value={template}
          placeholder="Select template..."
          onValueChange={onTemplateChange}
          isClearable
        />
      </Col>
      <Col xs="12" lg="2">
        {template !== null &&
          <Button type="button" color="default" onClick={toggleModal} className="mt-1 mt-lg-0">
            <i className="fa fa-repeat" aria-hidden="true"></i>{' '} Load
          </Button>
        }
      </Col>
      <Modal size="lg" isOpen={showModal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          Warning
        </ModalHeader>
        <ModalBody className="text-danger font-weight-bold">
          Loading the template will owerwrite the project Attributes, Prescreening and Click Balancing settings, continue ?
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button type="button" color="warning" onClick={loadTemplate}>Yes</Button>
          <Button type="button" color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
