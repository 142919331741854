import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { AbstractQuota, ClipboardButton } from '../abstract';
import TargetCriteria from './TargetCriteria';
import { hasOwnProperty, randomIntInc } from 'emi-frontend';

export default class Quota extends AbstractQuota {

  getDefaultValues(props) {
    return {
      id: randomIntInc(1000, 99999),
      name: '',
      project_id: props.partnerProjectId,
      clicksWanted: 0,
      completesWanted: 0,
      cpi: '',
      qualifications: {
        and: []
      }
    };
  }

  resetValues() {
    return {
      id: randomIntInc(1000, 99999),
      name: '',
      clicksWanted: 0,
      completesWanted: 0,
      cpi: '',
      qualifications: {
        and: []
      }
    };
  }

  getId = () => {
    return this.props.values.id;
  }

  isUpdate() {
    return hasOwnProperty(this.props.values || {}, 'status');
  }

  isNew() {
    return true;
  }

  getTargetingCriteria() {
    const qualifications = this.state.values.qualifications.and.slice();

    return {
      qualifications
    };
  }

  updateTargetCriteria(method, ...args) {
    const [name, targeting_criteria] = Object.entries(this.getTargetingCriteria()).shift();
    targeting_criteria[method](...args);

    const values = this.valueChanged(name, { and: targeting_criteria });

    this.updateValues(values);
  }

  cloneTargetCriteria(data) {
    const [name, targeting_criteria] = Object.entries(data).shift();
    const values = this.valueChanged(name, { and: targeting_criteria });

    this.updateValues(values);
  }

  pasteQualifications = (qualifications) => {
    this.cloneTargetCriteria(
      AbstractQuota.cloneData({ qualifications })
    );
  }

  getDeleteParams() {
    return {
      id: this.state.values.id,
      project_id: this.props.partnerProjectId
    };
  }

  updateStatus = (status) => {
    const params = {
      status,
      id: this.state.values.id,
      project_id: this.props.partnerProjectId
    };

    this.setState({
      sendingData: true
    }, () =>
      this.clientApi('PUT', `${this.getQuotaApiPath()}/status`, params)
        .done(resp => {
          this.updateValues({ status });
          this.submitDone(resp);
        })
        .fail(this.submitFail)
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.values.currentClicks !== nextProps.values.currentClicks || 
        this.state.values.currentCompletes !== nextProps.values.currentCompletes) {

      const { currentClicks, currentCompletes } = nextProps.values;
      this.updateValues({ currentClicks, currentCompletes });
    }

    if (nextProps.cloneData && AbstractQuota.valuesDidChange(this.props.cloneData, nextProps.cloneData)) {
      this.cloneTargetCriteria(nextProps.cloneData);
    }
  }

  getFormContent() {
    return (
      <div>
        {this.isUpdate() && 
          <div className="form-row">
            {this.getFormRow({
              name: 'status',
              type: 'select',
              disabled: true,
              fieldWidth: { xs: 12, sm: 6 },
              labelWidth: { xs: 12, sm: 5 },
              valueWidth: { xs: 12, sm: 7 },
              children: [
                <option key="paused" value="PAUSED">PAUSED</option>,
                <option key="live" value="LIVE">LIVE</option>
              ]
            })}

            <div className="col-12 col-sm-2 action-buttons">
              {this.state.values.status === 'LIVE' &&
                <button
                  type="button" className="btn btn-danger text-lowercase text-capitalize"
                  onClick={() => this.updateStatus('PAUSED')}
                >
                  <i className="fa fa-pause"></i> PAUSE
                </button>
              }
              {this.state.values.status === 'PAUSED' &&
                <button
                  type="button" className="btn btn-success text-lowercase text-capitalize"
                  onClick={() => this.updateStatus('LIVE')}
                >
                  <i className="fa fa-play"></i> LIVE
                </button>
              }
            </div>
          </div>
        }

        <div className="form-row">
          {this.getFormRow({
            name: 'name',
            required: true,
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'completes',
            name: 'completesWanted',
            type: 'number',
            required: true,
            prefix: hasOwnProperty(this.state.values, 'currentCompletes') ? `${this.state.values.currentCompletes} /` : null,
            fieldWidth: { xs: 6 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}

          {this.getFormRow({
            label: 'clicks',
            name: 'clicksWanted',
            type: 'number',
            required: true,
            prefix: hasOwnProperty(this.state.values, 'currentClicks') ? `${this.state.values.currentClicks} /` : null,
            fieldWidth: { xs: 6 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'cpi',
            type: 'number',
            step: 0.01,
            min: 0,
            prefix: '$',
            required: true,
            fieldWidth: { xs: 6 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}
        </div>

        <div className="form-row">
          <ListGroup style={{ width: '100%' }}>
            {!this.isUpdate() &&
              <ListGroupItem className="prodege-target-criteria">
                <ClipboardButton
                  title="Paste Qualifications"
                  className="float-right"
                  dataType="qualifications" isCopy={false}
                  readClipboardFn={this.pasteQualifications}
                />
              </ListGroupItem>
            }
            <TargetCriteria
              questions={this.props.questions}
              country={this.props.country}
              onAdd={this.addTargetCriteria}
              onDelete={this.removeTargetCriteria}
              onChange={this.modifyTargetCriteria}
              identifier="question"
              allowCreate
            />
            {this.state.values.qualifications.and.map((criteria, idx) =>
              <TargetCriteria
                idx={idx}
                key={(criteria.equals || criteria.range).question}
                criteria={criteria}
                questions={this.props.questions}
                country={this.props.country}
                onAdd={this.addTargetCriteria}
                onDelete={this.removeTargetCriteria}
                onChange={this.modifyTargetCriteria}
                identifier="question"
                isDeletable
              />
            )}
          </ListGroup>
        </div>
      </div>
    );
  }
}