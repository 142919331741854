import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  CardFooter
} from 'reactstrap';
import { AbstractForm } from 'emi-frontend';

export default class ResetIDStatusModal extends AbstractForm {

  get modelName() {
    return 'invoice';
  }

  getDefaultValues() {
    return {
      attachment: ''
    };
  }

  getID = () => {
    return null;
  }

  getFormHeader() {
    return null;
  }

  getApiPath = () => {
    return `/invoice/${this.props.invoice_id}/resetstatus`;
  }

  sendFormData = () => {
    const fd = new FormData();

    if (this.upload) {
      const files = this.upload.files;
      for (let i = 0; i < files.length; i++) {
        fd.append('attachment', files[i], files[i].name);
      }
    }

    return this.clientApi('POST', this.getApiPath(), fd, false);
  }

  cancelAction = () => {
    this.resetForm(this.props);
  }

  getFileName() {
    if (this.upload && this.upload.files && this.state.values.attachment) {
      return this.upload.files[0].name;
    }

    return 'Choose file...';
  }

  successAction = (response) => {
    this.resetForm(this.props);
    this.setState({ message : `Updated ${response.data.rowCount} respondent` });
    this.props.onSuccess();
  }

  getActionButtons = () => {
    return (
      <CardFooter className="action-buttons d-none d-lg-block">
        <Button type="submit" color="success" disabled={this.state.sendingData}>
          <i className="fa fa-upload"></i> Upload
        </Button>
      </CardFooter>
    );
  }

  getFormContent() {
    return (
      <div className="form-row">
        <div className="col">
          <div className="form-group custom-file">
            <input
              type="file"
              accept=".xlsx"
              value={this.state.values.attachment}
              className="custom-file-input"
              ref={(comp) => { this.upload = comp; }}
              name="attachment"
              required
              onChange={this.handleInputChange}
            />
            <label className="custom-file-label" htmlFor="validatedCustomFile">{this.getFileName()}</label>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle}>Reset ID Status</ModalHeader>
        <ModalBody>
          {super.render()}
        </ModalBody>
      </Modal>
    );
  }
}
