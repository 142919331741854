import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { client } from 'emi-frontend';

export default class TargetQuotaCell extends Component {
  static propTypes = {
    target_quota: PropTypes.number.isRequired,
    project_id: PropTypes.number.isRequired,
    row: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
  
    this.state = {
      target_quota: props.summary ? props.target_quota : props.row.quota,
      enable_click_quota: props.enable_click_quota,
      total_clicks: props.summary ? props.total_clicks : props.row.total_clicks,
      saved: true
    };
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      this.updateTargetQuota();
    }
  }

  inputOnClick = (event) => {
    event.stopPropagation();
  }

  updateTargetQuota = () => {
    if (this.state.saved) {
      return;
    }

    let url = `/project/${this.props.project_id}`;
    let data = {
      target_quota: this.state.target_quota,
      total_clicks: this.state.total_clicks
    };

    if (!this.props.summary) {
      url = `/route/${this.props.row.id}`;
      data = {
        quota: this.state.target_quota,
        total_clicks: this.state.total_clicks
      };
    }

    client.api('PUT', url, data)
      .done((resp) => {
        this.setState({ saved: true });
        this.props.onDataChange();
      })
      .fail(console.error);
  }

  render() {
    let addon = '';
    if (this.props.summary) {
      addon = (<InputGroupAddon addonType="append">
        <InputGroupText>{' / '}{this.props.data}</InputGroupText>
      </InputGroupAddon>);
    }
    
    const content = [(<InputGroup key="rq" className="target-quota-cell" size="sm">
      <InputGroupAddon addonType="prepend">
        <InputGroupText>Q</InputGroupText>
      </InputGroupAddon>
      <Input
        className={classNames('form-control', 'form-control-sm', { 'text-info': !this.state.saved })}
        type="text"
        step={1}
        min={0}
        value={this.state.target_quota}
        onChange={e => this.setState({ target_quota: e.target.value, saved: false })}
        onBlur={this.updateTargetQuota}
        onKeyPress={this.handleKeyPress}
        onClick={this.inputOnClick}
        style={{ width: 50 }}
      />
      {addon}
    </InputGroup>)];

    if (this.props.enable_click_quota) {
      let clickAddon = (<InputGroupAddon addonType="append">
        <InputGroupText>{' / '}{this.props.row.entry}</InputGroupText>
      </InputGroupAddon>);
      if (this.props.summary) {
        clickAddon = (<InputGroupAddon addonType="append">
          <InputGroupText>{' / '}{this.props.row.route_total_clicks}</InputGroupText>
        </InputGroupAddon>);
      }
      content.push((<InputGroup key="rc" className="target-quota-cell" size="sm">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>C</InputGroupText>
        </InputGroupAddon>
        <Input
          className={classNames('form-control', 'form-control-sm', { 'text-info': !this.state.saved })}
          type="text"
          step={1}
          min={0}
          value={this.state.total_clicks}
          onChange={e => this.setState({ total_clicks: e.target.value, saved: false })}
          onBlur={this.updateTargetQuota}
          onKeyPress={this.handleKeyPress}
          onClick={this.inputOnClick}
          style={{ width: 50 }}
        />
        {clickAddon}
      </InputGroup>));
    }

    return content;
  }
}
