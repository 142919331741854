import React from 'react';
import {
  CardFooter, Button, Form
} from 'reactstrap';
import { AbstractForm, formatCurrency, ButtonSelect, LocalSelect, FormDisabledRow, CollapseCard } from 'emi-frontend';
import classNames from 'classnames';
import Specification from './Specification';
import { deviceList, methodology } from './constants';
import QuickbooksSelectMain from '../../lib/form/QuickbooksSelectMain';
import ClientSelect from '../../lib/form/ClientSelect';
import ContactSelect from '../../lib/form/ContactSelect';
import UserSelect from '../../lib/form/UserSelect';
import Quotas from './Quotas';

export default class BidForm extends AbstractForm {

  constructor(props) {
    super(props);

    this.state.activeTab = 1;
    this.state.clientCse = {};
    this.state.clientSales = {};
  }

  get modelName() {
    return 'bid';
  }

  getApiPath = () => {
    return '/bid';
  }

  showDeleteAction() {
    return false;
  }

  isReadonlyForm() {
    return true;
  }

  showSaveButton() {
    return false;
  }

  getFormData() {
    return {};
  }

  getId = () => {
    return this.parseInt(this.props.bidId);
  }

  getActionButtons() {
    return (
      <CardFooter className="action-buttons d-lg-block">
        <Button type="button" onClick={this.onBackButton} outline>Back</Button>
      </CardFooter>
    );
  }

  getHeaderButtons() {
    return null;
  }

  redirectPath = () => {
    return '/admin#2';
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const bidId = this.parseInt(nextProps.bidId);
    if (this.props.bidId != bidId) {
      this.loadFormData(bidId);
    }
  }

  onBackButton = () => {
    this.context.history.replace(this.redirectPath());
  }

  getFormHeader() {
    return '';
  }

  getFormContent() {
    return (
      <div>
        <CollapseCard title="Estimator fields">
          <div className="form-row">
            {this.getFormRow({
              name: 'name',
              required: true,
              disabled: true,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.getFormRow({
              name: 'id',
              label: 'IO#',
              required: true,
              disabled: true,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              label: 'methodology',
              name: 'methodology',
              component: LocalSelect,
              options: methodology.map(label => ({ value: label, label })),
              onChange: this.selectChangeHandler('methodology'),
              required: true,
              isDisabled: true,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.getFormRow({
              label: 'classification',
              name: 'classification',
              component: QuickbooksSelectMain,
              onChange: this.selectChangeHandler('classification'),
              isMulti: true,
              required: true,
              isDisabled: true,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              label: 'client',
              name: 'client_id',
              component: ClientSelect,
              onChange: this.selectChangeHandler('client_id'),
              required: true,
              isDisabled: true,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.getFormRow({
              label: 'client contact',
              name: 'contact_id',
              component: ContactSelect,
              client: this.state.values.client_id,
              type: 'project',
              onChange: this.selectChangeHandler('contact_id'),
              required: true,
              isDisabled: true,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              label: 'client project id',
              name: 'client_project_id',
              disabled: true,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.getFormRow({
              label: 'Pricing support',
              name: 'pricing_support_id',
              component: UserSelect,
              type: 'admin',
              department: 'sales',
              active: true,
              required: true,
              isDisabled: true,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              label: 'sales rep',
              name: 'sales_id',
              component: UserSelect,
              type: 'admin',
              department: 'sales',
              sales_rule: 'AE',
              active: true,
              required: true,
              isDisabled: true,
              onChange: this.selectChangeHandler('sales_id'),
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.getFormRow({
              label: 'CSE',
              name: 'cse_id',
              component: UserSelect,
              type: 'admin',
              department: 'sales',
              sales_rule: 'CSE',
              active: true,
              isClearable: true,
              isDisabled: true,
              onChange: this.selectChangeHandler('cse_id'),
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              name: 'devices',
              label: 'enabled devices',
              required: true,
              component: ButtonSelect,
              options: deviceList,
              textOnly: true,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.getFormRow({
              label: 'Minimum fee',
              name: 'minimum_fee',
              required: true,
              type: 'number',
              step: 0.01,
              min: 0,
              formatter: formatCurrency,
              prefix: '$',
              disabled: true,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              label: 'Days in field',
              name: 'days_in_field',
              required: true,
              type: 'number',
              min: 1,
              step: 1,
              disabled: true,
              cssClass: 'fixwidth',
              style: { width: 100 },
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>

          <div className="form-row">
            <FormDisabledRow
              name='specification'
              label='specification'
              component={Specification}
              value={this.state.values.specification}
              fieldWidth={{ xs: 12, lg: 12 }}
              labelWidth={{ xs: 12, lg: 2 }}
              valueWidth={{ xs: 12, lg: 10 }}
            />
          </div>
        </CollapseCard>

        <CollapseCard title="Kickoff fields">
          <div className="form-row">
            {this.getFormRow({
              label: 'start date',
              name: 'start_date',
              required: true,
              disabled: true,
              type: 'date',
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.getFormRow({
              label: 'end date',
              name: 'end_date',
              required: true,
              disabled: true,
              type: 'date',
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              label: 'Special circumstances',
              name: 'special_circumstances',
              type: 'textarea',
              rows: 5,
              disabled: true,
              fieldWidth: { xs: 12 },
              labelWidth: { xs: 12, lg: 3 },
              valueWidth: { xs: 12, lg: 9 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              name: 'additional_sourcing',
              label: 'Additional sourcing',
              type: 'textarea',
              rows: 5,
              disabled: true,
              fieldWidth: { xs: 12 },
              labelWidth: { xs: 12, lg: 3 },
              valueWidth: { xs: 12, lg: 9 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              name: 'internal_note',
              label: 'internal note',
              type: 'textarea',
              rows: 5,
              disabled: true,
              fieldWidth: { xs: 12 },
              labelWidth: { xs: 12, lg: 3 },
              valueWidth: { xs: 12, lg: 9 }
            })}
          </div>
        </CollapseCard>
      </div>
    );
  }

  getForm() {
    return (
      <Form
        innerRef={(comp) => { this.form = comp; }}
        onSubmit={this.onFormSubmit}
        autoComplete="off"
        noValidate
        className={classNames({'was-validated': this.state.clientSideValidated})}
        id={`${this.modelName}Form`}
      >
        {this.getFormContent()}
        {this.getActionButtons()}
      </Form>
    );
  }
}
