import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import AlertForm from './AlertForm';
import { useAuth } from 'emi-frontend';

export default function AlertModal({ projectId, isOpen, toggle }) {
  const { user } = useAuth();

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        Alert Settings
      </ModalHeader>
      <ModalBody>
        <AlertForm
          projectId={projectId}
          onSuccess={() => 1}
          user={user}
        />
      </ModalBody>
    </Modal>
  );
}
