import React from 'react';
import ClientRow from './Row';
import 'intl-tel-input/build/js/utils.js';
import { Grid, Button, GridMobileBody } from 'emi-frontend';
import GridMobileRow from './GridMobileRow';
import SalesforceIcon from '../../lib/SalesforceIcon';
import QuickbooksIcon from '../../lib/QuickbooksIcon';
import HubspotIcon from '../../lib/HubspotIcon';

export default class ClientGrid extends Grid {

  getInitialState(props) {
    return Object.assign(
      super.getInitialState(props),
      { letterFilter: true }
    );
  }

  get gridName() {
    return 'clientGrid';
  }

  getApiPath = () => {
    return '/client';
  }

  getBasePath = () => {
    return '/clients';
  }

  setDefaultComponents() {
    super.setDefaultComponents();

    Object.assign(this.components, { Row: ClientRow, onHubspot: this.props.onHubspotSelect });
  }

  addContact = (id) => {
    this.context.history.push(`${this.getBasePath()}/${id}/contact/0`);
  }

  isOpen = (row) => {
    return Object.prototype.hasOwnProperty.call(row, 'opened') && 
      row.opened;
  }

  toggleContacts = (event, row, length) => {
    event.stopPropagation();

    if (length) {
      const newRow = Object.assign({}, row);
      newRow.opened = !this.isOpen(row);
      this.updateRowData(newRow);
    }
  }

  hasContacts(contacts) {
    return Array.isArray(contacts) && contacts.length > 0;
  }

  ContactsButton = ({ data, row }) => {
    const contacts = this.hasContacts(data) ? data.length : 0;

    return (
      <div className="left-border">
        <button
          type="button" className="btn grid-button"
          onClick={(e) => this.toggleContacts(e, row, contacts)}
        >
          {contacts} {contacts > 1 ? 'contacts' : 'contact'}
        </button>
      </div>
    );
  }

  ConnenctorIcon = ({ data, row }) =>
    (<i className={`fa fa-plug text-${data ? 'success' : 'danger'}`}></i>);

  SalesforceIcon({ data }) {
    return (
      <div style={{ width: '100%', padding: 2 }}>
        <SalesforceIcon color={data ? undefined : '#6c757d'} />
      </div>
    );
  }

  QuickbooksIcon({ data }) {
    return (
      <div style={{ width: '100%', padding: 6 }}>
        <QuickbooksIcon color={data ? undefined : '#6c757d'} />
      </div>
    );
  }

  HubspotIcon = ({ data, row }) => {
    return (
      <div style={{ width: '100%', padding: 6 }} onClick={e => this.props.onHubspotSelect(e, 'client', row.id)}>
        <HubspotIcon color={data ? '#ff7a59' : undefined } />
      </div>
    );
  }

  gridButtonRenderer = (props) => {
    const {className, btnClassName, ...rest} = props; 
    rest.className = btnClassName;
    return (
      <div className={className}>
        <Button {...rest} clickHandler={() => this.props.onHubspotSelect(null, 'contact', props.row.id, true)} />
      </div>
    );
  }

  phoneRenderer = ({ data }) => {
    return window.intlTelInputUtils.formatNumber(data, null, window.intlTelInputUtils.numberFormat.INTERNATIONAL);
  }

  getColumns() {
    return [{
      name: 'id',
      title: 'ID',
      width: 55,
      exactWidth: true
    }, {
      name: 'client_name',
      title: 'Client name',
      width: 200,
      asc: true
    }, {
      name: 'location',
      title: 'Location',
      sortable: false,
      width: 200
    }, {
     name: 'client_phone',
     title: 'Phone',
     width: 200,
     renderer: this.phoneRenderer
    }, {
      name: 'parent_name',
      title: 'Parent',
      width: 200
    }, {
      name: 'hubspot_id',
      title: '',
      width: 50,
      exactWidth: true,
      renderer: this.HubspotIcon
    }, {
      name: 'client_sf_id',
      title: '',
      width: 50,
      exactWidth: true,
      renderer: this.SalesforceIcon
    }, {
      name: 'client_qb_id',
      title: '',
      width: 50,
      exactWidth: true,
      renderer: this.QuickbooksIcon
    }, {
      name: 'client_connector',
      title: '',
      width: 50,
      exactWidth: true,
      renderer: this.ConnenctorIcon
    }, {
      name: 'contacts',
      title: '',
      dataField: 'contacts',
      sortable: false,
      width: 100,
      exactWidth: true,
      renderer: this.ContactsButton
    }, {
      name: 'add',
      title: '',
      dataField: 'id',
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderer: this.gridButtonRenderer,
      params: {
        icon: 'plus',
        className: 'left-border',
        btnClassName: 'text-success',
        //clickHandler: this.addContact
      }
    }];
  }

  getMobileHeader() {
    return '';
  }

  getMobileBody() {
    return <GridMobileBody RowClass={GridMobileRow} columns={this.state.columns} data={this.state.data} onClick={this.onRowClick} loading={this.state.loadingData} />;    
  }
}
