import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  CardFooter
} from 'reactstrap';
import ProjectSelect from '../../lib/form/ProjectSelect';
import { client, AbstractForm, Excel, ButtonSelect } from 'emi-frontend';
import AttachmentGrid from './AttachmentGrid';

export default class ExportMatchForm extends AbstractForm {

  getDefaultValues() {
    return {
      id: '',
      type: 'project',
      matchby: 'redirect_id',
      clientFile: '',
      reconciliationFile: ''
    };
  }

  getID = () => {
    return null;
  }

  getFormHeader() {
    return null;
  }

  get modelName() {
    return 'ExportMatch';
  }

  getApiPath = () => {
    return `/${this.state.values.type}`;
  }

  sendFormData = () => {
    const fd = new FormData();
    fd.append('matchby', this.state.values.matchby);

    if (this.clientFile) {
      const files = this.clientFile.files;
      for (let i = 0; i < files.length; i++) {
        fd.append('clientFile', files[i], files[i].name);
      }
    }

    if (this.reconciliationFile) {
      const files = this.reconciliationFile.files;
      for (let i = 0; i < files.length; i++) {
        fd.append('reconciliationFile', files[i], files[i].name);
      }
    }

    return client.api('POST', `${this.getApiPath()}/${this.state.values.id}/export`, fd, false);
  }

  successAction = (response) => {
    const excel = new Excel();
    excel.addJsonData(response.data, { project_id: 'Job ID', project_name: 'Project Name', rid: 'Redirect ID', cc: 'Client Code', partner: 'Partner', pid: 'Partner ID', fixed_values: 'Fixed variables', status: 'Status', test: 'Test', created: 'Timestamp', loi: 'LOI' }, 'Partner IDs');
    excel.saveAs('export.xlsx');

    this.resetForm(this.props);
    this.reloadGrid();
  }

  cancelAction = () => {
    this.resetForm(this.props);
  }

  getFileName(file, defaultText) {
    if (this[file] && this[file].files && this.state.values[file]) {
      return this[file].files[0].name;
    }

    return defaultText;
  }

  handleProjectChange = (option) => {
    this.updateValues({ id: option ? option.value : '' });
  }

  valueChanged(name, value, isEdit) {
    const changed = super.valueChanged(name, value, isEdit);
    if (name === 'id' && value && this.grid) {
      this.grid.setExtraParam('project_id', value);
    }

    return changed;
  }

  getActionButtons = () => {
    return (
      <CardFooter className="action-buttons d-none d-lg-block">
        <Button type="submit" color="success" disabled={this.state.sendingData}>
          <i className="fa fa-upload"></i> Upload
        </Button>
      </CardFooter>
    );
  }

  showProjectSelect() {
    return !this.props.values || !this.props.values.id;
  }

  getFormContent() {
    return (
      <div>
        {this.showProjectSelect() &&
          <div className="form-row">
            {this.getFormRow({
              label: 'project',
              name: 'id',
              component: ProjectSelect,
              onChange: this.selectChangeHandler('id'),
              required: true,
              labelWidth: { xs: 3 },
              valueWidth: { xs: 9 },
              fieldWidth: { xs: 12 }
            })}
          </div>
        }

        <div className="form-row">
          {this.getFormRow({
            label: 'Match by',
            name: 'matchby',
            options: { redirect_id: 'REDIRECT ID', client_code: 'CLIENT CODE', respondent_id: 'PARTNER ID'},
            component: ButtonSelect,
            type: 'text',
            required: true,
            labelWidth: { xs: 3 },
            valueWidth: { xs: 9 },
            fieldWidth: { xs: 12 }
          })}
        </div>

        <div className="form-row">
          <div className="col">
            <div className="form-group custom-file">
              <input
                type="file"
                value={this.state.values.clientFile}
                className="custom-file-input"
                accept=".xlsx"
                ref={(comp) => { this.clientFile = comp; }}
                name="clientFile"
                required
                onChange={this.handleInputChange}
              />
              <label className="custom-file-label">{this.getFileName('clientFile', 'Choose Redirect ID XLSX file...')}</label>
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col">
            <div className="form-group custom-file">
              <input
                type="file"
                accept=".xlsx"
                value={this.state.values.reconciliationFile}
                className="custom-file-input"
                ref={(comp) => { this.reconciliationFile = comp; }}
                name="reconciliationFile"
                onChange={this.handleInputChange}
              />
              <label className="custom-file-label">{this.getFileName('reconciliationFile', 'Choose Reconciliation XLSX file...')}</label>
            </div>
          </div>
        </div>

      </div>
    );
  }

  reloadGrid = () => {
    if (this.grid) {
      this.grid.reload();
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle}>
          Export Partner Ids
        </ModalHeader>
        <ModalBody>
          {super.render()}

          {this.state.values.id && this.state.values.type === 'project' &&
            <AttachmentGrid
              extraParams={{ type: 'reconciliation', project_id: this.state.values.id }}
              storeState={false}
              ref={(comp) => { this.grid = comp; }}
            />
          }
        </ModalBody>
      </Modal>
    );
  }
}
