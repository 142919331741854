import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Range from './Range';

export default class RangeSet extends Component {

  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
      from: PropTypes.number.isRequired,
      to: PropTypes.number.isRequired,
      units: PropTypes.number.isRequired
    })),
    readOnly: PropTypes.bool,
    onDelete: PropTypes.func.isRequired
  };

  static defaultProps = {
    readOnly: false
  };

  render() {
    const { data, ...props } = this.props;
    return (
      <div className="Select has-value Select--multi">
        <span className="Select-multi-value-wrapper">
          {data.map((range, idx) =>
            <Range key={idx} idx={idx} data={range} {...props} />
          )}
        </span>
      </div>
    );
  }
}