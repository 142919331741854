import React from 'react';
import { AbstractForm } from 'emi-frontend';

export default class ClientCodeNotificationLimitForm extends AbstractForm {

  get modelName() {
    return 'project';
  }

  getDefaultValues() {
    return {
      client_code_notification_limit: ''
    };
  }

  getApiPath = () => {
    return '/project';
  }

  getId = () => {
    return this.parseInt(this.props.id);
  }

  getFormHeader() {
    return null;
  }

  getActionButtons() {
    return null;
  }

  getFormContent() {
    return (
      <div className="form-row">
        {this.getFormRow({
          name: 'client_code_notification_limit',
          label: 'notification limit',
          type: 'number',
          min: 0,
          step: 1,
          labelWidth: { xs: 3 },
          valueWidth: { xs: 5 }
        })}
      </div>
    );
  }
}
