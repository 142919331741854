import React from 'react';
import PropTypes from 'prop-types';
import { LoadServerData } from '../abstract';
import ReportTable from './ReportTable';

export default class Report extends LoadServerData {
  constructor(props) {
    super(props, 'report');
  }

  static propTypes = {
    routeId: PropTypes.number.isRequired,
    partnerProjectId: PropTypes.string.isRequired
  };

  getApiUrl() {
    return `/panelapi/${this.props.routeId}/project/stats`;
  }

  getApiMethod() {
    return 'POST';
  }

  getApiData() {
    return {
      extProjectId: this.props.partnerProjectId
    };
  }

  renderContent() {
    const { report } = this.state;

    return (
      <div>
        <ReportTable report={report} />
        {report.lineItems.map(lineItemReport =>
          <ReportTable key={lineItemReport.extLineItemId} report={lineItemReport} />
        )}
      </div>
      
    );
  }
}
