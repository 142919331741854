import React from 'react';
import $ from 'jquery';
import { client, Grid, Button } from 'emi-frontend';
import FilePagination from './FilePagination';

export default class AttachmentGrid extends Grid {

  get gridName() {
    return 'attachmentGrid';
  }

  getApiPath = () => {
    return `/project_attachment`;
  }

  getBasePath = () => {
    return '/jobs';
  }

  downloadApiCall(path) {
    let qs = '';
    if (!this.state.allSelected) {
      qs = '?' + decodeURIComponent(
        $.param({ id: this.state.selected })
      );
    }

    client.fileDownload(`${this.getApiPath()}/${path}${qs}`)
      .fail(error => this.showError(error));
  }

  getPagination() {
    return this.props.paging && (
      <div>
        <FilePagination
          currentPage={this.state.currentPage}
          pageSize={this.state.pageSize}
          recordCount={this.state.recordCount}
          getPage={this.onGetPage}
          showDownload={this.state.allSelected || this.state.selected.length > 0}
          onDownload={() => this.downloadApiCall('0')}
          onMerge={() => this.downloadApiCall('merge')}
        />
      </div>
    );
  }

  DownloadButton = (props) => {
    const { data, className, btnClassName, summary, ...rest } = props;

    return (
      <div className={className}>
        <Button
          className={btnClassName} {...rest} 
          clickHandler={() => {
            client.fileDownload(`${this.getApiPath()}/${data}`)
              .fail(error => this.showError(error));
          }} 
        />
      </div>
    );
  }

  deleteFile = (data) => {
    client.api('DELETE', `${this.getApiPath()}/${data}`)
      .done(() => this.reload())
      .fail((error) => this.showError(error, { loadingData: false }));;
  }

  DeleteButton = (props) => {
    const { data, className, btnClassName, summary, ...rest } = props;

    return (
      <div className={className}>
        <Button
          className={btnClassName} {...rest} 
          clickHandler={() => this.deleteFile(data)} 
        />
      </div>
    );
  }

  getColumns() {
    return [{
      name: 'selected',
      dataField: 'id',
      title: '',
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderHeader: true,
      renderer: this.RowCheckBox,
      align: 'center',
      params: {
        className: 'right-border'
      }
    }, {
      name: 'originalname',
      title: 'file',
      width: 200
    }, {
      name: 'type',
      title: 'type',
      width: 100
    }, {
      name: 'user_name',
      title: 'user',
      width: 150
    }, {
      name: 'created',
      title: 'created',
      width: 130
    }, {
      name: 'delete',
      dataField: 'id',
      title: '',
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderer: this.DeleteButton,
      params: {
        icon: 'trash-o',
        className: 'left-border',
        btnClassName: 'text-danger',
      }
    }, {
      name: 'export',
      dataField: 'id',
      title: '',
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderer: this.DownloadButton,
      params: {
        icon: 'download',
        className: 'left-border'
      }
    }];
  }

  isRowSelectable = () => {
    return false;
  }
}
