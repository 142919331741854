import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem, Card, CardBody, CardTitle } from 'reactstrap';
import { ClipboardButton } from '../abstract';
import TargetCriteria from './TargetCriteria';

export default class Filters extends Component {

  static propTypes = {
    filters: PropTypes.array.isRequired,
    questions: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    showCopyButton: PropTypes.bool
  };

  static defaultProps = {
    showCopyButton: true
  };

  emptyFn = () => {}

  modifyFilters = (...args) => {
    this.props.onChange(['quotaPlan', 'filters'], ...args);
  }

  copyFilters = () => {
    return this.props.filters.slice();
  }

  render() {
    const { filters, showCopyButton } = this.props;

    return (
      <Card className="top-margin-10">
        <CardBody>
          <CardTitle>
            Filters{' '}
            <span className="quotaplan-subtitle">
              attributes which all panelists should have before qualifying for a survey
            </span>
          </CardTitle>
          <ListGroup style={{ width: '100%' }} className="top-margin-10">
            {showCopyButton && filters.length > 0 &&
              <ListGroupItem className="prodege-target-criteria">
                <ClipboardButton
                  title="Copy Filters"
                  className="float-right"
                  dataType="filters"
                  writeClipboardFn={this.copyFilters}
                />
              </ListGroupItem>
            }
            <TargetCriteria
              questions={this.props.questions}
              onAdd={criteria => this.modifyFilters('push', criteria)}
              onDelete={this.emptyFn}
              onChange={this.emptyFn}
              filterProp="isAllowedInFilters"
              identifier="attributeId"
              filterDeprecated
              allowCreate
            />
            {filters.map((criteria, key) =>
              <TargetCriteria
                idx={key}
                key={key}
                criteria={criteria}
                questions={this.props.questions}
                onAdd={this.emptyFn}
                onDelete={() => this.modifyFilters('splice', key, 1)}
                onChange={(i, criteria) => this.modifyFilters('splice', key, 1, criteria)}
                filterProp="isAllowedInFilters"
                identifier="attributeId"
                isDeletable
              />
            )}
          </ListGroup>
        </CardBody>
      </Card>
    );
  }
}