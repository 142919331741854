import React from 'react';
import PropTypes from 'prop-types';
import { LoadServerData } from '../abstract';

export default class SurveyPerformance extends LoadServerData {
  constructor(props) {
    super(props, 'performance');
  }

  static propTypes = {
    routeId: PropTypes.number.isRequired,
    partnerProjectId: PropTypes.number.isRequired
  };

  getApiUrl() {
    return `/panelapi/${this.props.routeId}/project/stats`;
  }

  getApiMethod() {
    return 'POST';
  }

  getApiData() {
    return {
      ps_survey_id: this.props.partnerProjectId
    };
  }

  getStats(field) {
    if (!this.state.performance) {
      return '-';
    }

    return Object.prototype.hasOwnProperty.call(this.state.performance, field) ?
      this.state.performance[field] : '-';
  }

  renderContent() {
    const fields = {
      completes_goal: 'Total number of completes required for the survey',
      completes_fielded: 'Number of completes attained so far',
      cpi_forecast_at_launch: 'The cost per complete as forecasted by the creator of the survey',
      current_cpi: 'The current CPI of the survey. This is an indicative measure of the potential cost of next complete',
      projected_cpi: 'Purespectrum’s projection of the average CPI for the total completes',
      total_cost_forecast_at_launch: 'The total fielding cost forecasted by the creator of the survey at launch',
      current_cost: 'The accrued fielding cost so far',
      projected_total_cost: 'The projected final cost of the survey',
      stated_loi: 'The length of the interview in minutes',
      current_overall_loi: 'The current length of interview',
      last_block_loi: 'LOI of last block of respondents. An indication of most recent characteristics.',
      stated_incidence: 'The incidence in percentage',
      last_block_incidence: 'Incidence rate for the most recent block of respondents. An indication of recent characteristics.',
      current_overall_incidence: 'The current incidence of the survey'
    };

    return (
      <table className="table top-margin-10">
        <thead className="thead-dark">
          <tr className="text-center">
            <th scope="col" colSpan="2">Survey performance</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(fields).map(key => 
            <tr key={key}>
              <th scope="row">{fields[key]}</th>
              <td className="text-center">{this.getStats(key, true)}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}
