import Grid from './Grid';
import { Button } from 'emi-frontend';

export default class KickOffGrid extends Grid {

  getInitialState(props) {
    const initialState = super.getInitialState(props);
    Object.assign(initialState, {
      title: 'Kick-Off Projects',
      className: 'job-grid',
      preload: false
    });

    Object.assign(initialState.extraParams, { status: 'kickoff' });

    return initialState;
  }

  get gridName() {
    return 'kickOffGrid';
  }

  setProjectTest = (projectId, row) => {
    const update = this.getStatusUpdate('test', !row.project_group_id, { policy: 'monitor' });
    this.updateProject(projectId, update, 2);
  }

  getColumns() {
    return super
      .getColumns()
      .concat({
        name: 'play',
        dataField: 'id',
        title: '',
        sortable: false,
        width: 40,
        exactWidth: true,
        className: 'job-button',
        renderer: Button,
        params: {
          icon: 'play',
          className: 'left-border',
          clickHandler: this.setProjectTest
        }
      });
  }
}
