import React from 'react';
import UsersList from './UsersList';

export default function IncludedExcludedUsers(props) {
  return (
    <React.Fragment>
      <UsersList {...props} type="included" />
      <UsersList {...props} type="excluded" />
    </React.Fragment>
  );
}
