import React from 'react';
import ValueRow from '../attribute/ValueRow';
import { AbstractForm, Grid } from 'emi-frontend';

export default class AttributeValueGrid extends Grid {

  get gridName() {
    return 'projectAttributeValueGrid';
  }

  getApiPath = () => {
    return '/project_attribute_value';
  }

  getBasePath = () => {
    return '/jobs';
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.extraParams.project_attribute_id &&
      AbstractForm.valuesDidChange(this.state.extraParams, nextProps.extraParams)) {

      this.setExtraParams(nextProps.extraParams);
    }
  }

  onValueDelete = (seq, id) => {
    this.callApi('DELETE', id);
  }

  moveValue = (direction, seq, id) => {
    this.callApi('PUT', id, {
      seq,
      direction,
      project_attribute_id: this.props.extraParams.project_attribute_id
    });
  }

  callApi(method, id, params) {
    this.clientApi(method, `${this.getApiPath()}/${id}`, params)
      .done(this.reload)
      .fail(error => this.showError(error));
  }

  isRowSelectable = () => {
    return false;
  }

  getColumns() {
    return [{
      name: 'attribute_value',
      title: 'value',
      sortable: false,
      width: 250
    }, {
      name: 'seq',
      title: '',
      sortable: true,
      asc: true,
      width: 50
    }];
  }

  getGrid() {
    return (
      <div>
        <table className="table table-sm">
          <tbody>
          {this.state.data.map((value, index) =>
            <ValueRow
              key={value.id} index={index}
              id={value.id} seq={value.seq}
              attributeCount={this.state.recordCount}
              value={value.attribute_value}
              onMove={this.moveValue}
              onDelete={this.onValueDelete}
            />
          )}
          </tbody>
        </table>
      </div>
    );
  }
}
