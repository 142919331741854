import { AbstractQuotas } from '../abstract';
import Quota from './Quota';

export default class Quotas extends AbstractQuotas {

  setQuotaComponent() {
    this.components = { Quota };
  }

  getDefaultValues() {
    return {
      prodege_project_id: this.props.partnerProjectId
    };
  }
}