import React from 'react';

export default function PercentageField({ value, colored = false }) {
  const percent = Number(value);

  return (
    <span className={colored ? (value < 100 ? 'text-danger' : 'text-success') : null}>
      {percent.toFixed(2)} {'%'}
    </span>
  );
}
