import React, { useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import { RouterContext } from 'emi-frontend';

export default function TitleField({ data, row, placement }) {
  const { history } = useContext(RouterContext);
  const elRef = useRef();

  useEffect(() => {
    if (elRef.current) {
      $(elRef.current).tooltip();

      return () => $(elRef.current).tooltip('dispose');
    }
  }, [])

  function goToProject() {
    history.replace(`/jobs/${row.id}`);
  }

  return (
    <span ref={elRef} data-toggle="tooltip" data-placement={placement} title={data}>
      {row.group === 'project' || row.rowType === 'project' ? (
        <a href="#" onClick={goToProject}>{data}</a>
      ) : (
        data
      )}
    </span>
  );
}

TitleField.propTypes = {
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  // title: PropTypes.string.isRequired,
  // tag: PropTypes.string
};

TitleField.defaultProps = {
  // tag: 'span',
  placement: 'top'
};
