import React, { useState } from 'react';
import ClipboardContext from './ClipboardContext';

export default function ClipboardProvider({ children }) {
  const [clipboard, setClipboard] = useState({ data: null, type: null });

  function setClipboardData(type, data) {
    setClipboard({ type, data });
  }

  function getClipboardData(type) {
    return clipboard.type === type ? clipboard.data : null;
  }

  return (
    <ClipboardContext.Provider
      value={{
        copyToClipboard: setClipboardData,
        pasteFromClipboard: getClipboardData
      }}
    >
      {children}
    </ClipboardContext.Provider>
  );
}
