import React from 'react';
import { Alert } from 'reactstrap';
import { ProjectForm } from '../abstract';
import CountrySelect from './CountrySelect';

export default class Project extends ProjectForm {

  getDefaultValues() {
    return {
      project_id: 0,
      project_type_id: 1,
      project_name: '',
      loi: 0,
      expected_ir: 0,
      country_id: 1,
      project_url: '',
      cpi: 0,
      current_avg_cpi: 0,
      max_cpi: ''
    };
  }

  getId = () => {
    return this.parseInt(this.state.values.prodege_project_id);
  }

  reloadData = () => {
    this.clientApi('POST', this.resolveApiPath('refresh'), {
      prodege_project_id: this.getId()
    })
    .done(this.successAction)
    .fail(this.deleteFail);
  }

  isDisabledDueRealTimePricing() {
    return this.isUpdate() && this.state.values.disabled_by_cpi_reality_check === true;
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            label: 'name',
            name: 'project_name',
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, sm: 2 },
            valueWidth: { xs: 12, sm: 10 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'country',
            name: 'country_id',
            component: CountrySelect,
            partnerId: this.props.partnerId,
            required: true,
            isClearable: false,
            onChange: this.selectChangeHandler('country_id'),
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, sm: 2 },
            valueWidth: { xs: 12, sm: 6 }
          })}
        </div>

        {this.isUpdate() &&
          <div className="form-row">
            {this.getFormRow({
              label: 'current cpi',
              name: 'cpi',
              type: 'number',
              step: 0.01,
              min: 0,
              prefix: '$',
              readOnly: true,
              fieldWidth: { xs: 12, sm: 6 },
              labelWidth: { xs: 12, sm: 4 },
              valueWidth: { xs: 12, sm: 8 }
            })}
            {this.getFormRow({
              label: 'average cpi',
              name: 'current_avg_cpi',
              type: 'number',
              step: 0.01,
              min: 0,
              prefix: '$',
              readOnly: true,
              fieldWidth: { xs: 12, sm: 6 },
              labelWidth: { xs: 12, sm: 4 },
              valueWidth: { xs: 12, sm: 8 }
            })}
          </div>
        }

        {this.isDisabledDueRealTimePricing() &&
          <Alert color="warning">
            Project is disabled due to reaching the Max CPI, update its value to automatically resume sampling.
          </Alert>
        }

        <div className="form-row">
          {this.getFormRow({
            label: 'max cpi',
            name: 'max_cpi',
            type: 'number',
            step: 0.01,
            min: 0,
            prefix: '$',
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.isDisabledDueRealTimePricing() &&
            this.getFormRow({
              label: 'CPI Requested',
              name: 'ir_based_cpi',
              type: 'number',
              prefix: '$',
              readOnly: true,
              fieldWidth: { xs: 12, sm: 6 },
              labelWidth: { xs: 12, sm: 4 },
              valueWidth: { xs: 12, sm: 8 }
            })
          }

        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'loi',
            type: 'number',
            required: true,
            step: 1,
            min: 0,
            max: 45,
            postfix: 'mins',
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'ir',
            name: 'expected_ir',
            type: 'number',
            step: 1,
            min: 1,
            max: 100,
            postfix: '%',
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'type',
            name: 'project_type_id',
            type: 'select',
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 },
            disabled: this.isUpdate(),
            children: [
              <option key="adhoc" value="1">Adhoc</option>,
              <option key="recontact" value="22">Recontact</option>
            ]
          })}

          {this.isUpdate() && this.getFormRow({
            name: 'status',
            type: 'select',
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 },
            children: [
              <option key="pending" value="PENDING">PENDING</option>,
              <option key="live" value="LIVE">LIVE</option>,
              <option key="pause" value="PAUSED">PAUSED</option>,
              <option key="complete" value="COMPLETE">COMPLETE</option>
            ]
          })}
        </div>

      </div>
    );
  }
}
