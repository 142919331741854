import React from 'react';
import { Nav, TabContent, TabPane } from 'reactstrap';
import { hasOwnProperty } from 'emi-frontend';
import { ApiTabs, ClipboardProvider } from '../abstract';
import Survey from './Survey';
import Quotas from './Quotas';
import Qualifications from './Qualifications';

export default class Marketcube extends ApiTabs {

  hasProject() {
    return this.state.api_data !== null && hasOwnProperty(this.state.api_data.project || {}, 'surveyId');
  }

  getQuotas() {
    return this.state.api_data.quotas || {};
  }

  getQualifications() {
    return this.state.api_data.qualifications || {};
  }

  getTabs() {
    const hasProject = this.hasProject();

    return (
      <Nav tabs>
        {this.getTab('Project', 1)}
        {hasProject && this.getTab('Qualifications', 2)}
        {hasProject && this.getTab('Quotas', 3, 'quotas')}
      </Nav>
    );
  }

  getTabContent() {
    const hasProject = this.hasProject();
    const project = this.getProjectValues();

    return (
      <ClipboardProvider>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId={1}>
            <Survey
              routeId={this.props.routeId}
              partnerId={this.props.partnerId}
              values={project}
              onUpdate={this.updateApiData}
            />
          </TabPane>
          {hasProject &&
            <TabPane tabId={2}>
              <Qualifications
                routeId={this.props.routeId}
                partnerId={this.props.partnerId}
                partnerProjectId={project.surveyId}
                quotas={this.getQualifications()}
                countryId={project.countryLanguageID}
                onUpdate={this.updateApiData}
              />
            </TabPane>
          }
          {hasProject &&
            <TabPane tabId={3}>
              <Quotas
                routeId={this.props.routeId}
                partnerId={this.props.partnerId}
                partnerProjectId={project.surveyId}
                quotas={this.getQuotas()}
                countryId={project.countryLanguageID}
                onUpdate={this.updateApiData}
                ref={(c) => this.setComponentRef('quotas', c)}
              />
            </TabPane>
          }
        </TabContent>
      </ClipboardProvider>
    );
  }
}
