import React, { Component } from 'react';

import { client, Loader, AlertMessage } from 'emi-frontend';

export default class LoadServerData extends Component {
  constructor(props, dataProp, defaultValue = null) {
    super(props);

    this.dataProp = dataProp;

    this.state = {
      [this.dataProp]: defaultValue,
      loadingData: null,
      message: '',
      success: true
    };
  }

  getApiUrl() {
    throw new Error('getApiUrl() method should be overwritten');
  }

  getApiMethod() {
    return 'GET';
  }

  getApiData() {
    return null;
  }

  shouldLoadData() {
    return true;
  }

  clientApi() {
    return client.api.apply(client, arguments);
  }

  resolveApiPath(...args) {
    return args.join('/');
  }

  reloadData = () => {
    this.loadData(this.getApiData());
  }

  loadData(data) {
    this.setState({
      loadingData: true
    }, () =>
      client.api(this.getApiMethod(), this.getApiUrl(), data)
        .done((resp) => this.loadDone(resp))
        .fail(this.showError)
    );
  }

  componentDidMount() {
    if (this.shouldLoadData()) {
      this.loadData(this.getApiData());
    }
  }

  loadDone = (response) => {
    this.setState({
      loadingData: false,
      [this.dataProp]: response.data
    });
  }

  showError = (error) => {
    const state = error.responseJSON ? error.responseJSON : { success: false, message: error.responseText };
    Object.assign(state, { sendingData: false, loadingData: false });

    this.setState(state);
  }

  showMessage = (message, success, cb) => {
    this.setState({ message, success }, cb);
  }

  renderContent() {
    return null;
  }

  render() {
    if (this.state.loadingData !== false) {
      return <Loader loading={this.state.loadingData} />
    }

    if (this.state.message) {
      return (
        <AlertMessage
          color={this.state.success ? 'info' : 'danger'}
          message={this.state.message}
          closeHandler={() => this.showMessage('', true)}
        />
      );
    }

    return this.renderContent();
  }
}