import React from 'react';
import PropTypes from 'prop-types';
import { AbstractTargetCriteria, CreatableReactSelect } from '../abstract';
import { ReactSelect } from 'emi-frontend';
import RangeSet from './RangeSet';
import RangeModal from './RangeModal';
import OperatorHint from '../abstract/OperatorHint';

export default class TargetCriteria extends AbstractTargetCriteria {

  constructor(props) {
    super(props);

    this.state.rangeModal = false;
  }

  static propTypes = {
    ...TargetCriteria.propTypes,
    criteria: PropTypes.shape({
      qualification_code: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      range_sets: PropTypes.array,
      condition_codes: PropTypes.array
    })
  };

  get name() {
    return 'purespectrum';
  }

  getDefaultCriteria() {
    return {
      qualification_code: null,
      condition_codes: [],
      range_sets: undefined
    };
  }

  toggleRangeModal = () => {
    this.setState({ rangeModal: !this.state.rangeModal });
  }

  getCurrentTargetCriteria() {
    const { qualification_code, range_sets, condition_codes } = this.state;
    const targetCriteria = Object.assign({}, { qualification_code });
    if (this.hasCondition('range_sets')) {
      Object.assign(targetCriteria, { range_sets });
    } else {
      Object.assign(targetCriteria, { condition_codes });
    }

    return targetCriteria;
  }

  hasCondition(prop) {
    return Object.prototype.hasOwnProperty.call(this.state, prop) && this.state[prop] !== undefined;
    // return this.state[prop] && Array.isArray(this.state[prop]);
  }

  filterQuestion(key) {
    return true
  }

  getQuestionSelectOptions() {
    const { questions } = this.props;
    return Object.keys(questions).filter(this.filterQuestion).map((key) => {
      return { value: key, label: `${questions[key].desc}` };
    });
  }

  getPrecodesSelectOptions(question) {
    const options = this.useCreatable(question) ? 
      this.state.condition_codes.map(value => ({ value, label: value })) :
      Object.keys(question.answers).map(value => ({ value, label: question.answers[value] }));

    return options;
  }

  shouldParseValue(prop) {
    return prop === 'condition_codes' && this.useCreatable(this.getSelectedQuestion()) && this.hasCondition('condition_codes');
  }

  valueChanged(prop, value) {
    const changed = super.valueChanged(prop, value);

    if (prop === this.props.identifier && this.getIdentifierValue()) {
      Object.assign(changed, { range_sets: undefined, condition_codes: [] });
    }

    return changed;
  }

  operatorChangeHandler = (event) => {
    this.updateState({ operator: event.target.value });
  }

  precodesCount() {
    return this.hasCondition('range_sets') ?
      this.state.range_sets.length : this.state.condition_codes.length;
  }

  static isQuestionType(question, type) {
    return question.type === type;
  }

  useCreatable(question) {
    return TargetCriteria.isQuestionType(question, 5);
  }

  updateRange(method, ...args) {
    const ranges = this.state.range_sets.slice();
    ranges[method](...args);

    const values = this.valueChanged('range_sets', ranges);

    this.updateState(values);
  }

  getPrecodesSelect() {
    if (!this.getIdentifierValue()) {
      return null;
    }

    const question = this.getSelectedQuestion();
    const useCreatable = this.useCreatable(question);

    if (useCreatable && this.hasCondition('range_sets')) {
      return (
        <div>
          <RangeSet
            data={this.state.range_sets}
            readOnly={this.props.readOnly}
            onDelete={idx => this.updateRange('splice', idx, 1)}
          />
          {!this.props.readOnly &&
            <RangeModal
              idx={this.props.idx}
              isOpen={this.state.rangeModal}
              toggle={this.toggleRangeModal}
              onCreateRange={range => this.updateRange('push', range)}
            />
          }
        </div>
      );
    }

    const props = {
      options: this.getPrecodesSelectOptions(question),
      value: this.state.condition_codes,
      onChange: option => this.selectChangeHandler('condition_codes', option),
      isMulti: true,
      closeMenuOnSelect: false,
      isDisabled: this.props.readOnly
    };

    return useCreatable ? (
      <CreatableReactSelect {...props} />
    ) : (
      <ReactSelect {...props} closeOnSelect={false} />
    );
  }

  setCriteria = () => {
    if (this.hasCondition('range_sets')) {
      this.updateState({ range_sets: undefined, condition_codes: [] });
    } else {
      this.updateState({ range_sets: [], condition_codes: undefined });
    }
  }

  getButtonClass(selected) {
    return `btn btn-${selected ? 'warning' : 'default'} range-btn`;
  }

  getOperatorButton(label) {
    const propMap = { condition: 'condition_codes', range: 'range_sets' };
    const selected = this.hasCondition(propMap[label]);

    return (
      <button
        type="button"
        className={this.getButtonClass(selected)}
        onClick={() => this.setCriteria()}
        disabled={selected || this.props.readOnly}
      >
        {label}
      </button>
    );
  }

  showModalButton() {
    return !this.props.readOnly && this.hasCondition('range_sets');
  }

  getOperatorSelect() {
    return (
      <div className="form-group col-md-2">
        {this.useCreatable(this.getSelectedQuestion()) ? (
          <div className="btn-group-vertical btn-group-sm">
            {this.getOperatorButton('condition')}
            {this.getOperatorButton('range')}
          </div>
        ):(
          this.getOperatorButton('condition')
        )}
        {this.showModalButton() && 
          <button
            type="button" className="btn btn-default btn-sm range-modal-btn"
            onClick={this.toggleRangeModal}
          >
            <i className="fa fa-plus-square" aria-hidden="true"></i>
          </button>
        }
        <OperatorHint {...this.operatorHintProps()} />
      </div>
    );
  }

  render() {
    if (typeof this.props.idx === 'number' && !this.getSelectedQuestion()) {
      return null;
    }

    return super.render();
  }
}
