import React from 'react';
// import { Row, Col } from 'reactstrap';
import LineItemForm from '../job/LineItemForm';
import LineItemGrid from '../job/LineItemGrid';

export default class MultiLineItemGrid extends LineItemGrid {

  get gridName() {
    return 'multilineItemGrid';
  }

  getColumns() {
    const columns = super.getColumns();
    columns.unshift({
      name: 'project_id',
      title: 'Job #',
      width: 100,
      exactWidth: true
    }, {
      name: 'name',
      title: 'Job name',
      width: 100
    });
    return columns;
  }

  getPagination() {
    return (
      <div>
        {
          !this.props.readOnly
            ? <LineItemForm
                id={this.state.lineItemId}
                isOpen={this.state.lineItemModal}
                toggle={this.toggleLineItemModal}
                firstItem={this.state.firstItem}
                values={{
                  invoice_id: this.props.extraParams.invoice_id
                }}
                onSuccess={this.onLineItemChanged}
                onDelete={this.onLineItemChanged}
                reload={this.reload}
              />
            : ''
        }

        {
          !this.props.readOnly
            ? (
              <div
                className="d-flex flex-row flex-nowrap grid-row content rounded job-add-partner"
                onClick={this.toggleLineItemModal}
              >
                <span className="text-success"><i className="fa fa-plus"></i> Add Item</span>
              </div>
            )
            : ''
        }

        {
          this.state.discounts && Array.isArray(this.state.discounts) && this.state.discounts.length && (
            <div className="d-flex flex-row-reverse">
              <div className="invoice-project-card card text-white bg-secondary">
                <div className="card-header"><span className="project-title"><b>Job Discount Summary</b></span></div>
                <div className="card-body">
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th>Job Name</th>
                        <th>Discount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.discounts.map((discount, idx) => (
                          <tr key={`discount_idx${idx}`}>
                            <td style={{borderTop: 0 }}>{discount.name}</td>
                            <td align="right" style={{borderTop: 0}}>{`${discount.discount_type === 'amount' ? '$': ''}${discount.discount}${discount.discount_type === 'percentage' ? '%': ''}`}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )
        }
      </div>
    );
  }
}
