import React from 'react';
import { AbstractForm } from 'emi-frontend';

export default class Suppliers extends AbstractForm {

  get modelName() {
    return 'suppliers';
  }

  getFormHeader() {
    return null;
  }

  getApiPath = () => {
    return `/panelapi/${this.props.routeId}/suppliers`;
  }

  getApiMethod() {
    return 'POST';
  }

  shouldLoadData() {
    return this.props.partnerId > 0;
  }

  getDefaultValues() {
    return {
      suppliers: []
    };
  }

  loadFormData() {
    this.setState({
      loadingData: true
    }, () =>
      this.clientApi('POST', `/panelapi/${this.props.partnerId}/suppliers`, { ps_survey_id: this.props.partnerProjectId })
        .done((resp) => this.loadDone(resp))
        .fail(this.loadFail)
    );
  }

  getFormData() {
    const { partnerProjectId: ps_survey_id } = this.props;
    const { suppliers } = this.state.values;

    return { ps_survey_id, suppliers };
  }

  sendFormData = () => {
    return this.clientApi('PUT', this.getApiPath(), this.getFormData());
  }

  showDeleteAction() {
    return false;
  }

  showSaveButton() {
    return true;
  }

  isNew() {
    return false;
  }

  convertResponse(data) {
    const values = [];
    if (Array.isArray(data)) {
      data.forEach(({ completes_goal, cpi, currency_symbol, fielded, valid_clicks, suppliers }) => {
        values.push(
          ...suppliers.map(supplier =>
            Object.assign({}, supplier, { suppliers: [supplier.id], cpi, completes_goal, currency_symbol, fielded, valid_clicks })
          )
        );
      });
    }

    values.sort((a, b) => {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }

      if (nameA > nameB) {
        return 1;
      }
    
      // names must be equal
      return 0;
    });

    return { suppliers: values };
  }

  handleInputChange = (event, idx) => {
    const { name, value } = event.target;
    const suppliers = AbstractForm.cloneData(this.state.values.suppliers);

    suppliers[idx][name] = value;

    this.updateValues({ suppliers });
  }

  getFormContent() {
    return (
      <table className="table table-sm table-hover top-margin-10">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Supplier</th>
            <th scope="col">
              <i className="fa fa-hand-pointer-o" aria-hidden="true" />
            </th>
            <th scope="col">Completed</th>
            <th scope="col" className="text-center">Quota</th>
            <th scope="col" className="text-center">CPI</th>
          </tr>
        </thead>
        <tbody>
          {this.state.values.suppliers.map((supplie, idx) =>
            <tr key={supplie.id}>
              <td>{supplie.name}</td>
              <td className="text-center">{supplie.valid_clicks || 0}</td>
              <td className="text-center">{supplie.fielded || 0}</td>
              <td>
                <input
                  className="form-control form-control-sm"
                  type="number" min="0"
                  name="completes_goal"
                  value={supplie.completes_goal}
                  onChange={e => this.handleInputChange(e, idx)}
                />
              </td>
              <td>
                <div className="input-group input-group-sm">
                  <div className="input-group-prepend">
                    <span className="input-group-text">{supplie.currency_symbol}</span>
                  </div>
                  <input
                    className="form-control"
                    type="number" min="0" step="0.01"
                    name="cpi"
                    value={supplie.cpi}
                    onChange={e => this.handleInputChange(e, idx)}
                  />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }
}
