import React from 'react';
import { GridMobileRow } from 'emi-frontend';
import { departments } from './constants';

export default class Row extends GridMobileRow {
  displayDepartments(deps) {
    return deps.map(department => departments[department]).join(', ');
  }

  getProjectCounts() {
    return (
      <div className="d-flex flex-row justify-content-between">
        <div>Live: {this.props.row.live}</div>
        <div>Tech.Diff: {this.props.row.paused}</div>
        <div>Closed: {this.props.row.closed}</div>
      </div>
    );
  }

  getMainContent() {
    return (
      <div className="d-flex flex-column grid-cell mobile-cell">
        <div className="d-flex flex-row">
          <div className="grid-cell name">{this.props.row.name} (#{this.props.row.id})</div>
          <div className="grid-cell d-flex justify-content-end">
            <div className="gray">{this.displayDepartments(this.props.row.department)}</div>
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className="grid-cell">{this.getProjectCounts()}</div>
        </div>
      </div>
    );
  }

  getData() {
    return (
      <div key={`cont${this.props.row.id}`} className="grid-row-cell d-flex">
        {this.getMainContent()}
        {this.getButtonFromColumn('active', 30)}
      </div>
    );
  }
}
