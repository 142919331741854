import React from 'react';
import { Pagination } from 'emi-frontend';
import FilePaginationItem from './FilePaginationItem';

export default class FilePagination extends Pagination {

  static defaultProps = {
    showDownload: false
  };

  render() {
    if (!this.props.recordCount || !this.props.pageSize) {
      return null;
    }

    return (
      <nav className="pagination-nav">
        <ul className="pagination pagination-sm">
          {this.props.showDownload &&
            <FilePaginationItem
              onClick={this.props.onMerge}
              faIcon="file-excel-o"
              tooltip="Merge xlsx files"
            />
          }
          {this.props.showDownload &&
            <FilePaginationItem
              onClick={this.props.onDownload}
              faIcon="download"
              tooltip="Download as zip"
            />
          }
          {this.getFirst()}
          {this.getPrev()}
          {this.getPages()}
          {this.getNext()}
          {this.getLast()}
        </ul>
      </nav>
    );
  }
}
