import React from 'react';
import { Nav, TabContent, TabPane } from 'reactstrap';
import { ApiTabs, ClipboardProvider } from '../abstract';
import Survey from './Survey';
import QuotasPerformance from './QuotasPerformance';
import Qualifications from './Qualifications';
import Suppliers from './Suppliers';
import SurveyPerformance from './SurveyPerformance';
import Reconciliation from './Reconciliation';
import Channels from './Channels';

export default class PureSpectrum extends ApiTabs {

  hasProject() {
    return this.state.api_data !== null && Object.prototype.hasOwnProperty.call(this.state.api_data.project || {}, 'ps_survey_id');
  }

  getProjectData(prop, key) {
    const { project } = this.state.api_data;
    if (!project || !Array.isArray(project[prop])) {
      return {};
    }

    return project[prop].reduce((accumulator, data) => {
        accumulator[data[key]] = data;
        return accumulator;
      }, {});
  }

  getTabs() {
    const hasProject = this.hasProject();

    return (
      <Nav tabs>
        {this.getTab('Project', 1)}
        {hasProject && this.getTab('Qualifications', 2)}
        {hasProject && this.getTab('Quotas', 3)}
        {hasProject && this.getTab('Suppliers', 4)}
        {hasProject && this.getTab('Performance', 5)}
        {hasProject && this.getTab('Channels', 6)}
        {hasProject && this.getTab('Reconciliation', 7)}
      </Nav>
    );
  }

  getTabContent() {
    const hasProject = this.hasProject();

    return (
      <ClipboardProvider>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId={1}>
            <Survey
              routeId={this.props.routeId}
              partnerId={this.props.partnerId}
              values={this.getProjectValues()}
              onUpdate={this.updateApiData}
            />
          </TabPane>
          {hasProject &&
            <TabPane tabId={2}>
              <Qualifications
                routeId={this.props.routeId}
                partnerId={this.props.partnerId}
                partnerProjectId={this.state.api_data.project.ps_survey_id}
                quotas={this.getProjectData('qualifications', 'qualification_code')}
                onUpdate={this.updateApiData}
              />
            </TabPane>
          }
          {hasProject &&
            <TabPane tabId={3}>
              <QuotasPerformance
                routeId={this.props.routeId}
                partnerId={this.props.partnerId}
                partnerProjectId={this.state.api_data.project.ps_survey_id}
                quotas={this.getProjectData('quotas', 'buyer_quota_id')}
                onUpdate={this.updateApiData}
              />
            </TabPane>
          }
          {hasProject &&
            <TabPane tabId={4}>
              <Suppliers
                routeId={this.props.routeId}
                partnerId={this.props.partnerId}
                projectId={this.props.projectId}
                partnerProjectId={this.state.api_data.project.ps_survey_id}
              />
            </TabPane>
          }
          {hasProject &&
            <TabPane tabId={5}>
              <SurveyPerformance
                routeId={this.props.routeId}
                partnerProjectId={this.state.api_data.project.ps_survey_id}
              />
            </TabPane>
          }
          {hasProject &&
            <TabPane tabId={6}>
              <Channels
                routeId={this.props.routeId}
                partnerId={this.props.partnerId}
                partnerProjectId={this.state.api_data.project.ps_survey_id}
              />
            </TabPane>
          }
          {hasProject &&
            <TabPane tabId={7}>
              <Reconciliation
                routeId={this.props.routeId}
                partnerProjectId={this.state.api_data.project.ps_survey_id}
                acceptFileType=".xlsx"
              />
            </TabPane>
          }
        </TabContent>
      </ClipboardProvider>
    );
  }
}
