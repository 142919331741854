import { Component } from 'react';
import { ListGroup, ListGroupItem, Card, CardBody } from 'reactstrap';
import { AbstractForm } from 'emi-frontend';
import TargetCriteria from './TargetCriteria';

export default class ChannelQuota extends Component {

  static defaultProps = {
    allowCreate: false,
    isDeletable: false,
  };

  constructor(props) {
    super(props);

    this.state = Object.assign({}, this.getDefaultValues(), props.values || {});
  }

  getDefaultValues() {
    return {
      required_count: 1,
      buyer_quota_id: '',
      criteria: []
    };
  }

  addCriteria = (criteria) => {
    this.updateCriteria('push', criteria);
  }

  removeCriteria = (idx) => {
    this.updateCriteria('splice', idx, 1);
  }

  modifyCriteria = (idx, criteria) => {
    this.updateCriteria('splice', idx, 1, criteria);
  }

  updateCriteria(method, ...args) {
    const criteria = AbstractForm.cloneData(this.state.criteria);
    criteria[method](...args);

    this.updateState({ criteria });
  }

  onInputChange = (event) => {
    this.updateState({
      [event.target.name]: event.target.value
    });
  }

  addQuota = () => {
    this.props.onAdd(
      AbstractForm.cloneData(this.state)
    );

    this.updateState(this.getDefaultValues());
  }

  updateState(update) {
    this.setState(update, () => {
      if (!this.props.allowCreate) {
        this.props.onChange(AbstractForm.cloneData(this.state));
      }
    })
  }

  render() {
    return (
      <ListGroupItem className="p-1">
        <Card>
          <CardBody className="p-3">
            <div className="form-row">
              <div className="col-6">
                <div className="row form-group">
                  <label for="buyer_quota_id" className="text-uppercase col-12 col-sm-4 col-form-label">quota id<span className="text-danger">*</span></label>
                  <div className="col-12 col-sm-8">
                    <input
                      label="quota id" name="buyer_quota_id"
                      type="text" className="form-control"
                      value={this.state.buyer_quota_id}
                      onChange={this.onInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="row form-group">
                  <label for="required_count" className="text-uppercase col-12 col-sm-4 col-form-label">quota<span className="text-danger">*</span></label>
                  <div className="col-12 col-sm-8">
                    <input
                      label="quota" name="required_count" type="number"
                      className="form-control"
                      value={this.state.required_count}
                      onChange={this.onInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-row">
              <ListGroup style={{ width: '100%' }}>
                <TargetCriteria
                  questions={this.props.questions}
                  onAdd={this.addCriteria}
                  onDelete={this.removeCriteria}
                  onChange={this.modifyCriteria}
                  identifier="qualification_code"
                  allowCreate
                />
                {this.state.criteria.map((criteria, idx) =>
                  <TargetCriteria
                    idx={idx}
                    key={criteria.qualification_code}
                    criteria={criteria}
                    questions={this.props.questions}
                    onAdd={this.addCriteria}
                    onDelete={this.removeCriteria}
                    onChange={this.modifyCriteria}
                    identifier="qualification_code"
                    isDeletable
                  />
                )}
              </ListGroup>
            </div>
            {this.props.allowCreate &&
              <button type="button" className="btn btn-sm btn-default mt-2 pull-right" onClick={this.addQuota}>
                <i className="fa fa-plus" aria-hidden="true" /> Quota
              </button>
            }
            {this.props.isDeletable &&
              <button type="button" className="btn btn-sm btn-danger mt-2 pull-right" onClick={this.props.onDelete}>
                <i className="fa fa-trash-o" aria-hidden="true" /> Quota
              </button>
            }
          </CardBody>
        </Card>
      </ListGroupItem>
    );
  }
}
