import DefinitionsSelect from './DefinitionsSelect';

export default class IndustrySelect extends DefinitionsSelect {

  get definitionsPath() {
    return 'industries';
  }

  get valueProperty() {
    return 'industryId';
  }
}
