import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  CardFooter
} from 'reactstrap';
import { client, AbstractForm, ButtonSelect } from 'emi-frontend';
import PubSub from 'pubsub-js';

function maxDate(start) {
  const startDate = new Date(start || null);
  startDate.setMonth(startDate.getMonth() + 1);

  return startDate.toISOString().substring(0, 10);
}

export default class FraudExportModal extends AbstractForm {

  constructor(props) {
    super(props);

    this.state.loadingData = false;
  }

  getApiPath = () => {
    return '/redirect/fraud';
  }

  get modelName() {
    return 'fraud';
  }

  isNew() {
    return true;
  }

  componentDidMount() {
  }

  getDefaultValues() {
    return {
      start: '',
      end: '',
      status_filter: null
    };
  }

  valueChanged(name, value, isEdit) {
    if (name === 'status_filter') {
      const status = [].concat(value);
      return super.valueChanged(name, status.length === 0 ? null : status, isEdit);
    }

    return super.valueChanged(name, value, isEdit);
  }

  getFormData() {
    const extraParams = { ...super.getFormData(), user_id: this.context.user.id };
    if (this.props.partner_id) {
      Object.assign(extraParams, { partner_id: this.props.partner_id });
    }

    return { extraParams, sort: { id: false } };
  }

  successAction = (response) => {
    this.setState({ message: 'Background job created', success: true });
    PubSub.publish(`background_job_update`, false);
    // this.resetForm(this.props);
  }

  sendFormData = () => {
    return client.api(
      'GET',
      `${this.getApiPath()}?${decodeURIComponent($.param(this.getFormData()))}`
    );
  }

  getFormHeader() {
    return null;
  }

  getFormContent() {
    return (
      <React.Fragment>
        <div className="form-row">
          {this.getFormRow({
            name: 'start',
            label: 'start date',
            type: 'date',
            required: true,
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 6 }
          })}

          {this.getFormRow({
            name: 'end',
            label: 'end date',
            type: 'date',
            min: this.state.values.start || null,
            max: maxDate(this.state.values.start),
            required: true,
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 6 }
          })}
        </div>
        <div className="form-row">
          {this.getFormRow({
            name: 'status_filter',
            label: 'Status',
            component: ButtonSelect,
            options: {
              external_duplicate: 'External Duplicate',
              internal_duplicate: 'Internal Duplicate',
              ghost: 'Ghost complete',
              captcha: 'Captcha',
              manual: 'Manual removal',
              fraud: 'Fraud Block',
              insurvey: 'In Survey Fail',
              prescreen: 'Pre-screener term',
              blocklist: 'Blocklist',
              scoreblock: 'Score Block'
            },
            fieldWidth: { xs: 12, lg: 12 },
            labelWidth: { xs: 12, lg: 2 },
            valueWidth: { xs: 12, lg: 10 }
          })}
        </div>
      </React.Fragment>
    );
  }

  getActionButtons() {
    return (
      <CardFooter className="action-buttons">
        <Button type="submit" color="default" className="float-right action-buttons">
          <i className="fa fa-download" aria-hidden="true"></i>{' '}
          Export
        </Button>
      </CardFooter>
    );
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle}>Export Fraud Respondent Data</ModalHeader>
        <ModalBody>
          {super.render()}
        </ModalBody>
      </Modal>
    );
  }
}

FraudExportModal.contextTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
