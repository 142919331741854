import React from 'react';

export default function ValueRow({ value, id, index, seq, attributeCount, onMove, onDelete }) {
  return (
    <tr>
      <td className="align-middle">{value}</td>
      <td style={{ width: 100 }}>
        {attributeCount - 2 >= index &&
          <button type="button" className="btn btn-sm grid-button text-success" onClick={() => onMove('down', seq, id)}>
            <i className="fa fa-arrow-down" aria-hidden="true" />
          </button>
        }{' '}
        {index > 0 &&
          <button type="button" className="btn btn-sm grid-button text-success" onClick={() => onMove('up', seq, id)}>
            <i className="fa fa-arrow-up" aria-hidden="true" />
          </button>
        }
      </td>
      <td style={{ width: 74 }}>
        <button type="button" value="true" className="btn btn-sm btn-danger" onClick={() => onDelete(seq, id)}>Delete</button>
      </td>
    </tr>
  );
}