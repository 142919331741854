import { Filter } from 'emi-frontend';

export default class EsearchFilter extends Filter {

  constructor(props) {
    super(props);

    this.state.searchTitle = 'Search by Job Name, Project Manager, etc.';
  }

  get filterName() {
    return 'esearchFilter';
  }

  initialize() {
    this.setFilter();
  }

  setFilter(type) {
    this.props.onFilter({});
  }
}
