import React from 'react';
import { ReconciliationForm } from '../abstract';

export default class Reconciliation extends ReconciliationForm {
  getFormContent() {
    return (
      <div>
        <div className="alert alert-info" role="alert">
          You can upload XLSX file. 
          No header is needed in the first row. 
          The first column should contain the PARTNER IDs, the second one should contain the completion type:<br />
          <ul style={{ listStyleType: 'none', paddingLeft: 20 }}>
            <li><span className="font-weight-bold">1</span> - Success</li>
            <li><span className="font-weight-bold">2</span> - Overquota</li>
            <li><span className="font-weight-bold">3</span> - Disqualification</li>
            <li><span className="font-weight-bold">7</span> - Quality Termination</li>
          </ul><br />
          Then please don't forget to change the Status to COMPLETE on the first tab.<br />
        </div>
        {super.getFormContent()}
      </div>
    );
  }
}
