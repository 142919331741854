import { client, RemoteSelect } from 'emi-frontend';

export default class DefinitionsSelect extends RemoteSelect {

  get definitionsPath() {
    throw new Error('definitionsPath() method should be overwritten');
  }

  get valueProperty() {
    throw new Error('valueProperty() method should be overwritten');
  }

  get apiMethod() {
    return 'POST';
  }

  get apiPath() {
    return `/panelapi/${this.props.partnerId}/list/${this.definitionsPath}`;
  }

  loadDone = (response) => {
    if (Array.isArray(response.data) && !response.recordCount) {
      Object.assign(response, { recordCount: response.data.length });
    }

    this.setState(response);
  }

  loadData = () => {
    if (this.shouldLoadData()) {
      client.api(this.apiMethod, this.apiPath, this.getLoadParams())
        .done(this.loadDone)
        .fail(this.showError);
    }
  }

  findValue = (value) => {
    if (!value) {
      return value;
    }

    return this.state.data.find((element) => {
      return element[this.valueProperty] ? (element[this.valueProperty] === value) : (element === value);
    });
  }

  composeProps() {
    const { onValueChange, textOnly, partnerId, ...props } = this.props;
    const options = this.getOptions();

    return Object.assign(props, { options });
  }

  getSelectOption = (obj) => {
    return { value: obj[this.valueProperty], label: obj.description };
  }

  getOptionsFromArray() {
    return this.state.data.map(this.getSelectOption);
  }

  getSelectedOptionText() {
    return this.getArrayOptionText();
  }
}
