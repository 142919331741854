import { Grid } from 'emi-frontend';

export default class AttributeGrid extends Grid {

  get gridName() {
    return 'attributeGrid';
  }

  getApiPath = () => {
    return '/attribute';
  }

  getBasePath = () => {
    return '/attributes';
  }

  typeRenderer = ({ data, row }) => {
    switch (data) {
      case 'r': return 'Radio Button';
      case 'd': return 'Drop-down List';
      case 'c': return 'Checkbox';
      default: return null;
    }
  }

  onRowSelection = (rowData) => {
    if (this.props.rowSelector) {
      this.props.rowSelector(rowData.id);
      return;
    }

    this.context.history.push(`${this.getBasePath()}/${rowData.id}`);
  }

  getColumns() {
    return [{
      name: 'name',
      title: 'name',
      asc: true,
      width: 250
    }, {
      name: 'title',
      title: 'Question',
      width: 250
    }, {
      name: 'display_type',
      title: 'Display type',
      width: 100,
      renderer: this.typeRenderer
    }];
  }
}
