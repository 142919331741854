import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { AbstractQuotas, ClipboardButton } from '../abstract';
import Qualification from './Qualification';

const cantDelete = ['gender', 'age'];

export default class Qualifications extends AbstractQuotas {

  static defaultProps = {
    countryId: 0
  };

  setQuotaComponent() {
    this.components = { Quota: Qualification };
  }

  emptyFn = () => {}

  copyQualifications = () => {
    return this.props.quotas.and.slice();
  }

  renderContent() {
    const { quotas, country } = this.props;

    return (
      <ListGroup style={{ width: '100%' }} className="top-margin-10">
        {quotas.and.length > 0 &&
          <ListGroupItem className="prodege-target-criteria">
            <ClipboardButton
              title="Copy Qualifications"
              className="float-right"
              dataType="qualifications"
              writeClipboardFn={this.copyQualifications}
              readClipboardFn={this.emptyFn}
            />
          </ListGroupItem>
        }
        <Qualification
          questions={this.state.questions}
          country={country}
          onAdd={qualification => this.props.onUpdate(['project', 'qualifications', 'and'], 'push', qualification)}
          onDelete={this.emptyFn}
          onChange={this.emptyFn}
          identifier="question"
          allowCreate
        />
        {quotas.and.map((criteria, idx) => {
          const [type] = Object.keys(criteria);// Predicate
          const predicate = criteria[type];
          const { question } = predicate;

          return (
            <Qualification
              idx={idx}
              key={question}
              criteria={criteria}
              questions={this.state.questions}
              country={country}
              onAdd={this.emptyFn}
              onDelete={idx => this.props.onUpdate(['project', 'qualifications', 'and'], 'splice', idx, 1)}
              onChange={(idx, qualification) => this.props.onUpdate(['project', 'qualifications', 'and'], idx, qualification)}
              identifier="question"
              isDeletable={!cantDelete.includes(question)}
            />
          );
        })}
      </ListGroup>
    );
  }
}