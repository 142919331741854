import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Range extends Component {

  static unitMap = {
    311: 'Years',
    312: 'Months',
    321: 'USD',
    322: 'EUR',
    323: 'GBP',
    324: 'AUD'
  }

  static propTypes = {
    idx: PropTypes.number.isRequired,
    data: PropTypes.shape({
      from: PropTypes.number.isRequired,
      to: PropTypes.number.isRequired,
      units: PropTypes.number.isRequired
    }),
    readOnly: PropTypes.bool,
    onDelete: PropTypes.func.isRequired
  };

  static defaultProps = {
    readOnly: false
  };

  render() {
    return (
      <div className="Select-value">
        {!this.props.readOnly &&
          <span
            className="Select-value-icon" aria-hidden="true"
            onClick={this.props.onDelete}
          >×</span>
        }
        <span className="Select-value-label" role="option" aria-selected="true">
          {`${Range.unitMap[this.props.data.units]}: ${this.props.data.from} => ${this.props.data.to}`}
          <span className="Select-aria-only">&nbsp;</span>
        </span>
      </div>
    );
  }
}