import React, { Component } from 'react';
import UserFilter from './Filter';
import UserGrid from './Grid';
import { filterList } from 'emi-frontend';

const UserFilterList = filterList(
  UserFilter,
  [UserGrid]
);

class List extends Component {

  render() {
    return (
      <div className="row">
        <div className="col">
          <UserFilterList />
        </div>
      </div>
    );
  }
}

export default List;
