import React from 'react';
import { client, Grid, GridMobileBody, Button } from 'emi-frontend';
import { departments } from './constants';
import GridMobileRow from './GridMobileRow';
import SalesforceIcon from '../../lib/SalesforceIcon';
import HubspotIcon from '../../lib/HubspotIcon';
import { HubspotSyncModal } from '../hubspot';

export default class UserGrid extends Grid {

  get gridName() {
    return 'userGrid';
  }

  getApiPath = () => {
    return '/user';
  }

  getBasePath = () => {
    return '/users';
  }

  getInitialState(props) {
    return Object.assign(
      super.getInitialState(props),
      { hubspotModal: false, userId: 0 });
  }

  toggleHubspotModal = () => {
    this.setState({
      hubspotModal: !this.state.hubspotModal,
    });
  }

  showHubspotModal = (event, userId) => {
    event.stopPropagation();

    this.setState({
      hubspotModal: true,
      userId
    });
  }

  userStatusHandler = (userId, user) => {
    client
      .api('PUT', `${this.getApiPath()}/${userId}`, { active: !user.active })
      .done(() => this.reload());
  }

  SalesforceIcon({ data }) {
    return (
      <div style={{ width: '100%', padding: 2 }}>
        <SalesforceIcon color={data ? undefined : '#6c757d'} />
      </div>
    );
  }

  HubspotIcon = ({ data, row }) => {
    return (
      <div style={{ width: '100%', padding: 6 }} onClick={e => this.showHubspotModal(e, row.id)}>
        <HubspotIcon color={data ? '#ff7a59' : undefined } />
      </div>
    );
  }

  displayDepartments = (props) => props.data.map(department => departments[department]).join(', ');

  FormatName = ({ data, row }) => `${data} (${row.initials})`
  
  getColumns() {
    return [{
      name: 'id',
      dataField: 'id',
      title: 'ID',
      width: 55,
      exactWidth: true
    }, {
      name: 'name',
      title: 'Name',
      asc: true,
      width: 250,
      renderer: this.FormatName
    }, {
      name: 'department',
      title: 'Department',
      width: 150,
      renderer: this.displayDepartments
    }, {
      name: 'live',
      title: 'Live',
      width: 100
    }, {
      name: 'paused',
      title: 'Tech. diff',
      width: 100
    }, {
      name: 'closed',
      title: 'Closed',
      width: 100
    }, {
      name: 'hubspot_id',
      title: '',
      width: 50,
      exactWidth: true,
      renderer: this.HubspotIcon
    }, {
      name: 'salesforce_id',
      title: '',
      width: 50,
      exactWidth: true,
      renderer: this.SalesforceIcon
    }, {
      name: 'active',
      dataField: 'id',
      title: 'Status',
      width: 100,
      exactWidth: true,
      renderer: Button,
      params: {
        icon: (row) => row.active === true ? 'user' : 'user-o',
        className: 'left-border',
        btnClassName: (row) => (row.active === true ? 'text-success' : 'text-danger'),
        clickHandler: this.userStatusHandler,
      }
    }];
  }

  getMobileHeader() {
    return '';
  }

  getMobileBody() {
    return (
      <GridMobileBody
        RowClass={GridMobileRow}
        columns={this.state.columns}
        data={this.state.data}
        onClick={this.onRowClick}
        loading={this.state.loadingData}
      />
    );
  }

  getPagination() {
    return (
      <div>
        <HubspotSyncModal
          model="user"
          modelId={this.state.userId}
          isOpen={this.state.hubspotModal}
          toggle={this.toggleHubspotModal}
          onChange={this.reload}
        />
        {super.getPagination()}
      </div>
    );
  }
}
