import { Fragment, useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import {
  Alert, Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { client } from 'emi-frontend';
import ManagerStat from './ManagerStat';
import AssignmentGrid from './AssignmentGrid';
import TabSelector from './TabSelector';

const status = {
  'live': 'Live',
  'paused': 'Paused',
  'kickoff': 'Kickoff',
  'test': 'Test'
};

function List() {
  const [activeTab, setActiveTab] = useState(1);
  const [rm, setRm] = useState([]);
  const [associateRm, setAssociateRm] = useState([]);

  const loadStat = useCallback(() => {
    client
      .api('GET', '/research_manager/stat')
      .done((response) => {
        setRm(response.rm);
        setAssociateRm(response.associate_rm);
      });
  }, []);

  function toggleTab(tabId) {
    if (tabId !== activeTab) {
      setActiveTab(tabId);
    }
  }

  useEffect(() => {
    loadStat();
  }, [loadStat]);

  return (
    <Fragment>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classNames('cursor-pointer', { active: activeTab === 1 })}
            onClick={() => toggleTab(1) }
          >
            Stats
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames('cursor-pointer', { active: activeTab === 2 })}
            onClick={() => toggleTab(2) }
          >
            Assignment
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1} className="mt-3">
          {(!Array.isArray(rm) || !rm.length) ? (
            <Alert color="warning">No data</Alert>
          ) : (
            <Fragment>
              <ManagerStat id="rm" title="RM Project Status Summary" rows={rm} />
              <ManagerStat id="arm" title="Associate RM Project Status Summary" rows={associateRm} />
            </Fragment>
          )}
        </TabPane>
        <TabPane tabId={2} className="mt-3">
          <AssignmentGrid />
        </TabPane>
      </TabContent>
      <TabSelector selectTab={(tabId) => toggleTab(tabId)} />
    </Fragment>
  );
}

export default List;