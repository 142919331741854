import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import CoverageSettingForm from './SettingForm';

export default class CoverageSettingModal extends Component {

  getBodyContent() {
    return this.props.projects.map((projectId) => (
      <CoverageSettingForm key={projectId} id={projectId} />
    ));
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="xl">
        <ModalHeader toggle={this.props.toggle}>
          Coverage Settings
        </ModalHeader>
        <ModalBody>
          {this.getBodyContent()}
        </ModalBody>
      </Modal>
    );
  }
}
