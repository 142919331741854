import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { AbstractForm } from 'emi-frontend';

export default class ClickBalancingTemplateModal extends AbstractForm {

  constructor(props) {
    super(props);

    this.state.loadingData = false;
  }

  get modelName() {
    return 'template';
  }

  getDefaultValues() {
    return {
      name: ''
    };
  }

  getApiPath = () => {
    return '/click_balancing_template';
  }

  shouldLoadData() {
    return false;
  }

  isNew() {
    return true;
  }

  showDeleteAction() {
    return false;
  }

  getFormHeader() {}

  getFormData() {
    const { template } = this.props.values;

    return Object.assign(super.getFormData(), { template });
  }

  sendFormData = () => {
    return this.clientApi('POST', this.getApiPath(), this.getFormData());
  }

  successAction = () => {
    this.hideModal();
  }

  hideModal = () => {
    this.resetForm(this.props);
    this.props.toggle();
  }

  getFormContent() {
    return (
      <div className="form-row">
        {this.getFormRow({
          label: 'template name',
          name: 'name',
          required: true,
          labelWidth: { xs: 12, xl: 3 },
          valueWidth: { xs: 12, xl: 9 },
          fieldWidth: { xs: 12, xl: 11 }
        })}
      </div>
    );
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.hideModal} size="lg">
        <ModalHeader toggle={this.hideModal}>
          <span className="text-uppercase">Save as template</span>
        </ModalHeader>
        <ModalBody>
          {super.render()}
        </ModalBody>
      </Modal>
    );
  }
}
