import React from 'react';
import PropTypes from 'prop-types';
import { RemoteSelect } from 'emi-frontend';

export default class ContactSelect extends RemoteSelect {

  static propTypes = {
    ...RemoteSelect.propTypes,
    client: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['project', 'accounting'])
  };

  subscribeToMessage() {
    const message = this.props.type ? `contact.new.${this.props.type}` : 'contact.new';
    this.subscribe(message, this.loadData);
  }

  getApiPath() {
    return '/contact'
  }

  getLoadParams() {
    const { type: contact_type, client: client_id } = this.props;

    if (contact_type) {
      return { extraParams: { contact_type, client_id } };
    }

    return {};
  }

  getSelectedOptionText() {
    return this.getArrayOptionText();
  }

  getOptionText(row) {
    return `${row.first_name} ${row.last_name}`;
  }

  getOptionsFromArray() {
    return this.state.data.map((row) => {
      return { value: row.id, label: `${row.first_name} ${row.last_name}` };
    });
  }

  render() {
    if (!this.props.client) {
      return (
        <p className="text-danger">Select Client</p>
      );
    }

    return super.render();
  }
}
