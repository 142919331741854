import React from 'react';
// import { Row, Col } from 'reactstrap';
import LineItemForm from './LineItemForm';
import QuickbooksSelectMain from '../../lib/form/QuickbooksSelectMain';
import QuickbooksSelectSub from '../../lib/form/QuickbooksSelectSub';
import { formatCurrency, Grid } from 'emi-frontend'

export default class LineItemGrid extends Grid {

  getInitialState(props) {
    return Object.assign(super.getInitialState(props), {
      lineItemModal: false,
      lineItemId: 0
    });
  }

  get gridName() {
    return 'lineItemGrid';
  }

  getApiPath = () => {
    return '/projectlineitem';
  }

  getBasePath = () => {
    return '/jobs';
  }

  FormatCost = ({ data, row }) => {
    if (!data) {
      return '-';
    }

    return formatCurrency(data);
  }

  QuickbooksMainField = ({ data, row }) => {
    if (!data) {
      return data;
    }

    return (
      <QuickbooksSelectMain value={data} textOnly />
     );
  }

  QuickbooksSubField = ({ data, row }) => {
    if (!data) {
      return data;
    }

    return (
      <QuickbooksSelectSub value={data} textOnly />
     );
  }

  getColumns() {
    return [{
      name: 'cpi',
      title: 'cpi',
      width: 100,
      exactWidth: true,
      renderer: this.FormatCost
    }, {
      name: 'completed',
      title: 'completed',
      width: 150,
      exactWidth: true
    }, {
      name: 'quickbooks_main',
      title: 'QB Category',
      renderer: this.QuickbooksMainField,
      width: 100
    }, {
      name: 'quickbooks_sub',
      title: 'QB List',
      renderer: this.QuickbooksSubField,
      width: 100
    }, {
      name: 'description',
      title: 'description',
      width: 100
    }, {
      name: 'discount',
      title: 'discount',
      renderer: this.FormatCost,
      width: 150,
      exactWidth: true,
      align: 'end'
    }, {
      name: 'total',
      title: 'total',
      renderer: this.FormatCost,
      summarize: true,
      align: 'end',
      width: 150,
      exactWidth: true,
      className: 'grid-last-cell'
    }];
  }

  isRowSelectable = () => {
    return !this.props.readOnly;
  }

  onLineItemChanged = () => {
    this.setState({
      lineItemModal: false,
      lineItemId: 0
    }, this.reload);
  }

  getPagination() {
    return (
      <div>
        {
          !this.props.readOnly
            ? <LineItemForm
                id={this.state.lineItemId}
                isOpen={this.state.lineItemModal}
                toggle={this.toggleLineItemModal}
                firstItem={this.state.firstItem}
                values={{
                  project_id: this.props.extraParams.project_id
                }}
                connectorProject={this.props.connectorProject}
                onSuccess={this.onLineItemChanged}
                onDelete={this.onLineItemChanged}
                reload={this.reload}
              />
            : ''
        }

        {
          !this.props.readOnly
            ? (
              <div
                className="d-flex flex-row flex-nowrap grid-row content rounded job-add-partner"
                onClick={this.toggleLineItemModal}
              >
                <span className="text-success"><i className="fa fa-plus"></i> Add Item</span>
              </div>
            )
            : ''
        }
        {super.getPagination()}
      </div>
    );
  }

  toggleLineItemModal = (id) => {
    this.setState({
      lineItemModal: !this.state.lineItemModal,
      lineItemId: id || 0
    });
  }

  onRowSelection = (rowData) => {
    this.toggleLineItemModal(rowData.id);
  }

  getMobilColumns() {
    return Object.fromEntries(
      Object.entries(this.state.columns).filter(([prop]) => !prop.startsWith('quickbooks'))
    );
  }

  getMobileHeader() {
    return <this.components.Head columns={this.getMobilColumns()} onSort={this.onSort} />;
  }

  getMobileBody() {
    return <this.components.Body
      columns={this.getMobilColumns()}
      data={this.state.data}
      recordCount={this.state.recordCount}
      currentPage={this.state.currentPage}
      pageSize={this.state.pageSize}
      onClick={this.onRowClick}
      loading={this.state.loadingData}
      rowStyler={this.getRowStyler()}
      adjustTotal={this.props.adjustTotal}
      extraParams={this.state.extraParams}
    />;
  }
}
