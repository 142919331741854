import React from 'react';
import { Nav, TabContent, TabPane } from 'reactstrap';
import { ApiTabs, ClipboardProvider } from '../abstract';
import Project from './Project';
import Qualifications from './Qualifications';
import Quotas from './Quotas';
import IncludedExcludedUsers from './IncludedExcludedUsers';

export default class Disqo extends ApiTabs {

  hasProject() {
    return this.hasApiData() && Object.prototype.hasOwnProperty.call(this.state.api_data.project || {}, 'status');
  }

  getQualifications() {
    return this.hasApiData() ? this.state.api_data.project.qualifications : { and: [] };
  }

  getTabs() {
    const hasProject = this.hasProject();

    return (
      <Nav tabs>
        {this.getTab('Project', 1)}
        {this.getTab('Qualifications', 2)}
        {hasProject && this.getTab('Quotas', 3, 'quotas')}
        {hasProject && this.getTab('Include & Exclude Users', 4)}
      </Nav>
    );
  }

  getTabContent() {
    const hasProject = this.hasProject();
    const project = this.getProjectValues();

    return (
      <ClipboardProvider>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId={1}>
            <Project
              routeId={this.props.routeId}
              partnerId={this.props.partnerId}
              values={project}
              onUpdate={this.updateApiData}
            />
          </TabPane>
          <TabPane tabId={2}>
            <Qualifications
              routeId={this.props.routeId}
              partnerId={this.props.partnerId}
              partnerProjectId={this.state.api_data.project.id}
              quotas={this.getQualifications()}
              country={project.country}
              onUpdate={this.modifyApiData}
            />
          </TabPane>
          {hasProject &&
            <TabPane tabId={3}>
              <Quotas
                routeId={this.props.routeId}
                partnerId={this.props.partnerId}
                partnerProjectId={this.state.api_data.project.id}
                quotas={this.getQuotas()}
                country={project.country}
                onUpdate={this.updateApiData}
                ref={(c) => this.setComponentRef('quotas', c)}
              />
            </TabPane>
          }
          {hasProject &&
            <TabPane tabId={4}>
              <IncludedExcludedUsers
                routeId={this.props.routeId}
                partnerId={this.props.partnerId}
                partnerProjectId={this.state.api_data.project.id}
              />
            </TabPane>
          }
        </TabContent>
      </ClipboardProvider>
    );
  }
}
