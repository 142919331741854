import LiveGrid from './LiveGrid';

export default class PausedGrid extends LiveGrid {

  get statusUpdateModalId() {
    return 'paused-job-status';
  }

  getInitialState(props) {
    const initialState = super.getInitialState(props);
    Object.assign(initialState, {
      title: 'Paused Projects'
    });

    Object.assign(initialState.extraParams, { status: 'paused' });

    return initialState;
  }

  get gridName() {
    return 'pausedGrid';
  }
}
