import React from 'react';
import Grid from './Grid';
import { precisionRound, ProgressRing } from 'emi-frontend';

const dropoutRateLimit = 20;

export default class LiveGrid extends Grid {

  get showStatusUpdateModal() {
    return true;
  }

  get statusUpdateModalId() {
    return 'live-job-status';
  }

  getInitialState(props) {
    const initialState = super.getInitialState(props);
    Object.assign(initialState, {
      title: 'Live Projects',
      className: 'job-grid',
      preload: false
    });

    Object.assign(initialState.extraParams, { status: 'live' });

    return initialState;
  }

  get gridName() {
    return 'liveGrid';
  }

  updateProjectStatus = ({ id, group, ...update }) => {
    const gridMap = {
      closed: undefined,
      live: 0,
      paused: 1
    };

    this.updateProject(id, update, gridMap[update.status]);
  }

  dropoutRenderer(props) {
    if (props.data === null) {
      return '';
    }

    const text = `${props.data}%`;
    
    if (props.data > dropoutRateLimit) {
      return (<span className="text-danger">{text}</span>);
    }
    
    return text;
  }

  getColumns() {
    const columns = super.getColumns();
    columns.splice(2, 0, {
      name: 'progress',
      title: '',
      sortable: false,
      width: 60,
      exactWidth: true,
      renderer: ProgressRing,
      params: {
        size: 40,
        stroke: 4,
        showCompleted: true
      }
    });

    columns.splice(3, 0, {
      name: 'redirects',
      title: 'clicks',
      sortable: false,
      width: 80,
      exactWidth: true
    });

    return columns
      .concat([{
        name: 'incidence',
        title: 'IR',
        width: 60,
        exactWidth: true,
        renderer: (props) => props.data === null ? '' : `${props.data}%`
      }, {
        name: 'dropout',
        title: 'DR',
        width: 60,
        exactWidth: true,
        renderer: this.dropoutRenderer
      }, {
        name: 'loi',
        title: 'LOI',
        width: 70,
        exactWidth: true,
        renderer: (props) => props.data === null ? '' : `${precisionRound(props.data / 60, 2)}m`
      }, {
        name: 'loi_median',
        title: 'Median',
        width: 70,
        exactWidth: true,
        renderer: (props) => props.data === null ? '' : `${precisionRound(props.data / 60, 2)}m`
      }, {
        name: 'secure_callback',
        title: '',
        width: 30,
        exactWidth: true,
        renderer: (props) => props.data === false ? '' : <i className="text-success fa fa-shield" />
      }, {
        name: 'stop',
        dataField: 'id',
        title: '',
        sortable: false,
        width: 46,
        exactWidth: true,
        className: 'button-cell',
        renderer: this.statusButton,
        params: {
          icon: 'stop',
          status: 'closed',
          className: 'left-border',
          btnClassName: 'text-danger'
        }
      }, {
        name: 'play',
        dataField: 'id',
        title: '',
        sortable: false,
        width: 46,
        exactWidth: true,
        className: 'button-cell',
        renderer: this.statusButton,
        params: {
          icon: row => row.status === 'live' ? 'pause' : 'play',
          status: row => row.status === 'live' ? 'paused' : 'live',
          className: 'left-border'
        }
      }]);
  }
}
