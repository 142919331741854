import { ProdegeCountrySelect } from '../prodege';

export default class SurveyCategorySelect extends ProdegeCountrySelect {
  
  getApiPath() {
    return '/panelapi/purespectrum/categories';
  }

  getOptionsFromArray() {
    return this.state.data.map((row) => {
      return { value: row.code, label: row.category };
    });
  }
}
