import React from 'react';
import { Grid } from 'emi-frontend';

export default class AssignmentGrid extends Grid {

  get gridName() {
    return 'assignmentGrid';
  }

  getApiPath = () => {
    return '/assignment';
  }

  getBasePath = () => {
    return '/admin/assignment';
  }

  getColumns() {
    return [{
      name: 'id',
      dataField: 'id',
      title: 'ID',
      width: 100,
      exactWidth: true
    }, {
      name: 'name',
      title: 'Name',
      width: 250
    }, {
      name: 'sales_name',
      title: 'Sales',
      width: 160
    }, {
      name: 'cse_name',
      title: 'CSE',
      width: 160
    }, {
      name: 'rm_name',
      title: 'RM',
      width: 160
    }, {
      name: 'arm_name',
      title: 'Associate RM',
      width: 160
    }, {
      name: 'created',
      title: 'Created',
      width: 180,
      exactWidth: true,
      renderer: this.dateFormatter,
      asc: false
    }, {
      name: 'processed',
      title: 'Processed',
      width: 180,
      exactWidth: true,
      renderer: this.dateFormatter
    }];
  }
}
