import React from 'react';
import { Filter, NewButton,  } from 'emi-frontend';

export default class AttributeFilter extends Filter {

  constructor(props) {
    super(props);

    this.state.searchTitle = 'Search by Attribute name';
    this.state.exportMatchModal = false;
  }

  get filterName() {
    return 'attributeFilter';
  }

  getRightButtons() {
    return [(
      <NewButton key="newattribute" model="attribute" onClick={this.newAttribute} style={{ marginRight: 5 }} />
    )];
  }

  newAttribute = () => {
    this.context.history.replace('/attributes/0');
  }
}
