import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { client } from 'emi-frontend';
import AttributeQuota from './AttributeQuota';
import { ExportButton, filterDataProps, saveDataAsXlsx } from '../../../lib/export_data';

export default class AttributeGroup extends Component {

  static propTypes = {
    showMessage: PropTypes.func.isRequired,
    project_id: PropTypes.number.isRequired,
    project: PropTypes.object.isRequired,
    edit: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    nested: PropTypes.bool
  };

  static defaultProps = {
    nested: false
  };

  state = {
    data: []
  };

  showError = (error) => {
    const { success, message } = error.responseJSON || { success: false, message: error.responseText };

    this.props.showMessage(message, success);
  }

  loadData() {
    client.api('GET', '/project_quota', {
      sort: { 'attr_value_seq' : true },
      extraParams: {
        project_id: this.props.project_id,
        nested: this.props.nested
      }
    })
      .done(({ data }) => this.setState({ data }))
      .fail(this.showError);
  }

  exportAsXlsx() {
    client.api('GET', '/project_quota/export', { extraParams: {
      project_id: this.props.project_id,
      nested: this.props.nested
    }})
      .done(this.exportData)
      .fail(this.showError);
  }

  exportData = ({ data }) => {
    const baseHeaders = {
      target: 'Target',
      target_percentage: 'Target %',
      clicks: 'Clicks',
      completes: 'Completes',
      progress: 'Current %',
      remaining: 'Remaining',
      variance: 'Variance',
      status: 'Status'
    };
    const normalHeaders = {
      attribute_name: 'Attribute name',
      attribute_value: 'Attribute value',
    };

    // get nested attribute first row for header data
    const [{ attribute = {} }] = data;
    const headers = Object.assign(
      {},
      this.props.nested ? Object.fromEntries(Object.keys(attribute).map(key => [key, key])) : normalHeaders,
      baseHeaders
    );
    
    saveDataAsXlsx(
      this.props.nested ? AttributeGroup.mapNestedExportData(data) : filterDataProps(data, Object.keys(headers)),
      headers
    );
  }

  static mapNestedExportData(data) {
    return data.map(({ attribute, attribute_name, attribute_value, ...row }) => Object.assign(row, attribute));
  }

  mergeData(id, params) {
    const data = this.state.data.map(row => row.id === id ? Object.assign({}, row, params) : row);
    this.setState({ data });
  }

  saveAttributeQuota = (id, params) => {
    client.api('PUT', `/project_quota/${id}`, params)
      .done(({ data }) => {
        this.mergeData(id, params);
        this.props.onEdit(null, null);
      })
      .fail(this.showError);
  }

  getTableData() {
    return this.props.nested ? this.state.data : 
      Object.entries(
        this.state.data.reduce((acc, curr) => {
          const [[id]] = Object.entries(curr.attribute);
          if (!acc[id]) {
            acc[id] = [];
          }
          acc[id].push(curr);

          return acc;
        }, {})
      );
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    if (!this.state.data.length) {
      return null;
    }

    const {
      clickbalance_quota = 0,
      clickbalance_track = 'click'
    } = this.props.project;

    const [{ attribute }] = this.state.data;
    const attributes = Object.values(attribute).length;

    return (
      <div>
        <table className="table table-sm table-bordered">
          <thead>
            <tr className="text-center">
              <th scope="col" colSpan={attributes}>Name</th>
              <th scope="col" width="130">Target</th>
              <th scope="col" width="140">Target %</th>
              <th scope="col">Clicks</th>
              <th scope="col">Completes</th>
              <th scope="col">Current %</th>
              <th scope="col">Remaining</th>
              <th scope="col">Variance</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          {this.props.nested ? (
            <AttributeQuota
              data={this.state.data} nested
              clickbalance_quota={clickbalance_quota}
              clickbalance_track={clickbalance_track}
              edit={this.props.edit}
              onEdit={this.props.onEdit}
              onSave={this.saveAttributeQuota}
            />
          ) : (
            this.getTableData().map(([key, data]) =>
              <AttributeQuota
                key={key} data={data}
                clickbalance_quota={clickbalance_quota}
                clickbalance_track={clickbalance_track}
                edit={this.props.edit}
                onEdit={this.props.onEdit}
                onSave={this.saveAttributeQuota}
              />
            )
          )}
        </table>
        <ExportButton className="float-right mb-2" onClick={() => this.exportAsXlsx()} />
      </div>
    );
  }
}