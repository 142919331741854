import React from 'react';
import { Filter, NewButton } from 'emi-frontend';

export default class ProjectGroupFilter extends Filter {

  constructor(props) {
    super(props);

    this.state.searchTitle = 'Search by Group Name, Job Name';
  }

  get filterName() {
    return 'groupFilter';
  }

  getRightButtons() {
    return (
      <NewButton model="group" onClick={this.newProjectGroup} />
    );
  }

  newProjectGroup = () => {
    this.context.history.replace('/groups/0');
  }
}
