import { RemoteSelect } from 'emi-frontend';

export default class ClickBalancingTemplateSelect extends RemoteSelect {

  getApiPath() {
    return '/click_balancing_template';
  }

  getOptionsFromArray() {
    return this.state.data.map(({ id: value, name: label }) => {
      return { value, label };
    });
  }
}
