import JobKickoffGrid from '../job/KickoffGrid';

export default class KickOffGrid extends JobKickoffGrid {
  get gridName() {
    return 'esearchkickoffGrid';
  }

  getApiPath = () => {
    return '/esearch';
  }
}
