import React from 'react';
import ReactDOM from 'react-dom';

import './global';

// Styles
// Import Bootstrap
import 'bootstrap/dist/css/bootstrap.css';

// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';

// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';

// Import Custome styles
import './style.css';

import './script';

// International phone number
import 'intl-tel-input/build/css/intlTelInput.css';

import { AdminApp } from './application/site/App';
import { ApplicationRouter, PrivateRoute } from 'emi-frontend';

ReactDOM.render(
  <ApplicationRouter>
    <PrivateRoute component={AdminApp} />
  </ApplicationRouter>
  , document.getElementById('root'));
