import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { hasOwnProperty } from 'emi-frontend';
import { AbstractQuota, ClipboardButton } from '../abstract';
import TargetCriteria from './TargetCriteria';
import QuotaCalculationTypeSelect from './QuotaCalculationTypeSelect';

export default class Quota extends AbstractQuota {

  getDefaultValues(props) {
    return {
      name: '',
      quotaCalculationType: 1,
      completesRequired: 1,
      completesDelivered: 0,
      conditions: []
    };
  }

  resetValues() {
    return {
      name: '',
      quotaCalculationType: 1,
      completesRequired: 1,
      completesDelivered: 0,
      conditions: []
    };
  }

  getId = () => {
    return this.props.values.surveyQuotaId;
  }

  isUpdate() {
    return hasOwnProperty(this.props.values || {}, 'surveyQuotaId');
  }

  isNew() {
    return true;
  }

  showDeleteAction() {
    return false;
  }

  getTargetingCriteria() {
    const conditions = this.state.values.conditions.slice();

    return {
      conditions
    };
  }

  pasteQualifications = (conditions) => {
    this.cloneTargetCriteria(
      AbstractQuota.cloneData({ conditions })
    );
  }

  getFormData() {
    const quota = super.getFormData();

    return {
      surveyId: this.props.partnerProjectId,
      quotas: [ quota ]
    }
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            name: 'name',
            type: 'text',
            required: true,
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, sm: 2 },
            valueWidth: { xs: 12, sm: 10 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'quota',
            name: 'completesRequired',
            type: 'number',
            step: 1,
            min: 1,
            prefix: `${this.state.values.completesDelivered} /`,
            required: true,
            fieldWidth: { xs: 12, sm: 5 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'calculation type',
            name: 'quotaCalculationType',
            component: QuotaCalculationTypeSelect,
            partnerId: this.props.partnerId,
            required: true,
            isClearable: false,
            onChange: this.selectChangeHandler('quotaCalculationType'),
            fieldWidth: { xs: 12, sm: 7 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}
        </div>
        
        <div className="form-row">
          <ListGroup style={{ width: '100%' }}>
            {!this.isUpdate() &&
              <ListGroupItem className="prodege-target-criteria">
                <ClipboardButton
                  title="Paste Qualifications"
                  className="float-right"
                  dataType="qualifications" isCopy={false}
                  readClipboardFn={this.pasteQualifications}
                />
              </ListGroupItem>
            }
            <TargetCriteria
              questions={this.props.questions}
              onAdd={this.addTargetCriteria}
              onDelete={this.removeTargetCriteria}
              onChange={this.modifyTargetCriteria}
              identifier="qualificationId"
              allowCreate
            />
            {this.state.values.conditions.map((criteria, idx) =>
              <TargetCriteria
                idx={idx}
                key={criteria.qualificationId}
                criteria={criteria}
                questions={this.props.questions}
                onAdd={this.addTargetCriteria}
                onDelete={this.removeTargetCriteria}
                onChange={this.modifyTargetCriteria}
                identifier="qualificationId"
                isDeletable
              />
            )}
          </ListGroup>
        </div>
      </div>
    );
  }
}