import React from 'react';
import { Button, ButtonGroup, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { RemoteSelect } from 'emi-frontend';

const visibleTags = 2;

class TargetingTagSelect extends RemoteSelect {
  getApiPath() {
    return '/tag';
  }

  generateOptions() {
    return this.state.data.map(({ id: value, tag_name: label}) => ({ value, label }));
  }
}

export default class ProjectTag extends RemoteSelect {

  getApiPath() {
    return '/project_tag';
  }

  getLoadParams() {
    return {
      sort: {
        tag_name: true
      },
      extraParams: {
        project_id: this.props.project_id
      }
    };
  }

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  onTagSelectChange = (tag) => {
    this.setState({
      tag
    });
  }

  addProjectTag(tag) {
    const data = this.state.data.slice();
    data.push(tag);

    this.setState({ data, tag: undefined });
  }

  removeProjectTag(tag_id) {
    const data = this.state.data.filter(tag => tag.tag_id != tag_id);
    this.setState({ data });
  }

  saveTag = () => {
    const { classification, id: tag_id, tag_name } = this.state.tag;
    this.toggleModal();
    this.clientApi('POST', this.getApiPath(), {
      project_id: this.props.project_id,
      tag_id
    })
      .done(({ data }) => this.addProjectTag({ ...data, classification, tag_name }))
      .fail(error => console.error(error));
  }

  deleteTag = (event) => {
    const { value: tag_id } = event.currentTarget;

    this.clientApi('DELETE', `${this.getApiPath()}/${this.props.project_id}/${tag_id}`)
      .done(() => this.removeProjectTag(tag_id))
      .fail(error => console.error(error));
  }

  render () {
    const showTags = this.state.data.slice(0, visibleTags);
    const restTags = this.state.data.slice(visibleTags);

    return (
      <div className="float-right">
        <button type="button" className="btn btn-primary btn-sm mr-2" onClick={this.toggleModal}>
          <i className="fa fa-plus mr-1" aria-hidden="true" />
          <i className="fa fa-tags" aria-hidden="true" />
        </button>
        {showTags.map(tag =>
          <ButtonGroup key={tag.tag_id} size="sm" className="mr-2">
            <Button color="primary" type="button">{tag.tag_name}</Button>
            <Button color="primary" type="button" value={tag.tag_id} onClick={this.deleteTag}>
              <i className="fa fa-times text-danger" aria-hidden="true"/>
            </Button>
          </ButtonGroup>
        )}
        {restTags.length > 0 &&
          <div className="btn-group mr-2">
            <button className="btn btn-primary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
              <span className="badge badge-light">{restTags.length}</span> More tag
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              {restTags.map(tag =>
                <button key={tag.tag_id} className="dropdown-item text-right" value={tag.tag_id} type="button" onClick={this.deleteTag}>
                  {tag.tag_name}
                  <i className="fa fa-times text-danger ml-2" aria-hidden="true"/>
                </button>
              )}
            </div>
        </div>
        }
        <Modal isOpen={this.state.isOpen === true} toggle={this.toggleModal}>
          <ModalHeader toggle={this.toggleModal}>Add project tag</ModalHeader>
          <ModalBody>
            <Row>
              <Col sm="10">
                <TargetingTagSelect
                  onValueChange={this.onTagSelectChange}
                  value={this.state.tag ? this.state.tag.id : ''}
                />
              </Col>
              <Col sm="2">
                <Button color="success" type="button" onClick={this.saveTag} disabled={!this.state.tag} >
                  <i className="fa fa-save" aria-hidden="true" />
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
