import Grid from './Grid';
import { client } from 'emi-frontend';

export default class ArchivedGrid extends Grid {

  getInitialState(props) {
    const initialState = super.getInitialState(props);
    Object.assign(initialState, {
      title: 'Archived Projects',
    });

    Object.assign(initialState.extraParams, { status: 'archived' });

    return initialState;
  }

  get gridName() {
    return 'archivedGrid';
  }

  restoreProject = (projectId) => {
    return client
      .api('GET', `${this.getApiPath()}/${projectId}/restore`)
      .done(() => {
        this.reload();
        this.props.onDataChanged(0);
      })
      .fail(this.showError);
  }

  getColumns() {
    return super
      .getColumns()
      .slice(1)
      .concat({
        name: 'archived',
        title: 'Archived',
        width: 120
      }, {
        name: 'restore',
        dataField: 'id',
        title: '',
        sortable: false,
        width: 40,
        exactWidth: true,
        className: 'button-cell',
        renderer: this.gridButtonRenderer,
        params: {
          icon: 'undo',
          className: 'left-border',
          clickHandler: this.restoreProject
        }
      });
  }
}
