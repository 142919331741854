import React from 'react';
import { AbstractForm, ButtonSelect } from 'emi-frontend';
import { CreatableReactSelect } from '../abstract';

export default class ProjectSettings extends AbstractForm {

  get modelName() {
    return 'settings';
  }

  getDefaultValues(props) {
    return {
      prodege_project_id: this.parseInt(props.prodegeProjectId),
      enable_max_clicks: false,
      enable_max_clicks_allowed_clicks: Math.ceil(props.routeQuota * 0.2),
      enable_max_clicks_max_click_rate_id: 0,
      limit_ids: [],
      filter_type: '',
      limit_by: ''
    };
  }

  getApiPath = () => {
    return '/panelapi';
  }

  getId = () => {
    return this.parseInt(this.props.prodegeProjectId);
  }

  loadFormData() {
    const id = this.parseInt(this.props.routeId);
    const params = { prodege_project_id: this.getId() };

    this.setState({
      loadingData: true
    }, () =>
      this.clientApi('POST', `${this.getApiPath()}/${id}/project/settings`, params)
        .done((resp) => this.loadDone(resp))
        .fail(this.loadFail)
    );
  }

  convertResponse(data) {
    const { limit_panelists = {}, ...baseData } = data;
    return { ...baseData, ...limit_panelists };
  }

  isNew() {
    return true;
  }

  showDeleteAction() {
    return false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.prodegeProjectId !== nextProps.prodegeProjectId) {
      const values = Object.assign({}, this.getDefaultValues(nextProps));
      this.setState({ values });
    }
  }

  getFormHeader() {
    return null;
  }

  getFormData() {
    const { limit_ids, filter_type, limit_by, ...data } = super.getFormData();
    return limit_by && filter_type ? { ...data, limit_panelists: { filter_type, limit_by, limit_ids }} : { ...data };
  }

  sendFormData = () => {
    return this.clientApi(
      'PUT', `${this.getApiPath()}/${this.props.routeId}/project/settings`, this.getFormData()
    );
  }

  successAction = (response) => {
    this.setState({ message: 'Data updated successfully' });
    // this.props.onUpdate(response.data, this.getId() === 0);
  }

  onCreateLimitIds = (value) => {
    const ids = [...this.state.values.limit_ids];
    const separator = new RegExp(/\s*,\s*/, 'g');
    if (separator.test(value)) {
      Array.prototype.push.apply(ids, value.split(separator));
    } else {
      ids.push(value);
    }

    this.updateLimitIds(
      Array.from(new Set(ids)).filter(element => element !== '')
    );
  }

  limitIdsChangeHandler = (option) => {
    this.updateLimitIds(
      Array.isArray(option) ? option.map(selected => selected.value) : []
    );
  }

  updateLimitIds(ids) {
    const values = this.valueChanged('limit_ids', ids, false);

    this.updateValues(values, 'values');
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            name: 'enable_max_clicks',
            label: 'Enable rate limit',
            required: true,
            component: ButtonSelect,
            options: { true: 'Yes', false: 'No' },
            fieldWidth: { xs: 8 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'Max clicks in batch',
            name: 'enable_max_clicks_allowed_clicks',
            type: 'number',
            min: 0,
            step: 1,
            required: true,
            fieldWidth: { xs: 8 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}

        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'Refill time',
            name: 'enable_max_clicks_max_click_rate_id',
            type: 'select',
            fieldWidth: { xs: 8 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 },
            children: [
              <option key="0" value="0">Default</option>,
              <option key="1" value="1">Every 30 minutes</option>,
              <option key="2" value="2">Every hour</option>,
              <option key="3" value="3">Every 24 hrs.</option>
            ]
          })}
        </div>

        <div className="form-row">
          <div className="col-8 text-center font-weight-bold">
            Include / Exclude panelists
          </div>
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'filter type',
            name: 'filter_type',
            type: 'select',
            fieldWidth: { xs: 8 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 },
            children: [
              <option key="none" value="">Select type...</option>,
              <option key="1" value="1">Include</option>,
              <option key="2" value="2">Exclude</option>
            ]
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'identified by',
            name: 'limit_by',
            type: 'select',
            fieldWidth: { xs: 8 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 },
            children: [
              <option key="none" value="">Select...</option>,
              <option key="panelist_id" value="panelist_id">panelist_id</option>,
              <option key="transaction_id" value="transaction_id">transaction_id</option>
            ]
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'IDs',
            name: 'limit_ids',
            component: CreatableReactSelect,
            options: this.state.values.limit_ids.map(value => ({ value, label: value })),
            isMulti: true,
            placeholder: 'Define ids...',
            onChange: this.limitIdsChangeHandler,
            onCreateOption: this.onCreateLimitIds,
            fieldWidth: { xs: 8 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 },
          })}
        </div>

      </div>
    );
  }
}
