import React from 'react';
import { RemoteSelect } from 'emi-frontend';

export default class PanelapiSelect extends RemoteSelect {

  getApiPath() {
    return '/panelapi/0/panelapis'
  }

  getFirstOption() {
    return (
      <option key="_" value="">Select Panel API...</option>
    );
  }

  generateOptions() {
    return this.getOptionsFromObject();
  }

  getOptionsFromObject() {
    return Object.keys(this.state.data).map((api) => {
      return { value: api, label: this.state.data[api].name };
    });
  }

  getSelectedOptionText() {
    return this.getObjectOptionText();
  }

  getObjectOptionText() {
    if (!this.state.recordCount || !this.props.value || (Array.isArray(this.props.value) && this.props.value.length === 0)) {
      return this.noOptionSelected();
    }

    if (this.isMulti() && this.state.recordCount) {
      return this.props.value.map(v => this.state.data[v].name).join(', ');
    }

    return this.state.data[this.props.value].name || this.noOptionSelected();
  }

  getLoadParams() {
    return {
      sort: {
        name: true
      }
    };
  }

  composeProps() {
    const props = super.composeProps();
    // Select all Panels by default
    if (props.value === undefined && this.state.recordCount) {
      props.value = Object.keys(this.state.data);
    }
    return props;
  }
}
