import React from 'react';
import { hasOwnProperty } from 'emi-frontend';
import { LoadServerData } from '../abstract';
import LineItem from './LineItem';

export default class LineItemDetailedReport extends LoadServerData {
  constructor(props) {
    super(props, 'report', {});

    if (!LineItemDetailedReport.exists(props)) {
      this.state.loadingData = false;
    }
  }

  static exists(props) {
    return hasOwnProperty(props.values || {}, 'state');
  }

  shouldLoadData() {
    return LineItemDetailedReport.exists(this.props);
  }

  getQuotaApiPath() {
    return `/panelapi/${this.props.routeId}/quota`;
  }

  getRefreshQuotaApiPath() {
    return this.resolveApiPath(this.getQuotaApiPath(), 'refresh');
  }

  getApiUrl() {
    return this.resolveApiPath(this.getQuotaApiPath(), 'stats');
  }

  getApiMethod() {
    return 'POST';
  }

  reloadData = () => {
    this.setState({
      loadingData: true
    }, () =>
      this.clientApi(this.getApiMethod(), this.getRefreshQuotaApiPath(), this.getApiData())
        .done(resp => {
          this.setState({ loadingData: false });
          this.props.onUpdate(resp.data);
        })
        .fail(this.showError)
    );
  }

  getApiData() {
    return {
      extProjectId: this.props.partnerProjectId,
      extLineItemId: this.props.values.extLineItemId
    };
  }

  renderContent() {
    return (
      <LineItem
        {...this.props}
        stats={this.state.report.stats || {}}
      />
    );
  }
}
