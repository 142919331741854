import React, { Component } from 'react';
import ClosedFilter from './ClosedFilter';
import ClosedGrid from './ClosedGrid';
import ArchivedGrid from './ArchivedGrid';
import { filterList } from 'emi-frontend';

const ProjectFilterList = filterList(
  ClosedFilter,
  [ClosedGrid, ArchivedGrid]
);

export default class ClosedList extends Component {

  render() {
    return (
      <div className="row">
        <div className="col">
          <ProjectFilterList ref={(c) => this.filterList = c}/>
          <br /><br />
        </div>
      </div>
    );
  }
}
