import React, { useEffect, useState } from 'react';
import {
  Modal, Alert,
  ModalHeader,
  ModalBody, Button
} from 'reactstrap';
import { client } from 'emi-frontend'; 

import { formatCurrency } from '../../lib/utils';

export default function CreateJobsModal({ bidId, isOpen, toggle, onCreateJob }) {
  const [projects, setProjects] = useState([]);
  const [countries, setCountries] = useState({});

  useEffect(() => {
    client
      .api('GET', `/assignment/${bidId}/job`)
      .done((response) => {
        setProjects(response.data);
      });
  }, [bidId]);

  useEffect(() => {
    client
      .api('GET', `/country`)
      .done((response) => {
        setCountries(response.data);
      });
  }, []);

  function createJobs() {
    client.api('PUT', `/assignment/${bidId}/job`)
      .done(() => {
        toggle();
        onCreateJob(true);
      })
      .fail(() => {
        toggle();
        onCreateJob(false)
      });
  }

  function getContent() {
    if (projects.length === 0 ) {
      return 'No quota found in the Bid';
    }

    return (
      <>
        <Alert color="warning">
          Please note only Invoice / Multi-view page is created right now.
        </Alert>
        <table className="table table-bordered table-sm">
          <thead>
            <tr>
              <th>Projects</th>
              <th>N</th>
              <th>CPI</th>
              <th>IR</th>
              <th>LOI</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project, idx) => (
              <>
                <tr idx={`pr_${idx}_main`}>
                  <th>{project.description} ({countries[project.country]})</th>
                  <td align='right'>{project.n}</td>
                  <td align='right'>{formatCurrency(project.cpi)}</td>
                  <td align='right'>{project.ir}%</td>
                  <td align='right'>{project.loi} mins</td>
                </tr>
                {project.partners.map((partner, pidx) => (
                  <tr idx={`pr_${idx}_${pidx}`}>
                    <td style={{ paddingLeft: 20 }}>{partner.name}</td>
                    <td align='right'>{partner.n}</td>
                    <td align='right'>{formatCurrency(partner.cpi)}</td>
                    <td colSpan={2}></td>
                  </tr>
                ))}
              </>
            ))}
          </tbody>
        </table>
        <Button 
          type="button" className="project-button"
          color="primary"
          onClick={() => createJobs()}
        >
          Create Invoice 
        </Button>
      </>
    );

  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        Create SWIFT Jobs - IO #{bidId}
      </ModalHeader>
      <ModalBody>
        {getContent()}
      </ModalBody>
    </Modal>
  );
}
