import React from 'react';
import { ListGroup, ListGroupItem, CardHeader } from 'reactstrap';
import { AbstractForm } from 'emi-frontend';
import ChannelQualification from './ChannelQualification';
import ChannelQuota from './ChannelQuota';

export default class Channel extends AbstractForm {

  constructor(props) {
    super(props);

    this.state.loadingData = false;
  }

  getApiPath = () => {
    return `/panelapi/${this.props.routeId}/channels`;
  }

  get modelName() {
    return 'channel';
  }

  isNew() {
    return true;
  }

  shouldLoadData() {
    return true;
  }

  loadFormData() {
    this.setState({
      loadingData: true
    }, () =>
      this.clientApi('GET', `/panelapi/${this.props.partnerId}/questions/${this.props.countryId}`)
        .done((resp) => this.loadDone(resp))
        .fail(this.loadFail)
    );
  }

  loadDone = (response) => {
    this.setState({
      loadingData: false,
      questions: response.data 
    });
  }

  getDefaultValues() {
    return {
      ps_survey_id: this.props.partnerProjectId,
      survey_title: '',
      type: '',
      psids: [],
      psids_str: '',
      qualifications: [],
      quotas: []
    };
  }

  valueChanged(name, value, isEdit) {
    const changed = super.valueChanged(name, value, isEdit);
    if (name === 'psids_str') {
      changed.psids = value.split(',').map(str => str.trim());
    }
  
    return changed;
  }

  successAction = (response) => {
    this.resetForm(this.props);
    this.setState({
      message: response.data.message
    });
    this.props.onSuccess();
  }

  sendFormData = () => {
    return this.clientApi('PUT', this.getApiPath(), this.getFormData());
  }

  getFormHeader() {
    return (
      <CardHeader className="mt-3">
        <h5>
          <span className="text-uppercase">new channel</span>
        </h5>
      </CardHeader>
    );
  }

  emptyFn = () => {}

  modifyQualifications = (method, ...args) => {
    const qualifications = AbstractForm.cloneData(this.state.values.qualifications);
    qualifications[method](...args);

    this.updateValues({ qualifications });
  }

  modifyQuotas = (method, ...args) => {
    const quotas = AbstractForm.cloneData(this.state.values.quotas);
    quotas[method](...args);

    this.updateValues({ quotas });
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            label: 'title',
            name: 'survey_title',
            required: true,
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'type',
            name: 'type',
            type: 'select',
            required: true,
            children: [
              <option key="SEL" value="">-- Select --</option>,
              <option key="REP" value="REP">Replica</option>,
              <option key="TL" value="TL">Targeted List</option>,
              <option key="SPA" value="SPA">Specific Audience</option>,
            ],
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 5 }
          })}
        </div>

        {this.state.values.type === 'TL' &&
          <div className="form-row">
            {this.getFormRow({
              label: 'PureSpectrum IDs',
              name: 'psids_str',
              type: 'textarea',
              required: true,
              labelWidth: { xs: 12, sm: 3 },
              valueWidth: { xs: 12, sm: 9 }
            })}
          </div>
        }

        {this.state.values.type === 'SPA' &&
          <ListGroup style={{ width: '100%' }} className="my-3">
            <ListGroupItem className="font-weight-bold">Qualifications</ListGroupItem>
            <ChannelQualification
              questions={this.state.questions}
              onAdd={qualification => this.modifyQualifications('push', qualification)}
              onDelete={this.emptyFn}
              onChange={this.emptyFn}
              identifier="qualification_code"
              allowCreate
            />
          {this.state.values.qualifications.map((qualification, key) =>
            <ChannelQualification
              idx={key}
              key={qualification.qualification_code}
              criteria={qualification}
              questions={this.state.questions}
              onAdd={this.emptyFn}
              onDelete={() => this.modifyQualifications('splice', key, 1)}
              onChange={(idx, criteria) => this.modifyQualifications('splice', idx, 1, criteria)}
              identifier="qualification_code"
              isDeletable
            />
          )}
          </ListGroup>
        }
        {this.state.values.type === 'SPA' &&
          <ListGroup style={{ width: '100%' }} className="mb-3">
            <ListGroupItem className="font-weight-bold">Quotas</ListGroupItem>
            <ChannelQuota
              questions={this.state.questions}
              onAdd={quota => this.modifyQuotas('push', quota)}
              onDelete={this.emptyFn}
              onChange={this.emptyFn}
              allowCreate
            />
            {this.state.values.quotas.map((quota, key) =>
              <ChannelQuota
                idx={key}
                key={quota.buyer_quota_id}
                values={quota}
                questions={this.state.questions}
                onAdd={this.emptyFn}
                onDelete={() => this.modifyQuotas('splice', key, 1)}
                onChange={quota => this.modifyQuotas('splice', key, 1, quota)}
                isDeletable
              />
            )}
          </ListGroup>
        }
      </div>
    );
  }
}
