import React from 'react';
import PropTypes from 'prop-types';
import {
  Nav, NavItem, NavLink, TabContent
} from 'reactstrap';
import classnames from 'classnames';
import LoadServerData from './LoadServerData';
import { AbstractForm } from 'emi-frontend';

export default class ApiTabs extends LoadServerData {
  constructor(props) {
    super(props, 'api_data');

    this.state.activeTab = 1;
  }

  static propTypes = {
    routeId: PropTypes.number.isRequired,
    partnerId: PropTypes.number.isRequired,
    projectId: PropTypes.number.isRequired,
    routeQuota: PropTypes.number
  };

  getApiUrl() {
    return `/panelapi/${this.props.routeId}`;
  }

  toggleTab = (activeTab) => {
    if (this.state.activeTab !== activeTab) {
      this.setState({ activeTab });
    }
  }

  hasApiData() {
    return this.state.api_data !== null;
  }

  assingApiData = (prop, values) => {
    const api_data = AbstractForm.cloneData(this.state.api_data);
    Object.assign(api_data[prop], values);

    this.setState({ api_data });
  }

  modifyApiData = (path, prop, ...args) => {
    const api_data = AbstractForm.cloneData(this.state.api_data);
    const target = AbstractForm.findTarget(api_data, path);

    if (typeof target[prop] === 'function') {
      target[prop](...args);
    } else {
      target[prop] = args.shift();
    }

    this.setState({ api_data });
  }

  updateApiData = (api_data, isCreateProject = false) => {
    const state = Object.assign({ api_data }, isCreateProject ? { activeTab: 2 } : null);
    this.setState(state)
  }

  getProjectValues() {
    return Object.assign({}, this.state.api_data.project);
  }

  getQuotas() {
    return Object.assign({}, this.state.api_data.quotas);
  }

  getTab(title, idx, refreshProp) {
    return (
      <NavItem>
        <NavLink
          className={classnames({ active: this.state.activeTab === idx })}
          onClick={() => this.toggleTab(idx)}
        >
          {title}
          {refreshProp !== undefined &&
            <button
              type="button" className="close tab-refresh-btn"
              aria-label="Refresh" title="refresh"
              onClick={() => this.reloadTabData(refreshProp)}
            >
              <i className="fa fa-refresh" aria-hidden="true"></i>
            </button>
          }
        </NavLink>
      </NavItem>
    );
  }

  reloadTabData = (prop) => {
    if (this[prop] && typeof this[prop].reloadData === 'function') {
      this[prop].reloadData();
    }
  }

  setComponentRef = (prop, component) => {
    this[prop] = component;
  }

  getTabs() {
    return <Nav tabs></Nav>;
  }

  getTabContent() {
    return <TabContent activeTab={this.state.activeTab}></TabContent>;
  }

  renderContent() {
    return (
      <div>
        {this.getTabs()}
        {this.getTabContent()}
      </div>
    );
  }
}
