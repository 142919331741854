import React from 'react';
import classNames from 'classnames';
import { GridMobileRow, formatCurrency, composeStartUrl, CopyClipboard, Button } from 'emi-frontend';
import TargetQuotaCell from './TargetQuotaCell';

export default class Row extends GridMobileRow {

  getProgressClass() {
    return {
      incomplete: this.props.row.progress < 100,
      complete: this.props.row.progress >= 100
    }
  }

  getProgress() {
    return (
      <div key={`progress${this.props.row.id}`} className="grid-progress">
        <div
          className={classNames(this.getProgressClass())} 
          style={{ width: `${this.props.row.progress || 0}%`}}
        />
      </div>
    );
  }

  getMainContent() {
    const params = {
      project_id: this.props.extraParams.project_id,
      partner_id: this.props.summary ? undefined : this.props.row.partner_id 
    };

    const content = [(
      <div key={`row1-${this.props.row.id}`} className="d-flex grid-row align-items-center route-mobile-cell">
        <div className="grid-cell" style={{width: 100}}>{this.props.row.partner_name}</div>
        <div className="grid-cell" style={{width: 50, textAlign: 'right'}}>
          <a href="#" onClick={(e) => this.props.modalFnc(e, Object.assign({}, params, {status: 'test'}))}>{this.props.row.test}</a>
        </div>
        <div className="grid-cell" style={{width: 50, textAlign: 'right'}}>
          <a href="#" onClick={(e) => this.props.modalFnc(e, Object.assign({}, params, {status: 'completed'}))}>{this.props.row.completed}</a>
        </div>
        <div className="grid-cell" style={{width: 100, textAlign: 'center'}}>
          <span>{this.props.row.quota}</span>
        </div>
        <div className="grid-cell" style={{width: 70, textAlign: 'right'}}>{formatCurrency(this.props.row.cost)}</div>
      </div>)];

    if (this.isSummaryRow()) {
      content.push((
        <div key={`row2-${this.props.row.id}`} className="d-flex grid-row align-items-center route-mobile-cell" >
          <div className="grid-cell">
            <TargetQuotaCell
              row={this.props.row}
              data={this.props.row.quota}
              column={this.props.columns.quota}
              {...this.props.columns.quota.params}
              summary={this.props.summary}
            />
          </div>
        </div>));
    }
    
    return content;
  }

  getData() {
    return (
      <div key={`cont${this.props.row.id}`} className="grid-row-cell d-flex">
        {this.getMainContent()}
      </div>
    );
  }

  copyButton() {
    return <CopyClipboard
      icon="clipboard"
      getText={() =>
        composeStartUrl(this.props.row.survey_url, this.props.row.variables, this.props.row.fixed_variables)
      }
    />;
  }

  routeLineItemButton() {
    return <Button
      className="btn grid-button"
      icon="usd"
      clickHandler={() => this.props.columns.custom_cost.params.costHandler(this.props.row.partner_id, this.props.row.id)} 
    />;
  }

  downloadButton() {
    return <Button
      className="btn grid-button"
      icon="download"
      clickHandler={() => this.props.columns.export.params.mobileExportHandler(this.props.row.partner_id)} 
    />;
  }

  apiButton() {
    if (!this.props.row.use_panel_api) {
      return null;
    }

    const params = {
      partnerId: this.props.row.partner_id,
      panelApi: this.props.row.panel_api,
      apiRouteId: this.props.row.id,
      routeQuota: this.props.row.quota
    };

    return <Button
      className="btn grid-button"
      icon="random"
      clickHandler={() => this.props.columns.use_panel_api.params.apiHandler(params)} 
    />;
  }

  getButtons() {
    return (
      <div key={`btn${this.props.row.id}`} className="grid-row-cell d-flex">
        <div className="d-flex grid-row align-items-center">
          <div className="grid-cell" style={{width: '25%'}}>
            {this.copyButton()}
          </div>
          <div className="grid-cell" style={{width: '25%'}}>
            {this.routeLineItemButton()}
          </div>
          <div className="grid-cell" style={{width: '25%'}}>
          {this.downloadButton()}
          </div>
          <div className="grid-cell" style={{width: '25%'}}>
          {this.apiButton()}
          </div>
        </div>
      </div>
    );
  }

  getContent() {
    return this.isSummaryRow() ? this.getMainContent() : [
      this.getProgress(),
      this.getData(),
      this.getButtons()
    ];
  }
}
