import React, { Component } from 'react';
import ClientFilter from './Filter';
import ClientGrid from './Grid';
import FeedbackGrid from './FeedbackGrid';
import FeedbackForm from './FeedbackForm';
import { HubspotSyncModal } from '../hubspot';
/*
import { filterList } from 'emi-frontend';
const ClientFilterList = filterList(
  ClientFilter,
  [ClientGrid]
);
<ClientFilterList />
*/
class List extends Component {

  constructor(props) {
    super(props);

    this.grids = [];
    this.state = {
      feedbackModal: false,
      feedbackId: null,
      hubspotModal: false,
      model: 'client',
      modelId: 0,
      create: false,
    };
  }

  toggleFeedbackModal = () => {
    this.setState({
      feedbackId: null,
      feedbackModal: !this.state.feedbackModal
    });
  }

  toggleHubspotModal = () => {
    this.setState({
      hubspotModal: !this.state.hubspotModal,
    });
  }

  showHubspotModal = (event, model, modelId, create = false) => {
    if (event) {
      event.stopPropagation();
    }

    this.setState({
      hubspotModal: true,
      model,
      modelId,
      create
    });
  }

  reloadGridData = (grid) => {
    if (this.grids[grid]) {
      this.grids[grid].reload();
    }
  }

  getFilter() {
    return this.filter;
  }

  filterGrids = (params) => {
    this.grids.forEach((grid) =>
      grid.setExtraParams(params)
    );
  }

  exportFeedback = () => {
    if (this.grids[1]) {
      this.grids[1].exportData();
    }
  }

  onEditFeedback = (row) => {
    this.setState({
      feedbackId: row.id,
      feedbackModal: true
    });
  }

  onSuccessFeedback = () => {
    this.toggleFeedbackModal();
    this.reloadGridData(1);
  }

  filterFeedback = (params) => {
    if (this.grids[1]) {
      this.grids[1].setExtraParams(params);
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col">
          <ClientFilter
            onFilter={this.filterGrids}
            onToggleFeedback={this.toggleFeedbackModal}
            onExportFeedback={this.exportFeedback}
            onCreate={this.showHubspotModal}
          />
          <ClientGrid
            ref={(c) => this.grids[0] = c}
            onDataChanged={this.reloadGridData}
            onFilterLetter={this.filterFeedback}
            onHubspotSelect={this.showHubspotModal}
          />
          <FeedbackGrid 
            ref={(c) => this.grids[1] = c}
            onDataChanged={this.reloadGridData}
            onRowSelection={this.onEditFeedback} 
          />
          <FeedbackForm 
            isOpen={this.state.feedbackModal} toggle={this.toggleFeedbackModal}
            onSuccess={this.onSuccessFeedback} onDelete={() => this.reloadGridData(1)}
            id={this.state.feedbackId}
          />
          <HubspotSyncModal
            model={this.state.model}
            modelId={this.state.modelId}
            create={this.state.create}
            isOpen={this.state.hubspotModal}
            toggle={this.toggleHubspotModal}
            onChange={() => this.reloadGridData(0)}
          />
        </div>
      </div>
    );
  }
}

export default List;
