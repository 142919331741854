import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { components } from 'react-select';
import { AbstractTargetCriteria, RangeModal, CreatableReactSelect } from '../abstract';
import Precodes from './Precodes';
import OperatorHint from '../abstract/OperatorHint';

function RangeIndicatorsContainer(toggleRangeModal) {
  return function IndicatorsContainer(props) {
    return (
      <components.IndicatorsContainer {...props}>
        <span>
          <i
            title="Create range"
            className="fa fa-arrows-h tap-range-icon"
            aria-hidden="true"
            onMouseDown={(e) => {
              e.stopPropagation();
              toggleRangeModal();
            }}
          ></i>
          <span className="Select-arrow" onMouseDown={props.onMouseDown}></span>
        </span>
        <components.IndicatorSeparator {...props} />
        {props.children}
      </components.IndicatorsContainer>
    )
  }
}

export default class TargetCriteria extends AbstractTargetCriteria {

  constructor(props) {
    super(props);

    this.rangeModal = false;
  }

  static propTypes = {
    ...TargetCriteria.propTypes,
    partnerId: PropTypes.number.isRequired,
    countryId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    criteria: PropTypes.shape({
      question_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      pre_codes: PropTypes.array
    })
  };

  getCurrentTargetCriteria() {
    const { question_id, pre_codes } = this.state;
    return Object.assign({}, { question_id, pre_codes });
  }

  get name() {
    return 'tapresearch';
  }

  getDefaultCriteria() {
    return {
      question_id: null,
      pre_codes: []
    };
  }

  getQuestionSelectOptions() {
    const { questions } = this.props;
    return Object.keys(questions).map((key) => {
      return { value: key, label: `${questions[key].name} (${questions[key].en_translation})` };
    });
  }

  getPrecodesSelectOptions() {
    return this.state.pre_codes.map(value => ({ value, label: value }));
  }

  shouldParseValue(prop) {
    return prop === 'pre_codes' && this.useCreatable();
  }

  valueChanged(prop, value) {
    const changed = super.valueChanged(prop, value);

    if (prop === 'question_id') {
      Object.assign(changed, { pre_codes: [] });
    }

    return changed;
  }

  precodesCount() {
    return this.state.pre_codes.length;
  }

  useCreatable() {
    const question = this.getSelectedQuestion();

    return question.answer_type  ===  5;
  }

  toggleRangeModal = () => {
    this.setState({ rangeModal: !this.state.rangeModal });
  }

  updatePreCodes = (pre_codes) => {
    this.updateState({ pre_codes, rangeModal: false });
  }

  getPrecodesSelect() {
    if (!this.state.question_id) {
      return null;
    }

    const props = {
      value: this.state.pre_codes,
      onChange: option => this.selectChangeHandler('pre_codes', option),
      isMulti: true,
      closeMenuOnSelect: false,
      isDisabled: this.props.readOnly
    };

    return this.useCreatable() ? (
      <div>
        <CreatableReactSelect
          {...props}
          options={this.getPrecodesSelectOptions()}
          components={{ IndicatorsContainer: RangeIndicatorsContainer(this.toggleRangeModal) }}
          className={classnames({ 'range-select': !this.props.readOnly })}
        />
        {!this.props.readOnly &&
          <RangeModal
            isOpen={this.state.rangeModal}
            toggle={this.toggleRangeModal}
            onCreateRange={this.updatePreCodes}
          />
        }
      </div>
    ) : (
      <Precodes 
        {...props}
        question_id={this.state.question_id}
        partnerId={this.props.partnerId}
        countryId={this.props.countryId}
        closeOnSelect={false}
      />
    );
  }

  getOperatorSelect() {
    return (
      <div className="form-group col-md-2">
        <select
          name="operator" className="form-control"
          value="OR"
          disabled
        >
          <option value="OR">OR</option>
        </select>
        <OperatorHint {...this.operatorHintProps()} />
      </div>
    );
  }
}
