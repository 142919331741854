import React from 'react';
import ClientSelect from '../../lib/form/ClientSelect';
import { AbstractForm, ButtonSelect, CollapseCard } from 'emi-frontend';

class ContactForm extends AbstractForm {
  
  getDefaultValues() {
    return {
      first_name: '',
      last_name: '',
      email: '',
      contact_type: ['project'],
      client_id: this.getIntParam('id'),
      connector: false,
      hubspot_id: '',
    };
  }

  getApiPath = () => {
    return '/contact';
  }

  getId = () => {
    return this.getIntParam('cid');
  }

  get modelName() {
    return 'contact';
  }

  redirectPath = () => {
    return '/clients';
  }

  isReadonlyForm() {
    // Used to make the whole form read-only
    return true;
  }

  showDeleteAction() {
    return false;
  }

  convertResponse(data) {
    // Overwrite this because loadDone can't be overwritten
    if (this.props.onLoadDone) {
      this.props.onLoadDone(data);
    }
    return data;
  }

  valueChanged(name, value, isEdit) {
    if (name === 'contact_type') {
      const types = [].concat(value);
      return super.valueChanged(name, types.length === 0 ? null : types, isEdit);
    }

    return super.valueChanged(name, value, isEdit);
  }

  getFormContent() {
    return (
      <CollapseCard title="Contact Profile">
        <div className="form-row">
          {this.getFormRow({
            label: 'first name',
            name: 'first_name',
            required: true,
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}

          {this.getFormRow({
            label: 'last name',
            name: 'last_name',
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'company',
            name: "client_id",
            component: ClientSelect,
            onChange: this.selectChangeHandler('client_id'),
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}

          {this.getFormRow({
            name: "email",
            type: "email",
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'type',
            name: "contact_type",
            component: ButtonSelect,
            options: {
              project: 'Project',
              accounting: 'Accounting'
            },
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}

          {this.getFormRow({
            name: 'connector',
            label: 'Enable Connector',
            component: ButtonSelect,
            options: { true: 'Yes', false: 'No' },
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}
        </div>

        <div className='form-row'>
          {this.getFormRow({
            name: 'hubspot_id',
            label: 'hubspot id',
            type: 'number',
            step: 1,
            min: 0,
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}
        </div>
      </CollapseCard>
    );
  }
}

export default ContactForm;
