import React from 'react';
import { Button, CardHeader } from 'reactstrap';
import { AbstractForm } from 'emi-frontend';

export default class Quota extends AbstractForm {

  constructor(props) {
    super(props);

    this.state.loadingData = false;
  }

  getApiPath = () => {
    return '/panelapi';
  }

  get modelName() {
    return 'quota';
  }

  shouldLoadData() {
    return false;
  }

  getFormHeader() {
    return this.showCloneButton() ? (
      <CardHeader className="border-0">
        <Button className="float-right" type="button" color="info" size="sm" onClick={() => this.onCloneQuota()}>
          <i className="fa fa-clone" aria-hidden="true"></i>{' '}Clone
        </Button>
      </CardHeader>
    ) : ( null );
  }

  isNew() {
    return true;
  }

  getTargetingCriteria() {
    return {};
  }

  resetValues() {
    return {};
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.cloneData && AbstractForm.valuesDidChange(this.props.cloneData, nextProps.cloneData)) {
      this.cloneTargetCriteria(nextProps.cloneData);
    }
  }

  showCloneButton() {
    return this.isUpdate();
  }

  getCloneQuotaData() {
    return this.getTargetingCriteria();
  }

  onCloneQuota() {
    const quotaData = this.getCloneQuotaData();

    this.props.cloneQuota(
      AbstractForm.cloneData(quotaData)
    );
  }

  cloneTargetCriteria(data) {
    const [name, targeting_criteria] = Object.entries(data).shift();
    const values = this.valueChanged(name, targeting_criteria);

    this.updateValues(values);
  }

  addTargetCriteria = (criteria) => {
    this.updateTargetCriteria('push', criteria);
  }

  removeTargetCriteria = (idx) => {
    this.updateTargetCriteria('splice', idx, 1);
  }

  modifyTargetCriteria = (idx, criteria) => {
    this.updateTargetCriteria('splice', idx, 1, criteria);
  }

  updateTargetCriteria(method, ...args) {
    const [name, targeting_criteria] = Object.entries(this.getTargetingCriteria()).shift();
    targeting_criteria[method](...args);

    const values = this.valueChanged(name, targeting_criteria);

    this.updateValues(values);
  }

  sendFormData = () => {
    const id = this.getId();
    const method = id ? 'PUT' : 'POST';

    return this.clientApi(method, this.getQuotaApiPath(), this.getFormData());
  }

  getQuotaApiPath() {
    return `${this.getApiPath()}/${this.props.routeId}/quota`;
  }

  getDeleteParams() {
    return {};
  }

  onDelete = () => {
    this.clientApi('DELETE', this.getQuotaApiPath(), this.getDeleteParams())
    .done(this.deleteDone)
    .fail(this.deleteFail);
  }

  deleteDone = (response) => {
    this.props.onUpdate(response.data);
  }

  successAction = (response) => {
    this.setState({ message: 'Data updated successfully' }, () => {
      if (!this.getId()) {
        this.updateValues(this.resetValues());
      }

      this.props.onUpdate(response.data);
    });
  }
}