import React from 'react';
import classNames from 'classnames';
import { Grid, Button, GridMobileBody } from 'emi-frontend';
import StatusUpdateModal from './StatusUpdateModal';
import GridMobileRow from './GridMobileRow';
import RouteForm from './RouteForm';
import { coverageTypes } from '../coverage/constants';

function StatusButton({ btnClassName, icon, data, status, row, target }) {
  return (
    <button
      type="button"
      className={classNames('btn', 'grid-button', btnClassName)}
      onClick={e => e.stopPropagation()}
      data-id={data} data-status={typeof status === 'function' ? status(row) : status} data-group={row.project_group_id}
      data-toggle="modal" data-target={target}
    >
      <i className={classNames('fa', `fa-${typeof icon === 'function' ? icon(row) : icon}`)} />
    </button>
  );
}

export default class JobGrid extends Grid {

  get showStatusUpdateModal() {
    return false;
  }

  get statusUpdateModalId() {
    return 'job-status';
  }

  getInitialState(props) {
    const initialState =  Object.assign(super.getInitialState(props), {
      routeModal: false,
      routeValues: {
        project_id: 0
      }
    });

    Object.assign(initialState.extraParams, { connector: false });
    return initialState;
  }

  getApiPath = () => {
    return '/project';
  }

  getUpdateApiPath = () => {
    return '/project';
  }

  getBasePath = () => {
    return '/jobs';
  }

  getStatusUpdate(status, merge, data) {
    return Object.assign({ status }, merge ? data : null);
  }

  updateProject(projectId, update, gridIndex) {
    return this.clientApi('PUT', `${this.getUpdateApiPath()}/${projectId}`, update)
      .done(() => {
        this.reload();
        if (gridIndex !== undefined) {
          this.props.onDataChanged(gridIndex);
        }
      })
      .fail(this.showError);
  }

  updateProjectStatus = () => {}

  statusButton = ({ className, ...props }) => {
    return className !== undefined ? (
      <div className={className}>
        <StatusButton {...props} target={`#${this.statusUpdateModalId}`} />
      </div> 
    ) : (
      <StatusButton {...props} target={`#${this.statusUpdateModalId}`} />
    );
  } 

  gridButtonRenderer = (props) => {
    const {className, btnClassName, ...rest} = props; 
    rest.className = btnClassName;
    return (
      <div className={className}>
        <Button {...rest} />
      </div>
    );
  }

  coverageRenderer = (props) => {
    if (props.data === null || !Object.keys(props.data).length) {
      return '';
    }
    const types = [];
    Object.keys(props.data).forEach((key) => {
      if (props.data[key] && Array.isArray(props.data[key])) {
        types.push(coverageTypes[key].label);
      }
    });
    return types.join(', ');
  }

  getColumns() {
    return [{
      name: 'partner',
      dataField: 'id',
      title: '',
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderer: this.gridButtonRenderer,
      params: {
        icon: 'user-plus',
        className: 'right-border',
        clickHandler: this.toggleRouteModal
      }
    }, {
      name: 'id',
      dataField: 'id',
      title: 'ID',
      asc: false,
      width: 55,
      exactWidth: true
    }, {
      name: 'name',
      title: 'Job name',
      width: 200
    }, {
      name: 'client_name',
      title: 'Client name',
      width: 230,
      exactWidth: true
    }, {
      name: 'user_name',
      title: 'RM',
      width: 200,
      exactWidth: true
    }, {
      name: 'coverage_type',
      title: 'Coverage',
      width: 150,
      exactWidth: true,
      renderer: this.coverageRenderer
    },{
      name: 'io_number',
      title: 'IO#',
      width: 80,
      exactWidth: true
    }];
  }

  getMobileHeader() {
    return '';
  }

  getMobileBody() {
    return <GridMobileBody
      RowClass={GridMobileRow}
      columns={this.state.columns}
      data={this.state.data}
      onClick={this.onRowClick}
      loading={this.state.loadingData}
      rowStyler={this.getRowStyler()}
    />;    
  }

  toggleRouteModal = (id) => {
    this.setState({
      routeModal: !this.state.routeModal,
      routeValues: {
        project_id: id || 0
      }
    });
  }

  onNewPartner = () => {
    this.setState({
      routeModal: false,
      routeValues: {
        project_id: 0
      }
    }, this.reload);
  }

  getPagination() {
    return (
      <div>
        <RouteForm
          id={0}
          isOpen={this.state.routeModal}
          toggle={this.toggleRouteModal}
          values={this.state.routeValues}
          onSuccess={this.onNewPartner}
        />
        {this.showStatusUpdateModal &&
          <StatusUpdateModal id={this.statusUpdateModalId} updateFn={this.updateProjectStatus} />
        }
        {super.getPagination()}
      </div>
    );
  }
}
