import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import PubSub from 'pubsub-js';
import { client, Grid, Desktop, Tablet, Mobile } from 'emi-frontend';

export default class QuickSearchGrid extends Grid {

  get gridName() {
    return 'redirectGrid';
  }

  getApiPath = () => {
    return '/redirect';
  }

  ShortDate({ data }) {
    if (!data) {
      return null;
    }
    const [year, month, day] = data.substring(2, 10).split('-');

    return (
      <span>{[month, day, year].join('/')}</span>
    );
  }

  getColumns() {
    return [{
      name: 'redirect_id',
      title: 'redirect id',
      width: 100
    }, {
      name: 'partner_name',
      title: 'Partner',
      width: 80
    }, {
      name: 'respondent_id',
      title: 'partner id',
      width: 100
    }, {
      name: 'status',
      title: 'status',
      width: 80
    }, {
      name: 'loi',
      title: 'LOI',
      width: 80,
      exactWidth: true
    }, {
      name: 'samplechain_threat_potential_score',
      title: 'Fraud Score',
      width: 130,
      exactWidth: true
    }, {
      name: 'rd_activity_count',
      title: 'Activity Count',
      width: 145,
      exactWidth: true
    }];
  }

  isRowSelectable = () => {
    return false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.extraParams) {
      this.setExtraParams(nextProps.extraParams);
    }
  }

  getPagination() {
    return this.props.paging && (
      <div>
        <Desktop>{this.getDesktopPagination()}</Desktop>
        <Tablet>{this.getTabletPagination()}</Tablet>
        <Mobile>{this.getMobilePagination()}</Mobile>

        {this.state.recordCount > 0 && this.getExportButton('float-right btn-sm')}
      </div>
    );
  }

  exportData = (extraParams) => {
    const params =  {
      sort: this.state.sortProperties
    };

    if (extraParams) {
      Object.assign(params, { extraParams });
    } else {
      this.applyExtraParams(params);
    }

    params.extraParams.user_id = this.context.user.id;

    // filter out empty values
    if (params.extraParams) {
      params.extraParams = Object.fromEntries(
        Object.entries(params.extraParams).filter(([key, val]) => !!val)
      );
    }

    client
      .api('GET', `${this.getApiPath()}/export`, params)
      .done(() => {
        this.setState({ message: 'Background job created', success: true });
        PubSub.publish(`background_job_update`, false);
      })
      .fail(console.error);
  }
}

QuickSearchGrid.contextTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
