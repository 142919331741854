import { Header as HeaderComponent } from 'emi-frontend';
import AppSelector from './AppSelector';
import NavRightItems from './RightItems';

export default class Header extends HeaderComponent {
  constructor(props) {
    super(props);

     Object.assign(this.components, { AppSelector, NavRightItems });
  }
}
