import { useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  Modal, ModalHeader, ModalBody,
  Nav, NavItem, NavLink } from 'reactstrap';
import { client } from 'emi-frontend';
import PanelApiLogResult from './PanelApiLogResult';

const nameMap = {
  prodege: 'Prodege',
  tapresearch: 'TapResearch',
  purespectrum: 'PureSpectrum',
  researchnow: 'ResearchNow',
  disqo: 'Disqo',
  marketcube: 'MarketCube',
};

export default function PanelApiLogModal({ isOpen, toggle, apis, externalIds }) {
  const defaultPanel = apis.concat().shift();
  const [isLoading, setLoading] = useState(false);
  const [activePanel, setActivePanel] = useState(defaultPanel);
  const [externalId, setExternalId] = useState(externalIds[defaultPanel]);
  const [data, setData] = useState({ entries: [], pageToken: null });

  useEffect(() => {
    if (isOpen && activePanel && externalId) {
      setLoading(true);

      if (data.entries.length > 0) {
        setData({ entries: [], pageToken: null });
      }

      loadData();
    }
  }, [isOpen, activePanel, externalId]);

  function loadData(pageToken = '') {
    client.api('POST', '/panelapilogs/entries', { api: nameMap[activePanel], externalId, pageToken })
      .done((resp) => {
        setLoading(false);
        setData(resp.data);
      })
      .fail(error => {
        setLoading(false);
        console.error(error);
      });
  }

  function setActiveApi(panel, externalId) {
    if(activePanel !== panel) {
      setActivePanel(panel);
      setExternalId(externalId);
      setData({ entries: [], pageToken: null });
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle}>
        Panel API: {nameMap[activePanel]} - {externalId}
      </ModalHeader>
      <ModalBody>
        {apis.length > 1 &&
          <Nav tabs className="mb-3">
            {Object.entries(externalIds).map(([key, value]) =>
              <NavItem key={key}>
                <NavLink
                  className={classNames({ active: activePanel === key })}
                  onClick={() => setActiveApi(key, value)}
                >
                  {nameMap[key]}
                </NavLink>
              </NavItem>
            )}
          </Nav>
        }
        <PanelApiLogResult {...data} isLoading={isLoading} />
        {data.entries.length > 0 && !!data.pageToken &&
          <nav aria-label="Next Page">
            <ul className="pagination pagination-sm justify-content-end">
              <li className="page-item">
                <button type="button" className="btn btn-link page-link" onClick={() => loadData(data.pageToken)}>
                  Next
                </button>
              </li>
            </ul>
          </nav>
        }
      </ModalBody>
    </Modal>
  );
}
