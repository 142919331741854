import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem } from 'reactstrap';
import { ReactSelect } from 'emi-frontend';

export default class TargetCriteria extends Component {

  constructor(props) {
    super(props);

    this.state = Object.assign({}, props.criteria || this.getDefaultCriteria());
  }

  get name() {
    return '';
  }

  static propTypes = {
    idx: PropTypes.number,
    identifier: PropTypes.string,
    questions: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    isDeletable: PropTypes.bool,
    allowCreate: PropTypes.bool,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    allowCreate: false,
    readOnly: false,
    isDeletable: false,
    identifier: 'question_id'
  };

  static parseValue(value) {
    const separator = new RegExp(/\s*,\s*/, 'g');
    const rs = [];
    value.forEach(element => {
      if (separator.test(element)) {
        Array.prototype.push.apply(rs, element.split(separator));
      } else {
        rs.push(element);
      }
    });

    // make array values unique
    return Array.from(new Set(rs)).filter(element => element !== '');
  }

  getDefaultCriteria() {
    return {};
  }

  getCurrentTargetCriteria() {
    return Object.assign({}, this.state);
  }

  getIdentifierValue() {
    return this.state[this.props.identifier];
  }

  shouldResetCriteria() {
    return true;
  }

  addCriteria = () => {
    this.props.onAdd(this.getCurrentTargetCriteria());

    if (this.shouldResetCriteria()) {
      this.setState(this.getDefaultCriteria());
    }
  }

  getQuestionSelectOptions() {
    return [];
  }

  shouldParseValue(prop) {
    return false;
  }

  valueChanged(prop, value) {
    return {
      [prop]: this.shouldParseValue(prop) ? TargetCriteria.parseValue(value) : value
    };
  }

  getSelectedOptionValue(option) {
    if (Array.isArray(option)) {
      return option.map(selected => selected.value);
    }

    return  option ? option.value : this.getEmptyOptionValue();
  }

  getEmptyOptionValue() {
    return '';
  }

  selectChangeHandler = (field, option) => {
    const changed = this.valueChanged(field, this.getSelectedOptionValue(option, field));

    this.updateState(changed);
  }

  updateState(state) {
    this.setState(state, () => {
      if (this.props.idx !== null && this.props.idx !== undefined) {
        this.props.onChange(this.props.idx, this.getCurrentTargetCriteria());
      }
    });
  }

  getSelectedQuestion(question_id) {
    return this.props.questions[question_id || this.getIdentifierValue()];
  }

  precodesCount() {
    return 0;
  }

  showAddButton() {
    return this.props.allowCreate && !this.props.readOnly && this.showOperator() && this.precodesCount() > 0;
  }

  showOperator() {
    return this.getIdentifierValue() > 0;
  }

  getPrecodesSelect() {
    return null;
  }

  getOperatorSelect() {
    return null;
  }

  getAddButton() {
    return (
      <button key="create" type="button" className="btn btn-sm btn-default mb-1" onClick={() => this.addCriteria()}>
        <i className="fa fa-plus" aria-hidden="true" />
      </button>
    );
  }

  getDeleteButton() {
    return (
      <button
        key="delete" type="button" className="btn btn-sm btn-danger"
        onClick={() => this.props.onDelete(this.props.idx)}
      >
        <i className="fa fa-trash-o" aria-hidden="true" />
      </button>
    );
  }

  getButtons() {
    const buttons = [];
    if (this.showAddButton()) {
      buttons.push(this.getAddButton());
    }

    if (this.isDeletable()) {
      buttons.push(this.getDeleteButton());
    }

    return buttons;
  }

  isDeletable() {
    return this.props.isDeletable;
  }

  isQuestionSelectDisabled() {
    return this.props.readOnly;
  }

  operatorHintProps() {
    return {
      name: this.name,
      criteria: this.getCurrentTargetCriteria()
    };
  }

  hasError() {
    const question_id = this.getIdentifierValue();
    if (!question_id) {
      return false;
    }

    return this.getSelectedQuestion(question_id) === undefined;
  }

  render() {
    return (
      <ListGroupItem className="prodege-target-criteria">
        {this.hasError() ? (
          <div className="form-row">
            <div className="col-md-11 text-danger font-weight-bold">
              {`An error occurred, selected question [${this.getIdentifierValue()}] not found`}
            </div>
            <div className="form-group col-md-1 text-center">
              {this.getDeleteButton()}
            </div>
          </div>
        ) : (
          <div className="form-row">
            <div className="form-group col-md-6">
              <ReactSelect 
                options={this.getQuestionSelectOptions()}
                value={this.getIdentifierValue()}
                isClearable={false}
                placeholder="Select question..."
                onChange={option => this.selectChangeHandler(this.props.identifier, option)}
                isDisabled={this.isQuestionSelectDisabled()}
              />
            </div>
            {this.showOperator() && 
              <React.Fragment>
                {this.getOperatorSelect()}
                <div className="form-group col-md-3">
                  {this.getPrecodesSelect()}
                </div>
              </React.Fragment>
            }
            <div className="form-group col-md-1 text-center">
              {this.getButtons()}
            </div>
          </div>
        )}
      </ListGroupItem>
    );
  }
}