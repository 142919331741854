import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { AbstractQuotas, ClipboardButton } from '../abstract';
import Qualification from './Qualification';

export default class Qualifications extends AbstractQuotas {

  static defaultProps = {
    countryId: 0
  };

  setQuotaComponent() {
    this.components = { Quota: Qualification };
  }

  getDefaultValues() {
    return {
      ps_survey_id: this.props.partnerProjectId
    };
  }

  getQualificationApiPath() {
    return `/panelapi/${this.props.routeId}/qualification`;
  }

  sendData = (data, method) => {
    if (method === 'PUT') {
      Object.assign(data, { code: data.qualification_code });
    }

    this.clientApi(method, this.getQualificationApiPath(), Object.assign(data, this.getDefaultValues()))
      .done((resp) => this.props.onUpdate(resp.data))
      .fail(this.showError);
  }

  emptyFn = () => {}

  copyQualifications = () => {
    return Object.values(this.props.quotas);
  }

  renderContent() {
    const { quotas } = this.props;
    const quotaKeys = Object.keys(quotas);

    return (
      <ListGroup style={{ width: '100%' }} className="top-margin-10">
        {quotaKeys.length > 0 &&
          <ListGroupItem className="prodege-target-criteria">
            <ClipboardButton
              title="Copy Qualifications"
              className="float-right"
              dataType="qualifications"
              writeClipboardFn={this.copyQualifications}
              readClipboardFn={this.emptyFn}
            />
          </ListGroupItem>
        }
        <Qualification
          questions={this.state.questions}
          onAdd={qualification => this.sendData(qualification, 'POST')}
          onDelete={this.emptyFn}
          onChange={this.emptyFn}
          identifier="qualification_code"
          allowCreate
        />
        {quotaKeys.map(key =>
          <Qualification
            idx={key}
            key={key}
            criteria={quotas[key]}
            questions={this.state.questions}
            onAdd={qualification => this.sendData(qualification, 'PUT')}
            onDelete={() => this.sendData(quotas[key], 'DELETE')}
            onChange={this.emptyFn}
            identifier="qualification_code"
            allowCreate
            isDeletable
          />
        )}
      </ListGroup>
    );
  }
}