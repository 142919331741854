import { Filter } from 'emi-frontend';

export default class AttributeFilter extends Filter {

  constructor(props) {
    super(props);

    this.state.searchTitle = 'Search by Name';
  }

  get filterName() {
    return 'attributeFilter';
  }
}
