import RouteForm from '../job/RouteForm';
import MultiProjectSelect from './MultiProjectSelect';

export default class MultiRouteForm extends RouteForm {

  getApiPath = () => {
    return '/multi_route';
  }

  getDefaultValues(props) {
    return Object.assign({
      project_id: []
    }, this.getDefaultPartnerValues(), props.values);
  }

  getProjectsField() {
    return (
      <div className="form-row">
        {this.getFormRow({
          label: 'projects',
          name: 'project_id',
          component: MultiProjectSelect,
          onChange: this.selectChangeHandler('project_id'),
          isMulti: true,
          required: true,
          closeMenuOnSelect: false,
          invoice_id: this.props.invoice_id,
          fieldWidth: { xs: 12 },
          labelWidth: { xs: 12, lg: 3 },
          valueWidth: { xs: 12, lg: 9 }
        })}
      </div>
    );
  }
}
