import React from 'react';
import UserSelect from '../../lib/form/UserSelect';

export default function SyncClientData({ state, field, label, create, onSelect, onEdit }) {
  const isEmpty = Object.keys(state.hubspot).length === 0;

  function onChange(option) {
    onEdit('hubspot', { ...state.hubspot, [field]: option.value });
  }

  return (
    <div className="form-group row align-items-center">
      <label htmlFor="objectId" className="col-sm-3 col-form-label font-weight-bold text-uppercase">{label || field}</label>
      {!create &&
        <div className="col-sm-4">
          <UserSelect
            value={state.data[field]} isDisabled
            department="sales"
            sales_rule="CSE"
          />
        </div>
      }
      <div className={create ? 'col-sm-8': 'col-sm-4'}>
        <UserSelect
          value={state.hubspot[field] || ''}
          department="sales"
          sales_rule="CSE"
          isDisabled={!create || !state.selected.includes(field)}
          onChange={onChange}
        />
      </div>
      <div className="col-sm-1 align-self-center">
        <div className="form-check">
          <input
            className="form-check-input position-static"
            type="checkbox"
            value={field}
            aria-label="..."
            onChange={onSelect}
            disabled={isEmpty}
          />
        </div>
      </div>
    </div>
  );
}
