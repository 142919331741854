import React from 'react';
// import { ListGroup } from 'reactstrap';
import { hasOwnProperty } from 'emi-frontend';
import { AbstractQuota, Clicks } from '../abstract';
import CountrySelect from './CountrySelect';
import LanguageSelect from './LanguageSelect';
import QuotaPlan from './QuotaPlan';

export default class LineItem extends AbstractQuota {

  constructor(props) {
    super(props);

    LineItem.assignQuotaPlanDefaults(this.state.values);
  }

  static assignQuotaPlanDefaults(values) {
    // make sure these have default array value
    const { filters = [], quotaGroups = [] } = values.quotaPlan;
    Object.assign(values.quotaPlan, { filters, quotaGroups });
  }

  getDefaultValues() {
    return {
      extLineItemId: 'eli001',
      title: '',
      countryISOCode: '',
      languageISOCode: '',
      surveyURL: '',
      surveyTestURL: '',
      indicativeIncidence: 0,
      lengthOfInterview: 0,
      daysInField: 1,
      deliveryType: 'BALANCED',
      requiredCompletes: 1,
      quotaPlan: {
        filters: [],
        quotaGroups: []
      }
    };
  }

  getId = () => {
    return this.state.values.extLineItemId;
  }

  isUpdate() {
    return Object.prototype.hasOwnProperty.call(this.props.values || {}, 'state');
  }

  showDeleteAction() {
    return false;
  }

  showCloneButton() {
    return false;
  }

  submitForm = () => {
    if (this.isUpdate()) {
      this.setState(
        { sendingData: true, message: '', errors: {} },
        () => {
          this.sendFormData()
            .done(this.submitDone)
            .fail(this.submitFail);
        }
      );
    } else {
      this.props.onCreate(this.getFormData());
    }
  }

  sendFormData = () => {
    return this.clientApi('PUT', this.getQuotaApiPath(), {
      extProjectId: this.props.partnerProjectId,
      ...this.getFormData()
    });
  }

  sendQuotaData = (method, path, data) => {
    const params = Object.assign({
      extLineItemId: this.state.values.extLineItemId,
      extProjectId: this.props.partnerProjectId
    }, data);

    this.setState({
      loadingData: true
    }, () =>
      this.clientApi(method, `${this.getQuotaApiPath()}/${path}`, params)
        .done((resp) => this.loadDone(resp))
        .fail(this.loadFail)
    );
  }

  reloadData = () => {
    this.sendQuotaData('POST', 'refresh');
  }

  updateStatus = (action) => {
    this.sendQuotaData('PUT', 'status', { action });
  }

  loadDone = (response) => {
    const [values] = response.data.project.lineItems;
    LineItem.assignQuotaPlanDefaults(values);

    this.setState({ loadingData: false, values }, () => this.props.onUpdate(response.data));
  }

  valueChanged(name, value) {
    const changed = super.valueChanged(name, value);
    if (name === 'countryISOCode' && this.state.values.countryISOCode && this.state.values.languageISOCode) {
      Object.assign(changed, { languageISOCode: '' });
    }

    return changed;
  }

  modifyValues = (path, prop, ...args) => {
    const values = AbstractQuota.cloneData(this.state.values);
    let target = AbstractQuota.findTarget(values, path);

    if (typeof target[prop] === 'function') {
      target[prop](...args);
    } else {
      target[prop] = args.shift();
    }

    this.updateValues(values);
  }

  showStatusButton(status) {
    return Array.isArray(status) ? status.includes(this.state.values.state) : this.state.values.state === status;
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row action-buttons">
          <div className="col">
            <div className="form-group float-right">
              {this.showStatusButton(['QA_APPROVED', 'PAUSED']) &&
                <button type="button" className="btn btn-default" onClick={() => this.updateStatus('launch')}>
                  <i className="fa fa-play"></i> Launch
                </button>
              }
              {this.showStatusButton('LAUNCHED') &&
                <button type="button" className="btn btn-default" onClick={() => this.updateStatus('pause')}>
                  <i className="fa fa-pause"></i> Pause
                </button>
              }
              <button type="button" className="btn btn-danger" onClick={() => this.updateStatus('close')}>
                <i className="fa fa-stop"></i> Close
              </button>
            </div>
          </div>
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'title',
            name: 'title',
            required: true,
            labelWidth: { xs: 12, sm: 2 },
            valueWidth: { xs: 12, sm: 10 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'country',
            name: 'countryISOCode',
            component: CountrySelect,
            required: true,
            isClearable: false,
            onChange: this.selectChangeHandler('countryISOCode'),
            partnerId: this.props.partnerId,
            fieldWidth: { xs: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'language',
            name: 'languageISOCode',
            component: LanguageSelect,
            required: true,
            isClearable: false,
            onChange: this.selectChangeHandler('languageISOCode'),
            partnerId: this.props.partnerId,
            countryISOCode: this.state.values.countryISOCode,
            fieldWidth: { xs: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'ir',
            name: 'indicativeIncidence',
            type: 'number',
            min: 0,
            max: 100,
            step: 1,
            postfix: '%',
            required: true,
            fieldWidth: { xs: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'days in field',
            name: 'daysInField',
            type: 'number',
            step: 1,
            min: 1,
            required: true,
            fieldWidth: { xs: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'loi',
            name: 'lengthOfInterview',
            type: 'number',
            min: 1,
            step: 1,
            required: true,
            fieldWidth: { xs: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'delivery',
            name: 'deliveryType',
            type: 'select',
            children: [
              <option key="slow" value="SLOW">SLOW</option>,
              <option key="balanced" value="BALANCED">BALANCED</option>,
              <option key="fast" value="FAST">FAST</option>
            ],
            fieldWidth: { xs: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'quota',
            name: 'requiredCompletes',
            type: 'number',
            min: 1,
            step: 1,
            required: true,
            prefix: hasOwnProperty(this.props.stats, 'completes') ? `${this.props.stats.completes} /` : null,
            postfix: hasOwnProperty(this.props.stats, 'attempts') ? <Clicks value={this.props.stats.attempts} /> : null,
            fieldWidth: { xs: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.isUpdate() && this.getFormRow({
            name: 'state',
            type: 'select',
            disabled: true,
            children: [
              <option key="provisioned" value="PROVISIONED">PROVISIONED</option>,
              <option key="awaiting" value="AWAITING_APPROVAL">AWAITING_APPROVAL</option>,
              <option key="approved" value="QA_APPROVED">QA_APPROVED</option>,
              <option key="rejected" value="REJECTED">REJECTED</option>,
              <option key="launched" value="LAUNCHED">LAUNCHED</option>,
              <option key="paused" value="PAUSED">PAUSED</option>,
              <option key="closed" value="CLOSED">CLOSED</option>,
              <option key="cancelled" value="CANCELLED">CANCELLED</option>,
              <option key="invoiced" value="INVOICED">INVOICED</option>
            ],
            fieldWidth: { xs: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}
        </div>

        <div className="form-row">
          <div className="col-12">
            <QuotaPlan
              routeId={this.props.routeId}
              partnerId={this.props.partnerId}
              partnerProjectId={this.props.partnerProjectId}
              countryISOCode={this.state.values.countryISOCode}
              languageISOCode={this.state.values.languageISOCode}
              quotaPlan={this.state.values.quotaPlan}
              onUpdate={this.props.onUpdate}
              onChange={this.modifyValues}
            />
          </div>
        </div>

      </div>
    );
  }
}
