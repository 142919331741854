import React from 'react';
import moment from 'moment';
import { client, Excel } from 'emi-frontend';

class ClientCodeDownload extends React.Component {
  render() {
    if (parseInt(this.props.value, 10) === 0) {
      return <p className="form-control-plaintext">{this.props.value}</p>;
    }
    return (<p className="form-control-plaintext">
      <button type="button" className="btn btn-primary btn-sm" onClick={this.clientCodeDownload}>{this.props.value}</button>
  </p>);
  }

  getExportHeaders() {
    return {
      code: 'Client Code',
      used: 'Used',
      created: 'Uploaded',
      assinged: 'Reserved'
    };
  }

  getExportFileName() {
    return `client_code_${this.props.projectId}_${moment().format('YYYYMMDDHHmm')}.xlsx`;
  }

  clientCodeDownload = () => {
    client
      .api('PUT', `/project/${this.props.projectId}/client_code`, { type: this.props.clientCodeType })
      .done((response) => {
        const excel = new Excel();
        excel.addJsonData(response.data, this.getExportHeaders(), 'Client Codes');
        excel.saveAs(this.getExportFileName());
      });
  }
}

export default ClientCodeDownload;