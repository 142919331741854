import React from 'react';
import TagFilter from './Filter';
import TagGrid from './Grid';
import { filterList } from 'emi-frontend';

const TagFilterList = filterList(
  TagFilter,
  [TagGrid]
);

function List() {
  return (
    <div className="row">
      <div className="col">
        <TagFilterList />
      </div>
    </div>
  );
}

export default List;
