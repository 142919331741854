import React from 'react';
import { Card, CardHeader, CardBody, CardFooter, Button } from 'reactstrap';
import RouteGrid from './RouteGrid';
import ProjectButton from './ProjectButton';
import ClientSelect from '../../lib/form/ClientSelect';
import UserSelect from '../../lib/form/UserSelect';
import ContactSelect from '../../lib/form/ContactSelect';
import ProjectGroupSelect from '../../lib/form/ProjectGroupSelect';
import LanguageSelect from '../../lib/form/LanguageSelect';
import ResearchDefenderQuestionSelect from '../../lib/form/ResearchDefenderQuestionSelect';
import { emptyFn, hasOwnProperty } from '../../lib/utils';
import ClientCodeForm from './ClientCodeForm';
import ExportMatchForm from './ExportMatchForm';
import LineItemGrid from './LineItemGrid';
import CloneProjectForm from './CloneProjectForm';
import ContactModal from './ContactModal';
import AttachmentModal from './AttachmentModal';
import StatusUpdateModal from './StatusUpdateModal';
import ReconciliationModal from './ReconciliationModal';
import QuickSearchModal from './QuickSearchModal';
import AlertModal from './AlertModal';
import { fingerprints, fingerprintSettings, deviceList } from './constants';
import ProjectAttributeGrid from './AttributeGrid';
import EndUrlModal from './EndUrlModal';
import { ucFirst, AbstractForm, CountrySelect, ButtonSelect, DeleteAction, SaveButton, CollapseCard,
  formatCurrency, FormDisabledRow, precisionRound, calcPercentage } from 'emi-frontend';
import ProjectTag from './ProjectTag';
import Prescreening from './Prescreening';
import PanelApiLogs from './PanelApiLogs';
import ClickBalancing from './ClickBalancing/index';
import FeedbackForm from '../partner/FeedbackForm';
import ResetIDStatusModal from './ResetIDStatusModal';
import ChangeStatusModal from './ChangeStatusModal';
import { Desktop, Mobile } from 'emi-frontend/lib/Responsive';
import CoverageSettingModal from '../coverage/SettingModal';

const deviceStats = {
  mobile: ['smartphone', 'phablet', 'Mobile Phone', 'Mobile Device'],
  tablet: ['tablet', 'Tablet'],
  desktop: ['desktop', 'Desktop']
};

export default class EditJob extends AbstractForm {

  constructor(props) {
    super(props);

    this.state.clientCodeModal = false;
    this.state.exportMatchModal = false;
    this.state.cloneProjectModal = false;
    this.state.contactModal = false;
    this.state.feedbackModal = false;
    this.state.attachmentModal = false;
    this.state.reconciliationModal = false;
    this.state.resetModal = false;
    this.state.changeModal = false;
    this.state.endUrlModal = false;
    this.state.coverageSettingModal = false;
    this.state.quicksearchModal = false;
    this.state.alertModal = false;
    this.state.panelApis = {};
    this.state.attributes = {};
    this.state.clientCse = {};
    this.state.clientSales = {};
  }

  getApiPath = () => {
    return '/project';
  }

  cancelPath = () => {
    return '/jobs';
  }

  get modelName() {
    return 'project';
  }

  getDefaultValues() {
    return Object.assign({
      project_group_id: '',
      secure_callback: true,
      status_based: true,
      enable_captcha: false,
      enabled_devices: Object.keys(deviceList),
      start_date: (new Date()).toJSON().slice(0, 10),
      prescreen: false,
      prescreen_intro: 'Please answer the following questions.',
      clickbalance: false,
      clickbalance_track: 'click',
      enable_coverage: null,
      samplechain_threat_score: 31,
      enable_click_quota: false,
      language: '',
      enable_research_defender_review: false,
      research_defender_review_question: '',
      research_defender_composite_score: 100,
      research_defender_activity_count: 100,
      reporting_link: '',
      live_test: true,
    }, this.getDefaultSecuritySettings());
  }

  loadPanelData(connector) {
    // Load Panel APIs' definition if project is created from Connector
    if (connector) {
      this.clientApi('GET', `/panelapi/0/panelapis`)
      .done((response) => {
        this.panelapiLoaded(response);
      })
      .fail(this.loadFail);

      this.clientApi('GET', `/panelapi/0/attributes`)
      .done((response) => {
        this.atrributesLoaded(response);
      })
      .fail(this.loadFail);
    }
  }

  loadClientCse() {
    this.clientApi('GET', `/client/cse`)
      .done((response) => {
        this.clientCseLoaded(response);
      })
      .fail(this.loadFail);
  }

  loadClientSales() {
    this.clientApi('GET', `/client/sales`)
      .done((response) => {
        this.clientSalesLoaded(response);
      })
      .fail(this.loadFail);
  }

  clientCseLoaded = (response) => {
    this.setState({
      clientCse: response.data.reduce((prev, curr) => {
        prev[curr.id] = curr.cse_id;
        return prev;
      }, {})
    });
  }

  clientSalesLoaded = (response) => {
    this.setState({
      clientSales: response.data.reduce((prev, curr) => {
        prev[curr.id] = curr.sales_id;
        return prev;
      }, {})
    });
  }

  panelapiLoaded = (response) => {
    this.setState({
      panelApis: response.data
    });
  }

  atrributesLoaded = (response) => {
    this.setState({
      attributes: response.data
    });
  }

  getDefaultSecuritySettings() {
    return {
      fingerprint: ['internal', 'samplechain'],
      fingerprint_setting: 2,
      policy: 'block'
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.loadClientCse();
    this.loadClientSales();
  }

  convertResponse(data) {
    this.loadPanelData(data.connector);
    return data;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const id = this.parseInt(nextProps.match.params.id);
    if (this.getId() !== id) {
      this.loadFormData(id);
    }
  }

  updateProjectStatus = (update) => {
    const { id, ...data } = update;
    if (!this.state.values.project_group_id) {
      // Project is not part of project group
      if (data.status === 'test') {
        data.policy = 'monitor';
      } else if (data.status === 'live') {
        data.policy = 'block';
      }
    }

    return this
      .clientApi('PUT', `${this.getApiPath()}/${id}`, data)
      .done(() => {
        this.loadFormData(this.getId());
      })
      .fail((error) => this.showError(error));
  }

  onGroupChange = (group) => {
    let values;
    if (!group) {
      values = { project_group_id: '', ...this.getDefaultSecuritySettings() };
    } else {
      const {
        id: project_group_id, fingerprint, fingerprint_setting, policy
      } = group;

      values = { project_group_id, fingerprint, fingerprint_setting, policy };
    }

    const prop = this.editingInProgress() ? 'edit' : 'values';

    this.updateValues(values, prop);
  }

  getHeaderButtonList() {
    const buttons = [
      <Mobile key="multiViewButton">{this.getMultiViewButton()}</Mobile>,
      <Mobile key="invoiceButton">{this.getInvoiceButton()}</Mobile>
    ];

    if (this.state.values.status === 'kickoff') {
      buttons.push(<ProjectButton key="startButton" type="start" text="test" onClick={() => this.updateProjectStatus({ id: this.getId(), status: 'test'})} />);
    } else if (this.state.values.status === 'test') {
      buttons.push(<ProjectButton key="startButton" type="start" data-id={this.getId()} data-status="live" data-toggle="modal" data-target="#job-status" onClick={emptyFn} />);
    } else if (this.state.values.status === 'live') {
      buttons.push(<ProjectButton key="pauseButton" type="pause" data-id={this.getId()} data-status="paused" data-toggle="modal" data-target="#job-status" onClick={emptyFn} />);
      buttons.push(<ProjectButton key="stopButton" type="stop" data-id={this.getId()} data-status="closed" data-toggle="modal" data-target="#job-status" onClick={emptyFn} />);
    } else if (this.state.values.status === 'paused') {
      buttons.push(<ProjectButton key="startButton" type="start" data-id={this.getId()} data-status="live" data-toggle="modal" data-target="#job-status" onClick={emptyFn} />);
      buttons.push(<ProjectButton key="stopButton" type="stop" data-id={this.getId()} data-status="closed" data-toggle="modal" data-target="#job-status" onClick={emptyFn} />);
    } else if (this.state.values.status === 'closed') {
      buttons.push(<ProjectButton key="startButton" type="start" text="re-open" data-id={this.getId()} data-status="live" data-toggle="modal" data-target="#job-status" onClick={emptyFn} />);
    }
    return buttons;
  }

  getInvoiceButton() {
    return (
      <Button 
        type="button" className="project-button"
        color="default"
        onClick={() => this.context.history.replace(`/invoices/${this.state.values.invoice_id}`)}
      >
        <Desktop>View </Desktop>Invoice 
      </Button>
    );
  }

  getAlertButton() {
    return (
      <Button 
        type="button" className="project-button"
        color="default"
        onClick={() => this.toggleModal('alertModal')}
      >
        Alert
      </Button>
    );
  }

  getMultiViewButton() {
    return (
      <Button 
        type="button" className="project-button"
        color="default"
        onClick={() => this.context.history.replace(`/multi/${this.state.values.invoice_id}`)}
      >
        Multi-View
      </Button>
    );
  }

  getHeaderButtons() {
    if (this.isNew()) {
      return null;    
    }

    return (
      <span className="action-buttons float-right d-none d-sm-block">
        {this.getAlertButton()}
        {this.getMultiViewButton()}
        {this.getInvoiceButton()}
        {this.getHeaderButtonList()}
      </span>
    );
  }

  getProjectStatus() {
    if (this.isNew()) {
      return '';
    }

    return (
      <span 
        className="bg-success text-white rounded float-right"
        style={{ padding: '3px 15px', fontSize: '1rem' }}
      >
        {ucFirst(this.state.values.status)} Project
      </span>
    );
  }

  getContactFields() {
    if (this.isNew()) {
      return '';
    }

    return (
      <div className="form-row">
        {this.getFormRow({
          label: 'project contact',
          name: 'contact_id',
          component: ContactSelect,
          client: this.state.values.client_id,
          type: 'project',
          onChange: this.selectChangeHandler('contact_id'),
          required: true,
          ...this.getFieldWidth()
        })}

        {this.getFormRow({
          label: 'accounting contact',
          name: 'accounting_id',
          component: ContactSelect,
          client: this.state.values.client_id,
          type: 'accounting',
          onChange: this.selectChangeHandler('accounting_id'),
          required: true,
          ...this.getFieldWidth()
        })}
      </div>
    );
  }

  getRouteGrid() {
    if (this.isNew()) {
      return '';
    }

    return (
      <RouteGrid
        title="Partner Information" paging={false}
        extraParams={{ project_id: this.getId() }}
        routeValues={{
          project_id: this.getId(),
          country: [].concat(this.state.values.countries).shift() || 'US'
        }}
        ref={comp => { this.routeGrid = comp; }}
        connectorProject={this.state.values.connector}
        ioNumber={this.state.values.io_number}
        target_quota={this.state.values.target_quota}
        total_clicks={this.state.values.total_clicks}
        enable_click_quota={this.state.values.enable_click_quota}
        readOnly={this.isArchived()}
        storeState={false}
        showDateFilter
      />
    );
  }

  isReadonlyForm(props) {
    // Make the whole form read-only for closed / archived projects
    return (this.isClosed() && props.name !== 'archive_date') || this.isArchived();
  }

  isClosed() {
    return this.state.values.status === 'closed';
  }

  isArchived() {
    return this.state.values.status === 'archived';
  }

  getDiscountAmount() {
    if (this.state.values.discount) {
      if (this.state.values.discount_type === 'percentage') {
        return `${this.state.values.discount}%`;
      }
      return `${this.state.values.discount}`;
    }

    return '0';
  }

  hasProjectGroup() {
    const prop = this.editingInProgress() ? 'edit' : 'values';
    return this.parseInt(this.state[prop].project_group_id) > 0;
  }

  getInvoiceSettings() {
    return (
      <CollapseCard title="Invoice settings">
        <div className="form-row">
          {this.state.values.discount_type === 'amount'
            ? this.getFormRow({
                name: 'discount',
                type: 'number',
                step: 0.01,
                min: 0,
                formatter: formatCurrency,
                prefix: '$',
                ...this.getFieldWidth()
              })
            : this.getFormRow({
                name: 'discount',
                type: 'number',
                step: 1,
                min: 0,
                max: 100,
                postfix: '%',
                ...this.getFieldWidth()
              })
          }

          {this.getFormRow({
            name: 'discount_type',
            label: 'discount type',
            component: ButtonSelect,
            options: {
              amount: '$',
              percentage: '%'
            },
            ...this.getFieldWidth()
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'prepaid',
            type: 'number',
            step: 0.01,
            min: 0,
            formatter: formatCurrency,
            prefix: '$',
            ...this.getFieldWidth()
          })}
        </div>
      </CollapseCard>
    );
  }

  getLineItemSection() {
    if (this.isUpdate()) {
      return <LineItemGrid 
        title="Line Items" paging={false}
        extraParams={{ project_id: this.getId() }}
        connectorProject={this.state.values.connector}
        readOnly={this.isArchived()}
        adjustTotal={this.getDiscountAmount()}
        storeState={false}
      />;
    } 
      
    return null;
  }

  getCriteria(quotaId, criteria) {
    return Object
      .keys(criteria)
      .map((attributeName) => {
        const attribute = this.state.attributes[attributeName];
        let values = <span className="badge badge-primary">{criteria[attributeName][0]}</span>;
        if (attribute.displayType === 'mr') {
          values = criteria[attributeName]
            .map((attributeValue) => (
              <span className="badge badge-primary" key={attributeName}>
                {attribute.options[
                  Object
                    .keys(attribute.options)
                    .find((optionValue) => optionValue.toString() === attributeValue.toString())
                ]}
              </span>
            ));
        }
        return (
          <div key={`criteria_${quotaId}_${attributeName}`}>{attribute.name} = {values}</div>
        );
      });
  }

  getCountersTable(counters) {
    return (
      <table>
        <tr>
          <td>Clicks</td>
          <td>{counters ? counters.clicks : 0}</td>
        </tr>
        <tr>
          <td>Completes</td>
          <td>{counters ? counters.completes : 0}</td>
        </tr>
      </table>
    );
  }

  getPanelTable(panels) {
    return (
      <table>
        <thead>
          <tr>
            <th width="120">Panel</th>
            <th width="80">Quota</th>
            <th width="100">Clicks</th>
            <th width="100">Completes</th>
            <th width="100">Orig.CPI</th>
            <th width="100">CPI</th>
          </tr>
        </thead>
        <tbody>
          {
            Object
              .keys(panels)
              .map((panel) => {
                if (!Object.keys(this.state.panelApis).includes(panel)) {
                  return null;
                }

                return (
                  <tr key={panel}>
                    <td><img height="22" src={`/panelapi/${this.state.panelApis[panel].image}`} alt="" /></td>
                    <td>{panels[panel].quota}</td>
                    <td>{panels[panel].clicks}</td>
                    <td>{panels[panel].completes}</td>
                    <td>{formatCurrency(panels[panel].originalCpi)}</td>
                    <td>{formatCurrency(panels[panel].cpi)}</td>
                  </tr>
                );
              })
          }
        </tbody>
      </table>
    );
  }

  getConnectorSection() {
    if (
      this.isUpdate() && this.state.values.connector &&
      Object.keys(this.state.panelApis).length &&
      Object.keys(this.state.attributes).length
    ) {
      let content = (<div className="alert alert-warning">Connector Details not available</div>);
      const connectorQuota = this.state.values.connector_quota;

      // Check if there is a background job running at this moment
      let jobReset = '';
      if (this.state.values.current_job) {
        jobReset = (
          <div className="text-danger">
            Background job is running at this moment, you can reset if it is stuck.
            <button 
              type="button"
              className="btn btn-danger"
              onClick={() => {
                this.clientApi('PUT', `${this.getApiPath()}/${this.getId()}/resetjob`)
                  .done((resp) =>
                    this.loadDone(resp, { success: true})
                  )
                  .fail((error) => this.showError(error));
              }}
            >Reset</button><br /><br />
          </div>
        );
      }
      if (connectorQuota) {
        content = (
          <div className="form-row" key="cd2">
            {jobReset}
            <table className="table table-sm table-hover">
              <thead className="thead-light">
                <tr>
                  <th>Criteria</th>
                  <th>Status</th>
                  <th>Quota</th>
                  <th>Counters</th>
                  <th>Panels</th>
                </tr>
              </thead>
              <tbody>
              {
                Object
                  .keys(connectorQuota)
                  .map((quotaId) => (
                    <tr key={quotaId}>
                      <td>{this.getCriteria(quotaId, connectorQuota[quotaId].criteria)}</td>
                      <td width="120" className={`text-${connectorQuota[quotaId].active ? 'success' : 'danger'}`}>
                        {connectorQuota[quotaId].active ? 'Open' : 'Close'}
                      </td>
                      <td width="120">{connectorQuota[quotaId].quota}</td>
                      <td width="170">{this.getCountersTable(connectorQuota[quotaId].counters)}</td>
                      <td width="500">{this.getPanelTable(connectorQuota[quotaId].panels)}</td>
                    </tr>
                  ))
              }
              </tbody>
            </table>
          </div>
        );
      }
      return <CollapseCard className="emi-collapse-card connector-details" title="Connector Details">
        {content}
      </CollapseCard>;
    } 
      
    return null;
  }

  monitorTestPolicy(name) {
    return name === 'policy' && this.state.values.status === 'test' && this.hasProjectGroup();
  }

  showProjectStats() {
    return this.state.values.status === 'live' || this.state.values.status === 'paused' ||
      this.state.values.status === 'closed';
  }

  getFingerprintProps(name, props = {}) {
    const prop = this.editingInProgress() ? 'edit' : 'values';
    const value = this.monitorTestPolicy(name) ? 'monitor' : this.state[prop][name];
    
    return Object.assign({}, {
      name,
      component: ButtonSelect,
      type: props.type || 'text',
      label: props.label || name,
      value,
      ...this.getFieldWidth()
    }, props);
  }

  getFieldWidth() {
    return {
      fieldWidth: { xs: 12, md: 6, xl: 6 },
      labelWidth: { xs: 12, xl: 3 },
      valueWidth: { xs: 12, xl: 9 }
    }
  }

  getFormContent() {
    return (
      <div>
        <Card className="emi-collapse-card">
          <CardHeader>
            <h5>
              Project Settings&nbsp;
              {this.state.values.connector && (<span className="badge badge-warning">Connector</span>)}
              {this.getProjectStatus()}
              {!this.isNew() && <ProjectTag project_id={this.getId()} />}
            </h5>
          </CardHeader>
          <CardBody>
            <div className="form-row d-sm-none">
              {
                this.getHeaderButtonList()
                  .map((button, idx) => (
                    <div key={`prheaderbtn${idx}`} className="col">
                      <div className="form-row justify-content-center">
                        <div className={`col-${5 * this.getHeaderButtonList().length}`}>
                          {button}
                        </div>
                      </div>
                    </div>
                  ))
              }
            </div>

            <div className="form-row">
              {this.getFormRow({
                name: 'name',
                required: true,
                ...this.getFieldWidth()
              })}

              {this.getFormRow({
                label: 'io#',
                name: 'io_number',
                required: true,
                ...this.getFieldWidth()
              })}
            </div>

            <div className="form-row">
              {this.getFormRow({
                label: 'client',
                name: 'client_id',
                component: ClientSelect,
                onChange: this.selectChangeHandler('client_id'),
                required: true,
                ...this.getFieldWidth()
              })}

              {this.getFormRow({
                label: 'customer job#',
                name: 'job_number',
                ...this.getFieldWidth()
              })}
            </div>

            <div className="form-row">
              {this.getFormRow({
                label: 'RM',
                name: 'user_id',
                component: UserSelect,
                type: 'admin',
                department: 'rm',
                active: true,
                onChange: this.selectChangeHandler('user_id'),
                required: true,
                ...this.getFieldWidth()
              })}

              {this.getFormRow({
                label: 'sales',
                name: 'sales_id',
                component: UserSelect,
                type: 'admin',
                department: 'sales',
                active: true,
                onChange: this.selectChangeHandler('sales_id'),
                ...this.getFieldWidth()
              })}
            </div>

            {this.getContactFields()}

            <div className="form-row">
              {this.getFormRow({
                label: 'start date',
                name: 'start_date',
                type: 'date',
                ...this.getFieldWidth()
              })}

              {this.getFormRow({
                label: 'Close date',
                name: 'close_date',
                type: 'date',
                ...this.getFieldWidth()
              })}
            </div>

            <div className="form-row">
              {this.getFormRow({
                name: 'quota',
                required: true,
                type: 'number',
                min: 0,
                step: 1,
                style: { width: 100 },
                ...this.getFieldWidth()
              })}

              {this.getFormRow({
                label: 'country',
                name: 'countries',
                component: CountrySelect,
                onChange: this.selectChangeHandler('countries'),
                isMulti: true,
                ...this.getFieldWidth()
              })}
            </div>

            <div className="form-row">
              {this.getFormRow({
                name: 'enable_click_quota',
                label: 'total clicks',
                required: true,
                component: ButtonSelect,
                options: { true: 'Yes', false: 'No' },
                ...this.getFieldWidth()
              })}

              {this.getFormRow({
                label: 'language',
                name: 'language',
                component: LanguageSelect,
                isClearable: true,
                onChange: this.selectChangeHandler('language'),
                ...this.getFieldWidth()
              })}
            </div>

            <div className="form-row">
              {this.getFormRow({
                label: 'ir',
                name: 'ir_threshold',
                type: 'number',
                min: 0,
                max: 100,
                step: 1,
                postfix: '%',
                formatter: (value) => value === null ? 'N/A' : `${value}%`,
                cssClass: 'fixwidth',
                style: { width: 100 },
                ...this.getFieldWidth()
              })}

              {this.getFormRow({
                label: 'loi',
                name: 'loi_threshold',
                type: 'number',
                postfix: 'mins',
                formatter: (value) => value === null ? 'N/A' : `${value} mins`,
                cssClass: 'fixwidth',
                style: { width: 100 },
                ...this.getFieldWidth()
              })}
            </div>

            <div className="form-row">
              {this.getFormRow({
                label: 'test link',
                name: 'test_link',
                ...this.getFieldWidth()
              })}

              {this.getFormRow({
                label: 'live link',
                name: 'survey_link',
                ...this.getFieldWidth()
              })}
            </div>

            <div className="form-row">
              {this.getFormRow({
                name: 'enable_coverage',
                label: 'enable coverage',
                required: true,
                component: ButtonSelect,
                options: { bg: 'EMI PM', us: 'US RM' },
                fieldWidth: { xs: 12, md: 6, xl: 6 },
                labelWidth: { xs: 12, xl: 3 },
                valueWidth: { xs: 11, xl: 8 },
                leftSideIcon: {
                  icon: 'cogs',
                  onClick: () => {
                    this.toggleModal('coverageSettingModal');
                  }
                }
              })}

              {this.getFormRow({
                label: 'Reporting link',
                name: 'reporting_link',
                ...this.getFieldWidth()
              })}
            </div>

            <div className="form-row">
              {this.getFormRow({
                label: 'Associate RM',
                name: 'associate_rm',
                component: UserSelect,
                type: 'admin',
                department: 'rm',
                active: true,
                isClearable: true,
                onChange: this.selectChangeHandler('associate_rm'),
                ...this.getFieldWidth()
              })}

              {this.getFormRow({
                label: 'CSE',
                name: 'cse',
                component: UserSelect,
                type: 'admin',
                department: 'sales',
                sales_rule: 'CSE',
                active: true,
                isClearable: true,
                onChange: this.selectChangeHandler('cse'),
                ...this.getFieldWidth()
              })}
            </div>

            {(this.isClosed() || this.isArchived()) && 
              <div className="form-row">
                {this.getFormRow({
                  name: 'archive_date',
                  label: 'archive date',
                  type: 'date',
                  ...this.getFieldWidth()
                })}
              </div>
            }

            {this.isUpdate() && 
              <div className="form-row d-none d-sm-block action-buttons">
                <button
                  type="button" className="btn btn-info"
                  onClick={() => this.toggleModal('clientCodeModal')}
                >
                  Client Codes
                </button>
                <button
                  type="button" className="btn btn-info"
                  onClick={() => this.toggleModal('exportMatchModal')}
                >
                  Export Partner IDs
                </button>
                <button
                  type="button" className="btn btn-info"
                  onClick={() => this.toggleModal('attachmentModal')}
                >
                  <i className="fa fa-files-o" aria-hidden="true"></i>{' '}
                  Project Files
                </button>
                <button
                  type="button" className="btn btn-info"
                  onClick={() => this.toggleModal('reconciliationModal')}
                >
                  <i className="fa fa-file-excel-o" aria-hidden="true"></i>{' '}
                  Reconciliation
                </button>
                <button
                  type="button" className="btn btn-info"
                  onClick={() => this.toggleModal('cloneProjectModal')}
                >
                  <i className="fa fa-clone" aria-hidden="true"></i>{' '}
                  Clone Project
                </button>
                <button
                  type="button" className="btn btn-info"
                  onClick={() => this.toggleModal('contactModal')}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>{' '}
                  New Contact
                </button>
                <button
                  type="button" className="btn btn-info"
                  onClick={() => this.toggleModal('feedbackModal')}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i>{' '}
                  Log Feedback
                </button>
                <button
                  type="button" className="btn btn-warning"
                  onClick={() => this.toggleModal('resetModal')}
                >
                  <i className="fa fa-recycle" aria-hidden="true"></i>{' '}
                  Reset ID Status
                </button>
                <button
                  type="button" className="btn btn-warning"
                  onClick={() => this.toggleModal('changeModal')}
                >
                  <i className="fa fa-exchange" aria-hidden="true"></i>{' '}
                  Change Status
                </button>
                <button
                  type="button" className="btn btn-info"
                  onClick={() => this.toggleModal('quicksearchModal')}
                >
                  <i className="fa fa-search" aria-hidden="true"></i>{' '}
                  Quick ID lookup
                </button>
              </div>
            }
          </CardBody>
        </Card>

        <CollapseCard title="Security Settings">
          <div className="form-row">
            {this.isUpdate() && this.getFormRow({
              name: 'project_group_id',
              label: 'project group',
              component: ProjectGroupSelect,
              isClearable: true, 
              onValueChange: this.onGroupChange,
              ...this.getFieldWidth()
            })}

            {this.getFormRow({
              name: 'enabled_devices',
              label: 'enabled devices',
              required: true,
              component: ButtonSelect,
              options: deviceList,
              ...this.getFieldWidth()
            })}
          </div>

          <div className="form-row">
            {this.hasProjectGroup() ? (
              <FormDisabledRow
                {...this.getFingerprintProps('policy', {
                  required: true,
                  options: { monitor: 'Monitor', block: 'Block'}
                })}
              />
            ) : (
              this.getFormRow(this.getFingerprintProps('policy', {
                required: true,
                options: { monitor: 'Monitor', block: 'Block'}
              }))
            )}

            {this.hasProjectGroup() ? (
              <FormDisabledRow
                {...this.getFingerprintProps('fingerprint', {
                  options: fingerprints,
                  multi: true
                })}
              />
            ) : (
              this.getFormRow(this.getFingerprintProps('fingerprint', {
                options: fingerprints,
                multi: true
              }))
            )}
          </div>

          <div className="form-row">
            {this.getFormRow({
              name: 'samplechain_threat_score',
              label: 'Research Defender block score',
              type: 'number',
              required: this.state.values.fingerprint && this.state.values.fingerprint.includes('samplechain'), 
              ...this.getFieldWidth()
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              name: 'research_defender_composite_score',
              label: 'Research Defender composite score',
              type: 'number',
              max: 100,
              min: 1,
              ...this.getFieldWidth()
            })}

            {this.getFormRow({
              name: 'research_defender_activity_count',
              label: 'Research Defender activity count',
              type: 'number',
              min: 1,
              ...this.getFieldWidth()
            })}
          </div>

          {this.currentValue('language') &&
            <div className="form-row">
              {this.getFormRow({
                name: 'enable_research_defender_review',
                label: 'enable RD review',
                component: ButtonSelect,
                options: { true: 'Yes', false: 'No' },
                ...this.getFieldWidth()
              })}

              {this.getFormRow({
                label: 'RD review question',
                name: 'research_defender_review_question',
                component: ResearchDefenderQuestionSelect,
                lng: this.currentValue('language'),
                required: this.currentValue('enable_research_defender_review'),
                onChange: this.selectChangeHandler('research_defender_review_question'),
                ...this.getFieldWidth()
              })}
            </div>
          }

          <div className="form-row">
            {this.hasProjectGroup() ? (
              <FormDisabledRow
                {...this.getFingerprintProps('fingerprint_setting', {
                  label: 'validation',
                  options: fingerprintSettings
                })}
              />
            ) : (
              this.getFormRow(this.getFingerprintProps('fingerprint_setting', {
                label: 'validation',
                options: fingerprintSettings
              }))
            )}

            {this.getFormRow({
              name: 'enable_captcha',
              label: 'captcha',
              required: true,
              component: ButtonSelect,
              options: { true: 'Yes', false: 'No' },
              ...this.getFieldWidth()
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              name: 'secure_callback',
              label: 'secure callback',
              required: true,
              component: ButtonSelect,
              options: { true: 'Yes', false: 'No' },
              ...this.getFieldWidth()
            })}

            {this.getFormRow({
              name: 'status_based',
              label: 'status based',
              required: true,
              component: ButtonSelect,
              options: { true: 'Yes', false: 'No' },
              ...this.getFieldWidth()
            })}
          </div>

          <div className="form-row">
            {this.isUpdate() && this.state.values.secure_callback &&
              <div className="col-12 col-md-6 col-xl-6">
                <div className="row form-group">
                  <label className="text-uppercase col-12 col-xl-3 col-form-label">end url</label>
                  <div className="col-12 col-xl-9">
                    <div className="form-control readonly-field">
                      <Button color="default" type="button" onClick={() => this.toggleModal('endUrlModal')} size="sm">
                        <i className="fa fa-link" aria-hidden="true" />
                      </Button>
                      <EndUrlModal
                        id={this.getId()}
                        isOpen={this.state.endUrlModal}
                        toggle={() => this.toggleModal('endUrlModal')}
                        endUrl={this.state.values.end_url}
                        values={this.state.values.secure_hash}
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
            {this.getFormRow({
              name: 'live_test',
              label: 'Test data',
              required: true,
              component: ButtonSelect,
              options: { true: 'Yes', false: 'No' },
              ...this.getFieldWidth()
            })}
          </div>

          {this.showProjectStats() &&
            <div className="form-row" style={{borderTop: '1px solid #999999'}}>
              <FormDisabledRow
                label="IR"
                name="incidence"
                id="incidence"
                value={this.state.values.incidence === null ? '' : `${this.state.values.incidence}%` }
                {...this.getFieldWidth()}
              />

              <FormDisabledRow
                label="LOI"
                name="loi"
                id="loi"
                value={this.state.values.loi === null ? '' : `${precisionRound(this.state.values.loi / 60, 2)}m`}
                {...this.getFieldWidth()}
              />

              {Object.keys(deviceStats).map(deviceType =>
                <FormDisabledRow
                  label={deviceType}
                  name={deviceType}
                  key={deviceType}
                  value={`${calcPercentage(
                    parseInt(this.state.values.redirect_total, 10), deviceStats[deviceType].reduce((prev, curr) => {
                      return prev + this.getDeviceCount(curr);
                    }, 0)
                  )} %`}
                  {...this.getFieldWidth()}
                />
              )}
            </div>
          }

        </CollapseCard>

        {this.getRouteGrid()}

        {this.isUpdate() && <PanelApiLogs projectId={this.getId()} />}

        {this.isUpdate() && this.getPanelistManagement()}

        {this.getInvoiceSettings()}

        {this.getConnectorSection()}

        {this.getLineItemSection()}
      </div>
    );
  }

  getDeviceCount(type) {
    return (this.state.values.device_stat && this.state.values.device_stat[type]) ? this.state.values.device_stat[type] : 0;
  }

  onProjectAttributeChanged() {
    this.reloadData('prescreening');
    this.reloadData('clickbalance');
  }

  reloadData(prop) {
    if (this[prop]) {
      this[prop].reloadData();
    }
  }

  getPanelistManagement() {
    return (
      <CollapseCard title="Panelist Management" className="emi-collapse-card panelist-management">
        <ProjectAttributeGrid
          extraParams={{ project_id: this.getId() }}
          readOnly={this.state.values.status === 'archived'}
          storeState={false}
          paging={false}
          onChange={() => this.onProjectAttributeChanged()}
        />

        <CollapseCard title="Prescreening">
          <div className="form-row">
            {this.getFormRow({
              name: 'prescreen',
              label: 'Enabled',
              component: ButtonSelect,
              options: { true: 'Yes', false: 'No' },
              ...this.getFieldWidth()
            })}

            {this.getFormRow({
              name: 'prescreen_intro',
              label: 'intro text',
              type: 'textarea',
              ...this.getFieldWidth()
            })}

            <div className="col-12 col-xl-1 text-right">
              <Button 
                type="button"
                color="primary"
                onClick={() => this.reloadData('prescreening')}
              >
                <i className="fa fa-refresh"></i>&nbsp;Refresh
              </Button>
            </div>
            
          </div>
          {this.state.values.prescreen &&
            <Prescreening
              project_id={this.getId()}
              ref={comp => { this.prescreening = comp; }}
            />
          }
        </CollapseCard>

        <CollapseCard title="Click Balancing" className="emi-collapse-card clickbalancing" >
          <div className="form-row">
            {this.getFormRow({
              name: 'clickbalance',
              label: 'Enabled',
              component: ButtonSelect,
              options: { true: 'Yes', false: 'No' },
              ...this.getFieldWidth()
            })}

            {this.getFormRow({
              name: 'clickbalance_track',
              label: 'Track by',
              component: ButtonSelect,
              options: { click: 'Click', complete: 'Complete' },
              ...this.getFieldWidth()
            })}

            {this.getFormRow({
              name: 'clickbalance_quota',
              label: 'Quota',
              ...this.getFieldWidth()
            })}

            <div className="col-12 col-xl-1 text-right">
              <Button 
                type="button"
                color="primary"
                onClick={() => this.reloadData('clickbalance')}
              >
                <i className="fa fa-refresh"></i>&nbsp;Refresh
              </Button>
            </div>
          </div>

          {this.state.values.clickbalance &&
            <ClickBalancing
              project_id={this.getId()}
              project={this.state.values}
              ref={comp => { this.clickbalance = comp; }}
            />
          }
          </CollapseCard>
      </CollapseCard>);
  }

  currentValue(field) {
    return this.state[this.editingInProgress() && hasOwnProperty(this.state.edit, field) ? 'edit': 'values'][field];
  }

  valueChanged(name, value, isEdit) {
    if (name === 'countries' && value.length === 0) {
      return super.valueChanged(name, null, isEdit);
    }

    if (name === 'enabled_devices') {
      const devices = [].concat(value);
      return super.valueChanged(name, devices.length === 0 ? null : devices, isEdit);
    }

    if (name === 'enable_coverage') {
      const coverage = [].concat(value);
      return super.valueChanged(name, coverage.length === 0 ? null : coverage, isEdit);
    }

    if (name === 'fingerprint') {
      const changed = {
        [name]: [].concat(value)
      };

      if (value.length === 0) {
        Object.assign(changed, { fingerprint: null, fingerprint_setting: null });
      } else if (!this.state[isEdit ? 'edit': 'values'].fingerprint || this.state[isEdit ? 'edit': 'values'].fingerprint.length === 0) {
        Object.assign(changed, { fingerprint_setting: 2 });
      }

      return changed;
    }

    if (name === 'language') {
      const changed = {
        [name]: value,
      };

      if (!value) {
        Object.assign(changed, {
          enable_research_defender_review: false,
          research_defender_review_question: ''
        });
      } else if (this.state.values[name] !== value) {
        Object.assign(changed, {
          research_defender_review_question: ''
        });
      }

      return changed;
    }

    if (name === 'client_id') {
      const changed = {
        [name]: value
      };

      // Assign default CSE to the Job
      if (value && this.state.clientCse[value]) {
        Object.assign(changed, { cse: this.state.clientCse[value] });
      }

      // Assign default Sales to the Job
      if (value && this.state.clientSales[value]) {
        Object.assign(changed, { sales_id: this.state.clientSales[value] });
      }
      return changed;
    }

    return super.valueChanged(name, value, isEdit);
  }

  getActionButtons() {
    return (
      <CardFooter className="action-buttons d-none d-lg-block">
        {this.isNew() && <SaveButton model={this.modelName} />}
        {this.isUpdate() && <DeleteAction model={this.modelName} onDelete={this.onDelete} />}
      </CardFooter>
    );
  }

  toggleModal = (prop) => {
    this.setState({
      [prop]: !this.state[prop]
    });
  }

  getClientCodeForm() {
    return <ClientCodeForm
      id={this.getId()}
      isOpen={this.state.clientCodeModal}
      toggle={() => this.toggleModal('clientCodeModal')}
      onSuccess={() => console.log('CALLED SUC')}
      onDelete={() => console.log('CALLED DEL')}
    />;
  }

  hasValues() {
    return this.state.loadingData === false;
  }

  render() {
    return (
      <div>
        {super.render()}
        {this.getClientCodeForm()}
        {this.isUpdate() &&
          <ExportMatchForm
            values={{ id: this.getId(), type: 'project' }}
            isOpen={this.state.exportMatchModal}
            toggle={() => this.toggleModal('exportMatchModal')}
          />
        }
        {this.isUpdate() &&
          <CloneProjectForm
            id={this.getId()}
            values={{
              io_number: this.state.values.io_number,
              source_io_number: this.state.values.io_number
            }}
            secure_callback={this.state.values.secure_callback}
            clickbalance={this.state.values.clickbalance}
            isOpen={this.state.cloneProjectModal}
            toggle={() => this.toggleModal('cloneProjectModal')}
          />
        }
        {this.isUpdate() &&
          <AttachmentModal
            values={{
              project_id: this.getId(),
              io_number: this.state.values.io_number,
              countries: this.state.values.countries
            }}
            isOpen={this.state.attachmentModal}
            toggle={() => this.toggleModal('attachmentModal')}
          />
        }
        {this.isUpdate() &&
          <ReconciliationModal
            values={{
              project_id: [this.getId()]
            }}
            isOpen={this.state.reconciliationModal}
            toggle={() => this.toggleModal('reconciliationModal')}
          />
        }

        {this.isUpdate() && this.hasValues() &&
          <FeedbackForm
            values={{
              client_id: this.state.values.client_id,
              io_number: this.state.values.io_number,
              feedback_date: (new Date()).toISOString().substring(0, 10),
            }}
            isOpen={this.state.feedbackModal}
            toggle={() => this.toggleModal('feedbackModal')}
            onSuccess={() => this.toggleModal('feedbackModal')}
            onDelete={() => this.toggleModal('feedbackModal')}
          />
        }

        {this.isUpdate() &&
          <ResetIDStatusModal
            project_id={this.getId()}
            isOpen={this.state.resetModal}
            toggle={() => this.toggleModal('resetModal')}
            onSuccess={() => {
              if (this.routeGrid) this.routeGrid.reload();
            }}
          />
        }

        {this.isUpdate() &&
          <ChangeStatusModal
            project_id={this.getId()}
            isOpen={this.state.changeModal}
            toggle={() => this.toggleModal('changeModal')}
            title="Change status"
            onSuccess={() => {
              if (this.routeGrid) this.routeGrid.reload();
            }}
          />
        }

        {this.isUpdate() &&
          <QuickSearchModal
            project_id={this.getId()}
            isOpen={this.state.quicksearchModal}
            toggle={() => this.toggleModal('quicksearchModal')}
            onSuccess={() => {}}
          />
        }

        {this.isUpdate() &&
          <AlertModal
            projectId={this.getId()}
            isOpen={this.state.alertModal}
            toggle={() => this.toggleModal('alertModal')}
            onSuccess={() => {}}
          />
        }

        {this.isUpdate() &&
          <CoverageSettingModal
            projects={[this.getId()]}
            isOpen={this.state.coverageSettingModal}
            toggle={() => this.toggleModal('coverageSettingModal')}
            onSuccess={() => this.toggleModal('coverageSettingModal')}
            onDelete={() => this.toggleModal('coverageSettingModal')}
          />
        }

        {this.isUpdate() &&
          <StatusUpdateModal id="job-status" updateFn={this.updateProjectStatus} />
        }

        <ContactModal
          id={0}
          values={{
            client_id: this.state.values.client_id
          }}
          isOpen={this.state.contactModal}
          toggle={() => this.toggleModal('contactModal')}
        />
      </div>
    );
  }

}
