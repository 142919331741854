import { Fragment, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Row, Col, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import QuotaTag from './QuotaTag';
import { getTabColor } from './colors.js';

function TotalRow({ irs, totals, n }) {
  return (
    <tr>
      <th scope="row" className="text-nowrap align-middle">Total</th>
      {irs.map(percent =>
        <Fragment key={percent}>
          <td>{' '}</td>
          <td>
            <Input
              bsSize="sm"
              className={classNames({'not-feasible': n !== 'MAX' && parseInt(n, 10) > parseInt(totals[percent].n_awarded, 10)})}
              value={totals[percent].n_awarded}
              disabled
            />
          </td>
          <td>
            <InputGroup size="sm">
              <InputGroupAddon addonType="prepend">$</InputGroupAddon>
              <Input
                value={totals[percent].n_awarded ? (totals[percent].cost / totals[percent].n_awarded).toFixed(2) : 0}
                disabled
              />
            </InputGroup>
          </td>
          <td>
            <InputGroup size="sm">
              <InputGroupAddon addonType="prepend">$</InputGroupAddon>
              <Input
                value={(totals[percent].cost).toFixed(2)}
                disabled
              />
            </InputGroup>
          </td>
          <td>{' '}</td>
        </Fragment>
      )}
    </tr>
  );
}

function MarginField({ value, totals, ir, onChange, edited }) {
  const [percent, setPercent] = useState(value || '');

  useEffect(() => {
    setPercent(value);
  }, [value]);

  return (
    <InputGroup size="sm">
      <Input
        min={0}
        type="number"
        step={0.01}
        name="margin"
        className={classNames({'field-changed': edited})}
        value={percent}
        disabled
      />
      <InputGroupAddon addonType="prepend">%</InputGroupAddon>
    </InputGroup>
  );
}

function ClientRow({ irs, totals, cpi, onChange, resetEdited }) {
  const [editedField, setEditedField] = useState({});

  useEffect(() => {
    if (resetEdited) {
      setEditedField({});
    }
  }, [resetEdited]);

  return (
    <tr>
      <th scope="row" className="text-nowrap align-middle">&nbsp;</th>
      {irs.map(percent =>
        <Fragment key={percent}>
          <td>{' '}</td>
          <td>{' '}</td>
          <td align='center'>
            <b>Client CPI</b>
            <InputGroup size="sm">
              <InputGroupAddon addonType="prepend">$</InputGroupAddon>
              <Input
                min={0}
                type="number"
                step={0.01}
                name="cpi"
                className={classNames({'field-changed': editedField?.[percent]})}
                value={cpi[percent] || 0}
                disabled
              />
            </InputGroup>
          </td>
          <td align='center'>
            <b>Client Cost</b>
            <InputGroup size="sm">
              <InputGroupAddon addonType="prepend">$</InputGroupAddon>
              <Input
                value={(cpi[percent] ? cpi[percent] * totals[percent].n_awarded : 0).toFixed(2)}
                disabled
              />
            </InputGroup>
          </td>
          <td align='center'>
            <b>Margin</b>
            <MarginField
              value={(totals[percent].n_awarded && cpi[percent] ? ((cpi[percent] - totals[percent].cost / totals[percent].n_awarded) / cpi[percent] * 100) : 0).toFixed(2)}
              totals={totals}
              ir={percent}
              edited={editedField?.[percent]}
            />
          </td>
        </Fragment>
      )}
    </tr>
  );
}

export default function QuotaQuotation({ quota, hideSkipped, resetEdited }) {
  const [editedField, setEditedField] = useState({});
  const totals = {};

  useEffect(() => {
    if (resetEdited) {
      setEditedField({});
    }
  }, [resetEdited]);

  return (
    <Fragment>
      <Row className="justify-content-center my-3">
        <Col lg={8} className="text-center font-weight-bold">
          <h4>{quota.description}</h4>
        </Col>
      </Row>
      <Row className="justify-content-center text-center font-weight-bold text-info mb-2">
        <Col sm="6" md="2">Country = {quota.country}</Col>
        <Col sm="6" md="2">LOI = {quota.loi} mins</Col>
        <Col sm="12" md="2">N = {quota.max_possible ? 'MAX' : quota.n}</Col>
        <Col sm="12" md="2">Vertical = {quota.vertical.toUpperCase()}</Col>
        <Col sm="12" md="3">Sample type = {quota.sample_type.toUpperCase()}</Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <QuotaTag quota_id={quota.id} />
        </Col>
      </Row>
      <Row>
        <Col className="overflow-auto">
          <table className="table table-hover quota-table">
            <thead className="thead-dark">
              <tr>
                <th scope="col" rowSpan={2} className="align-middle">Partner</th>
                {quota.ir.map((percent) =>
                  <th
                    key={percent} scope="col"
                    className={classNames('text-center')}
                    style={getTabColor(percent)}
                    colSpan={5}
                  >
                    <div className="form-check form-check-inline">
                      <input className="form-check-input"
                        type="radio" name={`won_${quota.id}`} id={`won_${quota.id}_${percent}`}
                        value="option1" checked={quota.won === percent}
                        disabled
                      />
                      <label className="form-check-label bid_ir" htmlFor={`won_${quota.id}_${percent}`}>
                        {percent}%
                      </label>
                    </div>
                  </th>
                )}
              </tr>
              <tr className="text-center">
                {quota.ir.map(percent =>
                  <Fragment key={percent}>
                    <th scope="col">N</th>
                    <th scope="col">N&nbsp;Awarded</th>
                    <th scope="col">CPI</th>
                    <th scope="col">Cost</th>
                    <th scope="col">Priority</th>
                  </Fragment>
                )}
              </tr>
            </thead>
            <tbody>
              {(quota.partners || []).map(partner =>
                <tr key={partner.id}>
                  <th scope="row" className="text-nowrap align-middle">{partner.name}</th>
                  {quota.ir.map(percent => {
                    partner.irs[percent] ??= {};
                    totals[percent] ??= { cost: 0, n_awarded: 0 };
                    if (parseInt(partner.irs[percent].priority, 10) === 1) {
                      totals[percent].n_awarded += Number(partner.irs[percent].n_awarded || 0);
                      totals[percent].cost += (partner.irs[percent].n_awarded ?? 0) * (partner.irs[percent].cpi ?? 0);
                    }

                    const badAwarded = !isNaN(parseInt(partner.irs[percent].n, 10)) && parseInt(partner.irs[percent].n, 10) < partner.irs[percent].n_awarded;
                    return hideSkipped && partner.irs[percent].skip ? (
                      <Fragment key={percent}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </Fragment>
                    ) : (
                      <Fragment key={percent}>
                        <td style={{ verticalAlign: partner.irs[percent].best_effort || partner.irs[percent].skip ? 'middle': null }}>
                          {partner.irs[percent].best_effort && 
                            <span className="text-info text-nowrap">Best effort</span>
                          }
                          {partner.irs[percent].skip && 
                            <span className="text-warning text-nowrap">Passed</span>
                          }
                          {!partner.irs[percent].best_effort && !partner.irs[percent].skip && 
                            <Input
                              bsSize="sm"
                              min={0}
                              type="number"
                              step={1}
                              name="n"
                              className={classNames({'field-changed': editedField?.[partner.id]?.[percent]?.n})}
                              value={partner.irs[percent].n}
                              disabled
                            />
                          }
                        </td>
                        <td>
                          <Input
                            bsSize="sm"
                            min={0}
                            type="number"
                            step={1}
                            name="n_awarded"
                            className={classNames({'text-danger': badAwarded, 'field-changed': editedField?.[partner.id]?.[percent]?.n_awarded})}
                            value={partner.irs[percent].n_awarded}
                            disabled
                          />
                        </td>
                        <td>
                          <InputGroup size="sm">
                            <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                            <Input
                              min={0}
                              type="number"
                              step={0.01}
                              name="cpi"
                              className={classNames({'field-changed': editedField?.[partner.id]?.[percent]?.cpi})}
                              value={partner.irs[percent].cpi}
                              disabled
                            />
                          </InputGroup>
                        </td>
                        <td>
                          <InputGroup size="sm">
                            <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                            <Input
                              min={0}
                              type="number"
                              step={0.01}
                              name="cost"
                              value={(partner.irs[percent].n_awarded * partner.irs[percent].cpi).toFixed(2)}
                              disabled
                            />
                          </InputGroup>
                        </td>
                        <td>
                          <Input
                            bsSize="sm"
                            min={0}
                            type="number"
                            step={1}
                            name="priority"
                            className={classNames({'field-changed': editedField?.[partner.id]?.[percent]?.priority})}
                            value={partner.irs[percent].priority}
                            disabled
                          />
                        </td>
                      </Fragment>
                    );
                  })}
                </tr>
              )}
              {Object.keys(totals).length > 0 &&
                <>
                  <TotalRow irs={quota.ir} totals={totals} n={quota.max_possible ? 'MAX' : quota.n} />
                  <ClientRow
                    irs={quota.ir}
                    totals={totals}
                    cpi={quota.cpi}
                  />
                </>
              }
            </tbody>
          </table>
        </Col>
      </Row>
    </Fragment>
  );
}
