import React, { Component } from 'react';
import ProjectGroupFilter from './Filter';
import ProjectGroupGrid from './Grid';
import { filterList } from 'emi-frontend';

const ProjectGroupList = filterList(
  ProjectGroupFilter,
  [ProjectGroupGrid]
);

export default class List extends Component {

  render() {
    return (
      <div className="row">
        <div className="col">
          <ProjectGroupList />
        </div>
      </div>
    );
  }
}
