import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class AttributeTargetField extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: props.value
    };
  }

  static propTypes = { 
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    onChange: PropTypes.func.isRequired
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.valueDidChanged(nextProps)) {
      this.setState({ value: nextProps.value });
    }
  }

  valueDidChanged(props) {
    return this.state.value !== props.value;
  }

  saveChanges = () => {
    if (this.valueDidChanged(this.props)) {
      this.props.onChange(this.state.value);
    }
  }

  render() {
    return (
      <input
        type="number" step="1" min="0" value={this.state.value}
        onChange={e => this.setState({ value: e.target.value })}
        onBlur={this.saveChanges}
        className={classNames('form-control', { 'is-invalid': this.valueDidChanged(this.props) })}
      />
    );
  }
}