export const methodology = ['adhoc','community','tracker','ihut'];

export const vertical = ['consumer','b2b','healthcare'];

export const sampleType = [
  'Consumer', 'B2B', 'Community Builds', 'Healthcare', 'Other', 'USH', 'Mobile',
  'Niche', 'Expert Network', 'Patient/Ailment'
];
export const deviceList = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktop: 'desktop',
  other: 'other'
};

export const otherCostType = [
  'P&H',
  'Translation',
  'Coding',
  'DP',
  'PM Fees',
  'Internal Fees',
  'Other'
];
