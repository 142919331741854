import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

export default class RouteVariables extends Component {

  state = {
    modal: false,
    variable: ''
  };

  static propTypes = {
    variables: PropTypes.arrayOf(PropTypes.string),
    textOnly: PropTypes.bool,
    onValueChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    textOnly: false
  };

  hideVariableModal = () => {
    this.setState({ modal: false });
  }

  showVariableModal = () => {
    this.setState({ 
      modal: true,
      variable: ''
    });
  }

  addVariable = () => {
    const variable = this.state.variable.trim();

    this.hideVariableModal();

    if (variable) {
      this.props.onValueChange(this.props.value.concat(variable));
    }
  }

  removeVariable = (idx) => {
    const variables = this.props.value.slice();
    variables.splice(idx, 1);

    this.props.onValueChange(variables);
  }

  render() {
    const { value: variables, textOnly } = this.props;

    if (textOnly) {
      return variables.map((variable, idx) => { 
        return (
          <Button key={idx} type="button" className="route-variable-btn" color="secondary" size="sm" style={{ marginRight: 3 }} disabled>
            {variable}
          </Button>
        ); 
      });
    }
    
    return (
      <div className="form-control variables-field">
        <Modal isOpen={this.state.modal} toggle={this.hideVariableModal}>
          <ModalHeader toggle={this.hideVariableModal}>Variable</ModalHeader>
          <ModalBody>
            <Input
              id="variable"
              type="text"
              name="variable"
              value={this.state.variable}
              onChange={(event) => this.setState({ variable: event.target.value })}
              required
            />
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button color="primary" size="sm" onClick={this.addVariable}>Add</Button>{' '}
            <Button color="secondary" size="sm" onClick={this.hideVariableModal}>Cancel</Button>
          </ModalFooter>
        </Modal>
        {variables.map((variable, idx) => { 
          return (
            <Button
              key={idx} className="route-variable-btn" color="secondary"
              size="sm" style={{ marginRight: 3 }}
              onClick={() => this.removeVariable(idx)}
            >
              {variable} | <i className="fa fa-close text-danger"></i>
            </Button>
          );
        })}
        <Button className="btn btn-success btn-sm route-variable-btn" onClick={this.showVariableModal}>
          <i className="fa fa-plus-circle"></i>
        </Button>
      </div>
    );
  }
}
