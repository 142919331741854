import React from 'react';
import { ReconciliationForm } from '../abstract';

export default class Reconciliation extends ReconciliationForm {

  collectFormData() {
    const formData = super.collectFormData();
    formData.append('ps_survey_id', this.props.partnerProjectId);
    return formData;
  }

  successAction = (response) => {
    this.loadDone(response, {
      message : 'File successfully uploaded',
      values: {
        result: response.data,
        [this.props.fileName]: ''
      }
    });
  }

  hasResult() {
    return Object.keys(this.state.values.result || {}).length > 0;
  }

  getFormContent() {
    return (
      <div>
        {this.hasResult() &&
          <ul className="list-group mb-3">
            <li className="list-group-item">Adjustment ID: {this.state.values.result.adjustment_id}</li>
            {Object.keys(this.state.values.result.summary).map(key => {
              const val = this.state.values.result.summary[key];
              if (typeof val !== 'object' && !Array.isArray(val)) {
                return (
                  <li key={key} className="list-group-item">
                  {key}
                  <span className="badge badge-pill badge-primary float-right">
                    {this.state.values.result.summary[key]}
                  </span>
                </li>);
              }
              return '';
            })}
          </ul>
        }
        <div className="alert alert-info" role="alert">
          You can upload XLSX file. 
          No header is needed in the first row. 
          The first column should contain the TRANSACTION IDs.<br />
          Please don't forget to change the Status to Closed on the first tab.
        </div>
        {super.getFormContent()}
      </div>
    );
  }
}
