import DefinitionsSelect from './DefinitionsSelect';

export default class SurveyStatusSelect extends DefinitionsSelect {

  get definitionsPath() {
    return 'survey_statuses';
  }

  get valueProperty() {
    return 'surveyStatusId';
  }
}
