import React from 'react';
import PropTypes from 'prop-types';
import { LoadServerData, ClipboardProvider } from '../abstract';
import Filters from './Filters';
import QuotaGroups from './QuotaGroups';

export default class QuotaPlan extends LoadServerData {

  constructor(props) {
    super(props, 'attributes');

    this.state.loadingData = this.hasRightProps(props);
  }

  static propTypes = {
    routeId: PropTypes.number.isRequired,
    partnerId: PropTypes.number.isRequired,
    partnerProjectId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    countryISOCode: PropTypes.string.isRequired,
    languageISOCode: PropTypes.string.isRequired,
    quotaPlan: PropTypes.shape({
      filters: PropTypes.array,
      quotaGroups: PropTypes.array,
    }).isRequired,  
    onChange: PropTypes.func.isRequired
  };

  getApiUrl() {
    return `/panelapi/${this.props.partnerId}/attributes`;
  }

  getApiMethod() {
    return 'POST';
  }

  getLoadParams(props) {
    return {
      countryCode: props.countryISOCode,
      languageCode: props.languageISOCode
    };
  }

  getApiData() {
    return this.getLoadParams(this.props);
  }

  hasRightProps(props) {
    return props.countryISOCode.length > 0 && props.languageISOCode.length > 0;
  }

  shouldLoadData() {
    return this.hasRightProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.hasRightProps(nextProps) && 
        (nextProps.countryISOCode !== this.props.countryISOCode ||
        nextProps.languageISOCode !== this.props.languageISOCode)) {

      this.loadData(
        this.getLoadParams(nextProps)
      );
    }
  }

  renderContent() {
    // const { quotas, ...props } = this.props;

    return (
      this.state.attributes !== null &&
      <ClipboardProvider>
        <Filters
          questions={this.state.attributes}
          filters={this.props.quotaPlan.filters}
          onChange={this.props.onChange}
        />
        <QuotaGroups
          questions={this.state.attributes}
          quotaGroups={this.props.quotaPlan.quotaGroups}
          onChange={this.props.onChange}
        />
      </ClipboardProvider>
    );
  }
}