import 'bootstrap/js/dist/modal';

export default function ReminderModal({ id, updateFn }) {

  function onButtonClick(event) {
    const { value } = event.target;
    if (value === 'true') {
      updateFn();
    }
  }

  return (
    <div className="modal fade" id={id} tabIndex="-1" aria-labelledby={`${id}-label`} aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`${id}-label`}>Accounting/Invoice reminder</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            Please check that all data is properly entered prior to finalizing the invoice, especially the <b>Close Date</b> and the <b>Accounting Contact</b> fields.<br /><br />
            Are you sure the Invoice can be finalized?
          </div>
          <div className="modal-footer justify-content-center">
            <button type="button" className="btn btn-primary" value={true} data-dismiss="modal" onClick={onButtonClick}>Yes</button>
            <button type="button" className="btn btn-secondary" value={false} data-dismiss="modal" onClick={onButtonClick}>No</button>
          </div>
        </div>
      </div>
    </div>
  );
}
