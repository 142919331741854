import React from 'react';
import { AbstractForm, CollapseCard, ButtonSelect, formatCurrency, ReactSelect, LocalSelect } from 'emi-frontend';
import { departments, userStatus, alertTypes } from './constants';

function AlertTypes(props) {
  if (props.textOnly) {
    return Array.isArray(props.value) ? props.value.map(type => alertTypes[type]).join(', ') : '';
  }

  return (<ReactSelect {...props} />);
}

class UserForm extends AbstractForm {
  
  getDefaultValues() {
    return {
      name: '',
      email: '',
      user_type: 'admin',
      password: '',
      active: true,
      initials: '',
      department: [],
      yearly_quota: '',
      system_alerts: true,
      alert_types: Object.keys(alertTypes),
      imap_password: '',
      sales_rule: [],
      sales_pod: '',
      hubspot_id: '',
    };
  }

  getApiPath = () => {
    return '/user';
  }

  cancelPath = () => {
    return '/users';
  }

  get modelName() {
    return 'user';
  }

  redirectPath = () => {
    return this.cancelPath();
  }

  valueChanged(name, value, isEdit) {
    if (name === 'sales_rule') {
      const rules = [].concat(value);
      return super.valueChanged(name, rules.length === 0 ? null : rules, isEdit);
    }

    return super.valueChanged(name, value, isEdit);
  }

  getFormFields() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            name: 'name',
            required: true,
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}

          {this.getFormRow({
            name: 'initials',
            label: 'Class',
            required: true,
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'email',
            required: true,
            autoComplete: 'noemail',
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}

          {this.getFormRow({
            name: 'password',
            type: 'password',
            required: true,
            autoComplete: 'new-password',
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'imap_password',
            label: 'IMAP Password',
            type: 'password',
            autoComplete: 'imap-password',
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}

          {this.getFormRow({
            name: 'hubspot_id',
            label: 'hubspot id',
            type: 'number',
            step: 1,
            min: 0,
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'active',
            label: 'Status',
            required: true,
            component: ButtonSelect,
            options: userStatus,
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}

          {this.getFormRow({
            name: 'department',
            component: ButtonSelect,
            options: departments,
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'yearly_quota',
            label: 'yearly quota',
            type: 'number',
            step: 1,
            min: 0,
            formatter: formatCurrency,
            prefix: '$',
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            name: 'system_alerts',
            label: 'System alerts',
            required: true,
            component: ButtonSelect,
            options: userStatus,
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}

          {this.getFormRow({
            name: 'alert_types',
            label: 'Alert types',
            isMulti: true,
            component: AlertTypes,
            onChange: this.selectChangeHandler('alert_types'),
            options: Object.entries(alertTypes).map(([value, label]) => ({value, label})),
            fieldWidth: { xs: 12, lg: 6 },
            labelWidth: { xs: 12, lg: 4 },
            valueWidth: { xs: 12, lg: 8 }
          })}
        </div>

        {Array.isArray(this.state.values.department) && this.state.values.department.includes('sales') && (
          <div className="form-row">
            {this.getFormRow({
              name: 'sales_rule',
              label: 'Sales rule',
              component: ButtonSelect,
              options: {
                AE: 'AE',
                CSE: 'CSE'
              },
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.getFormRow({
              name: 'sales_pod',
              label: 'POD',
              component: LocalSelect,
              isClearable: true,
              options: [{ value: 'Harpoons', label: 'Harpoons' }, { value: 'Rainmakers', label: 'Rainmakers'}],
              onChange: this.selectChangeHandler('sales_pod'),
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>
        )}
      </div>
    );
  }

  getFormContent() {
    return (
      <div>
        <CollapseCard title="User Profile">
          {this.getFormFields()}
        </CollapseCard>
      </div>
    );
  }
}

export default UserForm;
