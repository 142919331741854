import React from 'react';
import classNames from 'classnames';
import { GridMobileRow } from 'emi-frontend';

export default class Row extends GridMobileRow {

  getProgressClass() {
    return {
      incomplete: this.props.row.progress < 100,
      complete: this.props.row.progress >= 100
    }
  }

  getProgress() {
    return (
      <div key={`progress${this.props.row.id}`} className="grid-progress">
        <div
          className={classNames(this.getProgressClass())} 
          style={{ width: `${this.props.row.progress > 100 ? 100 : this.props.row.progress}%`}}
        />
      </div>
    );
  }

  getMainContent() {
    return (
      <div className="d-flex flex-column grid-cell mobile-cell" style={{flexGrow: 1}}>
        <div className="d-flex flex-row">
          <div className="grid-cell name">{this.props.row.name}</div>
          <div className="grid-cell d-flex justify-content-end">
            <div className="gray">{this.props.row.io_number}</div>
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className={classNames('grid-cell', {complete: this.props.row.progress >= 100})} style={{width: 50}}>{this.props.row.progress}%</div>
          <div className="grid-cell d-flex justify-content-end"><div>{this.props.row.client_name}</div></div>
        </div>
      </div>
    );
  }

  getData() {
    return (
      <div key={`cont${this.props.row.id}`} className="grid-row-cell d-flex">
        {this.getButtonFromColumn('partner', 34)}
        {this.getMainContent()}
        {this.getButtonFromColumn('stop', 30)}
        {this.getButtonFromColumn('play', 30)}
      </div>
    );
  }

  getContent() {
    return [
      this.getProgress(),
      this.getData()
    ];
  }
}
