import React from 'react';

const fields = {
  attempts: 'Total number of panelists that entered the project',
  completes: 'Total number of panelists that completed the project',
  screenouts: 'Total number of panelists that got screened out of the project',
  overquotas: 'Total number of panelists that triggered an overquota for the project',
  starts: 'Total number of panelists currently in the project taking a survey',
  conversion: 'Ratio of completes over attempts. (ranges from 0%-100%)',
  remainingCompletes: 'Total remaining completes for the project',
  actualMedianLOI: 'Calculated median length of interview from actual panelists',
  incurredCost: 'Incurred cost for the project till now',
  estimatedCost: 'Estimated cost for the whole project',
  last_block_loi: 'LOI of last block of respondents. An indication of most recent characteristics.',
  stated_incidence: 'The incidence in percentage',
  last_block_incidence: 'Incidence rate for the most recent block of respondents. An indication of recent characteristics.',
  current_overall_incidence: 'The current incidence of the survey'
};

function getValue(report, field) {
  if (!report) {
    return '-';
  }

  return Object.prototype.hasOwnProperty.call(report, field) ? report[field] : '-';
}

function hasOwnProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop)
}

export default function ReportTable({ report }) {
  return (
    <table className="table table-hover top-margin-10">
      <thead className="thead-dark">
        <tr className="text-center">
          <th scope="col" colSpan="2">{report.title} ({report.state})</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(fields).filter(field => hasOwnProperty(report, field)).map(key => 
          <tr key={key}>
            <th scope="row">{fields[key]}</th>
            <td className="text-center">{getValue(report, key)}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
