import { Filter } from 'emi-frontend';

export default class ProposalFilter extends Filter {

  constructor(props) {
    super(props);

    this.state.searchTitle = 'Search by Proposal Name';
  }

  get filterName() {
    return 'proposalFilter';
  }
}
