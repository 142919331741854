import React from 'react';
import { AbstractForm, CreatableReactSelect } from 'emi-frontend';

export default class UsersList extends AbstractForm {

  get modelName() {
    return 'Users';
  }

  getDefaultValues(props) {
    return {
      users: [],
      project_id: props.partnerProjectId,
      user_list: `${props.type}Users`
    };
  }

  getId = () => {
    return this.props.partnerProjectId;
  }

  isNew() {
    return true;
  }

  shouldLoadData() {
    return true;
  }

  showDeleteAction() {
    return this.state.values.users.length > 0;
  }

  getFormHeader() {
    return null;
  }

  getApiPath = () => {
    return `/panelapi/${this.props.routeId}/project/users`;
  }

  loadFormData() {
    this.setState({
      loadingData: true
    }, () =>
      this.clientApi('POST', this.getApiPath(), this.getFormData())
        .done((resp) => this.loadDone(resp))
        .fail(this.loadFail)
    );
  }

  sendFormData = () => {
    return this.clientApi('POST', this.getApiPath(), { ...this.getFormData(), method: 'POST' });
  }

  successAction = (response) => {
    const { users = [] } = response.data || {};
    this.updateUsers(users);
    this.setState({ message: 'Successfully updated' });
  }

  onDelete = () => {
    this.clientApi('POST', this.getApiPath(), { ...this.getFormData(), method: 'DELETE' })
      .done(this.successAction)
      .fail(this.showError);
  }

  onCreateUsers = (value) => {
    const users = [...this.state.values.users];
    const separator = new RegExp(/\s*,\s*/, 'g');
    if (separator.test(value)) {
      Array.prototype.push.apply(users, value.split(separator));
    } else {
      users.push(value);
    }

    this.updateUsers(
      Array.from(new Set(users)).filter(element => element !== '')
    );
  }

  usersChangeHandler = (option) => {
    this.updateUsers(
      Array.isArray(option) ? option.map(selected => selected.value) : []
    );
  }

  updateUsers(users) {
    const values = this.valueChanged('users', users, false);

    this.updateValues(values, 'values');
  }

  getFormContent() {
    return (
      <div className="form-row">
        {this.getFormRow({
          label: `${this.props.type} users`,
          name: 'users',
          component: CreatableReactSelect,
          options: this.state.values.users.map(value => ({ value, label: value })),
          isMulti: true,
          placeholder: 'Define users...',
          onChange: this.usersChangeHandler,
          onCreateOption: this.onCreateUsers,
          labelWidth: { xs: 12, sm: 3 },
          valueWidth: { xs: 12, sm: 9 }
        })}
      </div>
    );
  }
}
