import React from 'react';
import { GridMobileRow } from 'emi-frontend';

export default class Row extends GridMobileRow {
  getProjectCounts() {
    return (
      <div className="d-flex flex-row">
        <div style={{flexGrow: 1, width: 100}}>Projects: {this.props.row.projects}</div>
        <div style={{flexGrow: 1, width: 100}}>Completes: {this.props.row.completes}</div>
      </div>
    );
  }

  getMainContent() {
    return (
      <div className="d-flex flex-column grid-cell mobile-cell">
        <div className="d-flex flex-row">
          <div className="grid-cell name">{this.props.row.name} (#{this.props.row.id})</div>
        </div>
        <div className="d-flex flex-row">
          <div className="grid-cell">{this.getProjectCounts()}</div>
        </div>
      </div>
    );
  }

  getData() {
    return (
      <div key={`cont${this.props.row.id}`} className="grid-row-cell d-flex">
        {this.getMainContent()}
      </div>
    );
  }
}
