import React from 'react';
import { ListGroupItem } from 'reactstrap';
import FieldsGroup from './FieldsGroup';
import QuotaCells from './QuotaCells';

export default class QuotaGroup extends FieldsGroup {

  static defaultProps = {
    path: ['quotaPlan', 'quotaGroups'],
    values: {
      name: '',
      quotaCells: []
    },
    exists: false
  };

  resetValues() {
    return {
      name: '',
      quotaCells: []
    };
  }

  showAddButton() {
    return this.isNew() && this.state.name.length > 0 && this.state.quotaCells.length > 0;
  }

  render() {
    const { exists } = this.props;
    return (
      <ListGroupItem className="researchnow-target-criteria bg-light">
        <div className="form-row">
          <div className="col">
            <div className="row form-group">
              <label htmlFor="name" className="text-uppercase col-12 col-sm-3 col-form-label">
                Group name<span className="text-danger">*</span>
              </label>
              <div className="col-12 col-sm-7">
                <input
                  type="text" name="name" className="form-control"
                  value={this.getValue('name')}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="col-12 col-sm-2">
                {this.showAddButton() &&
                  <button
                    type="button" className="btn btn-default"
                    onClick={this.addValues}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button>
                }
                {!this.isNew() &&
                  <button type="button" className="btn btn-danger" onClick={this.deleteValues}>
                    <i className="fa fa-trash-o" aria-hidden="true"></i>
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
        <QuotaCells
          quotaCells={this.getValue('quotaCells')}
          path={exists ? this.props.path.concat(this.props.idx, 'quotaCells') : ['quotaCells']}
          questions={this.props.questions}
          onChange={exists ? this.props.onChange : this.modifyValues}
          exists={exists}
        />
      </ListGroupItem>
    );
  }
}