import React from 'react';
import { ListGroup } from 'reactstrap';
import { FormDisabledRow } from 'emi-frontend';
import TargetCriteria from './TargetCriteria';
import CountrySelect from './CountrySelect';
import { AbstractFeasibility } from '../abstract';
import { formatCurrency } from 'emi-frontend';

export default class Feasibility extends AbstractFeasibility {

  getDefaultValues() {
    return {
      country: '',
      daysInField: '',
      incidenceRate: '',
      loi: '',
      completes: '',
      count: null,
      criterias: [],
      cpi: null
    };
  }

  countryChangeHandler = (option) => {
    const changed = this.valueChanged('country', option ? option.value : '');
    this.updateValues(changed);
    this.loadQuestionsData(0, true);
  }

  getTargetingCriteria() {
    const criterias = this.state.values.criterias.slice();
    return { criterias };
  }

  getFormData() {
    const { criterias, country, completes, loi, incidenceRate, daysInField } = this.state.values;

    const quotas = [{
      id: 'fea',
      completesWanted: completes,
      qualifications: {
        and: criterias
      }
    }];
    return {
      project: {
        quotas,
        country,
        devices: ['DESKTOP', 'PHONE', 'TABLET'],
        loi,
        incidenceRate,
        daysInField
      }
    };
  }

  valueChanged(name, value) {
    const changed = super.valueChanged(name, value);

    if (name === 'country') {
      changed.criterias = [];
      changed.count = null;
      changed.cpi = null;
    }

    if (name === 'criterias') {
      changed.count = null;
      changed.cpi = null;
    }

    return changed;
  }

  showButton() {
    return Array.isArray(this.state.values.criterias) &&
      this.state.values.criterias.length > 0;
  }

  showTargetCriteria() {
    return this.state.values.country !== '' && this.state.questions !== null;
  }

  successAction = (response) => {
    // Convert response
    const [ quota ] = response.data.project.quotas;
    this.updateValues({
      count: quota.numberOfPanelists,
      cpi: response.data.cpi
    });
  }

  getCardBodyContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            label: 'Country',
            name: 'country',
            component: CountrySelect,
            partnerId: this.props.partnerId,
            required: true,
            isClearable: false,
            onChange: this.countryChangeHandler,
            fieldWidth: { xs: 12, sm: 4 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'completes',
            name: 'completes',
            type: 'number',
            min: 0,
            step: 1,
            cssClass: 'fixwidth',
            required: true,
            style: { width: 100 },
            fieldWidth: { xs: 12, sm: 4 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'ir',
            name: 'incidenceRate',
            type: 'number',
            min: 0,
            max: 100,
            step: 1,
            postfix: '%',
            cssClass: 'fixwidth',
            required: true,
            style: { width: 100 },
            fieldWidth: { xs: 12, sm: 4 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'loi',
            name: 'loi',
            type: 'number',
            min: 1,
            step: 1,
            postfix: 'min',
            cssClass: 'fixwidth',
            required: true,
            style: { width: 100 },
            fieldWidth: { xs: 12, sm: 4 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}

          {this.getFormRow({
            label: 'field time',
            name: 'daysInField',
            type: 'number',
            min: 1,
            step: 1,
            postfix: 'days',
            cssClass: 'fixwidth',
            required: true,
            style: { width: 100 },
            fieldWidth: { xs: 12, sm: 4 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}
        </div>

        {this.showTargetCriteria() &&
          <div className="form-row">
            <ListGroup style={{ width: '100%' }}>
              <TargetCriteria
                questions={this.state.questions}
                country={this.state.values.country}
                onAdd={this.addTargetCriteria}
                onDelete={this.removeTargetCriteria}
                onChange={this.modifyTargetCriteria}
                identifier="question"
                allowCreate
              />
              {this.state.values.criterias.map((criteria, idx) =>
                <TargetCriteria
                  idx={idx}
                  key={idx}
                  criteria={criteria}
                  questions={this.state.questions}
                  country={this.state.values.country}
                  onAdd={this.addTargetCriteria}
                  onDelete={this.removeTargetCriteria}
                  onChange={this.modifyTargetCriteria}
                  identifier="question"
                  isDeletable
                />
              )}
            </ListGroup>
          </div>
        }

        {this.state.values.count !== null &&
          <div>
            <div className="form-row">
              <FormDisabledRow
                  label="Available respondents"
                  name="count"
                  value={this.state.values.count}
                  fieldWidth={ { xs: 6, sm: 6} }
                  labelWidth={ {xs: 6, sm: 6} }
                  valueWidth={ {xs: 6, sm: 6} }
              />
            </div>
            <div className="form-row">
              <FormDisabledRow
                  label="CPI"
                  name="cpi"
                  value={`${formatCurrency(this.state.values.cpi)}`}
                  fieldWidth={ { xs: 6, sm: 6} }
                  labelWidth={ {xs: 6, sm: 6} }
                  valueWidth={ {xs: 6, sm: 6} }
              />
            </div>
          </div>
        }
      </div>
    );
  }
}
