import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import { Grid } from 'emi-frontend';

export default class ProjectGrid extends Grid {

  get gridName() {
    return 'tagProjectGrid';
  }

  getApiPath = () => {
    return `/tag/${this.props.tagId}/project`;
  }

  isRowSelectable = () => {
    return false;
  }

  getColumns() {
    return [{
      name: 'id',
      dataField: 'id',
      title: 'ID',
      asc: false,
      width: 55,
      exactWidth: true
    }, {
      name: 'name',
      title: 'Job name',
      width: 200
    }, {
      name: 'client_name',
      title: 'Client name',
      width: 230,
      exactWidth: true
    }, {
      name: 'user_name',
      title: 'RM',
      width: 200,
      exactWidth: true
    }, {
      name: 'io_number',
      title: 'IO#',
      width: 80,
      exactWidth: true
    }, {
      name: 'close_date',
      title: 'Close date',
      width: 120,
      exactWidth: true
    }, {
      name: 'status',
      title: 'status',
      width: 80,
      exactWidth: true
    }];
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.tagId && nextProps.tagId !== this.props.tagId) {
      this.setState({ currentPage: 1 }, this.reload);
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="xl">
        <ModalHeader toggle={this.props.toggle}>
          Projects
        </ModalHeader>
        <ModalBody>
          {super.render()}
        </ModalBody>
      </Modal>
    );
  }
}
