import React from 'react';
import { Cell } from 'emi-frontend';
import { Desktop } from 'emi-frontend/lib/Responsive';
import classNames from 'classnames';
import TargetQuotaCell from './TargetQuotaCell';

export default class RouteGridCell extends Cell {

  getContent() {
    if (this.props.column.name === 'quota') {
      return (
        <Desktop>
          <TargetQuotaCell
            row={this.props.row}
            data={this.getData()}
            column={this.props.column}
            {...this.props.column.params}
            summary={this.props.summary}
          />
        </Desktop>
      );
    }

    return super.getContent();
  }

  render() {
    let alignClass = '';
    if (this.props.column.align) {
      alignClass = `justify-content-${this.props.column.align}`;
    }

    const style = {
      width: this.props.column.width
    };

    if (this.props.column.exactWidth) {
      style.flexGrow = 0;
    }

    let displayClass = '';
    if (this.props.column.name === 'quota' && this.props.column.params.enable_click_quota) {
      displayClass = 'partner-quota-summary-cell';
    }

    return (
      <div
        className={classNames(
          'grid-cell', 'd-flex', 'align-content-center', 'align-items-center', alignClass, this.props.column.className, displayClass
        )}
        style={style}
      >
        {this.getContent()}
      </div>
    );
  }
}
