import React, { useState } from 'react';
import {
  Button, Row, Col, Nav, NavItem, NavLink, Alert,
  TabContent, TabPane, Card, CardBody, CardHeader
} from 'reactstrap';
import classNames from 'classnames';
import AssignmentForm from './AssignmentForm';
import BidForm from './BidForm';
import TabSelector from './TabSelector';
import Quotas from './Quotas';
import { useModal } from '../../lib/hooks';
import CreateJobsModal from './CreateJobsModal';

export default function Assignment({ history, match: { params: { id: bidId }}}) {
  const [assignment, setAssignment] = useState({});
  const [jobCreated, setJobCreated] = useState(null);
  const [reload, setReload] = useState(false);
  const [tab, setTab] = useState(1);
  const { isModalOpen, toggleModal } = useModal();

  function onAssignmentChanged(data) {
    setAssignment(data);
  }

  function onCreateJob(status) {
    setJobCreated(status);
    setReload(true);
  }

  function getHeaderButtons() {
    if (assignment.bid_id) {
      return (
        <span className="action-buttons float-right d-none d-sm-block">
          <Button 
            type="button" className="project-button"
            color="default"
            onClick={() => window.location = `https://estimator.emi-rs.com//bids/${assignment.bid_id}/${assignment.current_revision}#1`}
          >
            View Bid 
          </Button>

          <Button 
            type="button" className="project-button"
            color="default"
            onClick={() => history.replace(`/multi/${assignment.invoice_id}`)}
          >
            Multi-View
          </Button>

          {assignment.processed && !assignment.job_id && <Button 
            type="button" className="project-button"
            color="primary"
            onClick={() => toggleModal()}
          >
            Create Jobs 
          </Button>}

          {assignment.processed && assignment.job_id && !assignment.jobs_created && <span 
            className={classNames('text-white', 'rounded', 'float-right', 'bg-warning' )}
            style={{ padding: '0px 15px', fontSize: '16px', marginLeft: '10px' }}
          >
          Job creation is running
          </span>}

          <span 
            className={classNames('text-white', 'rounded', 'float-right', { 'bg-success': assignment.processed, 'bg-info': !assignment.processed } )}
            style={{ padding: '0px 15px', fontSize: '16px' }}
          >
            {assignment.processed ? 'Processed' : 'Pending'}
          </span>
        </span>
      );
    }
  }

  function toggleTab(activeTab) {
    if (tab !== activeTab) {
      setTab(activeTab)
      window.location.hash = activeTab;
    }
  }

  function getTabs() {
    return (
      <>
        {jobCreated !== null && <Alert color={jobCreated ? 'success' : 'danger'}>
          {
            jobCreated
              ? 'Jobs are created in the background.'
              : 'Job creation failed.'
          }
        </Alert>}
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classNames('cursor-pointer', { active: tab === 1 })}
              onClick={() => toggleTab(1) }
            >
              Details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames('cursor-pointer', { active: tab === 2 })}
              onClick={() => toggleTab(2) }
            >
              Quota
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={tab} className='assignment-tab'>
          <TabPane tabId={1}>
            <AssignmentForm
              bidId={bidId}
              onAssignmentChanged={onAssignmentChanged}
              ref={(comp) => {
                if (comp && reload) {
                  comp.loadFormData(bidId);
                  setReload(false);
                }
              }}
            />
            <BidForm bidId={bidId} />
          </TabPane>
          <TabPane tabId={2}>
            <Quotas
              bid_revision_id={assignment.current_revision}
            />
          </TabPane>
        </TabContent>
        <TabSelector selectTab={(activeTab) => toggleTab(activeTab)} />
      </>
    );
  }

  return (
    <Row className="emi-form">
      <Col md="12">
        <Card className="form-card">
        <CardHeader>
          <h4>
            <span className="text-uppercase">
              Assignment Detail
            </span>
            {getHeaderButtons()}
          </h4>
        </CardHeader>
          <CardBody>
            {getTabs()}
          </CardBody>
        </Card>
      </Col>
      <CreateJobsModal
        bidId={bidId}
        isOpen={isModalOpen}
        toggle={toggleModal}
        onCreateJob={onCreateJob}
      />
    </Row>
  );
}
