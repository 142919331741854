import React from 'react';

export function getInvoiceStatus(status) {
  switch(status) {
    case 'open':
      return <div className="text-warning">Open</div>;
    case 'closed':
      return <div className="text-success">Closed</div>;
    case 'in_quickbooks':
      return <div className="text-success">In Quickbooks</div>;
    case 'quickbooks_error':
      return <div className="text-danger">Quickbooks error</div>;
    default:
      return '';
  }
}