import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function TabSelector({ selectTab }) {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      selectTab(Number(hash.split('#').pop()));
    }
  }, [hash]);

  return null;
}
