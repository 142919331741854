export function ucFirst(text) {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
}

export function formatCurrency(amount) {
  const cost = typeof amount !== 'number' ? Number(amount) : amount;
  return `$${cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
}

export function precisionRound(number, precision) {
  var factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
}

export function toNumber(num) {
  return typeof num !== 'number' ? Number(num) : num;
}

export function calcPercentage(total, number, round = 0) {
  const max = toNumber(total || 0);
  return (max ? (toNumber(number || 0) / max * 100) : 0).toFixed(round);
}

export function formatDate(data) {
  if (data !== null && data !== undefined) {
    let date;
    if (!(data instanceof Date)) {
      date = new Date(data);
    }

    return date.toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' });
  }

  return '-';
}

// DONT USE THIS, import it from emi-frontend
export function composeVariables(variables) {
  return variables.reduce((accumulator, current) => {
    return `${accumulator}/{${current}}`;
  }, '');
}

// DONT USE THIS, import it from emi-frontend
export function composeStartUrl(survey_url, variables) {
  return `${survey_url}${composeVariables(variables)}`;
}

export function swapArrayElements(arr, x, y) {
  if (arr[x] === undefined || arr[y] === undefined) {
    return arr.slice();
  }
  const a = x > y ? y : x
  const b = x > y ? x : y
  return [
    ...arr.slice(0, a),
    arr[b],
    ...arr.slice(a+1, b),
    arr[a],
    ...arr.slice(b+1)
  ];
}

export function hasOwnProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function randomIntInc(low, high) {
  return Math.floor((Math.random() * ((high - low) + 1)) + low);
}

export function emptyFn() {}

export function getUserIds(user) {
  if (!user) {
    return 0;
  }

  if (!user.connected_user) {
    return user.id;
  }

  return [user.id, user.connected_user];
}

export function generateHash(length) {
  let hash = '';
  const charset = ['abcdefghijklmnopqrstuvwxyz', '0123456789', 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];
  for (let i = 0; i < length; i += 1) {
    const idx = randomIntInc(0, 2);
    hash += charset[idx][randomIntInc(0, charset[idx].length - 1)];
  }

  return hash;
}

export function uniqueArray(...args) {
  return Array.from(new Set([].concat(...args)));
}
