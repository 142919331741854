import React from 'react';
import classNames from 'classnames';
import { RouterContext, GridMobileRow } from 'emi-frontend';

export default class Row extends GridMobileRow {

  constructor(props) {
    super(props);

    this.state = {
      opened: false
    };
  }

  getPhone() {
    if (this.props.row.phone) {
      return (<div style={{width: 100}}><i className="fa fa-phone" />{this.props.row.phone}</div>);
    } else {
      return (<div />);
    }
  }

  getContactHandler(contactId, router) {
    return (event) => {
      event.stopPropagation();
      router.history.push(`/clients/${this.props.row.id}/contact/${contactId}`);
    }
  }

  getContacts() {
    if (this.props.row.contacts === null) {
      return '';
    }

    return (
      <RouterContext.Consumer key="1">
        {(router) => (
          <div key={`client${this.props.row.id}`} className={classNames({'d-none': !this.state.opened, 'd-flex': this.state.opened}, 'flex-column grid-row-cell')}>
          {
            this.props.row.contacts.map((contact, i) =>
              (
                <div key={`contact${i}`} onClick={this.getContactHandler(contact.id, router)} className="d-flex flex-row">
                  <div style={{flexGrow: 1, width: 100}}>{contact.name}</div>
                  <div style={{flexGrow: 1, width: 100}}>{contact.email}</div>
                </div>
              )
            )
          }
          </div>
        )}
      </RouterContext.Consumer>
    );
  }

  toggleContact = (event) => {
    event.stopPropagation();
    this.setState({
      opened: !this.state.opened
    });
  }

  getContactCounter() {
    const count = Array.isArray(this.props.row.contacts) ? this.props.row.contacts.length : 0;
    const text = count > 0 ? 'contacts' : 'contact';

    return (
      <div className="grid-cell d-flex justify-content-end"><div onClick={this.toggleContact}>{count} {text}</div></div>
    );
  }

  getMainContent() {
    return (
      <div className="d-flex flex-column grid-cell mobile-cell">
        <div className="d-flex flex-row">
          <div className="grid-cell name">{this.props.row.name} (#{this.props.row.id})</div>
          <div className="grid-cell d-flex justify-content-end">
            <div className="gray">{(this.props.row.location)}</div>
          </div>
        </div>
        <div className="d-flex flex-row">
          {this.getPhone()}
          {this.getContactCounter()}
        </div>
      </div>
    );
  }

  getData() {
    return [
      (
        <div key={`cont${this.props.row.id}`} className="grid-row-cell d-flex">
          {this.getMainContent()}
          {this.getButtonFromColumn('add', 30)}
        </div>
      ),
      this.getContacts()
    ];
  }
}
