import React from 'react';
import { ButtonGroup, Button } from 'reactstrap';
import { Filter, Grid, Mobile, Desktop } from 'emi-frontend';

export default class InvoiceFilter extends Filter {
  constructor(props) {
    super(props);

    this.state.searchTitle = 'Search by Client Name, Job Name, etc.';
    this.state.invoices = InvoiceFilter.invoicesState();
    this.state.exportMatchModal = false;
  }

  static invoicesState() {
    const gridState = Grid.getGridState('invoiceGrid', { storeState: true });
    return gridState.extraParams && gridState.extraParams.typeFilter ? gridState.extraParams.typeFilter : 'all';
  }

  get filterName() {
    return 'invoiceFilter';
  }

  initialize() {
    this.setFilter('all');
  }

  getLeftButtons() {
    return (
      <ButtonGroup className="btn-block">
        <Button color="default" className={this.getButtonClass('all')} onClick={this.allInvoices}>ALL</Button>{' '}
        <Button color="default" className={this.getButtonClass('open')} onClick={this.openInvoices}>OPEN</Button>{' '}
        <Button color="default" className={this.getButtonClass('closed')} onClick={this.closedInvoices}>CLOSED</Button>
        <Button color="default" className={this.getButtonClass('quickbooks')} onClick={this.quickbooksInvoices}>
          <Mobile>QB</Mobile>
          <Desktop>QUICKBOOKS</Desktop>
        </Button>
        <Button color="default" className={this.getButtonClass('error')} onClick={this.errorInvoices}>ERROR</Button>
      </ButtonGroup>
    );
  }

  getLeftClass() {
    return 'col-12 col-sm-6 col-md-5 col-lg-4';
  }

  allInvoices = () => {
    this.setFilter('all');
  }

  openInvoices = () => {
    this.setFilter('open');
  }

  closedInvoices = () => {
    this.setFilter('closed');
  }

  quickbooksInvoices = () => {
    this.setFilter('quickbooks');
  }

  errorInvoices = () => {
    this.setFilter('error');
  }

  setFilter(type) {
    this.setState({
      invoices: type
    });
    this.props.onFilter({
      typeFilter: type === 'all' ? undefined : type
    });
  }

  getButtonClass(button) {
    return this.state.invoices === button ? 'selected' : '';
  }
}
