import { formatCurrency, Excel } from 'emi-frontend';


function getExportHeaders() {
  return {
    io_number: 'IO #',
    job_name: 'Study Name',
    partner: 'Partner',
    client: 'Client',
    class: 'Class',
    cpi: 'CPI',
    completes: 'Completes',
    total: 'Total'
  }
}

function getData(invoice) {
  const data = [];
  let cost = 0;
  Object.keys(invoice.projects).forEach((projectId) => {
    const project = invoice.projects[projectId];
    if (project.job_summary !== null && Array.isArray(project.job_summary)) {
      project.job_summary.forEach((summary) => {
        if (summary.line_items !== null && Array.isArray(summary.line_items)) {
          summary.line_items.forEach((item) => {
            const total = item.cpi * item.completed;
            cost += total;
            data.push({
              io_number: invoice.io_number,
              job_name: project.name,
              partner: summary.partner_name,
              client: project.client_name,
              class: `${project.sales}:${project.pm}`,
              cpi: formatCurrency(item.cpi),
              completes: item.completed, // Number(item.completed) ?
              total: formatCurrency(total)
            });
          });
        }
      });
    }
  });

  if (data.length) {
    data.push({
      io_number: '',
      job_name: '',
      partner: '',
      client: '',
      class: '',
      cpi: '',
      completes: 'Total:',
      total: formatCurrency(cost)
    })
  }

  return data;
}

export default function jobSummary(invoice) {
  const excel = new Excel();
  excel.addJsonData(getData(invoice), getExportHeaders(), 'Job Summary');
  excel.saveAs(`job_summary_${invoice.io_number}.xlsx`);
}
