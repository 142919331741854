import React, { Component} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from 'reactstrap';
import Range from './Range';

export default class RangeModal extends Component {

  state = {
    from: 0,
    to: 1,
    units: ''
  }

  static propTypes = {
    idx: PropTypes.number
  };

  static getModalId(idx) {
    return `range-modal${idx === undefined ? '' : `-${idx}`}`;
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: Number(event.target.value)
    });
  }

  createRange = () => {
    if (this.state.from && this.state.to && this.state.units) {
      const range = Object.assign(this.state);
      this.setState({ from: 0, to: 1, units: '' }, () => this.props.onCreateRange(range));
    }
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="sm" id={RangeModal.getModalId(this.props.idx)}>
        <ModalHeader toggle={this.props.toggle}>
            <span className="text-uppercase">Create Range</span>
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col">
              <Input
                type="number" step="1" name="from"
                placeholder="From" bsSize="sm"
                value={this.state.from}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col">
              <Input
                type="number" step="1" name="to"
                placeholder="To" bsSize="sm"
                value={this.state.to}
                onChange={this.handleInputChange}
              />
            </div>
            <div className="col">
              <Input
                type="select" name="units"
                placeholder="Unit" bsSize="sm"
                value={this.state.units}
                onChange={this.handleInputChange}
              >
                <option value="">Select</option>
                {Object.keys(Range.unitMap).map(key =>
                  <option key={key} value={key}>{Range.unitMap[key]}</option>
                )}
              </Input>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="default"
            size="sm" type="button"
            onClick={this.createRange}
          >Add range</Button>
        </ModalFooter>
      </Modal>
    );
  }
}
