import React, { useState, useCallback } from 'react';
import 'bootstrap/js/dist/modal';
import $ from 'jquery';

const statusTextMap = {
  live: 'start',
  paused: 'pause',
  closed: 'close'
}

export default function StatusUpdateModal({ id, updateFn }) {
  const [data, setData] = useState({ id: null, status: '' });

  function onShowModal(event) {
    const { toggle, target, ...statusData } = event.relatedTarget.dataset;
    setData(statusData);
  }

  function onButtonClick(event) {
    const { value: update_panel_api } = event.target;
    updateFn({ ...data, update_panel_api });
  }

  const modalRef = useCallback(modal => {
    if (modal !== null) {
      $(modal).on('show.bs.modal', onShowModal);
    }
  }, []);

  return (
    <div className="modal fade" ref={modalRef} id={id} tabIndex="-1" aria-labelledby={`${id}-label`} aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`${id}-label`}>Change status</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            Do you also want to {statusTextMap[data.status] || ''} the project in Panel API ?
          </div>
          <div className="modal-footer justify-content-center">
            <button type="button" className="btn btn-primary" value={true} data-dismiss="modal" onClick={onButtonClick}>Yes</button>
            <button type="button" className="btn btn-secondary" value={false} data-dismiss="modal" onClick={onButtonClick}>No</button>
          </div>
        </div>
      </div>
    </div>
  );
}
