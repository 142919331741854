import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { AbstractQuota, Clicks, ClipboardButton } from '../abstract';
import TargetCriteria from './TargetCriteria';
import { hasOwnProperty, FormDisabledRow } from 'emi-frontend';

export default class Quota extends AbstractQuota {

  getDefaultValues(props) {
    return {
      ps_survey_id: props.partnerProjectId,
      required_count: 1,
      buyer_quota_id: '',
      criteria: []
    };
  }

  resetValues() {
    return {
      buyer_quota_id: '',
      required_count: 1,
      criteria: []
    };
  }

  getId = () => {
    return this.props.values.buyer_quota_id;
  }

  isUpdate() {
    return hasOwnProperty(this.props.values || {}, 'buyer_quota_id') &&
      this.props.values.buyer_quota_id.length > 0;
  }

  isNew() {
    return true;
  }

  getTargetingCriteria() {
    const criteria = this.state.values.criteria.slice();

    return {
      criteria
    };
  }

  pasteQualifications = (criteria) => {
    this.cloneTargetCriteria(
      AbstractQuota.cloneData({ criteria })
    );
  }

  getDeleteParams() {
    const { ps_survey_id, buyer_quota_id } = this.state.values;

    return { ps_survey_id, buyer_quota_id };
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          {this.isUpdate() ? (
            <FormDisabledRow
              label="quota id"
              name="buyer_quota_id"
              value={this.state.values.buyer_quota_id}
              fieldWidth={{ xs: 6 }}
              labelWidth={{ xs: 12, sm: 4 }}
              valueWidth={{ xs: 12, sm: 8 }}
            />
          ) : (
            this.getFormRow({
              label: 'quota id',
              name: 'buyer_quota_id',
              required: true,
              fieldWidth: { xs: 6 },
              labelWidth: { xs: 12, sm: 4 },
              valueWidth: { xs: 12, sm: 8 }
            })
          )}

          {this.getFormRow({
            label: 'quota',
            name: 'required_count',
            type: 'number',
            required: true,
            prefix: hasOwnProperty(this.state.values, 'completes_fielded') ? `${this.state.values.completes_fielded} /` : null,
            postfix: hasOwnProperty(this.state.values, 'valid_clicks') ? <Clicks value={this.state.values.valid_clicks} /> : null,
            fieldWidth: { xs: 6 },
            labelWidth: { xs: 12, sm: 4 },
            valueWidth: { xs: 12, sm: 8 }
          })}
        </div>
        
        <div className="form-row">
          <ListGroup style={{ width: '100%' }}>
            {!this.isUpdate() &&
              <ListGroupItem className="prodege-target-criteria">
                <ClipboardButton
                  title="Paste Qualifications"
                  className="float-right"
                  dataType="qualifications" isCopy={false}
                  readClipboardFn={this.pasteQualifications}
                />
              </ListGroupItem>
            }
            <TargetCriteria
              questions={this.props.questions}
              onAdd={this.addTargetCriteria}
              onDelete={this.removeTargetCriteria}
              onChange={this.modifyTargetCriteria}
              identifier="qualification_code"
              allowCreate
            />
            {this.state.values.criteria.map((criteria, idx) =>
              <TargetCriteria
                idx={idx}
                key={criteria.qualification_code}
                criteria={criteria}
                questions={this.props.questions}
                onAdd={this.addTargetCriteria}
                onDelete={this.removeTargetCriteria}
                onChange={this.modifyTargetCriteria}
                identifier="qualification_code"
                isDeletable
              />
            )}
          </ListGroup>
        </div>
      </div>
    );
  }
}