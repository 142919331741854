import React from 'react';
import classNames from 'classnames';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  CardFooter
} from 'reactstrap';
import { client, AbstractForm } from 'emi-frontend';
import MultiProjectSelect from '../multiview/MultiProjectSelect';

function Checkbox({ label, className, ...props }) {
  return (
    <div className="form-row">
      <div className="col-12">
        <div className="row form-group">
          <div className="col-5 offset-3">
            <div className={classNames('form-check', className)}>
              <input
                className="form-check-input" type="checkbox"
                {...props}
              />
              <label className="form-check-label" htmlFor={props.name}>{label}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default class CloneProjectForm extends AbstractForm {

  constructor(props) {
    super(props);

    this.state.loadingData = false;
  }

  static defaultProps = {
    ...AbstractForm.defaultProps,
    isMultiView: false,
  };

  getApiPath = () => {
    return this.props.isMultiView ? '/invoice' : '/project';
  }

  get modelName() {
    return 'item';
  }

  isNew() {
    return true;
  }

  getId = () => {
    return this.parseInt(this.props.id);
  }

  componentDidMount() {
  }

  getDefaultValues() {
    return {
      io_number: '',
      source_io_number: '',
      tags: true,
      route: false,
      with_panel_api: false,
      keep_secure_callback: false,
      copy_click_balancing_clicks: false,
      copy_project_files: false
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let updates = {};
    if (nextProps.values.io_number !== this.props.values.io_number) {
      updates = { io_number: nextProps.values.io_number, source_io_number: nextProps.values.io_number };
    }

    if (nextProps.id !== this.props.id) {
      updates = { ...updates, route: false, with_panel_api: false  };
      
    }

    if (!nextProps.secure_callback && this.state.values.keep_secure_callback) {
      updates = { ...updates, keep_secure_callback: false };
    }

    if (!nextProps.clickbalance && this.state.values.clickbalance) {
      updates = { ...updates, clickbalance: false} ;
    }

    if (Object.keys(updates).length) {
      this.updateValues(updates);
    }
  }

  deleteDone = (response) => {
    this.props.onDelete();
  }

  successAction = (response) => {
    this.props.toggle();

    if (!this.props.isMultiView) {
      this.context.history.replace(`/jobs/${response.data.id}`);
    }
  }

  sendFormData = () => {
    return client.api('POST', `${this.getApiPath()}/${this.getId()}/clone`, this.getFormData());
  }

  valueChanged(name, value) {
    const changed = super.valueChanged(name, value);

    if (name === 'route' && !value) {
      Object.assign(changed, { with_panel_api: false });
    }

    return changed;
  }

  getFormHeader() {
    return null;
  }

  getFormContent() {
    return (
      <React.Fragment>
        <div className="form-row">
          {this.getFormRow({
            name: 'io_number',
            label: 'io#',
            required: true,
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 3 },
            valueWidth: { xs: 5 }
          })}
        </div>
        {this.props.isMultiView &&
          <div className="form-row">
            {this.getFormRow({
              label: 'projects',
              name: 'projects',
              component: MultiProjectSelect,
              onChange: this.selectChangeHandler('projects'),
              required: true,
              isMulti: true,
              invoice_id: this.props.id,
              fieldWidth: { xs: 12 },
              labelWidth: { xs: 12, lg: 3 },
              valueWidth: { xs: 12, lg: 9 }
            })}
          </div>
        }
        <Checkbox
          name="tags" id="tags"
          checked={this.state.values.tags}
          onChange={this.handleInputChange}
          label="Clone tags"
        />
        <Checkbox
          name="route" id="route"
          checked={this.state.values.route}
          onChange={this.handleInputChange}
          label="Clone with partners"
        />
        <Checkbox
          className={{ disabled: !this.state.values.route }}
          name="with_panel_api" id="with_panel_api"
          checked={this.state.values.with_panel_api}
          onChange={this.handleInputChange}
          disabled={!this.state.values.route}
          label="Clone with API setup"
        />
        {this.props.secure_callback &&
          <Checkbox
            name="keep_secure_callback" id="keep_secure_callback"
            checked={this.state.values.keep_secure_callback}
            onChange={this.handleInputChange}
            label="Keep secure callback"
          />
        }
        {this.props.clickbalance &&
          <Checkbox
            name="copy_click_balancing_clicks" id="copy_click_balancing_clicks"
            checked={this.state.values.copy_click_balancing_clicks}
            onChange={this.handleInputChange}
            label="Copy click balancing clicks"
          />
        }
        <Checkbox
          name="copy_project_files" id="copy_project_files"
          checked={this.state.values.copy_project_files}
          onChange={this.handleInputChange}
          label="Copy project files"
        />
      </React.Fragment>
    );
  }

  getActionButtons() {
    return (
      <CardFooter className="action-buttons d-none d-lg-block">
        <Button type="submit" color="success">
          <i className="fa fa-clone" aria-hidden="true"></i>{' '}
          Clone
        </Button>
      </CardFooter>
    );
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle}>
          Clone Project
        </ModalHeader>
        <ModalBody>
          {super.render()}
        </ModalBody>
      </Modal>
    );
  }
}
