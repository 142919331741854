import React from 'react';
import ProposalList from './ProposalList';
import ProjectList from './ProjectList';

export default function Page() {
  return (
    <React.Fragment>
      <ProposalList />
      <ProjectList />
    </React.Fragment>
  );
}
