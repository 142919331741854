import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { RouterContext } from 'emi-frontend';

export default class ClosedButton extends Component {
  static contextType = RouterContext;

  goClosedProjects = () => {
    this.context.history.push('/jobs/closed');
  }

  render() {
    return (
      <Button color="default" onClick={this.goClosedProjects}>
        View All Closed Projects
      </Button>
    );
  }
}
