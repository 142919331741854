import React from 'react';
import {
  CardFooter, Button, Nav, NavItem, NavLink,
  TabContent, TabPane, Form
} from 'reactstrap';
import { AbstractForm, SaveButton, CollapseCard, client } from 'emi-frontend';
import classNames from 'classnames';
import AttachmentModal from './AttachmentModal';
import TabSelector from './TabSelector';
import UserSelect from '../../lib/form/UserSelect';
import Quotas from './Quotas';

export default class AssignmentForm extends AbstractForm {

  constructor(props) {
    super(props);

    this.state.attachmentModal = false;
    this.state.activeTab = 1;
    this.state.clientCse = {};
    this.state.clientSales = {};
  }

  get modelName() {
    return 'assignment';
  }

  getApiPath = () => {
    return '/assignment';
  }

  getId = () => {
    return this.parseInt(this.props.bidId);
  }

  getFormHeader() {
    return '';
  }

  showDeleteAction() {
    return false;
  }

  isNew() {
    return true;
  }

  loadDone = (response, state = {}, cb) => {
    const values = Object.assign({}, this.state.values, this.convertResponse(response.data));

    this.setState(Object.assign({
      loadingData: false,
      values 
    }, state), cb);
    this.props.onAssignmentChanged(response.data);
  }

  successAction = (response) => {
    this.loadDone(response, { edit: {}, success: true, message: 'Assignment updated' });
  }

  createJobs() {
    client.api('PUT', `${this.getApiPath()}/${this.getId()}/job`)
      .done(() => {
        this.setState({ message: 'Jobs are created in the background, email will be sent out when once completed.', success: true });
      })
      .fail(() => {
        this.setState({ message: 'Jobs creation failed', success: false });
      });
  }

  getFormData() {
    const { rm_id, arm_id } = this.state.values;
    return { rm_id, arm_id };
  }

  getActionButtons() {
    return (
      <CardFooter className="action-buttons d-lg-block">
        {this.showSaveButton() && <SaveButton model={this.modelName} disabled={this.state.sendingData} />}
      </CardFooter>
    );
  }

  getHeaderButtons() {
    if (this.isNew()) {
      return null;
    }

    return (
      <span className="action-buttons float-right d-none d-sm-block">
        <Button 
          type="button" className="project-button"
          color="default"
          onClick={() => window.location = `https://estimator.emi-rs.com//bids/${this.state.values.bid_id}/${this.state.values.current_revision}#1`}
        >
          View Bid 
        </Button>

        {this.state.values.processed && !this.state.values.job_id && <Button 
          type="button" className="project-button"
          color="primary"
          onClick={() => this.createJobs()}
        >
          Create Jobs 
        </Button>}

        {this.state.values.processed && this.state.values.job_id && !this.state.values.jobs_created && <span 
          className={classNames('text-white', 'rounded', 'float-right', 'bg-warning' )}
          style={{ padding: '0px 15px', fontSize: '16px', marginLeft: '10px' }}
        >
         Job creation is running
        </span>}

        <span 
          className={classNames('text-white', 'rounded', 'float-right', { 'bg-success': this.state.values.processed, 'bg-info': !this.state.values.processed } )}
          style={{ padding: '0px 15px', fontSize: '16px' }}
        >
          {this.state.values.processed ? 'Processed' : 'Pending'}
        </span>
      </span>
    );
  }

  redirectPath = () => {
    return '/admin#2';
  }

  toggleTab(activeTab) {
    if (this.state.activeTab !== activeTab) {
      this.setState({ activeTab });
      window.location.hash = activeTab;
    }
  }

  toggleModal = (prop) => {
    this.setState({
      [prop]: !this.state[prop]
    });
  }

  onBackButton = () => {
    this.context.history.replace(this.redirectPath());
  }

  getFormContent() {
    return (
      <div>
        <CollapseCard title="SWIFT fields">
          <div className="form-row">
            {this.getFormRow({
              label: 'RM',
              name: 'rm_id',
              component: UserSelect,
              type: 'admin',
              department: 'rm',
              active: true,
              onChange: this.selectChangeHandler('rm_id'),
              required: true,
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}

            {this.getFormRow({
              label: 'Associate RM',
              name: 'arm_id',
              component: UserSelect,
              type: 'admin',
              department: 'rm',
              active: true,
              isClearable: true,
              onChange: this.selectChangeHandler('arm_id'),
              fieldWidth: { xs: 12, lg: 6 },
              labelWidth: { xs: 12, lg: 4 },
              valueWidth: { xs: 12, lg: 8 }
            })}
          </div>
        </CollapseCard>
      </div>
    );
  }

  getTabs() {
    return (
      <>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classNames('cursor-pointer', { active: this.state.activeTab === 1 })}
              onClick={() => this.toggleTab(1) }
            >
              Details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames('cursor-pointer', { active: this.state.activeTab === 2 })}
              onClick={() => this.toggleTab(2) }
            >
              Quota
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab} className='assignment-tab'>
          <TabPane tabId={1}>
            {this.getForm()}
            <AttachmentModal
              values={{
                bid_id: this.getId(),
              }}
              isOpen={this.state.attachmentModal}
              toggle={() => this.toggleModal('attachmentModal')}
            />
          </TabPane>
          <TabPane tabId={2}>
            <Quotas
              bid_id={this.state.values.bid_id}
              bid_revision_id={this.state.values.current_revision}
            />
          </TabPane>
        </TabContent>
        <TabSelector selectTab={(activeTab) => this.toggleTab(activeTab)} />
      </>
    );
  }

  getForm() {
    return (
      <Form
        innerRef={(comp) => { this.form = comp; }}
        onSubmit={this.onFormSubmit}
        autoComplete="off"
        noValidate
        className={classNames({'was-validated': this.state.clientSideValidated})}
        id={`${this.modelName}Form`}
      >
        {this.getFormContent()}
        {this.getActionButtons()}
      </Form>
    );
  }
}
