import { Grid } from 'emi-frontend';

export default class ProposalGrid extends Grid {

  getInitialState(props) {
    const initialState = super.getInitialState(props);
    Object.assign(initialState, {
      title: 'Proposals'
    });

    return initialState;
  }

  static defaultProps = {
    paging: true,
    storeState: true,
    extraParams: {
      user_type: 'connector'
    }
  };

  get gridName() {
    return 'proposalGrid';
  }

  getApiPath = () => {
    return '/feasibility';
  }

  getBasePath = () => {
    return '/feasibility';
  }

  getColumns() {
    return [{
      name: 'name',
      title: 'name',
      width: 250
    }, {
      name: 'created_by',
      title: 'Client',
      width: 250,
      renderer: this.projectNameRenderer
    }, {
      name: 'created',
      title: 'created',
      width: 100,
      renderer: this.dateFormatter
    }];
  }

  getMobileHeader() {
    return '';
  }
}
