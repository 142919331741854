import { useState, useEffect, useCallback } from 'react';
import { client } from 'emi-frontend';

export function useForm({
  apiPath, defaultValues = {}, params = {}, shouldLoad = true, isNew = true, onSuccess, valueProp
}) {
  const [processing, setProcessing] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const [loaded, setLoaded] = useState(false);
  const [errors, setErrors] = useState({});

  const loadData = useCallback(() => {
    if (shouldLoad) {
      client.api('GET', apiPath, params)
        .then((response) => {
          setValues(response.data);
          setLoaded(true);
        });
    }
  }, [apiPath, shouldLoad]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  function onFormSubmit(event) {
    event.preventDefault();

    submitValues(valueProp !== undefined ? values[valueProp] : values);
  }

  function submitValues(data) {
    setErrors({});

    sendData(
      isNew ? 'POST' : 'PUT', apiPath, data,
      typeof onSuccess === 'function' ? onSuccess : function() {}
    );
  }

  function sendData(method, path, data, onSuccessFn) {
    setProcessing(true);

    return client
      .api(method, path, data)
      .done(response => onSuccessFn(response.data))
      .fail(error => handleError(error))
      .always(() => setProcessing(false));
  }

  function handleChange(event) {
    const { name, type } = event.target;
    let value;

    switch (type) {
      case 'checkbox':
        value = event.target.checked;
        break;
      case 'select-multiple':
        value = [];
        Array.prototype.map.call(event.target.options, (option) => {
          if (option.selected) {
            value.push(option.value);
          }
        });
        break;
      default:
        value = event.target.value;
    }

    updateValue(name, value);
  }

  function handleSelectChange(changed) {
    updateValues(changed);
  }

  function updateValue(name, value) {
    if (valueProp) {
      setValues({ ...values, [valueProp]: { ...values[valueProp], [name]: value } });
    } else {
      setValues({ ...values, [name]: value });
    }
  }

  function updateValues(update) {
    if (valueProp) {
      setValues({ ...values, [valueProp]: { ...values[valueProp], ...update } });
    } else {
      setValues({ ...values, ...update });
    }
  }

  function resetValues() {
    setValues({ ...defaultValues });
    setErrors({});
  }

  function hasError(field) {
    return Object.prototype.hasOwnProperty.call(errors || {}, field);
  }

  function handleError(error) {
    const keys = Object.keys(error);
    if (keys.length) {
      scrollToError(keys.shift());
    }

    setErrors(error);
  }

  function scrollToError(key) {
    const errorLabel = document.getElementById(`label-${key}`);
    if (errorLabel) {
      errorLabel.scrollIntoView();
    }
  }

  function handleFocus(event) {
    const { name } = event.target;

    removeError(name);
  }

  function removeError(fieldName) {
    if (errors[fieldName]) {
      setErrors(
        Object.fromEntries(Object.entries(errors).filter(([key]) => key !== fieldName))
      );
    }
  }

  return {
    values, setValues, updateValue, updateValues, isNew, onFormSubmit, loadData, sendData,
    handleChange, resetValues, submitValues,
    processing, errors, hasError, setErrors, handleFocus, setProcessing, handleSelectChange,
    removeError, loaded, setLoaded
  };
}