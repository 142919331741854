import React from 'react';
import { Alert, Button } from 'reactstrap';
import { AbstractFeasibility } from '../abstract';
import CountrySelect from './CountrySelect';
import LanguageSelect from './LanguageSelect';
import FeasibilityInfo from './FeasibilityInfo';
import Filters from './Filters';

export default class PartnerFeasibility extends AbstractFeasibility {

  constructor(props) {
    super(props);

    Object.assign(this.state, {
      extProjectId: null,
      feasibility: null
    });
  }

  getDefaultValues() {
    return {
      countryISOCode: '',
      languageISOCode: '',
      indicativeIncidence: 0,
      lengthOfInterview: 0,
      daysInField: 1,
      requiredCompletes: 1,
      filters: []
    };
  }

  sendFormData = () => {
    return this.clientApi('POST', `/researchnow/feasibility`, this.getFormData());
  }

  isNew() {
    return true;
  }

  successAction = (response) => {
    const { extProjectId } = response.data;
    this.setState({ extProjectId });
  }

  setFeasibility = (response) => {
    const [{ feasibility }] = response.data;
    if (feasibility.status !== 'PROCESSING') {
      this.setState({ feasibility });
    }
  }

  loadFeasibility = () => {
    this.setState(
      { sendingData: true, message: '', errors: {} },
      () => {
        this.clientApi('GET', `/researchnow/feasibility/${this.state.extProjectId}`)
          .done(this.setFeasibility)
          .fail(error => this.showError(error));
      }
    );
  }

  getButton() {
    if (this.state.extProjectId === null) {
      return super.getButton();
    } else if (this.state.feasibility === null) {
      return (
        <Alert color="warning">
          Feasibility calculation in progress (takes 20 - 120 seconds)
          <Button color="link" onClick={this.loadFeasibility}>
            <i className="fa fa-refresh" aria-hidden="true" />
          </Button>
        </Alert>
      );
    } else {
      return (
        <div>
          <FeasibilityInfo feasibility={this.state.feasibility} />
        </div>
      );
    }
  }

  updateValues(values, prop = 'values') {
    super.updateValues(values, prop = 'values', () => {
      if (this.state.extProjectId !== null || this.state.feasibility !== null) {
        this.setState({ extProjectId: null, feasibility: null });
      }
    });
  }

  showButton() {
    return this.state.values.countryISOCode !== '' && this.state.values.languageISOCode !== '' &&
      this.state.values.indicativeIncidence > 0 && this.state.values.lengthOfInterview > 0 &&
      this.state.values.daysInField > 0 && this.state.values.requiredCompletes > 0;
  }

  valueChanged(name, value, isEdit) {
    const changed = super.valueChanged(name, value, isEdit);
    if (name === 'countryISOCode') {
      Object.assign(changed, { languageISOCode: '', filters: [] });
    }
    this.loadQuestionsData(changed);

    return changed;
  }

  loadQuestionsData(changed) {
    const { countryISOCode: countryCode, languageISOCode: languageCode } = Object.assign({}, this.state.values, changed);
    if (countryCode && languageCode && (this.state.questions === null)) {
      this.setState({
        loadingData: true
      }, () =>
        this.clientApi('POST', `/panelapi/${this.props.partnerId}/attributes/`, { countryCode, languageCode })
          .done((resp) => this.loadDone(resp))
          .fail(this.loadFail)
      );
    }
  }

  modifyFilters = (path, prop, ...args) => {
    const filters = this.state.values.filters.slice();

    if (typeof filters[prop] === 'function') {
      filters[prop](...args);
    }

    this.updateValues({ filters });
  }
  
  getCardBodyContent() {
    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            label: 'country',
            name: 'countryISOCode',
            component: CountrySelect,
            required: true,
            isClearable: false,
            onChange: this.selectChangeHandler('countryISOCode'),
            partnerId: this.props.partnerId,
            fieldWidth: { xs: 6 },
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 9 }
          })}

          {this.getFormRow({
            label: 'language',
            name: 'languageISOCode',
            component: LanguageSelect,
            required: true,
            isClearable: false,
            onChange: this.selectChangeHandler('languageISOCode'),
            partnerId: this.props.partnerId,
            countryISOCode: this.state.values.countryISOCode,
            fieldWidth: { xs: 6 },
            labelWidth: { xs: 12, sm: 3 },
            valueWidth: { xs: 12, sm: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'ir',
            name: 'indicativeIncidence',
            type: 'number',
            min: 0,
            max: 100,
            step: 1,
            postfix: '%',
            required: true,
            fieldWidth: { xs: 3 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}

          {this.getFormRow({
            label: 'loi',
            name: 'lengthOfInterview',
            type: 'number',
            min: 1,
            step: 1,
            required: true,
            fieldWidth: { xs: 3 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}

          {this.getFormRow({
            label: 'quota',
            name: 'requiredCompletes',
            type: 'number',
            min: 1,
            step: 1,
            required: true,
            fieldWidth: { xs: 3 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}

          {this.getFormRow({
            label: 'days in field',
            name: 'daysInField',
            type: 'number',
            step: 1,
            min: 1,
            required: true,
            fieldWidth: { xs: 3 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}
        </div>

        <div className="form-row">
          <div className="col-12">
            {this.state.questions !== null &&
              <Filters
                questions={this.state.questions}
                filters={this.state.values.filters}
                onChange={this.modifyFilters}
                showCopyButton={false}
              />
            }
          </div>
        </div>

      </div>
    )
  }

}
