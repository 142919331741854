import { RemoteSelect } from 'emi-frontend';

export default class LanguageSelect extends RemoteSelect {

  getApiPath() {
    return '/research_defender/language'
  }

  generateOptions() {
    return this.getOptionsFromObject();
  }

  getSelectedOptionText() {
    return this.getObjectOptionText();
  }
}
