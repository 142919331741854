import { Fragment } from 'react';
import { CollapseCard } from 'emi-frontend';

const status = {
  'live': 'Live',
  'paused': 'Paused',
  'kickoff': 'Kickoff',
  'test': 'Test'
};

export default function ManagerStat({ id, title, rows }) {

  const statusSummary = {
    projects: Object.keys(status).reduce((prev, curr) => ({ ...prev, [curr]: 0 }), {}),
    links: Object.keys(status).reduce((prev, curr) => ({ ...prev, [curr]: 0 }), {})
  };
  const userSummary = {};
  const total = { projects: 0, links: 0};
  // Calculate stats
  rows.forEach((row, idx) => {
    let projects = 0;
    let links = 0;
    Object.keys(status).forEach((st) => {
      projects += parseInt(row[`${st}_project`], 10);
      links += parseInt(row[`${st}_link`], 10);
      statusSummary.projects[st] += parseInt(row[`${st}_project`], 10);
      statusSummary.links[st] += parseInt(row[`${st}_link`], 10);
      total.projects += parseInt(row[`${st}_project`], 10);
      total.links += parseInt(row[`${st}_link`], 10);
    });
    userSummary[row.name ?? 'Unassigned'] = { projects, links };
  });

  return (
    <CollapseCard className="emi-collapse-card" title={title}>
      <div className="form-row" key="cd2">
        <table className="table table-sm table-hover table-bordered">
          <thead className="thead-light">
            <tr>
              <th scope="col" rowSpan={2}>User</th>
              {Object.keys(status).map((st, idx) => (<th key={`th_${id}_${st}`} scope="col" colSpan={idx === 0 ? 3 : 2} style={{ textAlign: 'center' }}>{status[st]}</th>))}
              <th scope="col" colSpan={3} style={{ textAlign: 'center' }}>Total</th>
            </tr>
            <tr>
                {Object.keys(status).map((st, idx) => (
                  <Fragment key={`th_${id}_st_${st}`}>
                    <th scope="col" style={{ textAlign: 'center' }}>Projects</th>
                    <th scope="col" style={{ textAlign: 'center' }}>Links</th>
                    {
                      idx === 0 && <th scope="col" style={{ textAlign: 'center' }}>Load</th>
                    }
                  </Fragment>
                ))}
                <th scope="col" style={{ textAlign: 'center' }} className="table-primary">Projects</th>
                <th scope="col" style={{ textAlign: 'center' }} className="table-primary">Links</th>
                <th scope="col" style={{ textAlign: 'center' }} className="table-primary">Load</th>
              </tr>
          </thead>
          <tbody>
            {
              rows.map((row, idx) => {
                const load = Math.round(userSummary[row.name ?? 'Unassigned'].projects / total.projects * 100);
                return (
                  <tr key={`tr_${id}_${idx}`}>
                    <th scope="row">{row.name ?? 'Unassigned'}</th>
                    {Object.keys(status).map((st, idx) => {
                      return (
                        <Fragment key={`tr_${id}_${idx}_${st}`}>
                          <td style={{ textAlign: 'center' }}>{row[`${st}_project`]}</td>
                          <td style={{ textAlign: 'center' }}>{row[`${st}_link`]}</td>
                          {
                            idx === 0 && <td style={{ textAlign: 'center' }}>{Math.round(row[`${st}_project`] / statusSummary.projects[st] * 100)}%</td>
                          }
                        </Fragment>
                      );
                    })}
                    <td style={{ textAlign: 'center' }}>{userSummary[row.name ?? 'Unassigned'].projects}</td>
                    <td style={{ textAlign: 'center' }}>{userSummary[row.name ?? 'Unassigned'].links}</td>
                    <td style={{ textAlign: 'center', backgroundColor: `rgb(0 144 81 / ${load * 2}%)` }}>{load}%</td>
                  </tr>
                );
              })
            }
            <tr className="table-secondary">
              <th scope="row">TOTAL</th>
              {Object.keys(status).map((st, idx) => {
                return (
                  <Fragment key={`tr_${id}_total_${st}`}>
                    <td style={{ textAlign: 'center' }}>{statusSummary.projects[st]}</td>
                    <td style={{ textAlign: 'center' }}>{statusSummary.links[st]}</td>
                    {
                      idx === 0 && <td style={{ textAlign: 'center' }}>100%</td>
                    }
                  </Fragment>
                );
              })}
              <td style={{ textAlign: 'center' }}>{total.projects}</td>
              <td style={{ textAlign: 'center' }}>{total.links}</td>
              <td style={{ textAlign: 'center' }}>100%</td>
            </tr>
          </tbody>
        </table>
      </div>
  </CollapseCard>
  );
}
