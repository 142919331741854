import React from 'react';
import { Button } from 'reactstrap';

function DeviceButton({ prop, value, title, onChange }) {
  return (
    <Button
      color={value[prop] ? 'primary' : 'default'}
      onClick={() => onChange({ ...value, [prop]: !value[prop] })}
    >
      {title}
    </Button>
  );
}

export default function AllowedDevicesButtons(props) {
  return (
    <div className="d-flex justify-content-around">
      <DeviceButton prop="isDesktopAllowed" title="Desktop" {...props} />
      <DeviceButton prop="isMobileAllowed" title="Mobile" {...props} />
      <DeviceButton prop="isTabletAllowed" title="Tablet" {...props} />
    </div>
  );
}
