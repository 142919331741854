import React from 'react';
import { LoadServerData } from '../abstract';
import Quotas from './Quotas';

export default class ProjectQuotas extends LoadServerData {
  constructor(props) {
    super(props, 'quotas');

    if (!ProjectQuotas.hasQuotas(props)) {
      this.state.loadingData = false;
    }
  }

  static hasQuotas(props) {
    return Object.keys(props.quotas || {}).length > 0;
  }

  shouldLoadData() {
    return ProjectQuotas.hasQuotas(this.props);
  }

  getApiUrl() {
    return `/panelapi/${this.props.routeId}/quota/stats`;
  }

  getApiMethod() {
    return 'POST';
  }

  getApiData() {
    return {
      prodege_project_id: this.props.partnerProjectId
    };
  }

  assingStats() {
    const { quotas } = this.props;
    if (this.shouldLoadData() && this.state.loadingData === false && Array.isArray(this.state.quotas)) {
      this.state.quotas.forEach(quota => {
        if (quotas[quota.quota_id]) {
          Object.assign(quotas[quota.quota_id], {
            quota_clicks: quota.quota_clicks,
            quota_completes: quota.quota_completes
          });
        }
      });
    }

    return quotas;
  }

  renderContent() {
    return (
      <Quotas
        {...this.props}
        quotas={this.assingStats()}
      />
    );
  }
}
