import React from 'react';
import {
  Button,
  CardFooter
} from 'reactstrap';
import { AbstractForm, ButtonSelect } from 'emi-frontend';
import SelectAllMenuList from '../multiview/SelectAllMenuList';
import MultiProjectSelect from '../multiview/MultiProjectSelect';

export default class ReconciliationForm extends AbstractForm {

  getDefaultValues() {
    return {
      project_id: [],
      attachment: '',
      matchby: 'redirect_id'
    };
  }

  getID = () => {
    return null;
  }

  getFormHeader() {
    return null;
  }

  get modelName() {
    return 'attachment';
  }

  getApiPath = () => {
    return '/reconciliation';
  }

  sendFormData = () => {
    const fd = new FormData();
    fd.append('matchby', this.state.values.matchby);
    this.state.values.project_id.forEach(project_id => fd.append('project_id[]', project_id));

    if (this.upload) {
      const files = this.upload.files;
      for (let i = 0; i < files.length; i++) {
        fd.append('attachment', files[i], files[i].name);
      }
    }

    return this.clientApi('POST', this.getApiPath(), fd, false);
  }

  successAction = (response) => {
    this.resetForm(this.props);
    this.setState({ message : `Updated ${response.data.rowCount} respondent` });
    this.props.onSuccess();
  }

  cancelAction = () => {
    this.resetForm(this.props);
  }

  getFileName() {
    if (this.upload && this.upload.files && this.state.values.attachment) {
      return this.upload.files[0].name;
    }

    return 'Choose file...';
  }

  getActionButtons = () => {
    return (
      <CardFooter className="action-buttons d-none d-lg-block">
        <Button type="submit" color="success" disabled={this.state.sendingData}>
          <i className="fa fa-upload"></i> Upload
        </Button>
      </CardFooter>
    );
  }

  getFormContent() {
    return (
      <div>
        <div className="form-row">
          <div className="col">
            <div className="form-group custom-file">
              <input
                type="file"
                accept=".xlsx"
                value={this.state.values.attachment}
                className="custom-file-input"
                ref={(comp) => { this.upload = comp; }}
                name="attachment"
                required
                onChange={this.handleInputChange}
              />
              <label className="custom-file-label" htmlFor="validatedCustomFile">{this.getFileName()}</label>
            </div>
          </div>
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'Match by',
            name: 'matchby',
            options: { redirect_id: 'REDIRECT ID', client_code: 'CLIENT CODE', respondent_id: 'PARTNER ID'},
            component: ButtonSelect,
            type: 'text',
            required: true,
            labelWidth: { xs: 3 },
            valueWidth: { xs: 9 },
            fieldWidth: { xs: 12 }
          })}
        </div>

        {this.props.values.project_id === undefined &&
          <div className="form-row">
            {this.getFormRow({
              label: 'project',
              name: 'project_id',
              component: MultiProjectSelect,
              components: { MenuList: SelectAllMenuList(this.selectChangeHandler('project_id')) },
              onChange: this.selectChangeHandler('project_id'),
              required: true,
              closeMenuOnSelect: false,
              isMulti: true,
              invoice_id: this.props.invoice_id,
              fieldWidth: { xs: 12 },
              labelWidth: { xs: 12, lg: 3 },
              valueWidth: { xs: 12, lg: 9 }
            })}
          </div>
        }
      </div>
    );
  }
}
