import JobPausedGrid from '../job/PausedGrid';

export default class PausedGrid extends JobPausedGrid {
  get gridName() {
    return 'esearchpauseGrid';
  }

  getApiPath = () => {
    return '/esearch';
  }
}