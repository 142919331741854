import React from 'react';
import classNames from 'classnames';

export default function ReadOnlyField({ children, onEdit }) {

  return (
    <div
      className={classNames('form-control', 'form-control-sm', 'readonly-field', { hovered: true })}
      onClick={onEdit}
    >
      {children}
      {<i className="fa fa-pencil text-success float-right" aria-hidden="true" />}
    </div>
  );
}
