import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, client } from 'emi-frontend';
import { FormGroup, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import AttributeSelectList from './AttributeSelectList';
import AttributeForm from './AttributeForm';
import AttributeValueSettingGrid from './AttributeValueSettingGrid';
import AttributeBody from './AttributeGridBody';
import ClickBalancingTemplateModal from './ClickBalancingTemplateModal';
import LoadClickBalancingTemplate from './LoadClickBalancingTemplate';
import { ExportButton, saveDataAsXlsx } from '../../lib/export_data';
import PubSub from 'pubsub-js';

export default class AttributeGrid extends Grid {

  getInitialState(props) {
    return Object.assign(
      super.getInitialState(props),
      {
        title: 'Project Attributes',
        templateModal: false,
        attributeModal: false,
        selectModal: false,
        settingModal: false,
        settingModalTitle: '',
        attributeId: 0
      }
    );
  }

  setDefaultComponents() {
    super.setDefaultComponents();

    Object.assign(this.components, { Body: AttributeBody });
  }

  getHeader() {
    return null;
  }

  toggleModal = (prop, id, title) => {
    this.setState({
      [prop]: !this.state[prop],
      attributeId: id || 0,
      settingModalTitle: title || ''
    });
  }

  onRowSelection = (rowData) => {
    this.toggleModal('attributeModal', rowData.id);
  }

  get gridName() {
    return 'projectAttributeGrid';
  }

  getApiPath = () => {
    return '/project_attribute';
  }

  getBasePath = () => {
    return '/jobs';
  }

  typeRenderer = ({ data }) => {
    switch (data) {
      case 'r': return 'Radio Button';
      case 'd': return 'Drop-down List';
      case 'c': return 'Checkbox';
      default: return null;
    }
  }

  onAttributeDelete = () => {
    this.toggleModal('attributeModal');
    this.onDataChanged();
  }

  moveValue(event, id, direction, seq) {
    event.stopPropagation();

    return this.clientApi('PUT', `/project_attribute/${id}`, {
        seq,
        direction,
        project_id: this.props.extraParams.project_id
      })
      .done(this.reload)
      .fail(this.showError);
  }

  seqRenderer = ({data, rowCount, rowIndex, currentPage, pageSize, row }) => {
    const rowNumber = ((currentPage - 1) * pageSize) + rowIndex;

    return (
      <div>
        {rowCount - 2 >= rowNumber &&
          <button type="button" className="btn grid-button text-success" onClick={(event) => this.moveValue(event, row.id, 'down', data)}>
            <i className="fa fa-arrow-down" aria-hidden="true" />
          </button>
        }{' '}
        {rowNumber > 0 &&
          <button type="button" className="btn grid-button text-success" onClick={(event) => this.moveValue(event, row.id, 'up', data)}>
            <i className="fa fa-arrow-up" aria-hidden="true" />
          </button>
        }
      </div>
    )
  }

  isRowSelectable = () => {
    return !this.props.readOnly;
  }

  updateBlocked = (blocked, row) => {
    const params = { blocked: !row.blocked, project_id: row.project_id };

    this.clientApi('PUT', `${this.getApiPath()}/${row.id}`, params)
      .done(() => this.reload())
      .fail((error) => this.showError(error, { loadingData: false }));
  }

  addAttribute = (attributeId) => {
    const params = { attributeId, project_id: this.props.extraParams.project_id };
    return this.clientApi('POST', this.getApiPath(), params)
      .done(() => {
        this.toggleModal('selectModal');
        this.onDataChanged();
      })
      .fail(this.showError);
  }

  onDataChanged() {
    this.reload();
    this.props.onChange();
  }

  exportData = () => {
    const { extraParams } = this.props;
    const params =  {};

    if (extraParams) {
      Object.assign(params, { extraParams });
    }

    this.setState(
      { loadingData: true },
      () => {
        this.clientApi('GET', `${this.getApiPath()}/export`, params)
          .done((response) => this.exportAction(response, params))
          .fail((error) => this.showError(error, { loadingData: false }));
      }
    );
  }

  getExportHeaders(response) {
    return response.headers || {};
  }

  exportAsXlsx() {
    const { extraParams } = this.props;
    this.clientApi('GET', `${this.getApiPath()}/rawexport`, { extraParams: { ...extraParams, user_id: this.context.user.id } })
      .done(() => {
        this.setState({ message: 'Background job created', success: true });
        PubSub.publish(`background_job_update`, false);
      })
      .fail(this.showError);
  }

  exportRawData = ({ data, headers }) => {
    saveDataAsXlsx(data.map(row => row.data), headers);
  }

  SettingsButton = (props) => {
    const { data, className, btnClassName, summary, ...rest } = props;

    return (
      <div className={className}>
        <Button
          className={btnClassName} {...rest}
          clickHandler={() => this.toggleModal('settingModal', data, rest.row.name)}
        />
      </div>
    );
  }

  BlockedButton = (props) => {
    const { data, className, btnClassName, summary, ...rest } = props;

    return (
      <div className={className}>
        <Button
          {...rest}
          className={rest.row.blocked ? 'text-danger': 'text-success'}
          icon={rest.row.blocked ? 'stop-circle': 'play-circle'}
          clickHandler={this.updateBlocked}
        />
      </div>
    );
  }

  getHeaderComponent() {
    return (
      <FormGroup row className="mt-2">
        <LoadClickBalancingTemplate
          project_id={this.props.extraParams.project_id}
          onSuccess={() => this.onDataChanged()}
        />
        <Col xs="12" lg="6" className="d-flex justify-content-end mt-1 mt-lg-0">
          { /* this.getExportButton('btn-sm right-margin-10') */}
          <button type="button" className="btn btn-primary btn-sm mr-2" onClick={() => this.toggleModal('templateModal')}>
            <i className="fa fa-save" aria-hidden="true"></i>{' '} Save as template
          </button>
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => this.toggleModal('selectModal')}
          >
            Attribute Library
          </button>
          <ExportButton className="ml-2" title="Export raw data" onClick={() => this.exportAsXlsx()} />
        </Col>
      </FormGroup>
    );
  }

  getColumns() {
    return [{
      name: 'seq',
      title: 'seq',
      sortable: true,
      asc: true,
      width: 10
    }, {
      name: 'name',
      title: 'name',
      //sortable: true,
      //asc: true,
      width: 250
    }, {
      name: 'title',
      title: 'title',
      width: 250
    }, {
      name: 'display_type',
      title: 'Display type',
      width: 100,
      renderer: this.typeRenderer
    }
    /*
    , {
      name: 'seq',
      title: 'seq',
      width: 100,
      asc: true,
      renderer: this.seqRenderer
    }, {
      name: 'blocked',
      title: '',
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderer: this.BlockedButton,
      params: {
        className: 'left-border'
      }
    }, {
      name: 'settings',
      dataField: 'id',
      title: '',
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderer: this.SettingsButton,
      params: {
        icon: 'bar-chart',
        className: 'left-border'
      }
    }
    */
    ];
  }

  getModals() {
    return [
      <AttributeForm
        key="attribute_form"
        id={this.state.attributeId}
        isOpen={this.state.attributeModal}
        toggle={() => this.toggleModal('attributeModal')}
        onDelete={this.onAttributeDelete}
        reload={this.reload}
      />,
      <AttributeValueSettingGrid
        key="setting_modal"
        paging={false}
        isOpen={this.state.settingModal}
        toggle={() => this.toggleModal('settingModal')}
        modalTitle={this.state.settingModalTitle}
        extraParams={{ project_attribute_id: this.state.attributeId }}
      />,
      <Modal
        key="select_modal"
        size="lg"
        isOpen={this.state.selectModal}
        toggle={() => this.toggleModal('selectModal')}
      >
        <ModalHeader toggle={() => this.toggleModal('selectModal')}>
          Attribute Library
        </ModalHeader>
        <ModalBody>
          <AttributeSelectList assignAttribute={this.addAttribute} rowSelector={() => {}} />
        </ModalBody>
      </Modal>,
      <ClickBalancingTemplateModal
        key="template_modal"
        values={{ project_id: this.props.extraParams.project_id }}
        isOpen={this.state.templateModal}
        toggle={() => this.toggleModal('templateModal')}
      />
    ];
  }

  getPagination() {
    return (
      <div>
        {
          !this.props.readOnly
            ? this.getModals()
            : ''
        }
        {super.getPagination()}
      </div>
    );
  }
}

AttributeGrid.contextTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};
