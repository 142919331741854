import React from 'react';
import eol from 'eol';
import MultiCell from './Cell';
import MultiRow from './Row';
import MultiBody from './Body';
import TitleField from './TitleField';
import TargetQuotaCell from './TargetQuotaCell';
import { Grid, Button, ProgressRing, formatCurrency, composeStartUrl, CopyClipboard } from 'emi-frontend';
import StatusUpdateModal from '../job/StatusUpdateModal';
import classNames from 'classnames';

function getSurveyList(listName, list) {
  return `${listName}${eol.auto}${list
    .map((item) => 
      `\t${item.name}${eol.auto}` +
      `\t\tLive: ${composeStartUrl(item.url, item.variables, item.fixed_variables)}${eol.auto}` +
      `\t\tTest: ${composeStartUrl(item.url, item.variables, item.fixed_variables)}/?test=1${eol.auto}`
    )
    .join('')}`;
}

function KickoffToTestButton({ row, updateFn }) {
  return (
    <button
      type="button"
      className="btn grid-button left-border"
      onClick={e => {
        e.stopPropagation();
        updateFn(row.id, row);
      }}
    >
      <i className="fa fa-play" />
    </button>
  );
}

function ProjectToLiveButton({ row, target }) {
  return (
    <button
      type="button"
      className="btn grid-button left-border"
      onClick={e => e.stopPropagation()}
      data-id={row.id} data-status="live" data-group={row.project_group_id}
      data-toggle="modal" data-target={target}
    >
      <i className="fa fa-play" />
    </button>
  );
}

function MultiStatus({ row, target }) {
  return (
    <div className="dropdown multi-status">
      <button className="btn grid-button dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
        <i className={`fa fa-${row.project_status === 'paused' ? 'pause' : 'play'}`} />
      </button>
      <div className="dropdown-menu dropdown-menu-right">
        {row.project_status === 'paused' ? (
          <button
            className="dropdown-item" type="button" 
            onClick={e => e.stopPropagation()}
            data-id={row.id} data-status="live" data-group={row.project_group_id}
            data-toggle="modal" data-target={target}
          >
            <i className="fa fa-play" />
          </button>
        ) : (
          <button
            className="dropdown-item" type="button" 
            onClick={e => e.stopPropagation()}
            data-id={row.id} data-status="paused" data-group={row.project_group_id}
            data-toggle="modal" data-target={target}
          >
            <i className="fa fa-pause" />
          </button>
        )}
        <button
          className="dropdown-item text-danger" type="button" 
          onClick={e => e.stopPropagation()}
          data-id={row.id} data-status="closed" data-group={row.project_group_id}
          data-toggle="modal" data-target={target}
        >
          <i className="fa fa-stop" />
        </button>
      </div>
    </div>
  );
}

export default class RouteGrid extends Grid {

  get gridName() {
    return 'multiRouteGrid';
  }

  getApiPath = () => {
    return '/route';
  }

  getBasePath = () => {
    return '/jobs';
  }

  FormatCost = ({ data, row }) => {
    if (!data) {
      return '-';
    }

    return formatCurrency(data);
  }

  onRowClick = (rowData) => {
    this.props.toggleRouteModal(rowData.route_id, this.props.extraParams.status, rowData.project_name);
  }

  setDefaultComponents() {
    super.setDefaultComponents();

    this.components = Object.assign(
      Object.assign({}, this.components), { Cell: MultiCell, Row: MultiRow, Body: MultiBody }
    );
  }

  goToProject = (projectId) => {
    this.context.history.replace(`/jobs/${projectId}`);
  }

  updateProject(projectId, update) {
    return this.clientApi('PUT', `/project/${projectId}`, update)
      .done(() => {
        this.reload();
        this.props.reloadGrid(update.status);
      })
      .fail(this.showError);
  }

  getStatusUpdate(status, merge, data) {
    return Object.assign({ status }, merge ? data : null);
  }

  setProjectTest = (projectId, row) => {
    const update = this.getStatusUpdate('test', !row.project_group_id, { policy: 'monitor' });
    this.updateProject(projectId, update);
  }

  setProjectLive = ({ id, group, ...update }) => {
    Object.assign(update, !group ? { policy: 'block' } : null);
    this.updateProject(id, update);
  }

  updateProjectStatus = ({ id, group, ...update }) => {
    this.updateProject(id, update);
  }

  CopyUrlButton = (props) => {
    const {data, className, btnClassName, summary, ...rest} = props;

    return (
      <div className={className}>
        <CopyClipboard
          className={btnClassName}
          title="Copy start URL"
          {...rest}
          getText={() => {
            let url = '';
            if (rest.row.rowType) {
              url = composeStartUrl(rest.row.survey_url, rest.row.variables, rest.row.fixed_variables);
            } else if (!summary && !rest.row.rowType) {
              url = getSurveyList(rest.row.name, rest.row.survey_url);
            } else {
              url = Object
                .keys(rest.row.survey_url)
                .map((item) => getSurveyList(item, rest.row.survey_url[item]))
                .join('');
            }
            return url;
          }}
        />
      </div>
    );
  }

  ExportField = ({ data, row, column, summary }) => {
    if (!data) {
      return data;
    }

    const { partner_id, project_id } = row;

    const params = Object.assign({
      invoice_id: this.props.extraParams.invoice_id,
      status: column.name,
      project_status: this.props.extraParams.status,
      project_id, partner_id: partner_id || undefined,
      from_date: this.state.extraParams.from_date || undefined,
      to_date: this.state.extraParams.to_date || undefined
    });

    return (
      <a href="#" onClick={(e) => this.props.showRedirectModal(e, params, this.props.extraParams.status)}>{data}</a>
    );
  }

  ExportButton = (props) => {
    const {data, className, btnClassName, summary, row: { partner_id, project_id }, ...rest} = props;

    return (
      <div className={className}>
        <Button
          className={btnClassName} {...rest} row={props.row} 
          clickHandler={() => {
            this.props.exportRedirects({
              invoice_id: this.props.extraParams.invoice_id,
              partner_id: partner_id || undefined, project_id,
              from_date: this.state.extraParams.from_date || undefined,
              to_date: this.state.extraParams.to_date || undefined
            });
            this.setState({ message: 'Background job created', success: true });
          }} 
        />
      </div>
    );
  }

  RouteLineItemButton = (props) => {
    const {data, className, btnClassName, summary, ...rest} = props;
    if (rest.row.group === 'project') {
      return (
        <div className={summary ? null : className}>
          {rest.row.project_status === 'kickoff' && 
            <KickoffToTestButton row={rest.row} updateFn={this.setProjectTest} />
          }
          {(rest.row.project_status === 'test' || rest.row.project_status === 'closed') && 
            <ProjectToLiveButton row={rest.row} target={`#${this.props.extraParams.status}-status-modal`} />
          }
          {(rest.row.project_status === 'live' || rest.row.project_status === 'paused') && 
            <MultiStatus row={rest.row} target={`#${this.props.extraParams.status}-status-modal`} />
          }
        </div>
      );
    }

    if (rest.row.group !== undefined) {
      return (
        <div className={summary ? null : className} />
      );
    }

    const params = {
      partnerId: rest.row.rowType === 'project' ? rest.row.partner_id : rest.row.id,
      routeId: rest.row.route_id,
      email: rest.row.reconciliation_email,
      projectName: rest.row.project_name
    };

    return (
      <div className={summary ? null : className}>
        <Button
          className={btnClassName} {...rest} 
          clickHandler={() => this.props.toggleRouteLineItemModal(params)} 
        />
      </div>
    );
  }

  PanelApiButton = (props) => {
    const {data, className, btnClassName, summary, ...rest} = props;
    if (rest.row.group !== undefined || !rest.row.use_panel_api) {
      return <div className={summary ? null : className} />;
    }

    const params = {
      partnerId: rest.row.rowType === 'project' ? rest.row.partner_id : rest.row.id,
      projectId: rest.row.project_id,
      panelApi: rest.row.panel_api,
      apiRouteId: rest.row.route_id,
      routeQuota: rest.row.quota
    };

    // Check if Partner has API and the project is LIVE
    let statusClass = '';
    if (rest.row.api_data && rest.row.api_data.project) {
      if (
        (rest.row.panel_api === 'prodege' && rest.row.api_data.project.status === 'LIVE') ||
        (rest.row.panel_api === 'tapresearch' && rest.row.api_data.project.status === 2) ||
        (rest.row.panel_api === 'researchnow' && rest.row.api_data.project.status === 'LAUNCHED') ||
        (rest.row.panel_api === 'purespectrum' && rest.row.api_data.project.ps_survey_status === 22) ||
        (rest.row.panel_api === 'disqo' && rest.row.api_data.project.status === 'OPEN') ||
        (rest.row.panel_api === 'marketcube' && rest.row.api_data.project.status === 5)
      ) {
        statusClass = 'text-success';
      }
    }

    return (
      <div className={summary ? null : className}>
        <Button
          className={classNames(btnClassName, statusClass)} {...rest}
          clickHandler={() => this.props.togglePanelApiModal(params)} 
        />
      </div>
    );
  }

  getColumns() {
    return [{
      name: 'progress',
      title: '',
      sortable: false,
      width: 60,
      exactWidth: true,
      renderer: ProgressRing,
      params: {
        size: 40,
        stroke: 4
      }
    }, {
      name: 'name',
      title: 'Name',
      renderer: TitleField,
      width: 200,
      asc: true
    }, {
      name: 'test',
      title: 'test',
      renderer: this.ExportField,
      summarize: true,
      width: 80,
      exactWidth: true
    }, {
      name: 'incomplete',
      title: 'incomplete',
      renderer: this.ExportField,
      summarize: true,
      width: 100
    }, {
      name: 'screenout',
      title: 'screenout',
      renderer: this.ExportField,
      summarize: true,
      width: 100
    }, {
      name: 'speeder',
      title: 'Quality Fail',
      renderer: this.ExportField,
      summarize: true,
      width: 100
    }, {
      name: 'duplicated',
      title: 'duplicated',
      renderer: this.ExportField,
      summarize: true,
      width: 100
    }, {
      name: 'quotafull',
      title: 'over quota',
      renderer: this.ExportField,
      summarize: true,
      width: 100
    }, {
      name: 'completed',
      title: 'completed',
      renderer: this.ExportField,
      summarize: true,
      width: 100
    }, {
      name: 'quota',
      title: 'quota',
      summarize: true,
      width: 140,
      exactWidth: true,
      renderer: TargetQuotaCell,
      params: {
        onDataChange: () => this.reload()
      }
    }, {
      name: 'cpi',
      title: 'cpi',
      width: 70,
      exactWidth: true,
      renderer: this.FormatCost
    }, {
      name: 'cost',
      title: 'cost',
      summarize: true,
      width: 90,
      exactWidth: true,
      renderer: this.FormatCost
    }, {
      name: 'use_panel_api',
      title: '',
      dataField: 'use_panel_api',
      summarize: false,
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderer: this.PanelApiButton,
      params: {
        icon: 'random',
        className: 'left-border',
        apiHandler: (params) => {
          this.props.togglePanelApiModal(params);
        }
      }
    }, {
      name: 'copy_url',
      dataField: 'survey_url',
      title: '',
      summarize: true,
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderer: this.CopyUrlButton,
      params: {
        icon: 'clipboard',
        className: 'left-border'
      }
    }, {
      name: 'custom_cost',
      dataField: 'partner_id',
      title: '',
      summarize: false,
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderer: this.RouteLineItemButton,
      params: {
        icon: 'usd',
        className: 'left-border',
        costHandler: (partnerId, routeId) => {
          this.props.toggleRouteLineItemModal({ partnerId, routeId });
        }
      }
    }, {
      name: 'export',
      dataField: 'fake',
      title: '',
      summarize: true,
      sortable: false,
      width: 46,
      exactWidth: true,
      className: 'button-cell',
      renderer: this.ExportButton,
      params: {
        icon: 'download',
        className: 'left-border'
      }
    }];
  }

  getMobilColumns() {
    return Object.fromEntries(
      Object.entries(this.state.columns).filter(([prop]) => ['name', 'test', 'completed', 'quota', 'cost'].includes(prop))
    );
  }

  getMobileHeader() {
    return <this.components.Head columns={this.getMobilColumns()} onSort={this.onSort} />;
  }

  getMobileBody() {
    return <this.components.Body
      columns={this.getMobilColumns()}
      data={this.state.data}
      recordCount={this.state.recordCount}
      currentPage={this.state.currentPage}
      pageSize={this.state.pageSize}
      onClick={this.onRowClick}
      loading={this.state.loadingData}
      rowStyler={this.getRowStyler()}
      adjustTotal={this.props.adjustTotal}
      extraParams={this.state.extraParams}
    />;
  }

  isRowSelectable = () => {
    return false;
  }

  getStatusUpdateFn() {
    switch (this.props.extraParams.status) {
      case 'test':
        return this.setProjectLive;
      case 'live':
      case 'paused':
      case 'closed':
        return this.updateProjectStatus;
      default:
        return () => alert('No function specified');
    }
  }

  getPagination() {
    return (
      <div>
        <div
          className="d-flex flex-row flex-nowrap grid-row content rounded job-add-partner cursor-pointer"
          onClick={this.props.toggleMultiRouteModal}
        >
          <span className="text-success"><i className="fa fa-plus"></i> Add a Partner</span>
        </div>
        {this.props.extraParams.status !== 'kickoff' &&
          <StatusUpdateModal
            id={`${this.props.extraParams.status}-status-modal`}
            updateFn={this.getStatusUpdateFn()}
          />
        }
        {super.getPagination()}
      </div>
    );
  }
}
