import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { client, Button } from 'emi-frontend';
import { ExportButton, filterDataProps, saveDataAsXlsx } from '../../lib/export_data';

export default class Prescreening extends Component {

  static propTypes = {
    project_id: PropTypes.number.isRequired
  };

  state = {
    data: []
  };

  reloadData() {
    this.getApiData(this.setData);
  }

  getApiData(doneFunc) {
    const { project_id } = this.props

    client.api('GET', '/project_attribute_value', {
      sort: { 'project_attribute.seq': true, 'project_attribute_value.seq': true },
      extraParams: { project_id }
    })
      .done(doneFunc)
      .fail(console.error);
  }

  componentDidMount() {
    this.reloadData();
  }

  setData = ({ data }) => {
    this.setState({ data });
  }

  exportAsXlsx() {
    this.getApiData(this.exportData);
  }

  exportData = ({ data }) => {
    const headers = {
      attribute_name: 'Attribute name',
      attribute_value: 'Attribute value',
      count: 'Clicks',
      blocked: 'Blocked'
    };

    saveDataAsXlsx(
      filterDataProps(data, Object.keys(headers)), headers
    );
  }

  mergeData(id, params) {
    const data = this.state.data.map(row => row.id === id ? Object.assign({}, row, params) : row);
    this.setState({ data });
  }

  setAttributeValueStatus(id, blocked) {
    client
      .api('PUT', `/project_attribute_value/${id}`, { blocked })
      .done(() => this.mergeData(id, { blocked }))
      .fail(console.error);
  }

  getTableData() {
    return Object.entries(
      this.state.data.reduce((acc, curr) => {
        if (!acc[curr.project_attribute_id]) {
          acc[curr.project_attribute_id] = [];
        }
        acc[curr.project_attribute_id].push(curr);

        return acc;
      }, {})
    );
  }

  render() {
    if (!this.state.data.length) {
      return null;
    }

    return (
      <div>
        <table className="table table-sm table-bordered">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Clicks</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          {this.getTableData().map(([attribute_id, values]) => {
            const [{attribute_name}] = values;

            return (
              <tbody key={attribute_id}>
                <tr>
                  <th colSpan="3" className="table-secondary">{attribute_name}</th>
                </tr>
                {values.map(row =>
                  <tr key={row.id} className={row.blocked ? 'text-danger' : 'text-success'}>
                    <td>{row.attribute_value}</td>
                    <td>{row.count}</td>
                    <td>
                      <Button 
                        row={row}
                        icon={row.blocked ? 'ban' : 'check'}
                        className={classNames('prescreening-button', row.blocked ? 'text-danger' : 'text-success')}
                        clickHandler={() => this.setAttributeValueStatus(row.id, !row.blocked)}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            );
          })}
        </table>
        <ExportButton className="float-right" onClick={() => this.exportAsXlsx()} />
      </div>
    );
  }
}
