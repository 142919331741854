import React from 'react';
import update from 'immutability-helper';
import ClientSelect from '../../lib/form/ClientSelect';
import ContactSelect from '../../lib/form/ContactSelect';
import AccountingContactSelect from './AccountingContactSelect';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import jobSummary from './JobSummary';
import classNames from 'classnames';
import { getInvoiceStatus } from './utils';
import QuickbooksGrid from './QuickbooksGrid';
import StatusField from './StatusField';
import EditField from '../../lib/form/EditField';
import ReadOnlyField from '../../lib/form/ReadOnlyField';
import ReminderModal from './ReminderModal';
import { emptyFn } from '../../lib/utils';
import { client, AbstractForm, CollapseCard, FormDisabledRow, formatCurrency, Mobile, Desktop } from 'emi-frontend';

class InvoiceForm extends AbstractForm {

  constructor(props) {
    super(props);

    this.projectTotal = 0;
    this.jobSummary = 0;
    this.prepaid = 0;

    this.state.projectTotal = 0;
    this.state.jobSummary = 0;
    this.state.partnerInvoiceEditType = '';
    this.state.partnerInvoiceEditId = '';
    this.state.partnerInvoiceEditValue = '';
    this.state.quickbooksMain = {};
    this.state.quickbooksSub = {};
    this.state.billInvoiceNumbers = {};
  }

  componentDidMount() {
    this.loadQuickbooksCategories('Main');
    this.loadQuickbooksCategories('Sub');
    super.componentDidMount();
  }

  loadQuickbooksCategories(type) {
    // Load Quickbooks main- and sub-categories
    this.clientApi('GET', `/quickbooks_${type.toLowerCase()}`)
    .done((response) => {
      this.setState({
        [`quickbooks${type}`]: response.data
      });
    })
    .fail(this.loadFail);
  }
  
  getDefaultValues() {
    return {
      name: '',
      email: '',
      user_type: 'admin',
      password: '',
      active: true,
      initials: '',
      department: []
    };
  }

  getApiPath = () => {
    return '/invoice';
  }

  cancelPath = () => {
    return '/invoices';
  }

  get modelName() {
    return 'invoice';
  }

  redirectPath = () => {
    return this.cancelPath();
  }

  generateJobSummaryData(projects) {
    // Group data by Partners
    const data = {};
    Object
      .keys(projects)
      .forEach((projectId) => {
        const project = projects[projectId];
        if (project.job_summary !== null && Array.isArray(project.job_summary)) {
          project.job_summary
            .forEach((summary) => {
              if (summary.line_items !== null && Array.isArray(summary.line_items)) {
                summary.line_items
                  .forEach((item) => {
                    if (!data[summary.partner_id]) {
                      data[summary.partner_id] = {
                        name: summary.partner_name,
                        projects: {}
                      };
                    }
                    if (!data[summary.partner_id].projects[projectId]) {
                      data[summary.partner_id].projects[projectId] = {
                        name: project.name,
                        initials: `${project.sales}:${project.pm}`,
                        items: []
                      };
                    }
                    data[summary.partner_id].projects[projectId].items.push(item);
                  });
              }
            });
        }
      });
      return data;
  }

  convertResponse(data) {
    if (data.projects !== null && Object.keys(data.projects).length) {
      const project = data.projects[Object.keys(data.projects)[0]];
      const response = Object.assign({}, data);
      response.accounting_id = project.accounting_id;
      response.client_id = project.client_id;
      response.contact_id = project.contact_id;
      response.job_number = project.job_number;
      response.job_type = project.client_job_type;
      response.qclass = `${project.sales}:${project.pm}`;
      response.jobSummaryData = this.generateJobSummaryData(data.projects);
      return response;
    } else {
      data.projects = {};
    }

    return data;
  }

  finalizeHasError() {
    let error;
    if (!this.state.values.close_date) {
      error = { responseText: 'Close Date Required when finalizing Invoice.' };
    }

    if (error) {
      this.showError(error);
    }
    
    return error;
  }

  showResolveButton() {
    return this.state.values.status === 'quickbooks_error' && this.state.values.quickbooks_error;
  }

  finalizeInvoice = () => {
    if (this.finalizeHasError()) {
      return;
    }

    client
      .api('PUT', `${this.getApiPath()}/${this.getId()}`, { finalize: true })
      .done((resp) =>
        this.loadDone(resp, { message: 'Invoice has been finalized.', success: true})
      )
      .fail((error) => this.showError(error));
  }

  resolveInvoice() {
    this.clientApi('PUT', `${this.getApiPath()}/${this.getId()}/resolve`)
      .done((resp) =>
        this.loadDone(resp, { message: 'Invoice has been resolved.', success: true})
      )
      .fail((error) => this.showError(error));
  }

  resyncInvoice() {
    client
      .api('PUT', `${this.getApiPath()}/${this.getId()}`, { resync: true })
      .done((resp) =>
        this.loadDone(resp, { message: 'Invoice can be resync\'ed to QuickBooks.', success: true})
      );
  }

  downloadInvoice() {
    client
      .fileDownload(`${this.getApiPath()}/${this.getId()}/invoice`, `invoice_${this.state.values.io_number}.pdf`);
  }

  downloadInvoiceXml() {
    client
      .fileDownload(`${this.getApiPath()}/${this.getId()}/invoice?type=xml`, `invoice_${this.state.values.io_number}.xml`);
  }

  downloadJobSummary() {
    client
      .api('GET', `${this.getApiPath()}/${this.getId()}`)
      .done((response) => jobSummary(response.data));
  }

  goToProject(projectId) {
    this.context.history.replace(`/jobs/${projectId}`);
  }

  isReadonlyForm() {
    // Invoice can be edited all the time
    return false;
  }

  formatDiscount(discount, type) {
    if (discount > 0) {
      if (type === 'amount') {
        return `$${discount}`;
      } else {
        return `${discount}%`;
      }
    }

    return '-';
  }
  
  getLineItemTotal(item) {
    let total = item.cpi * item.completed;
    if (total > 0) {
      if (item.discount_type === 'amount') {
        total -= item.discount;
      } else {
        total *= ((100 - item.discount) / 100);
      }

      return total;
    }

    return 0;
  }

  applyProjectDiscount(total, numLineItems, projectDiscount, projectDiscountType) {
    if (total > 0 && projectDiscount > 0) {
      if (projectDiscountType === 'amount') {
        total -= (projectDiscount / numLineItems);
      } else {
        total *= (100 - projectDiscount) / 100;
      }
    }
    return total;
  }

  getProjectLineItems(projectId, items, projectDiscount, projectDiscountType) {
    if (items === null) {
      return <div className="alert alert-warning fade show">No line item data.</div>;
    }
    let projectTotal = 0;

    const itemsLength = items
      .filter((item) => this.getLineItemTotal(item) > 0)
      .length;

    return (
      <table className="table table-sm table-hover">
        <thead>
          <tr>
            <th scope="col">DESCRIPTION</th>
            <th scope="col">CPI</th>
            <th scope="col">COMPLETED</th>
            <th scope="col">AMOUNT</th>
            <th scope="col">DISCOUNT</th>
            <th scope="col" className="right">TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {
            items.map((item, idx) => {

              const total = this.applyProjectDiscount(
                this.getLineItemTotal(item),
                itemsLength,
                projectDiscount,
                projectDiscountType
              );
              projectTotal += total;
              this.projectTotal += total;
              return (
                <tr key={`li_${projectId}_${idx}`}>
                  <td>{item.description}</td>
                  <td>{formatCurrency(item.cpi)}</td>
                  <td>{item.completed}</td>
                  <td>{formatCurrency(item.completed * item.cpi)}</td>
                  <td>{this.formatDiscount(item.discount, item.discount_type)}</td>
                  <td align="right">{formatCurrency(total)}</td>
                </tr>
              );
            })
          }
          <tr>
            <td colSpan="5" />
            <th scope="row" className="right">{formatCurrency(projectTotal)}</th>
          </tr>
        </tbody>
      </table>
    );
  }

  getProjects() {
    this.projectTotal = 0;
    this.prepaid = 0;
    return (
      <CollapseCard title="Project information">
        {
          Object.keys(this.state.values.projects).map((projectId) => {
            const project = this.state.values.projects[projectId];
            let prepaidRow = '';
            if (project.prepaid !== null && project.prepaid > 0) {
              prepaidRow = (
                <div className="form-row">
                  <FormDisabledRow
                    label="Prepaid"
                    name="prepaid"
                    id="prepaid"
                    value={formatCurrency(project.prepaid)}
                    fieldWidth={{ xs: 12, sm: 6 }}
                  />
                </div>
              );

              this.prepaid += project.prepaid;
            }
            return (
              <Card key={`invp_${projectId}`} className="invoice-project-card">
                <CardHeader>
                  <span className="project-title">
                    {project.name}
                  </span>
                  <span className="float-right">
                    <button type="button" className="btn btn-primary btn-sm" onClick={this.goToProject.bind(this, projectId)}>View Project</button>
                  </span>
                </CardHeader>
                <CardBody>
                  <div className="form-row">
                    <FormDisabledRow
                      label="Job#"
                      name="job_number"
                      id="job_number"
                      value={project.job_number || '-'}
                      fieldWidth={{ xs: 12, sm: 6 }}
                    />

                    <FormDisabledRow
                      label="Discount"
                      name="discount"
                      id="discount"
                      value={this.formatDiscount(project.discount, project.discount_type)}
                      fieldWidth={{ xs: 12, sm: 6 }}
                    />
                  </div>
                  {prepaidRow}

                  {this.getProjectLineItems(projectId, project.line_items, project.discount, project.discount_type)}

                </CardBody>
              </Card>
            );
          })
        }

      </CollapseCard>
    );
  }

  setPartnerInvoiceField = (type, key, currentValue) => {
    this.setState({
      partnerInvoiceEditType: type,
      partnerInvoiceEditId: key,
      partnerInvoiceEditValue: currentValue
    });
  }

  handlePartnerInvoiceChange = (name, value) => {
    this.setState({
      partnerInvoiceEditValue: value
    });
  }

  getInvoiceDueDate(partnerId, invoiceNumber) {
    let dueDate = null;
    Object
      .keys(this.state.values.jobSummaryData[partnerId].projects)
      .forEach((projectId) => {
        this.state.values.jobSummaryData[partnerId].projects[projectId].items
          .forEach((item) => {
            if (
              item.partner_invoice_number === invoiceNumber &&
              item.partner_due_date &&
              !dueDate
            ) {
              dueDate = item.partner_due_date;
            }
          });
      });
    return dueDate;
  }

  storeData(partnerId, itemId, modType, invoiceNumber, dueDate = null) {
    Object
    .keys(this.state.values.jobSummaryData[partnerId].projects)
    .forEach((projectId) => {
      const project = this.state.values.jobSummaryData[partnerId].projects[projectId];
      project.items
        .forEach((item, itemIdx) => {
          if (
            itemId === item.id || 
            (modType === 'invoice' && !item.partner_invoice_number && invoiceNumber) ||
            (modType === 'due' && item.partner_invoice_number === invoiceNumber)
          ) {
            client
              .api('PUT', `/routelineitem/${item.id}`, {
                partner_invoice_number: invoiceNumber,
                partner_due_date: invoiceNumber === '' ? null : dueDate
              })
              .done(() => {
                this.setState({
                  values: update(this.state.values, {
                    jobSummaryData: {
                      [partnerId]: {
                        projects: {
                          [projectId]: {
                            items: {
                              [itemIdx]: { 
                                partner_invoice_number: { $set: invoiceNumber },
                                partner_due_date: { $set: dueDate }
                              }
                            }
                          }
                        }
                      }
                    }
                  })
                });
              })
              .fail((error) => this.showError(error));
          }
        });
    });
  }

  savePartnerInvoice = (partnerId, projectId, itemIdx) => {
    const newData = this.state.partnerInvoiceEditValue;
    const modType = this.state.partnerInvoiceEditType;
    const itemId = this.state.partnerInvoiceEditId;

    if (modType === 'invoice') {
      const invoiceDue = this.getInvoiceDueDate(partnerId, newData);
      this.storeData(partnerId, itemId, modType, newData, invoiceDue);
    } else {
      const invoiceNumber = this.state.values.jobSummaryData[partnerId].projects[projectId].items[itemIdx].partner_invoice_number;
      this.storeData(partnerId, itemId, modType, invoiceNumber, newData);
    }

    this.setState({
      partnerInvoiceEditValue: '',
      partnerInvoiceEditType: '',
      partnerInvoiceEditId: ''
    });
  }

  getJobSummaryRows() {
    if (!Object.keys(this.state.quickbooksMain).length || !Object.keys(this.state.quickbooksSub).length) {
      return null;
    }

    if (!this.state.values.jobSummaryData || !Object.keys(this.state.values.jobSummaryData).length) {
      return (<div className="alert alert-warning fade show">No job summary data.</div>);
    }

    const rowClasses = "grid-cell d-flex align-content-center align-items-center";

    return Object
      .keys(this.state.values.jobSummaryData)
      .map((partnerId) => {
        const partner = this.state.values.jobSummaryData[partnerId];
        let partnerTotal = 0;
        let partnerCompletes = 0;
        return (
          <div key={partnerId} className="flex-column d-flex flex-nowrap grid-row content rounded">
            <div className="d-flex flex-row flex-nowrap grid-row font-weight-bold">
              <div className={rowClasses} style={{width: 100}}>{partner.name}</div>
            </div>
            <div className="d-flex flex-column flex-nowrap grid-client-row">
              {
                Object
                  .keys(partner.projects)
                  .map((projectId) => {
                    const project = partner.projects[projectId];
                    return project.items
                      .map((item, itemIdx) => {
                        const editInvoice = this.state.partnerInvoiceEditType === 'invoice' && this.state.partnerInvoiceEditId === item.id;
                        const editDue = this.state.partnerInvoiceEditType === 'due' && this.state.partnerInvoiceEditId === item.id;
                        partnerCompletes += item.completed;
                        partnerTotal += item.cpi * item.completed;
                        this.jobSummary += item.cpi * item.completed;;
                        return (
                          <div key={item.id} className="d-flex flex-row flex-nowrap grid-row">
                            <div className={rowClasses} style={{width: 300}}>{project.name}</div>
                            <div className={rowClasses} style={{width: 50}}>{project.initials}</div>
                            <div className={rowClasses} style={{width: 100}}>
                              {this.state.quickbooksMain[item.quickbooks_main]}:{this.state.quickbooksSub[item.quickbooks_sub]}
                            </div>
                            <div className={rowClasses} style={{width: 100}}>
                              {editInvoice ? (
                                <EditField
                                  name="partner_invoice_number"
                                  inputProps={{
                                    type: 'text'
                                  }}
                                  value={this.state.partnerInvoiceEditValue}
                                  onChange={this.handlePartnerInvoiceChange}
                                  onCancel={() => this.setPartnerInvoiceField('', '', '')}
                                  onSave={() => this.savePartnerInvoice(partnerId, projectId, itemIdx)}
                                />
                              ) : (
                                <ReadOnlyField onEdit={() => this.setPartnerInvoiceField('invoice', item.id, item.partner_invoice_number)}>
                                  {item.partner_invoice_number}
                                </ReadOnlyField>
                              )}
                            </div>
                            <div className={rowClasses} style={{width: 100}}>
                              {
                                item.partner_invoice_number
                                  ? (editDue
                                      ? (
                                      <EditField
                                        name="partner_due_date"
                                        inputProps={{
                                          type: 'date'
                                        }}
                                        value={this.state.partnerInvoiceEditValue}
                                        onChange={this.handlePartnerInvoiceChange}
                                        onCancel={() => this.setPartnerInvoiceField('', '', '')}
                                        onSave={() => this.savePartnerInvoice(partnerId, projectId, itemIdx)}
                                      />)
                                      : (
                                      <ReadOnlyField onEdit={() => this.setPartnerInvoiceField('due', item.id, item.partner_due_date)}>
                                        {item.partner_due_date}
                                      </ReadOnlyField>)
                                  )
                                  : <p className="text-danger">Enter IO#</p>
                              }
                            </div>
                            <div className={classNames(rowClasses, 'justify-content-end')} style={{width: 100, flexGrow: 0}}>
                              {formatCurrency(item.cpi)}
                            </div>
                            <div className={classNames(rowClasses, 'justify-content-end')} style={{width: 150, flexGrow: 0}}>
                              {item.completed}
                            </div>
                            <div className={classNames(rowClasses, 'justify-content-end', 'grid-last-cell')} style={{width: 200, flexGrow: 0}}>
                              {formatCurrency(item.cpi * item.completed)}
                            </div>
                          </div>
                        );
                      });
                  })
              }
              {
                partnerCompletes && (
                  <div className="d-flex flex-row flex-nowrap grid-row">
                    <div className={classNames(rowClasses, 'justify-content-end')} style={{width: 650}}>
                      <b>Total</b>
                    </div>
                    <div className={classNames(rowClasses, 'justify-content-end')} style={{width: 150, flexGrow: 0}}>
                      <b>{partnerCompletes}</b>
                    </div>
                    <div className={classNames(rowClasses, 'justify-content-end', 'grid-last-cell')} style={{width: 200, flexGrow: 0}}>
                      <b>{formatCurrency(partnerTotal)}</b>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        );
      });
    
  }

  getJobSummary() {
    this.jobSummary = 0;
    const headers = ['Project', 'Class', 'QuickBooks', 'Invoice #', 'Due Date', 'CPI', 'Completes', 'Total'];
    const width = [300, 50, 100, 100, 100, 100, 150, 200];
    const classes = 'grid-cell d-flex align-content-center align-items-center';
    return (
      <CollapseCard className="emi-collapse-card job-summary" title="Job Summary">
        <div className="emi-grid">
          <div className="card">
            <div className="collapse show">
              <div className="card-body">
                <div>
                  <div className="d-flex flex-row flex-nowrap grid-row header">
                    {
                      headers.map((title, idx) => (
                        <div
                          key={`js_${idx}`}
                          className={classNames(
                            'grid-cell d-flex cursor-pointer',
                            {
                              'grid-last-cell': idx === 7,
                              'justify-content-end': [5, 6, 7].includes(idx),
                            }
                          )}
                          style={{width: width[idx], flexGrow: [5, 6, 7].includes(idx) ? 0 : 1, marginRight: idx === 7 ? 18 : 0, paddingRight: idx === 7 ? 0 : 'auto'}}
                        >
                          <div className="head-cell">{title}</div>
                        </div>
                      ))
                    }
                  </div>
                </div>
                {this.getJobSummaryRows()}
                {
                  this.jobSummary > 0
                    ? (
                      <div className="d-flex flex-row flex-nowrap grid-row content rounded grid-sum-row">
                        <div className={classes}>&nbsp;</div>
                        <div className={classes}>&nbsp;</div>
                        <div className={classes}>&nbsp;</div>
                        <div className={classes}>&nbsp;</div>
                        <div className={classes}>&nbsp;</div>
                        <div className={classNames(classes, 'justify-content-end', 'grid-last-cell')}>
                          {formatCurrency(this.jobSummary)}
                        </div>
                      </div>
                    )
                    : null
                }
              </div>
            </div>
          </div>
        </div>
      </CollapseCard>
    );
  }

  getInvoiceSummary() {
    const style = { borderTop: 0 };
    return (
      <div className="d-flex flex-row-reverse">
        <Card inverse color="secondary" className="invoice-project-card">
          <CardHeader>
            <span className="project-title"><b>Invoice Summary</b></span>
          </CardHeader>
          <CardBody>
          <table className="table table-sm" style={{ width: 250}}>
            <tbody>
              <tr>
                <td style={style}>Invoice Total</td>
                <td style={style} align="right">{formatCurrency(this.projectTotal)}</td>
              </tr>
              <tr>
                <td style={style}>Prepaid</td>
                <td style={style} align="right">{formatCurrency(this.prepaid)}</td>
              </tr>
              <tr>
                <td>Amount Due</td>
                <td align="right">{formatCurrency(this.projectTotal - this.prepaid)}</td>
              </tr>
            </tbody>
          </table>
          </CardBody>
        </Card>
      </div>
    );
  }

  getInvoiceButtons() {
    return (
      <React.Fragment>
        <Button
          color="warning"
          type="button"
          onClick={() => this.downloadInvoiceXml()}
        >
          <i className="fa fa-file-code-o"></i>
        </Button>
        <Button
          color="info"
          type="button"
          onClick={() => this.downloadJobSummary()}
        >
          <i className="fa fa-credit-card"></i>
        </Button>
        <Button
          color="info"
          type="button"
          onClick={() => this.downloadInvoice()}
        >
          <i className="fa fa-file-pdf-o"></i>
        </Button>

        <Button 
          type="button" className="project-button"
          color="default"
          onClick={() => this.context.history.replace(`/multi/${this.state.values.id}`)}
        >
          Multi-View
        </Button>

        <Button 
          type="button" className="project-button"
          color="success"
          data-id={this.getId()}
          data-toggle="modal"
          data-target="#invoice-reminder"
          onClick={emptyFn}
        >
          {this.state.values.finalized ? 'Re-' : ''}Finalize Invoice
        </Button>
        {this.state.values.quickbooks_error &&
          <Button
            type="button" className="project-button"
            color="danger"
            onClick={() => this.resyncInvoice()}
          >
            Re-sync to QuickBooks
          </Button>
        }
        {this.showResolveButton() &&
          <Button 
            type="button"
            color="warning"
            onClick={() => this.resolveInvoice()}
          >
            <i className="fa fa-compress"></i>{' '}Resolve
          </Button>
        }
      </React.Fragment>
    );
  }

  getFormHeader() {
    return (
      <React.Fragment>
        {super.getFormHeader()}
        <ReminderModal id="invoice-reminder" updateFn={this.finalizeInvoice} />
        <Mobile>
          <div className="mt-1 d-flex justify-content-around">
            {this.getInvoiceButtons()}
          </div>
        </Mobile>
      </React.Fragment>
    );
  }

  getHeaderButtons() {
    return (
      <Desktop>
        <span className="action-buttons float-right">
          {this.getInvoiceButtons()}
        </span>
      </Desktop>
    );
  }

  getQuickBooksSyncGrid() {
    return (
      <QuickbooksGrid extraParams={{ invoice_id: this.getId() }} storeState={false} />
    );
  }

  saveState() {
    this.setState((prevState) => {
      if (
        prevState.jobSummary !== this.jobSummary ||
        prevState.projectTotal !== this.projectTotal
      ) {
        return {
          jobSummary: this.jobSummary,
          projectTotal: this.projectTotal
        };
      }
      return null;
    });
    return null;
  }

  calculateGrossMargin() {
    return this.state.projectTotal ? 
      (this.state.projectTotal - this.state.jobSummary) / this.state.projectTotal : 0;
  }

  getFormContent() {
    return (
      <div>
        <CollapseCard title="Invoice settings">
          <div className="form-row">
            {this.getFormRow({
              label: 'client',
              name: 'client_id',
              component: ClientSelect,
              onChange: this.selectChangeHandler('client_id'),
              required: true,
              fieldWidth: { xs: 12, xl: 4 }
            })}

            <FormDisabledRow
              label="IO#"
              name="io_number"
              id="io_number"
              value={this.state.values.io_number}
              fieldWidth={{ xs: 12, sm: 5 }}
            />

            {this.getFormRow({
              label: 'Start Date',
              name: 'start_date',
              type: 'date',
              fieldWidth: { xs: 12, sm: 3 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              label: 'project contact',
              name: 'contact_id',
              component: ContactSelect,
              client: this.state.values.client_id,
              type: 'project',
              onChange: this.selectChangeHandler('contact_id'),
              fieldWidth: { xs: 12, xl: 4 }
            })}

            {this.getFormRow({
              label: 'accounting contact',
              name: 'accounting_id',
              component: AccountingContactSelect,
              client: this.state.values.client_id,
              type: 'accounting',
              onChange: this.selectChangeHandler('accounting_id'),
              fieldWidth: { xs: 12, xl: 5 }
            })}

            {this.getFormRow({
              label: 'Close Date',
              name: 'close_date',
              type: 'date',
              fieldWidth: { xs: 12, sm: 3 }
            })}
          </div>

          <div className="form-row">
            {this.getFormRow({
              label: 'Customer PO#',
              name: 'po_number',
              fieldWidth: { xs: 12, xl: 4 }
            })}

            {this.getFormRow({
              label: 'Customer Job#',
              name: 'job_number',
              fieldWidth: { xs: 12, xl: 5 }
            })}

            <FormDisabledRow
              label="Job Type"
              name="job_type"
              id="job_type"
              value={this.state.values.job_type}
              fieldWidth={{ xs: 12, sm: 3 }}
            />
          </div>

          <div className="form-row">
            {this.getFormRow({
              label: 'Misc',
              name: 'misc',
              fieldWidth: { xs: 12, xl: 4 }
            })}

            <FormDisabledRow
              label="status"
              name="status"
              component={StatusField}
              value={getInvoiceStatus(this.state.values.status)}
              fieldWidth={{ xs: 12, xl: 5 }}
            />

            <FormDisabledRow
              label="Class"
              name="class"
              id="class"
              value={this.state.values.qclass}
              fieldWidth={{ xs: 12, sm: 3 }}
            />
          </div>

          <div className="form-row">
            <FormDisabledRow
              label="Revenue"
              name="revenue"
              id="revenue"
              value={formatCurrency(this.state.projectTotal)}
              fieldWidth={{ xs: 12, sm: 4 }}
            />

            <FormDisabledRow
              label="Cost"
              name="cost"
              id="cost"
              value={formatCurrency(this.state.jobSummary)}
              fieldWidth={{ xs: 12, sm: 5 }}
            />

            <FormDisabledRow
              label="Gross Margin"
              name="gross_margin"
              id="gross_margin"
              value={Number(this.calculateGrossMargin()).toLocaleString('en-US', { style: 'percent' })}
              fieldWidth={{ xs: 12, sm: 3 }}
            />
          </div>
        </CollapseCard>

        {this.getQuickBooksSyncGrid()}

        {this.getProjects()}

        {this.getJobSummary()}

        {this.getInvoiceSummary()}

        {this.saveState()}
      </div>
    );
  }
}

export default InvoiceForm;
