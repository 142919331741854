import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { client, AlertMessage } from 'emi-frontend';
import { HubspotSearch } from './HubspotSearch';
import SyncData from './SyncData';
import SyncListData from './SyncListData';
import SyncClientData from './SyncClientData';
import SyncClientTerm from './SyncClientTerm';
import SyncClientCse from './SyncClientCse';
import { jobType } from '../client/constants';


const contactTypeOptions = {
  project: 'Project',
  accounting: 'Accounting'
};

const clientProspectOptions = {
  true: 'Yes',
  false: 'No'
};

export function HubspotSyncModal({ isOpen, toggle, model, modelId, create = false, onChange }) {
  const [state, setState] = useState({
    msgColor: 'danger',
    message: '',
    data: {},
    hubspot: {},
    selected: []
  });

  useEffect(() => {
    if (modelId) {
      client.api('GET', `/hubspot/${model}/${modelId}`)
        .then(resp => setState({ ...state, ...resp }))
        .fail(showError);
    }
  }, [model, modelId]);

  function updateProp(prop, value) {
    setState({ ...state, [prop]: value });
  }

  function onObjectSelect(obj) {
    updateProp('hubspot', obj);
  }

  function onSelectedChange(event) {
    const { value, checked } = event.target;
    updateProp('selected', checked ? [...state.selected, value] : state.selected.filter(v => v !== value));
  }

  function onModalClose() {
    setState({ ...state, selected: [], message: '' });
  }

  function removeMessage() {
    setState({ ...state, message: '', msgColor: 'danger' });
  }

  function showMessage(message, msgColor = 'danger') {
    setState({ ...state, message, msgColor });
  }

  function showError(error) {
    let message = error.responseText || error.statusText;
    if (error.responseJSON) {
      if (error.responseJSON.message) {
        message = error.responseJSON.message;
      }
      if (error.responseJSON.errors) {
        message = Object.values(error.responseJSON.errors).join(' ');
      }
    }

    showMessage(message);
  }

  function onFormSubmit(event) {
    event.preventDefault();
    const { hubspot_id } = state.hubspot;
    const data = Object.fromEntries(
      Object.entries(state.hubspot).filter(([key]) => state.selected.includes(key))
    );

    client.api(create ? 'POST' : 'PUT', `/hubspot/${model}/${create ? '' : modelId}`, { ...data, hubspot_id })
      .then(({ data }) => {
        setState({
          ...state, data,
          message: 'Data successfully synchronized',
          msgColor: 'warning'
        });

        onChange();
      })
      .fail(showError);
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" onClosed={onModalClose}>
       <form onSubmit={onFormSubmit}>
        <ModalHeader toggle={toggle}>
          HubSpot <span className="text-capitalize">{model}</span> synchronization
        </ModalHeader>
        <ModalBody>
          <div className="form-group row">
            <div className="col text-center">
              <button type="button" className="btn btn-outline-warning disabled" disabled>
                {create ? 'Create' : 'Update'}
              </button>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="objectId" className="col-sm-2 col-form-label font-weight-bold text-uppercase">Search</label>
            <div className="col-sm-10">
              <HubspotSearch model={model} onSelect={onObjectSelect} onError={showError} />
            </div>
          </div>

          {Object.keys(state.hubspot) > 0 &&
            <div className="alert alert-primary" role="alert">
              Select data you want to synchronize
            </div>
          }

          <AlertMessage message={state.message} color={state.msgColor} closeHandler={removeMessage} />

          {model === 'user' &&
            <>
              <SyncData state={state} field="name" create={create} onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncData state={state} field="email" create={create} onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncData state={state} field="initials" label="Class" create={create} onSelect={onSelectedChange} onEdit={updateProp} />
            </>
          }

          {model === 'partner' &&
            <>
              <SyncData state={state} field="name" onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncData state={state} field="project_minimum" label="Minimum fee" create={create} onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncData state={state} field="sample_type" label="Sample type" create={create} onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncData state={state} field="sample_country" label="Sample country" create={create} onSelect={onSelectedChange} onEdit={updateProp} />
            </>
          }

          {model === 'client' &&
            <>
              <SyncData state={state} field="name" create={create} label="Company name" onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncListData state={state} field="prospect" options={clientProspectOptions} create={create} onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncListData state={state} field="job_type" label="Job Type" options={jobType} create={create} onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncClientTerm state={state} field="term" create={create} onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncClientCse state={state} field="cse_id" create={create} label="CSE" onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncData state={state} field="phone" create={create} onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncData state={state} field="country" create={create} onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncData state={state} field="city" create={create} onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncData state={state} field="zip" create={create} onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncData state={state} field="state" create={create} onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncData state={state} field="address" create={create} onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncData state={state} field="notes" create={create} onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncData state={state} field="annual_revenue" label="Annual revenue" create={create} onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncData state={state} field="sample_spend" label="Sample spend" create={create} onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncData state={state} field="annual_revenue_goal" label="Annual revenue goal" create={create} onSelect={onSelectedChange} onEdit={updateProp} />
            </>
          }

          {model === 'contact' &&
            <>
              <SyncData state={state} field="first_name" create={create} label="First name" onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncData state={state} field="last_name" create={create} label="Last name" onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncData state={state} field="email" create={create} label="Email" onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncListData state={state} field="contact_type" options={contactTypeOptions} create={create} label="Type" onSelect={onSelectedChange} onEdit={updateProp} />
              <SyncClientData state={state} field="client_id" create={create} label="Company" onSelect={onSelectedChange} onEdit={updateProp} />
            </>
          }
          
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button color="primary" type="submit" disabled={state.selected.length === 0}>
          <i className="fa fa-save mr-2" />
            Sync
          </Button>{' '}
          <Button color="secondary" type="button" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
