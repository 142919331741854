import { Component } from 'react';
import PropTypes from 'prop-types';
import { AbstractForm } from 'emi-frontend';

export default class FieldsGroup extends Component {
  constructor(props) {
    super(props);

    this.state = Object.assign({}, props.values);
  }

  static propTypes = {
    idx: PropTypes.number,
    values: PropTypes.object,
    path: PropTypes.array.isRequired,
    questions: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    exists: PropTypes.bool.isRequired
  };

  emptyFn = () => {}

  resetValues() {
    return {};
  }

  isNew() {
    return this.props.idx === undefined;
  }

  getStateValues() {
    return Object.assign({}, this.state);
  }

  getValue(prop) {
    return this.isNew() ? this.state[prop] : this.props.values[prop];
  }

  addValues = () => {
    const values = this.getStateValues();
    this.setState(
      this.resetValues(),
      () => this.props.onChange(this.props.path, 'push', values)
    );
  }

  deleteValues = () => {
    this.props.onChange(this.props.path, 'splice', this.props.idx, 1);
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    if (this.isNew()) {
      this.setState({ [name]: value });
    } else {
      this.props.onChange(this.props.path.concat(this.props.idx), name, value);
    }
  }

  modifyValues = (path, prop, ...args) => {
    const values = AbstractForm.cloneData(this.state);
    let target = AbstractForm.findTarget(values, path);

    if (typeof target[prop] === 'function') {
      target[prop](...args);
    } else {
      target[prop] = args.shift();
    }

    this.setState(values);
  }
}
