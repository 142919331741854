import React, { Component } from 'react';
import ConnectorUserForm from './ConnectorUserForm';
import ContactForm from './ContactForm';

export default class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      connectorUserId: 0
    };
  }
  contactLoaded(data) {
    this.setState({ connectorUserId: data.connector_user_id });
  }

  getConnectorUserForm() {
    if (this.state.connectorUserId) {
      return <ConnectorUserForm key="contact_user_form" {...this.props} id={this.state.connectorUserId} />;
    }

    return null;
  }

  render() {
    return [
      <ContactForm key="contact_form" {...this.props} onLoadDone={(data) => this.contactLoaded(data)} />,
      this.getConnectorUserForm()
    ];
  }
}