import React from 'react';
import { getInvoiceStatus } from './utils';
import { GridMobileRow, formatDate, formatCurrency } from 'emi-frontend';

export default class Row extends GridMobileRow {

  getProjectCounts() {
    return (
      <div className="d-flex flex-row justify-content-between">
        <div style={{ flex: 1 }}>{formatDate(this.props.row.invoice_date)}</div>
        <div style={{ flex: 1, textAlign: 'center' }}>{formatCurrency(this.props.row.invoice_amount)}</div>
        <div style={{ flex: 1, textAlign: 'right' }}>{this.statusRenderer()}</div>
      </div>
    );
  }

  statusRenderer() {
    return getInvoiceStatus(this.props.row.status);
  }

  getMainContent() {
    return (
      <div className="d-flex flex-column grid-cell mobile-cell">
        <div className="d-flex flex-row">
          <div className="grid-cell name">IO# {this.props.row.io_number}</div>
          <div className="grid-cell d-flex justify-content-end">
            <div className="gray">{this.props.row.client_name}</div>
          </div>
        </div>
        <div className="d-flex flex-row">
          <div className="grid-cell">{this.getProjectCounts()}</div>
        </div>
      </div>
    );
  }

  getData() {
    return (
      <div key={`cont${this.props.row.id}`} className="grid-row-cell d-flex">
        {this.getMainContent()}
      </div>
    );
  }
}
