import React from 'react';
import { Button, ListGroup, CardFooter } from 'reactstrap';
import { FormDisabledRow, ButtonSelect } from 'emi-frontend';
import TargetCriteria from './TargetCriteria';
import { AbstractQuota } from '../abstract';

export default class Quota extends AbstractQuota {

  static defaultProps = {
    values: {},
    feasibility: false
  };

  getDefaultValues() {
    return {
      name: '',
      campaign_id: 0,
      num_respondents: 1,
      is_click_balanced: false,
      campaign_qualifications: []
    };
  }

  resetValues() {
    return {
      name: '',
      num_respondents: 1,
      is_click_balanced: false,
      campaign_qualifications: []
    };
  }

  getId = () => {
    return this.parseInt(this.state.values.id);
  }

  getTargetingCriteria() {
    const campaign_qualifications = this.state.values.campaign_qualifications.slice();

    return {
      campaign_qualifications
    };
  }

  getDeleteParams() {
    const { campaign_id, id } = this.state.values;

    return { campaign_id, id };
  }

  showAddQuota() {
    return this.state.values.id === undefined &&
      Array.isArray(this.state.values.campaign_qualifications) &&
      this.state.values.campaign_qualifications.length > 0;
  }

  showRemoveQuota() {
    return this.state.values.id !== undefined;
  }

  addQuota = () => {
    const { num_respondents, campaign_qualifications, is_click_balanced } = this.state.values;

    this.props.addQuota({
      num_respondents, campaign_qualifications, is_click_balanced
    });

    this.updateValues(this.resetValues());
  }

  setStateValues(state) {
    this.setState(state, () => {
      if (this.props.feasibility && this.state.values.id !== undefined) {
        const { num_respondents, campaign_qualifications, is_click_balanced } = this.state.values;

        this.props.updateQuota(this.state.values.id, {
          num_respondents, campaign_qualifications, is_click_balanced
        });
      }
    });
  }

  deleteQuota = () => {
    this.props.deleteQuota(this.state.values.id);
  }

  getActionButtons() {
    if (this.props.feasibility) {
      return (
        <CardFooter className="action-buttons d-none d-lg-block">
          {this.showAddQuota() &&
            <Button color="default" onClick={this.addQuota}>
              <i className="fa fa-plus" aria-hidden="true"></i>{' '}
              <span className="text-capitalize">add quota</span>
            </Button>
          }
          {this.showRemoveQuota() &&
            <Button color="default" onClick={this.deleteQuota}>
              <i className="fa fa-minus" aria-hidden="true"></i>{' '}
              <span className="text-capitalize">remove quota</span>
            </Button>
          }
        </CardFooter>
      );
    }

    return super.getActionButtons();
  }

  showEstimatedCompletes() {
    return this.props.feasibility && this.props.values.estimated_completes !== undefined;
  }

  getFormContent() {
    const props = {
      questions: this.props.questions,
      partnerId: this.props.partnerId,
      countryId: this.props.countryId,
      onAdd: this.addTargetCriteria,
      onDelete: this.removeTargetCriteria,
      onChange: this.modifyTargetCriteria
    };

    return (
      <div>
        <div className="form-row">
          {this.getFormRow({
            name: 'name',
            required: true,
            fieldWidth: { xs: 12 },
            labelWidth: { xs: 12, lg: 3 },
            valueWidth: { xs: 12, lg: 9 }
          })}
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'quota',
            name: 'num_respondents',
            type: 'number',
            required: true,
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 4 }
          })}

          {this.showEstimatedCompletes() &&
            <FormDisabledRow
              label="estimated completes"
              name="estimated_completes"
              value={this.props.values.estimated_completes}
              fieldWidth={{ xs: 12, sm: 6 }}
              labelWidth={{ xs: 12, sm: 6 }}
              valueWidth={{ xs: 12, sm: 6 }}
            />
          }
        </div>

        <div className="form-row">
          {this.getFormRow({
            label: 'click balance',
            name: 'is_click_balanced',
            component: ButtonSelect,
            options: { true: 'Yes', false: 'No' },
            fieldWidth: { xs: 12, sm: 6 },
            labelWidth: { xs: 12, sm: 6 },
            valueWidth: { xs: 12, sm: 6 }
          })}
        </div>
        
        <div className="form-row">
          <ListGroup style={{ width: '100%' }}>
            <TargetCriteria
              {...props}
              allowCreate
            />
            {this.state.values.campaign_qualifications.map((criteria, idx) =>
              <TargetCriteria
                {...props}
                idx={idx}
                key={criteria.question_id}
                criteria={criteria}
                isDeletable
              />
            )}
          </ListGroup>
        </div>
      </div>
    );
  }
}