import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { client } from 'emi-frontend';

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below). 
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value] 
  );

  return debouncedValue;
}

export function HubspotSearch({ model, onSelect, onError }) {
  const [loading, setLoading] = useState(false);
  const [dropdownFocus, setDropdownFocus] = useState(false);
  const [menuFocus, setMenuFocus] = useState(false);
  const [search, setSearch] = useState('');
  const [results, setResults] = useState([]);
  const query = useDebounce(search, 600);

  useEffect(() => {
    if (!query) {
      setResults([]);
    } else {
      setLoading(true);
      client.api('GET', `/hubspot/${model}/search`, { query })
        .then(resp => setResults(resp.data))
        .fail(onError)
        .always(() => setLoading(false));
    }
  }, [query]);

  function toggleDropdownFocus() {
    setDropdownFocus(!dropdownFocus);
  }

  function toggleMenuFocus() {
    setMenuFocus(!menuFocus);
  }

  return (
    <div className={classNames('dropdown', { show: dropdownFocus || menuFocus })}>
      <div className="input-group">
        <input
          type="text"
          name="objectId"
          className="form-control"
          placeholder="Enter text to search"
          aria-label="HubSpot Search"
          aria-describedby="load-button"
          onFocus={toggleDropdownFocus}
          onBlur={toggleDropdownFocus}
          value={search}
          onChange={e => setSearch(e.target.value)}
          autoComplete="one-time-code"
        />
        {loading &&
        <div className="input-group-append">
          <button
            className="btn btn-outline-secondary"
            type="button"
            id="load-button"
            disabled
          >
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          </button>
        </div>
        }
      </div>
      <div
        className={classNames('dropdown-menu w-100', { show: (dropdownFocus || menuFocus) && results.length })}
        onMouseEnter={toggleMenuFocus}
        onMouseLeave={toggleMenuFocus}
      >
        {results.map(obj =>
          <button key={obj.hubspot_id} className="dropdown-item" type="button" onClick={() => onSelect(obj)}>
            {obj.name}
          </button>
        )}
      </div>
    </div>
  );
}
