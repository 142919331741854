import FileSaver from 'file-saver';
import { Spinner, Table, Alert } from 'reactstrap';

function strToArrayBuffer(str) {
  const buf = new ArrayBuffer(str.length);
  const view = new Uint8Array(buf);
  for (let i=0; i !== str.length; ++i) {
    view[i] = str.charCodeAt(i) & 0xFF;
  }

  return buf;
}

export default function PanelApiLogResult({ entries, isLoading }) {

  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner color="secondary" />
      </div>
    );
  }

  function saveAs(type, data) {
    FileSaver.saveAs(
      new Blob([strToArrayBuffer(JSON.stringify(data))], { type: 'application/json' }),
      `${type}.json`
    );
  }

  return entries.length === 0 ? (
    <Alert color="warning">No entries</Alert>
  ) : (
    <Table size="sm" hover> 
      <thead>
        <tr>
          <th>timestamp</th>
          <th>type</th>
          <th>API call</th>
          <th>callId</th>
        </tr>
      </thead>
      <tbody>
        {entries.map(({ timestamp, data }) =>
          <tr className="cursor-pointer" key={timestamp} onClick={() => saveAs(data.type, data)}>
            <th scope="row">{timestamp}</th>
            <td>{data.type}</td>
            <td>{data.functionName}</td>
            <td>{data.callId}</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
