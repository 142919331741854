import React, { Component } from 'react';
import $ from 'jquery';
import 'bootstrap/js/dist/tooltip';

export default class FilePaginationItem extends Component {

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  render() {
    return (
      <li
        className="page-item" data-toggle="tooltip"
        data-placement="bottom" title={this.props.tooltip}
        onClick={this.props.onClick}
      >
        <span className="page-link" >
          <i className={`fa fa-${this.props.faIcon}`}></i>
        </span>
      </li>
    );
  }
}
