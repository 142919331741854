import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import panelApi from '../panel_api';

const apiTitleMap = {
  marketcube: 'sago'
};

export default class PanelApiModal extends Component {

  static propTypes = {
    toggle: PropTypes.func,
    isOpen: PropTypes.bool,
    panelApi: PropTypes.string,
    routeId: PropTypes.number,
    partnerId: PropTypes.number,
    projectId: PropTypes.number,
    routeQuota: PropTypes.number,
    ioNumber: PropTypes.string
  };

  getPanelApiComponent() {
    return this.props.panelApi && panelApi[this.props.panelApi] ? panelApi[this.props.panelApi] : undefined;
  }

  render() {
    const { isOpen, toggle, panelApi, ...props } = this.props;
    const PanelApiComponent = this.getPanelApiComponent();
    const hasPanelApiComponent = PanelApiComponent !== undefined;

    return (
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          {hasPanelApiComponent &&
            <span className="text-uppercase">Panel API: {apiTitleMap[panelApi] || panelApi}</span>
          }
        </ModalHeader>
        <ModalBody>
          {hasPanelApiComponent ? (
            <PanelApiComponent {...props} />
          ) : (
            <Alert color="danger">Invalid Panel API</Alert>
          )}
        </ModalBody>
      </Modal>
    );
  }
}
