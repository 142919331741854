import React from 'react';
import { 
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import AttributeTargetField from './AttributeTargetField';
import { AbstractForm, Grid} from 'emi-frontend';

export default class AttributeValueSettingGrid extends Grid {

  get gridName() {
    return 'projectAttributeValueGrid';
  }

  getApiPath = () => {
    return '/project_attribute_value';
  }

  getBasePath = () => {
    return '/jobs';
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.extraParams.project_attribute_id &&
      AbstractForm.valuesDidChange(this.state.extraParams, nextProps.extraParams)) {

      this.setExtraParams(nextProps.extraParams);
    }
  }

  updateTarget(row, target) {
    this.clientApi('PUT', `${this.getApiPath()}/${row.id}`, { target })
      .done(() => this.updateRowData(Object.assign({}, row, { target })))
      .fail(error => this.showError(error));
  }

  isRowSelectable = () => {
    return false;
  }

  statusCell({ row }) {
    const status = row.count && row.target ? (row.count / row.target * 100) : 0.00;
    return <div className="cell">{status.toFixed(2)}{' %'}</div>;
  }

  targetCell = ({ data, row }) => {
    return (
      <div className="cell">
        <AttributeTargetField
          value={data || ''}
          onChange={(value) => this.updateTarget(row, value)}
        />
      </div>
    );
  }

  getColumns() {
    return [{
      name: 'seq',
      title: 'seq',
      sortable: true,
      asc: true,
      width: 50,
      exactWidth: true
    }, {
      name: 'attribute_value',
      title: 'value',
      sortable: false,
      width: 250
    }, {
      name: 'target',
      title: 'target',
      renderer: this.targetCell,
      sortable: false,
      width: 100,
      exactWidth: true
    }, {
      name: 'count',
      title: 'Starts',
      sortable: false,
      width: 120,
      exactWidth: true,
      align: 'center'
    }, {
      name: 'status',
      title: 'status %',
      sortable: false,
      width: 100,
      exactWidth: true,
      align: 'center',
      renderer: this.statusCell
    }];
  }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle}>{this.props.modalTitle}</ModalHeader>
        <ModalBody>
          {super.render()}
        </ModalBody>
      </Modal>
    );
  }
}
